<template>
    <div class="form-group okraina-control-location" :class="{
        'form-group-error': error
    }">
        <okraina-control-select v-for="(level, index) in internalLevels" :key="index" :label="index == 0 ? label : ''"
            :required="required" :list="level" v-model="values[index].ID" v-model:modelName="values[index].NAME"
            :name="name + '_' + index" @select="onSelect" />

        <div class="overlay" v-if="loading">
            <div class="loader">
                <okraina-loader-inline />
            </div>
        </div>
    </div>
</template>

<script>
import okrainaControlSelect from "@/components/controls/select.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "okraina-control-location",
    components: {
        okrainaControlSelect,
        okrainaLoaderInline
    },
    data() {
        return {
            value: this.modelValue,
            values: [],
            internalLevels: this.levels,
            loading: false
        };
    },
    emits: ["update:modelValue", "update:valid", "update:error"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        name: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
        levels: {
            type: Array,
            default() {
                return []
            }
        },
        order: {
            type: Object,
            default() {
                return {};
            },
        },
        fieldName: {
            type: String,
            default() {
                return "";
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        placeholder: {
            type: String,
            default() {
                return "";
            },
        },
        classes: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        levels: function () {
            this.internalLevels = this.levels;
        },
        internalLevels: function () {
            this.setValues();
            this.checkValid();
        },
        value: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },
    methods: {

        /**
         * Устанавливает по уровням выбранные значения
         */
        setValues() {
            this.values = [];
            this.internalLevels.forEach((level) => {
                let selected = level.find(i => i.SELECTED == 'Y');
                if (selected) {
                    this.values.push({ ID: selected.ID, NAME: selected.NAME });
                } else {
                    this.values.push({ ID: "", NAME: "" });
                }
            });
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            let selected = false;
            this.internalLevels.forEach((level) => {
                selected = level.find(i => i.ID == this.value);

            });

            this.$emit("update:valid", selected ? true : false);
        },

        /**
         * Обработка изменения
         */
        onSelect(valId) {
            if (valId) {
                this.value = valId;
                this.$emit("update:modelValue", this.value);
                this.reloadLevels();
            }
        },

        reloadLevels() {

            this.loading = true;

            rest
                .call("order.prepare", {
                    method: "post",
                    data: this.getReloadParams(),
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        let prop = data.PROPS.find(p => p.FIELD_NAME == this.fieldName);
                        if (prop) {
                            this.internalLevels = prop.LOCATIONS_LEVELS;
                        }

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        getReloadParams() {
            //избавляемся от реактивности
            let order = JSON.parse(JSON.stringify(this.order));
            let prepareParams = {};

            if (Object.keys(order.fields).length > 0) {
                prepareParams.FIELDS = order.fields;
            }
            if (Object.keys(order.props).length > 0) {
                prepareParams.PROPS = order.props;
                prepareParams.PROPS[this.fieldName] = this.value;
            }

            return prepareParams;
        }
    },

    mounted() {

        this.checkValid();
    },

    created() {
        this.setValues();
    }
}

</script>

<style lang="scss">
.okraina-control-location {


    .overlay {
        position: absolute;
        top: 0;
        right: -5px;
        bottom: -5px;
        left: -5px;
        backdrop-filter: blur(5px);
        background-color: rgba(var(--color-color1-rgb), .2);


        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
