<template>
    <okraina-view :class="'okraina-v-ac'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Контакты
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && items.length > 0 && canShow">
                <div class="row">
                    <template v-for="(item, index) in sortedItems" :key="index">
                        <template v-if="item.TYPE == 'contacts.block.phone'">
                            <okraina-about-contacts-block-phone :params="item.PARAMS" />
                        </template>
                        <template v-else-if="item.TYPE == 'contacts.block.email'">
                            <okraina-about-contacts-block-email :params="item.PARAMS" />
                        </template>
                        <template v-else-if="item.TYPE == 'contacts.block.map'">
                            <okraina-about-contacts-block-map :params="item.PARAMS" />
                        </template>
                        <template v-else-if="item.TYPE == 'contacts.block.requisites'">
                            <okraina-about-contacts-block-requisites :params="item.PARAMS" />
                        </template>
                        <template v-else-if="item.TYPE == 'contacts.block'">
                            <okraina-about-contacts-block :params="item.PARAMS" />
                        </template>
                    </template>

                </div>

                <div class="btn-wrap text-center">
                    <div class="btn btn1" v-ripple @click="onFeedbackClick">Задать вопрос
                    </div>
                </div>
            </template>

            <okraina-sheet v-model:visible="showFeedback" :maxHeight="90">
                <template v-slot:body>
                    <okraina-web-form :id="webFormId" :sheetMode="true"
                        :profileFields="{ NAME: 'NAME', EMAIL: 'EMAIL' }" />
                </template>
            </okraina-sheet>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaAboutContactsBlockPhone from '@/components/about/contacts.block.phone.vue'
import okrainaAboutContactsBlockEmail from '@/components/about/contacts.block.email.vue'
import okrainaAboutContactsBlockMap from '@/components/about/contacts.block.map.vue'
import okrainaAboutContactsBlockRequisites from '@/components/about/contacts.block.requisites.vue'
import okrainaAboutContactsBlock from '@/components/about/contacts.block.vue'
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.contacts.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-about-contacts",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaAboutContactsBlockPhone,
        okrainaAboutContactsBlockEmail,
        okrainaAboutContactsBlockMap,
        okrainaAboutContactsBlockRequisites,
        okrainaAboutContactsBlock,
        okrainaSheet,
        okrainaWebForm
    },
    data() {
        return {
            loading: true,
            items: [],
            showFeedback: false,
            canShow: false
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        sortedItems() {
            let items = this.items.filter(i => i.TYPE != 'contacts.block.map');

            let itemMap = this.items.find(i => i.TYPE == 'contacts.block.map');
            if (itemMap) {
                items.push(itemMap);
            }

            return items;
        },

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_FEEDBACK : "";
        }
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("company.contacts", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Клик по кнопке "Обратная связь"
         */
        onFeedbackClick() {
            this.showFeedback = true;
        }

    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },

    created() {
        this.loadData();
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-ac {

    .item {
        margin-bottom: 12px;
    }

    .okraina-block {
        position: relative;
        padding-bottom: 50px;
        margin-bottom: 0px;

        &.double {
            padding-bottom: 80px;
        }

        .value {
            position: absolute;
            bottom: 15px;
            left: 23px;
            right: 23px;

            font-size: 18px;
            font-weight: 500;
            color: var(--color-color1);
            border-bottom: 0px;

            a {
                border-bottom: 0px;
            }
        }
    }

    .btn-wrap {
        margin: 10px 0px;

        .btn {
            min-width: 270px;
        }
    }

    @media (min-width: 768px) {

        .okraina-block {
            min-height: 100%;
        }
    }

    @media (max-width: 767px) {


        .row {

            >div {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

}
</style>