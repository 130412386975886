const templates =  {
    "shop.okraina.ru": {
        title: "Поиск по составу: NAME",
        tags: [
        ]
    },
    "dostavka.okraina.ru": {
        title: "Поиск по составу: NAME",
        tags: [
            
        ]
    },
}

export default templates;