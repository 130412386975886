<template>
    <div class="okraina-auth-sms">

        <div v-if="step == 1" class="step-1">

            <div class="notes">
                Мы отправим Вам код для входа. Если Вы у нас в первый раз, то мы создадим для Вас аккаунт.
            </div>
            <br />

            <okraina-control-input label="Телефон" :required="true" :type="'text'" v-model="phone"
                v-model:error="errors.phone" v-model:valid="valids.phone" :mask="maskPhone" />

            <okraina-control-input v-if="useCaptcha" v-model="captchaWord" type="text" label="Слово на картинке"
                :captcha="captchaURL" :required="true" v-model:error="errors.captcha" v-model:valid="valids.captcha" />


            <div class="btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple @click="onGetCodeBtnClick">
                Получить код
                <div v-if="loading" class="btn-loader"></div>
            </div>

            <div class="toggle-mode">
                <div class="a" @click="toggleMode">Войти по логину</div>
            </div>

        </div>

        <div v-else-if="step == 2" class="step-2">

            <div class="notes">
                <template v-if="mode == 'EMAIL'">
                    Код для входа отправлен на Ваш почтовый адрес {{ email }}. Код действителен в течении {{ expire }}-х
                    минут.
                </template>
                <template v-else>
                    Код для входа отправлен на Ваш номер телефона. Код действителен в течении {{ expire }}-х минут.
                </template>
            </div>
            <br />

            <div class="codes">

                <template v-for="index in [0, 1, 2, 3, 4]" :key="index">
                    <div class="text-center">
                        <okraina-control-input class="code" type="tel" label="" :required="true" v-model="code[index]"
                            v-model:error="errors.code[index]" v-model:valid="valids.code[index]" :mask="{ mask: '9' }"
                            :ref="setCodeRef" @input="(value) => { onCodeInput(index, value) }"
                            @paste="(e) => { onCodePaste(index, e) }" />
                    </div>
                </template>

            </div>

            <okraina-control-input v-if="useCaptcha" v-model="captchaWord" type="text" label="Слово на картинке"
                :captcha="captchaURL" :required="true" v-model:error="errors.captcha" v-model:valid="valids.captcha" />

            <div class="btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple @click="onAuthBtnclick">
                Войти
                <div v-if="loading" class="btn-loader"></div>
            </div>

            <template v-if="mode == 'EMAIL'">

                <div class="text-center">
                    Не пришло письмо с кодом?
                    <div class="a btn-get-sms" @click="onGetCodeSmsClick">
                        Получить код по СМС
                    </div>
                </div>

                <div class="step-back">
                    <div class="a" @click="onBackClick">попробовать с другим номером</div>
                </div>
            </template>

            <template v-else>

                <div class="btn btn-block btn3" :class="loading2 ? 'wait' : ''" v-if="canReceiveAgain" v-ripple
                    @click="onGetCodeBtnClick">
                    Получить повторно
                    <div v-if="loading2" class="btn-loader"></div>
                </div>

                <div class="step-back">
                    <div class="a" @click="onBackClick">попробовать с другим номером</div>
                </div>

                <div class="timer text-center" v-if="left > 0">
                    Повторная отправка кода возможна через {{ left }} сек.
                </div>

            </template>




        </div>


    </div>
</template>

<script>
import okrainaControlInput from "@/components/controls/input.vue";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";
export default {
    name: 'okraina-auth-sms',
    components: {
        okrainaControlInput,
    },
    emits: ['onSuccess', "update:mode"],
    data() {
        return {
            step: 1,
            phone: "",
            mode: "EMAIL",
            email: "",

            captchaWord: "",
            captchaSid: "",
            captchaURL: "",
            useCaptcha: false,

            loading: false,
            loading2: false,
            errors: {
                phone: false,
                captcha: false,
                code: [
                    false,
                    false,
                    false,
                    false,
                    false
                ]
            },
            valids: {
                phone: true,
                captcha: true,
                code: [
                    true,
                    true,
                    true,
                    true,
                    true
                ]
            },

            code: [
                "",
                "",
                "",
                "",
                ""
            ],
            codeRefs: [],

            expire: 0,
            left: 0,
            timer: 0,
            canReceiveAgain: false

        }
    },
    props: ['login'],
    computed: {

        ...mapGetters({
            isAuthorized: "isAuthorized",
            connection: "getConnection"
        }),

        maskPhone() {
            return tools.maskPhone();
        },

    },
    methods: {

        /**
         * клик по кнопке "получить код"
         */
        onGetCodeBtnClick() {

            if (!this.checkValuesStep1()) {
                return;
            }

            if (this.loading || this.loading2) {
                return;
            }
            this.loading = true;
            this.loading2 = true;

            //запрашивает автооризацию
            rest
                .call("auth.sms.code", {
                    method: "post",
                    data: {
                        PHONE: this.phone,
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid,
                        sessid: this.connection.session.bitrix_sessid,
                        MODE: this.mode
                    },
                })
                .then((data) => {
                    //если ошибка входа
                    if (!data.SUCCESS) {
                        this.step = 1;
                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.useCaptcha = true;
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaURL = data.CAPTCHA_URL;
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        //обработка успешной отправки СМС
                        this.onStep1Success(data);
                    }

                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading2 = false;
                });
        },

        /**
         * Клик по ссылке "Получить код по смс"
         */
        onGetCodeSmsClick() {
            this.mode = "SMS";
            this.left = 0;
            this.onGetCodeBtnClick();
        },

        /**
         * Проверяет значения
         */
        checkValuesStep1(showErrors = true) {
            let arFeilds = ["phone", "captcha"];

            arFeilds.forEach((field) => {

                let error = !this.valids[field];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[field] = error;
                }
            });

            for (var i in arFeilds) {
                let key = arFeilds[i];

                //если капча не требуется, то не проверяем её
                if (key == "captcha" && !this.useCaptcha) {
                    continue;
                }

                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * обработка завершения шага 1
         */
        onStep1Success(data) {

            this.useCaptcha = false;
            this.captchaSid = "";
            this.captchaWord = "";
            this.step = 2;

            this.code = ["", "", "", "", ""];
            this.valids.code = [true, true, true, true, true];
            this.errors.code = [false, false, false, false, false];
            let vm = this;
            setTimeout(() => {
                vm.codeRefs[0].$refs.input.focus();
            }, 10)

            this.expire = data.EXPIRE ? Math.round(parseInt(data.EXPIRE) / 60) : 3;
            this.mode = data.MODE ? data.MODE : "SMS";
            this.email = data.EMAIL ? data.EMAIL : "";

            let timeout = data.TIMEOUT ? parseInt(data.TIMEOUT) : 10;

            if (this.mode == "SMS") {
                this.startTimer(timeout);
                this.canReceiveAgain = false;
            } else {
                this.canReceiveAgain = true;
            }

        },

        /**
         * Запускает таймер обратного отсчёт до повторной отправки кода
         */
        startTimer(timeout) {

            this.left = timeout;
            let vm = this;
            this.timer = setInterval(function () {
                vm.updateTimeLeft();
            }, 1000);
        },

        updateTimeLeft() {
            this.left -= 1;

            if (this.left == 0) {
                this.allowReceiveAgain();
            }
        },

        /**
         * Разрешить получить код повторно
         */
        allowReceiveAgain() {
            if (this.timer > 0) {
                clearInterval(this.timer);
            }

            this.canReceiveAgain = true;
        },


        /**
         * Смена режима
         */
        toggleMode() {

            this.$emit("update:mode", "LOGIN");
        },

        /**
         * Запоминает ссылки на элементы сообщений
         */
        setCodeRef(el) {
            if (el) {
                this.codeRefs.push(el);
            }
        },

        /**
         * Обработка измнения в полях ввода кода
         */
        onCodeInput(index, value) {

            //если значение есть
            if (value) {
                //если поле не последнее
                if (index < this.code.length - 1) {
                    //перейдём к следующему полю
                    this.codeRefs[index + 1].$refs.input.focus();
                } else {
                    //клик по кнопке входа
                    this.onAuthBtnclick();
                }
            }
        },

        /**
         * Обработка вставки в поле ввода
         */
        onCodePaste(index, e) {

            if (index != 0) {
                return
            }

            var clipboardData, pastedData;
            e.preventDefault();
            // Get pasted data via clipboard API
            clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
            pastedData = clipboardData.getData('Text').replace(/<[^>]*>/g, "");

            //пробуем вставить код во все поля ввода
            var arList = pastedData.split("");
            let vm = this;
            setTimeout(function () {
                vm.code.forEach((v, index) => {
                    if (arList[index]) {
                        vm.code[index] = arList[index]
                    }
                })

            }, 1);

        },


        /**
         * Клик по кнопке "Войти"
         */
        onAuthBtnclick() {

            if (!this.checkValuesStep2()) {
                return;
            }

            if (this.loading) {
                return;
            }
            this.loading = true;

            //запрашивает автооризацию
            rest
                .call("auth.sms.auth", {
                    method: "post",
                    data: {
                        PHONE: this.phone,
                        CODE: this.code.join(""),
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid,
                        sessid: this.connection.session.bitrix_sessid,

                    },
                })
                .then((data) => {
                    //если ошибка 
                    if (!data.SUCCESS) {
                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.useCaptcha = true;
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaURL = data.CAPTCHA_URL;
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        //ничего делать не надо, так как view сам увидит, что пользователь уже авторизован
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Проверяет значения
         */
        checkValuesStep2(showErrors = true) {
            let arFeilds = ["code", "captcha"];

            arFeilds.forEach((field) => {

                //обычное поле
                if (!Array.isArray(this.valids[field])) {
                    let error = !this.valids[field];

                    //если нужно показать ошибки или если у поля нет ошибок
                    if (showErrors || !error) {
                        this.errors[field] = error;
                    }
                }
                //иначе массив
                else {
                    for (let i in this.valids[field]) {
                        let error = !this.valids[field][i];

                        //если нужно показать ошибки или если у поля нет ошибок
                        if (showErrors || !error) {
                            this.errors[field][i] = error;
                        }
                    }
                }

            });

            for (var i in arFeilds) {
                let key = arFeilds[i];

                //если капча не требуется, то не проверяем её
                if (key == "captcha" && !this.useCaptcha) {
                    continue;
                }

                let list = Array.isArray(this.errors[key]) ? this.errors[key] : [this.errors[key]]
                if (list.find(i => !!i)) {
                    return false
                }
            }

            return true;
        },

        /**
         * Шаг назад
         */
        onBackClick() {

            this.step = 1;
            this.mode = "EMAIL"
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "data": this.$data
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },

    /**
     * Событие перед обновлением DOM
     */
    beforeUpdate() {
        this.codeRefs = [];
    },

    created() {
        //восстановление данных
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

}
</script>

<style lang="scss">
.okraina-auth-sms {
    position: relative;

    max-width: 400px;
    margin: auto;

    .btn {
        margin-bottom: 30px;
    }

    .toggle-mode {
        margin-bottom: 20px;
        text-align: center;
    }

    /**
        Шаг №2 - ввод кода
    **/

    .step-2 {
        position: relative;



        .codes {
            margin: 0px -7px;
            margin-bottom: 15px;

        }

        .codes::after {
            display: block;
            content: "";
            clear: both;
        }

        .codes>div {
            float: left;
            padding: 0px 7px;
            width: 20%;
        }

        .code {
            .form-control {
                text-align: center;
                font-size: 20px;
            }

        }

        .btn-get-sms {
            margin-bottom: 30px;
        }

        .step-back {
            text-align: center;
            margin-bottom: 20px;
        }

        .timer {
            margin-bottom: 30px;
        }


    }

}
</style>