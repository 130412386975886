<template>
    <div class="okraina-c-bu" v-if="bonusBalance">

        <okraina-control-switch label="Оплата бонусами" v-model="use" :value="true" :right="true" />

        <div>
            <div class="balance">
                <div class="desc"> У Вас есть</div>
                <div class="wrap">
                    <div class="count" v-html="tools.formatPrice(Math.floor(bonusBalance), {
        supDecimals: false,
        supCurrency: false,
        includeCurrency: false,
        includeDecimals: false
    })">
                    </div>
                    <div>
                        <div class="bonus">
                            <div class="s">Б</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="notes">
            Вы можете оплатить часть заказа бонусами, но не более {{ maxUseBonusPercent }}% от общей стоимости заказа
        </div>

        <div class="charged">
            <div class="label">
                <template v-if="!use">
                    Можно использовать
                </template>
                <template v-else>
                    Будет списано
                </template>

            </div>
            <div>
                <div class="dots"></div>
            </div>
            <div class="value-wrap">
                <template v-if="!use">
                    <div class="value">
                        {{ maxBonus }}
                    </div>
                </template>
                <template v-else>
                    <okraina-control-input type="tel" v-model="value" :noBottomMargin="true" :slim="true"
                        @change="onChange" :mask="{ mask: '9{+}' }" />
                </template>


            </div>

        </div>



    </div>
</template>

<script>
import okrainaControlSwitch from '@/components/controls/switch.vue'
import okrainaControlInput from "@/components/controls/input.vue";

import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-cart-bonus-use",
    components: {
        okrainaControlSwitch,
        okrainaControlInput
    },
    data() {
        return {
            use: false,
            tools,
            value: 0
        };
    },
    props: {
        price: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        ...mapGetters({
            bonusBalance: "getBonus",
            siteSettings: "getSiteSettings",
            bonusUse: "getBonusUse"
        }),

        /**
         * Максимальный процент оплаты бонусами
         */
        maxUseBonusPercent() {
            return this.siteSettings.ORDER_PAY_FROM_ACCOUNT_MAX_PERCENT;
        },

        /**
         * Сколько бонусов можно списать Максимум
         */
        maxBonus() {

            let max = Math.floor(this.price * this.maxUseBonusPercent / 100);

            return this.bonusBalance >= max ? max : Math.floor(this.bonusBalance);

        },

    },

    watch: {
        use: function () {
            if (this.use) {
                if (this.value <= 0 || this.value > this.maxBonus) {
                    this.value = this.maxBonus;
                }
            }

            this.save();
        },
    },

    methods: {

        /**
         * Обработка изменения кол-ва - не даём списать больше, чем есть
         */
        onChange() {
            let i = parseInt(this.value);
            if (i < 0) {
                this.value = 0;
            } else if (i > this.maxBonus) {
                this.value = this.maxBonus;
            }

            this.save();
        },

        /**
         * Сохранение данных об использовании бонусов - для экрана оформления заказа
         */
        save() {

            let bu = {
                use: this.use,
                value: this.value
            };

            this.$store.commit("setUseBonus", bu);
        }
    },

    created() {

        //устанавливаем использование бонусов из данных общего состояния приложения
        this.value = this.bonusUse.value;
        this.use = this.bonusUse.use;
    }
}

</script>


<style lang="scss">
.okraina-c-bu {
    margin-bottom: 35px;
    margin-left: -8px;
    margin-right: -8px;
    padding: 16px 24px;
    background: var(--color-block-background);

    .okraina-control-switch {
        .label {
            font-weight: 600;
            font-size: 18px;
            color: var(--color-text-dark);
        }
    }


    .balance {
        margin-top: 15px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        background: var(--color-view-background);
        border-radius: 10px;
        padding: 13px 25px;

        .desc {
            font-size: 12px;
        }

        .wrap {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 15px;
            align-items: center;
        }

        .bonus {
            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: var(--color-btn1-background);
            color: var(--color-btn1-text);

            .s {
                position: absolute;
                line-height: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 18px;
            }
        }
    }

    .notes {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .charged {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: end;
        column-gap: 3px;

        .label {
            color: var(--color-text-dark);
        }

        .value-wrap {
            max-width: 80px;
        }

        .dots {
            position: relative;
            top: -3px;
            border-bottom: 1px dashed var(--color-text-description);
        }

        .value {
            color: var(--color-text-dark);
            font-weight: 600;
            font-size: 16px;
        }
    }

}
</style>
