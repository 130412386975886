const templates =  {
    "shop.okraina.ru": {
        title: "Контроль качества PIC продукции Окраина",
        tags: [
            {
                name: "description",
                content: "PIC или Product Individual Code (продуктовый индивидуальный код) – это уникальный инструмент контроля качества продукции «ОКРАИНА». Узнайте всю информацию о своих любимых продуктах.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Узнать информацию о продукте – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Узнать информацию о продукте – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;