import Inputmask from 'inputmask';

export default {
    mounted(el, binding) {
        Inputmask(binding.value).mask(el);
    },
    beforeUnmount(el) {
        if(el.inputmask) {
            el.inputmask.remove();
        }
    }
}