export default function (base64, maxWidth, maxHeight) {

    // Max size for thumbnail
    if (typeof (maxWidth) === 'undefined') maxWidth = 500;
    if (typeof (maxHeight) === 'undefined') maxHeight = 500;

    // Create and initialize two canvas
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var canvasCopy = document.createElement("canvas");
    var copyContext = canvasCopy.getContext("2d");



    return new Promise((resolve) => {

        // Create original image
        var img = new Image();
        img.src = base64;
        img.onload = () => {
            // Determine new ratio based on max size
            var ratio = 1;

            //если ширина больше высоты,
            if (img.width > img.height) {
                //алгоритм проверки - сначала ширина, потом высота
                if (img.width > maxWidth) {
                    ratio = maxWidth / img.width;
                }
                else if (img.height > maxHeight) {
                    ratio = maxHeight / img.height;
                }
            }
            //иначе высота больше ширины
            else {
                //алгоритм проверки - сначала высота, потом ширина
                if (img.height > maxHeight) {
                    ratio = maxHeight / img.height;
                }
                else if (img.width > maxWidth) {
                    ratio = maxWidth / img.width;
                }
            }

            // Draw original image in second canvas
            canvasCopy.width = img.width;
            canvasCopy.height = img.height;
            copyContext.drawImage(img, 0, 0);

            // Copy and resize second canvas to first canvas
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;
            ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

            resolve(canvas.toDataURL("image/jpeg", 0.7));
        };

    });



}