<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-delivery">
                <div class="sheet-title">Выберите дату и время доставки</div>

                <transition>
                    <okraina-overlay v-show="loading" />
                </transition>

                <div class="items">

                    <div class="item" v-for="item in itemsInternal" :key="item.ID">

                        <!-- Дата -->
                        <template v-if="item.TYPE == 'DATE'">
                            <okraina-control-delivery-date :prop="item" v-model="values[item.FIELD_NAME]"
                                v-model:error="errors[item.FIELD_NAME]" v-model:valid="valids[item.FIELD_NAME]"
                                @select="onValueChange(item)" :isAddressEmpty="isAddressEmpty" />
                        </template>

                        <!-- Время -->
                        <template v-else-if="item.TYPE == 'TIME_INTERVAL'">
                            <okraina-control-delivery-time :prop="item" v-model="values[item.FIELD_NAME]"
                                v-model:error="errors[item.FIELD_NAME]" v-model:valid="valids[item.FIELD_NAME]"
                                :date="values[getRefFieldName(item)]" @select="onValueChange(item)" />
                        </template>

                        <!-- Желаемое время -->
                        <template v-else-if="item.CODE == 'DESIRED_TIME'">
                            <okraina-control-delivery-desired-time :prop="item" v-model="values[item.FIELD_NAME]"
                                v-model:error="errors[item.FIELD_NAME]" v-model:valid="valids[item.FIELD_NAME]"
                                @select="onValueChange(item)" />
                        </template>

                        <!-- Список -->
                        <template v-else-if="item.TYPE == 'SELECT'">
                            <okraina-control-select :label="item.NAME" :required="item.REQUIED == 'Y'"
                                v-model="values[item.FIELD_NAME]" v-model:error="errors[item.FIELD_NAME]"
                                v-model:valid="valids[item.FIELD_NAME]" @select="onValueChange(item)"
                                :list="getListFromVariants(item)" />
                        </template>

                    </div>
                </div>

                <div class="btn btn-block btn1" @click="apply">Принять</div>

            </div>
        </template>

    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlDeliveryDate from "@/components/controls/delivery.date.vue";
import okrainaControlDeliveryTime from "@/components/controls/delivery.time.vue";
import okrainaControlDeliveryDesiredTime from "@/components/controls/delivery.desired.time.vue";
import okrainaControlSelect from "@/components/controls/select.vue";
import okrainaOverlay from "@/components/overlay.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "okraina-order-delivery",
    components: {
        okrainaSheet,
        okrainaControlDeliveryDate,
        okrainaControlDeliveryTime,
        okrainaControlDeliveryDesiredTime,
        okrainaControlSelect,
        okrainaOverlay
    },
    emits: ["apply", "update:visible"],
    data() {
        return {
            itemsInternal: this.items,
            visibleInternal: this.visible,
            watchVisible: false,
            values: {},
            errors: {},
            valids: {},
            loading: false
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        addressItems: {
            type: Array,
            default() {
                return [];
            },
        },
        propsValues: {
            type: Object,
            default() {
                return {};
            },
        },
        order: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {

        /**
         * Флаг пустой ли адрес доставки
         */
        isAddressEmpty() {
            //если есть св-во "Местоположение" (Мурманск)
            let propLocation = this.addressItems.find(i => i.CODE == 'LOCATION');
            if (propLocation) {
                let values = [];
                //ищем все выбраные местоположения в иерархии
                propLocation.LOCATIONS_LEVELS.forEach(level => {
                    let selected = level.find(i => i.SELECTED == 'Y');
                    if (selected) {
                        values.push(selected.NAME)
                    }
                });

                if (values.length > 0) {
                    return false
                }
            }

            //если есть св-во "Город, улица, дом" (Москва)
            let propFullAddress = this.addressItems.find(i => i.CODE == 'FULL_ADDRESS');
            if (propFullAddress) {
                let value = this.propsValues[propFullAddress.FIELD_NAME];
                if (value) {
                    return false;
                }
            }

            return true
        }

    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.itemsInternal = this.items;
                //при показе попапа установим значения в те, что на основном экране
                this.setValues();
            }
        },
        items: function () {
            this.itemsInternal = this.items;
        },
        valids: {
            handler() {
                //при изменении значений снимим ошибки
                this.checkValues(false);
            },
            deep: true
        }
    },

    methods: {

        /**
         * Устанавилвает значения св-во согласно переданным значениям
         */
        setValues() {
            this.itemsInternal.forEach((p) => {
                this.valids[p.FIELD_NAME] = false;
                if (p.FIELD_NAME in this.propsValues) {
                    this.values[p.FIELD_NAME] = this.propsValues[p.FIELD_NAME];
                } else {
                    this.values[p.FIELD_NAME] = "";
                }
            });

        },

        /**
         * Применить
         */
        apply() {
            if (!this.checkValues()) {
                return;
            }
            this.$emit("apply", this.values);
            this.visibleInternal = false;
        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            //для каждого свойства
            this.itemsInternal.forEach((p) => {
                let error = !this.valids[p.FIELD_NAME];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[p.FIELD_NAME] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Получает для св-ва название св-ва, от которого зависит
         */
        getRefFieldName(item) {
            let prop = this.itemsInternal.find(i => i.CODE == item.DATE_FIELD_CODE);
            if (prop) {
                return prop.FIELD_NAME;
            }
            return "";
        },

        /**
         * Обработка изменения значения св-ва
         */
        onValueChange(item) {
            if (item.REFRESH == "Y") {
                this.reloadItems();
            }
        },


        /**
         * Перезагружаем список свойств
         */
        reloadItems() {

            this.loading = true;

            rest
                .call("order.prepare", {
                    method: "post",
                    data: this.getReloadParams(),
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        let props = data.PROPS.filter(p => p.GROUP_CODE == "DELIVERY");
                        if (props) {
                            this.itemsInternal = props;
                            this.itemsInternal.forEach((p) => {
                                this.valids[p.FIELD_NAME] = true;
                                this.values[p.FIELD_NAME] = p.VALUE;
                            });
                        }

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        getReloadParams() {
            //избавляемся от реактивности
            let order = JSON.parse(JSON.stringify(this.order));
            let prepareParams = {};

            if (Object.keys(order.fields).length > 0) {
                prepareParams.FIELDS = order.fields;
            }
            if (Object.keys(order.props).length > 0) {
                prepareParams.PROPS = order.props;
            }

            this.itemsInternal.forEach((p) => {
                if (p.FIELD_NAME in this.propsValues) {
                    prepareParams.PROPS[p.FIELD_NAME] = this.values[p.FIELD_NAME] ? this.values[p.FIELD_NAME] : "";
                }
            });

            return prepareParams;
        },

        /**
         * Получает для св-ва типа "Список" список для передачи в контрол выбора
         */
        getListFromVariants(item) {
            let list = [];
            item.VARIANTS.forEach(v => {
                list.push({
                    ID: v.VALUE,
                    NAME: v.NAME
                });
            });

            return list;
        }
    },

    created() {
        this.setValues();
    }
}
</script>

<style lang="scss">
.okraina-order-delivery {
    position: relative;

    .okraina-overlay {
        background-color: var(--color-sheet-bakground);
    }


    .items {
        margin-bottom: 30px;
    }
}
</style>