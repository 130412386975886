const templates =  {
    "shop.okraina.ru": {
        title: "Пригласить друга",
        tags: [

        ]
    },
    "dostavka.okraina.ru": {
        title: "Пригласить друга",
        tags: [

        ]
    },
}

export default templates;
