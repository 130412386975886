<template>
  <div class="okraina-cst">
    <div class="title" v-if="title">
      <h2>
        <template v-if="pathToAll">
          <a :href="pathToAll" @click.prevent="goToAll">
            {{ title }}
            <span class="icon fi fi-arrow-right"></span>
          </a>
        </template>
        <template v-else>
          {{ title }}
        </template>
      </h2>

    </div>
    <div clas="items">
      <a class="item" v-for="item in itemsReduced" :key="item.ID" :href="item.SECTION_PAGE_URL"
        :class="{ selected: item.selected }" @click.prevent="goTo(item)" v-ripple>
        <span class="picture" :class="{ empty: !item.UF_ICON }">
          <img v-if="item.UF_ICON" v-lazy="item.UF_ICON.SRC" />
        </span>
        <span class="name">
          {{ item.NAME }}
        </span>
      </a>
      <div class="item more" v-if="reduce" @click="onMoreClick">
        <span class="picture">
          <span class="icon fi fi-dots"></span>
        </span>
        <span class="name"> Ещё </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "okraina-catalog-sections-tabs",
  components: {},
  data() {
    return {
      reduce: true,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    pathToAll: {
      type: String,
      default() {
        return "";
      },
    },
    textToAll: {
      type: String,
      default() {
        return "";
      },
    },
  },
  computed: {
    itemsReduced() {
      if (this.reduce && this.items.length > 4) {
        return this.items.slice(0, 4);
      }

      return this.items;
    },
  },
  watch: {
    items: function () {
      this.reduce = true;
    }
  },

  methods: {
    goToAll() {
      this.$router.push({ path: this.pathToAll });
    },

    goTo(item) {
      this.items.forEach((item) => {
        item.selected = false;
      });

      setTimeout(() => {
        this.$router.push({ path: item.SECTION_PAGE_URL });
      }, 400);

    },

    onMoreClick() {
      this.reduce = false;
    },
  },
};
</script>


<style lang="scss">
.okraina-cst {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  overflow: hidden;

  .title {
    position: relative;
    padding-right: 100px;

    a {
      border-bottom: 0px;
      color: var(--color-text-dark);

      .icon {
        margin-left: 10px;
        font-size: 12px;
        color: var(--color-color1);
      }
    }


  }

  .items:after {
    content: "";
    display: block;
    clear: both;
  }

  .item {
    position: relative;
    display: block;
    float: left;
    padding: 8px 12px 8px 40px;
    border-radius: 80px;
    background-color: var(--color-tab-background);
    color: var(--color-tab-text);
    border-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    margin-bottom: 8px;

    &.selected {
      background-color: var(--color-tab-selected-background);
      color: var(--color-tab-selected-text);

      img {
        filter: brightness(0) invert(1);
      }
    }

    .picture {
      position: absolute;
      left: 11px;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      max-height: 20px;

      img {

        max-height: 20px;
      }

      .icon {
        font-size: 20px;
      }
    }
  }
}
</style>