<template>
    <okraina-view :class="'okraina-v-crssq'" ref="view" :contentHorizontalPadding="15">
        <template v-slot:header></template>
        <template v-slot:content>

            <div v-if="formId" class="wrapper">
                <okraina-web-form :id="formId" />
            </div>

        </template>
    </okraina-view>
</template>

<script>
import okrainaWebForm from "@/components/web.form.vue";
import okrainaView from "@/components/view2.vue";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/action.shop.service.quality.js";
import { mapGetters } from "vuex";

export default {
    name: "okraina-v-client-shop-service-quality",
    components: {
        okrainaWebForm,
        okrainaView
    },
    data() {
        return {}
    },
    props: {

    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        formId() {
            return this.siteSettings ? this.siteSettings.FORM_SHOP_SERVICE_QUALITY : "";
        }
    },
    methods: {

    },
    /**
     * Обработка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-crssq {

    .view-outer {
        background-color: var(--color-block-background);
    }
}
</style>