<template>
    <okraina-view :class="'okraina-v-order-detail'" @refresh="refresh" ref="view" :hideMenu="true"
        :contentHorizontalPadding="19">

        <template v-slot:header>
            <h1 v-if="order">
                Заказ № {{ fields.ACCOUNT_NUMBER }}
                <div class="date">от {{ dateInsert }}</div>
            </h1>
            <h1 v-else>Заказ </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="order">

                    <h2>Состав заказа</h2>

                    <div class="block items">

                        <div class="item" v-for="item in order.ITEMS" :key="item.FIELDS.ID"
                            :set="product = getProductByItem(item)" @click="goToProduct(item)">
                            <div>
                                <okraina-catalog-product-photo v-if="product" :item="product" />
                            </div>
                            <div>
                                <div class="name">{{ item.FIELDS.NAME }}</div>
                                <div class="params">
                                    <div class="param" v-for="param in getDisplayParams(item)" :key="param.CODE">
                                        <span class="label">{{ param.NAME }}:</span>
                                        <span class="value" @click.stop="onItemParamClick(param)">{{ param.VALUE
                                            }}</span>
                                    </div>
                                </div>

                                <div class="basket">
                                    <div class="price">
                                        <div v-html="tools.formatPrice(item.PRICE.DISCOUNT_VALUE, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
                                    </div>
                                    <div class="quantity">
                                        x {{ item.FIELDS.QUANTITY }} {{ item.FIELDS.MEASURE_NAME }}
                                    </div>
                                    <div class="sum">
                                        <div v-if="!isGiftItem(item)" v-html="tools.formatPrice(item.PRICE.SUM, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
                                        <div v-else>
                                            подарок
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <h2>Информация об оплате</h2>
                    <div class="block">

                        <div class="props">
                            <div class="prop" v-if=order.PAY_SYSTEM>
                                <div class="name">Способ оплаты</div>
                                <div class="value">
                                    {{ order.PAY_SYSTEM.NAME }}
                                    <template v-if="order.DATA.CAN_CHANGE_PAYMENT == 'Y'">
                                        <br />
                                        <span class="a" @click="openChangePayment">сменить</span>

                                        <okraina-order-payment-change v-model:visible="showChangePaymentPopup"
                                            :orderId="order.FIELDS.ID" :paymentId="order.FIELDS.PAY_SYSTEM_ID"
                                            @change="onPaymentChange" />
                                    </template>
                                </div>
                            </div>
                            <div class="prop">
                                <div class="name">Оплачено</div>
                                <div class="value">{{ order.FIELDS.PAYED == 'Y' ? 'Да' : 'Нет' }}</div>
                            </div>
                            <div class="prop" v-if="payBonusSum">
                                <div class="name">Оплачено бонусами</div>
                                <div class="value"
                                    v-html="tools.formatPrice(payBonusSum, { supDecimals: false, supCurrency: false })">
                                </div>
                            </div>

                            <div class="prop" v-if="order.DELIVERY">
                                <div class="name">Стоимость доставки</div>
                                <div class="value">
                                    <template v-if="order.DELIVERY.PRICE > 0">
                                        <div
                                            v-html="tools.formatPrice(order.DELIVERY.PRICE, { supDecimals: false, supCurrency: false })">
                                        </div>
                                    </template>
                                    <template v-else>
                                        бесплатно
                                    </template>
                                </div>
                            </div>
                            <div class="prop">
                                <div class="name">Сумма заказа</div>
                                <div class="value"
                                    v-html="tools.formatPrice(order.FIELDS.PRICE, { supDecimals: false, supCurrency: false })">
                                </div>
                            </div>

                            <div class="prop" v-if="order.FIELDS.PAYED == 'N'">
                                <div class="name">К оплате</div>
                                <div class="value"
                                    v-html="tools.formatPrice(paySum, { supDecimals: false, supCurrency: false })">
                                </div>

                            </div>

                            <div class="btn-pay-wrap" v-if="order.DATA.CAN_REPAY == 'Y'">
                                <div class="btn slim btn1 btn-pay" @click="orderPay">
                                    Оплатить заказ
                                </div>
                            </div>

                            <div class="pay-notes" v-if="order.DATA.CAN_REPAY != 'Y' && order.DATA.SHOW_NOTICE == 'Y'"
                                v-html="order.DATA.NOTICE">
                            </div>
                        </div>

                    </div>

                    <h2>Информация о заказе</h2>

                    <div class="block">

                        <div class="title">
                            Статус

                            <div v-if="order.STATUS" class="status" :style="{
        backgroundColor: order.STATUS.UF_COLOR,
        color: order.STATUS.UF_COLOR == '#56A000' ? '#FFFFFF' : 'var(--color-text-dark)'
    }">
                                {{ order.STATUS.NAME }}
                            </div>
                        </div>

                        <div class="props">

                            <div class="prop" v-if="addressComposite">
                                <div class="name">Адрес доставки</div>
                                <div class="value">{{ addressComposite }}</div>
                            </div>
                            <div class="prop" v-if="dateTimeDelivery">
                                <div class="name">Дата доставки</div>
                                <div class="value">{{ dateTimeDelivery }}</div>
                            </div>

                            <template v-for="prop in displayProps" :key="prop.ID">

                                <div class="prop" v-if="prop.VALUE_TEXT || prop.VALUE">
                                    <div class="name">{{ prop.NAME }}</div>
                                    <div class="value">
                                        <template v-if="prop.VALUE_TEXT">{{ prop.VALUE_TEXT }}</template>
                                        <template v-else>{{ prop.VALUE }}</template>

                                    </div>
                                </div>

                            </template>

                            <div class="prop" v-if="order.FIELDS.USER_DESCRIPTION">
                                <div class="name">Комментарий</div>
                                <div class="value">{{ order.FIELDS.USER_DESCRIPTION }}</div>
                            </div>


                        </div>

                    </div>

                    <div class="btns-wrap">
                        <div class="btn btn-block btn1" @click="showRepeat()">
                            Повторить заказ
                        </div>
                        <okraina-order-repeat :id="order.FIELDS.ID" v-model:visible="showRepeatPopup" />
                        <div v-if="order.DATA.CAN_CANCEL == 'Y'" class="btn btn-block btn3" @click="showCansel()">
                            Отменить заказ
                        </div>
                        <okraina-order-cancel v-if="order.DATA.CAN_CANCEL == 'Y'" :id="order.FIELDS.ID"
                            v-model:visible="showCancelPopup" @canceled="refresh" />
                    </div>
                </template>

                <template v-else>
                    <okraina-not-found caption="Заказ не найден">

                        <div class="btn btn1 slim" @click="retry">Повторить</div>

                    </okraina-not-found>
                </template>

            </template>

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaOrderRepeat from "@/components/order/repeat.vue";
import okrainaOrderCancel from "@/components/order/cancel.vue";
import okrainaNotFound from "@/components/not.found.vue";
import okrainaOrderPaymentChange from "@/components/order/payment.change.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/order.detail.js";

import tools from "@/plugins/tools";

export default {

    name: "okraina-v-order-detail",

    components: {
        okrainaView,
        okrainaOrderRepeat,
        okrainaOrderCancel,
        okrainaLoaderPage,
        okrainaCatalogProductPhoto,
        okrainaNotFound,
        okrainaOrderPaymentChange
    },

    data() {
        return {
            loading: true,
            timer: 0,
            order: false,
            moment: moment,
            showRepeatPopup: false,
            showCancelPopup: false,
            tools: tools,
            canShow: false,
            showChangePaymentPopup: false
        }
    },

    props: {
        id: {
            type: String,
            default() {
                return "";
            },
        },
    },

    computed: {

        dateInsert() {
            if (!this.order) {
                return "";
            }
            return moment(this.order.FIELDS.DATE_INSERT, 'DD.MM.YYYY').format("DD MMMM YYYY");
        },

        displayProps() {
            if (!this.order) {
                return [];
            }
            let properties = [];
            let arExclude = [
                "FLAG_X", "FULL_ADDRESS", "CITY", "ADDRESS_SELECTED", "LAT", "LONG", "LOCATION", "HOUSE", "CORPUS", "ENTRANCE", "FLOOR", "APARTMENT",
                "DELIVERY_DATE", "DELIVERY_TIME", "DESIRED_TIME"
            ]
            this.order.PROPS.forEach(function (prop) {
                if (prop.UTIL != "Y" && !arExclude.includes(prop.CODE)) {
                    properties.push(prop);
                }
            });
            return properties;
        },

        payBonusSum() {
            let prop = this.order.FIELDS.PROPS.find(p => p.CODE == "BONUS_AMOUNT");
            if (!prop) {
                return "";
            }
            return prop.VALUE;
        },

        paySum() {
            if (!this.order) {
                return 0;
            }
            return parseFloat(this.order.FIELDS.PRICE) - parseFloat(this.order.FIELDS.SUM_PAID);
        },

        fields() {
            if (!this.order) {
                return [];
            }
            return this.order.FIELDS;
        },

        /**
         * Собираем адрес
         */
        addressComposite() {

            let compositeAddress = "";

            let props = this.order.PROPS;

            if (props) {

                let propObject = {
                    HOUSE: "д. ",
                    CORPUS: "кор. ",
                    ENTRANCE: "под. ",
                    FLOOR: "эт. ",
                    APARTMENT: "кв. ",
                };
                let propsLoc = ["HOUSE", "CORPUS", "ENTRANCE", "FLOOR", "APARTMENT"];

                let fullAddress = props.find(p => p.CODE == 'FULL_ADDRESS');

                if (fullAddress) {
                    return fullAddress.VALUE;
                }

                let location = props.find(p => p.CODE == 'LOCATION');

                if (location.VALUE_TEXT) {
                    compositeAddress = location.VALUE_TEXT;
                    propsLoc.forEach(function (element) {
                        let p = props.find(p => p.CODE == element)
                        if (p.VALUE) {
                            compositeAddress = compositeAddress + " " + propObject[p.CODE] + " " + p.VALUE;
                        }
                    });
                }
            }
            return compositeAddress;
        },
        /**
        * Дата/время доставки
        */
        dateTimeDelivery() {

            if (!this.order) {
                return "";
            }
            let propDate = this.order.PROPS.find((p) => p.CODE == 'DELIVERY_DATE');
            let propTime = this.order.PROPS.find((p) => p.CODE == 'DELIVERY_TIME');
            let propDiserdTime = this.order.PROPS.find(p => p.CODE == 'DESIRED_TIME');

            let arParts = [];
            if (propDate && propDate.VALUE) {
                arParts.push(moment(propDate.VALUE, 'DD.MM.YYYY').format("DD MMMM"));

            }
            if (propTime && propTime.VALUE) {
                arParts.push(propTime.VALUE)
            }
            if (propDiserdTime && propDiserdTime.VALUE) {
                arParts.push("(" + propDiserdTime.VALUE + ")")
            }

            return arParts.join(' ');
        },

        //
    },

    watch: {
        id: function () {
            this.loadData();
        },
        order: function () {
            this.setMeta();
        }
    },

    methods: {
        /**
         * Перейти на страницу оплаты
         */
        orderPay() {
            this.$router.push({
                name: "order-pay",
                query: { ORDER_ID: this.fields.ID },
            });
        },

        /**
        * Показывает попап для повторения заказа
        */
        showRepeat() {
            this.showRepeatPopup = true;
        },

        /**
        * Показывает попап для повторения заказа
        */
        showCansel() {
            this.showCancelPopup = true;
        },

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("order.getbyid", {
                    method: "post",
                    data: {
                        ID: this.id,
                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.order = data;
                        //генерим событие просмотра заказа
                        bus.emit("OKRAINA_ORDER_E_VIEW", data);
                    } else {
                        this.loading = false;
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    this.loading = false;
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve = false) {
            this.loadData(resolve);
        },

        /**
         * Повторная попытка загрузить заказ
         */
        retry() {
            this.loading = true;
            this.loadData();
        },

        /**
        * Устанавливает мета данные страницы
        */
        setMeta() {
            if (this.fields) {
                let metaInfo = [];
                metaInfo.ORDER_ID = this.fields.ID;
                meta.write(metaTemplates, metaInfo);
            }
        },

        /**
         * Получает товар по позиции
         */
        getProductByItem(item) {
            let product = this.order.PRODUCTS.find(p => p.FIELDS.ID == item.FIELDS.PRODUCT_ID);
            if (!product) {
                //нужно, чтобы показать пустую картинку
                return {
                    "FIELDS": {

                    },
                    "PROPS": {

                    }
                };
            }
            return product;
        },

        /**
         * Получает параметры для отображения по позиции
         */
        getDisplayParams(item) {

            let props = [];

            let p = item.PARAMS.find(p => p.CODE == "RIFLING" || p.CODE == "CERTIFICATE");
            if (p) {
                props.push(p);
            }

            return props;

        },

        /**
         * Открытие карточки товара
         */
        goToProduct(item) {

            let product = this.getProductByItem(item);
            let gift = item.PARAMS.find(p => p.CODE == "GIFT");
            if (product && product.FIELDS.CODE && !gift) {
                bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                    id: product.FIELDS.ID,
                    code: product.FIELDS.CODE
                });
            }
        },

        /**
         * Проверяет является ли позиция подарком
         */
        isGiftItem(item) {
            let gift = item.PARAMS.find(p => p.CODE == "GIFT");
            return !!gift;
        },

        /**
         * Обработка события оплаты заказа
         */
        onOrderPayd(data) {
            //если был оплачен именно этот просматриваемый заказ
            if (data.id == this.id) {
                this.loadData();
            }
        },

        /**
         * Клик по параметру позиции корзины
         */
        onItemParamClick(param) {
            if (param.CODE == "CERTIFICATE") {
                bus.emit("OKRAINA_CLIPBOARD_E_WRITE", {
                    text: param.VALUE,
                    successMessage: 'Сертификат скопирован.',
                });

            }
        },

        /**
         * Открывает форму изменения способа оплаты
         */
        openChangePayment() {
            this.showChangePaymentPopup = true;
        },

        /**
         * Обработка изменения способа оплаты
         */
        onPaymentChange() {
            let vm = this;
            setTimeout(() => {
                vm.loadData();
            }, 300);

        }
    },

    created() {
        this.loadData();
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    mounted() {
        bus.on("OKRAINA_ORDER_E_PAYED", this.onOrderPayd);
    },

    unmounted() {
        bus.off("OKRAINA_ORDER_E_PAYED", this.onOrderPayd);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {

        next(vm => {
            vm.setMeta();
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

}
</script>

<style lang="scss">
.okraina-v-order-detail {
    .view-inner {
        .view-header {
            .header-center {
                overflow: visible;
            }
        }
    }

    h1 {
        position: relative;

        .date {
            position: absolute;
            top: 75%;
            font-size: 12px;
        }
    }

    .block {
        padding: 12px 8px;
        border-radius: 8px;
        background-color: var(--color-block-background);
        margin-bottom: 20px;

        .title {
            position: relative;
            font-size: 12px;
            color: var(--color-text-dark);
            font-weight: 600;
            margin-bottom: 8px;

            .status {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                padding: 5px 8px;
                border-radius: 4px;
                font-size: 10px;
                font-weight: 500;
                color: var(--color-text-dark);
                line-height: 1;
                max-width: 150px;
                text-align: right;

            }
        }
    }

    .prop {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 15px;
        align-items: center;
        border-bottom: 1px solid var(--color-color7);
        padding: 8px 0px;

        .name {

            font-size: 12px;
            color: var(--color-text-dark);
        }

        .value {
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            color: var(--color-text-dark);
            overflow-wrap: break-word;
            min-width: 0;
        }
    }

    .props {
        .prop:last-of-type {
            border-bottom: 0px;
        }
    }


    .okraina-price {
        font-size: 12px;
        font-weight: 500;
    }

    .btn-pay-wrap {
        margin-top: 20px;
        padding: 0px 20px;
        text-align: center;

        .btn-pay {
            min-width: 200px;
        }
    }

    .pay-notes {
        margin-top: 20px;
        font-size: 12px;
    }


    .items {

        .item {
            display: grid;
            grid-template-columns: 0.15fr 0.85fr;
            column-gap: 15px;

            border-bottom: 1px solid var(--color-color7);
            padding: 8px 0px;

            &:last-of-type {
                border-bottom: 0px;
            }

            .name {
                font-size: 12px;
                font-weight: 500;

                color: var(--color-text-dark);
            }

            .params {

                .param {
                    margin-top: 5px;
                    display: inline-block;
                    font-size: 11px;
                    font-weight: 500;

                    .label {
                        color: var(--color-text-title);

                        &:after {
                            content: " ";
                        }
                    }

                    .value {
                        font-weight: 500;
                        ;
                    }
                }
            }

            .basket {
                margin-top: 5px;
                display: grid;
                grid-template-columns: 0.35fr 0.3fr 0.35fr;
                column-gap: 10px;

                .quantity {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                }

                .sum {
                    text-align: right;
                }
            }
        }
    }

    .btns-wrap {
        padding: 0px 21px;

        .btn {
            margin-bottom: 15px;
        }
    }



}
</style>