import tools from "@/plugins/tools";

const basket = {
    state: () => {
        return {
            //корзина
            basket: [],

            //использование бонусов
            bonusUse: {
                use: false,
                value: 0
            },

            //флаг необходимости выгрузки оформленного заказа в Метрику
            exportPurchase: false
        }
    },
    getters: {
        /**
         * Получает корзину
         */
        getBasket(state) {
            return state.basket;
        },

        getBasketByHash(state) {
            return state.basket.reduce((out, item) => {
                out[tools.basketItemHash(item.id,item.params)] = item
                return out
            }, {})
        },

        /**
         * Проверяет есть ли товар в корзине
         */
        InBasket: state => (pid, params) => {
            return state.basket.find(item => item.id == pid && JSON.stringify(item.params) == JSON.stringify(params)) !== undefined;
        },

        /**
         * Получает указанный товар из корзины
         */
        getProductInBasket: state => (pid, params) => {
            let item = state.basket.find(item => item.id == pid && JSON.stringify(item.params) == JSON.stringify(params))
            return item;
        },

        /**
         * Получает количетсво товаров в корзине
         */
        getBasketCount(state) {
            let cnt = 0;
            state.basket.forEach(item => cnt += item.quantity)
            return cnt;
        },

        /**
         * Получает данные об использовании бонусов
         */
        getBonusUse(state) {
            return state.bonusUse;
        },

        /**
         * Получает флаг необходимости выгрузки заказа в Метрику
         */
        getExportPurchase(state) {
            return state.exportPurchase;
        }
    },
    mutations: {
        /**
         * Устанавливает корзину
         */
        setBasket: (state, value) => {
            state.basket = value;
        },

        /**
         * Обновляет товар в корзине
         */
        updateBasketItem: (state, data) => {

            let item = state.basket.find(item => item.id == data.pid && JSON.stringify(item.params) == JSON.stringify(data.params));
            if (item) {
                item.quantity = data.quantity;
                if(item.quantity == 0) {
                    var index = state.basket.indexOf(item);
                    if (index !== -1) {
                        state.basket.splice(index, 1);
                    }
                }
            } else if (data.quantity > 0) {
                state.basket.push({
                    id: data.pid,
                    quantity: data.quantity,
                    params: data.params
                });
            }
        },

        /**
         * Устанавливает даные по использованию бонусов
         */
        setUseBonus: (state, value) => {
            state.bonusUse = value;
        },

        /**
         * Получает флаг необходимости выгрузки заказа в Метрику
         */
        setExportPurchase: (state, value) => {
            state.exportPurchase = value;
        },
    }

}

export default basket;