<template>
    <div class="okraina-control-ll form-group" :class="{
        'form-group-error': error
    }">

        <div class="label">
            {{ name }}
            <span v-if="required" class="required">*</span>
        </div>

        <div v-if="list.length > 0" class="items">
            <div class="item" :class="{ selected: value == item.ID }" v-for="item in list" :key="item.ID"
                @click="onItemClick(item)">
                <span v-html="item.NAME"></span>

            </div>
        </div>


    </div>
</template>

<script>

export default {
    name: "okraina-control-list-line",
    components: {

    },
    emits: ["update:modelValue", "update:valid", "update:error", "select"],
    data() {
        return {
            value: this.modelValue
        };
    },
    props: {
        name: {
            type: String,
            default() {
                return "";
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },

    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
            if (this.modelValue !== this.value) {
                this.$emit("update:modelValue", this.value);
                this.$emit("select");
            }
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {
        onItemClick(item) {
            this.value = item.ID
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {

            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },
    },

    mounted() {
        this.checkValid();
    },
}
</script>

<style lang="scss">
.okraina-control-ll {

    .items {
        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            position: relative;
            display: inline-block;
            border-radius: 24px;
            font-weight: 500;
            color: var(--color-text-dark);
            line-height: 1;
            padding: 9px 12px;
            background-color: var(--color-color9);
            margin-right: 8px;
            margin-bottom: 8px;

            &.selected {
                background-color: var(--color-btn1-background);
                color: var(--color-btn1-text);

                * {
                    color: var(--color-btn1-text) !important;
                }
            }
        }
    }

    .error {
        color: var(--color-error);
        font-weight: 500;
    }
}
</style>
