<template>
    <div class="okraina-cart-order" :class="{ disabled: disabled }" v-if="items.length > 0">

        <template v-if="buyOneClickEnabled">
            <div class="btns-wrap">
                <div>
                    <div class="button btn-one-click" @click="onBuyOneClick" v-ripple>в 1 клик</div>
                </div>
                <div>

                    <div class="button btn-order" @click="onOrderClick" v-ripple>Оформить
                        заказ</div>

                </div>
            </div>
        </template>
        <template v-else>
            <div class="button btn-order" @click="onOrderClick" v-ripple>Оформить
                заказ</div>
        </template>

    </div>

    <okraina-sheet v-model:visible="showPaidDeliveryPopup">
        <template v-slot:body>
            <div class="okraina-cart-order-pd">
                <div class="note text-center">Оформить платную доставку ({{ deliveryPrice }}р)?</div>
                <div class="btn btn-block btn1" v-ripple @click="onConfirmPaidDelivery">
                    Да
                </div>

                <div class="btn btn-block btn3" v-ripple @click="onCancelClick">
                    Нет
                </div>
            </div>
        </template>
    </okraina-sheet>

    <okraina-sheet v-model:visible="showOffer" :maxHeight="90">
        <template v-slot:body>

            <div class="okraina-cart-order-offer">
                <div class="title">Добавить <br> <b>{{ offer.FIELDS.NAME }} ?</b></div>
                <div class="photo-wrap">
                    <okraina-catalog-product-photo :item="offer" />
                </div>
                <div class="wrap">
                    <div class="price">
                        <okraina-catalog-product-price :item="offer" v-if="offer.PRICE" :showMeasure="true"
                            :quantity="1" />
                    </div>
                    <okraina-catalog-product-buy-btn :productId="offer.FIELDS.ID" />
                </div>
            </div>

        </template>
    </okraina-sheet>

    <okraina-cart-buy-one-click v-model:visible="showBuyOneClick" />
</template>

<script>
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";
import okrainaSheet from "@/components/sheet.vue";
import okrainaCartBuyOneClick from "@/components/cart/buy.one.click.vue"
import okrainaCatalogProductBuyBtn from "@/components/catalog/product.buy.btn.vue";

import tools from "@/plugins/tools";
import bus from "@/plugins/bus";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
    name: "okraina-cart-order",
    components: {
        okrainaSheet,
        okrainaCartBuyOneClick,
        okrainaCatalogProductBuyBtn,
        okrainaCatalogProductPhoto,
        okrainaCatalogProductPrice
    },
    data() {
        return {
            tools: tools,
            showPaidDeliveryPopup: false,
            showBuyOneClick: false,
            showOffer: false,
            mode: "",
            now: moment(),
            timer: 0
        }
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        offer: {
            type: [Boolean, Object],
            default() {
                return false;
            },
        },
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        deliveryPrice: {
            type: Number,
            default() {
                return 0;
            },
        },

        disabled: {
            type: Boolean,
            default() {
                return true;
            }
        }

    },
    computed: {
        ...mapGetters({
            isMenuHidden: "isHideMenu",
            platform: "getPlatform",
            isAuthorized: "isAuthorized",
            siteSettings: "getSiteSettings",
        }),

        discountPrice() {
            let value = this.items.reduce(function (sum, item) {
                return sum + item.FIELDS.QUANTITY * item.PRICE.DISCOUNT_VALUE;
            }, 0);

            return value + this.deliveryPrice;
        },

        sum() {
            return this.discountPrice;
        },

        /**
         * В корзине только товары-сертификаты
         */
        haveOnlyCertificates() {

            let simpleProduct = this.products.find(p => !p.PROPS.CERTIFICATE);
            if (simpleProduct) {
                return false;
            }

            return true;
        },

        buyOneClickFrom() {
            if (!this.siteSettings || !this.siteSettings.BUY_ONE_CLICK_ENABLE_FROM) {
                return 0;
            }
            let temp = this.siteSettings.BUY_ONE_CLICK_ENABLE_FROM.split(":");
            if (temp.length != 2) {
                return 0;
            }

            return parseInt(temp[0]) * 60 + parseInt(temp[1]);
        },

        buyOneClickTo() {
            if (!this.siteSettings || !this.siteSettings.BUY_ONE_CLICK_ENABLE_TO) {
                return 0;
            }
            let temp = this.siteSettings.BUY_ONE_CLICK_ENABLE_TO.split(":");
            if (temp.length != 2) {
                return 0;
            }

            return parseInt(temp[0]) * 60 + parseInt(temp[1]);
        },

        buyOneClickEnabled() {

            let from = moment().startOf('day').add(this.buyOneClickFrom, 'minutes')
            let to = moment().startOf('day').add(this.buyOneClickTo, 'minutes')

            if (this.buyOneClickFrom > 0 && this.now.isBefore(from)) {
                return false;
            }

            if (this.buyOneClickTo > 0 && this.now.isAfter(to)) {
                return false;
            }

            if (this.haveOnlyCertificates) {
                return false
            }

            return true;
        }

    },

    watch: {
        offer: {
            handler: function (newVal) {
                if (!newVal) {
                    this.showOffer = false;
                }
            },
        },
        showOffer: {
            handler: function (newVal) {
                if (newVal == false && this.offer) {
                    this.goToOrder();
                }
            },
        },
    },
    methods: {

        /**
         * Клик по кнопке "Оформить заказ"
         */
        onOrderClick() {

            if (this.disabled) {
                return;
            }

            this.mode = "ORDER";

            //если не авторизован
            if (!this.isAuthorized) {
                bus.emit("OKRAINA_AUTH_NEED_E_OPEN");
            }
            //если платная доставка
            else if (this.deliveryPrice > 0) {
                //запросим подтверждение
                this.showPaidDeliveryPopup = true;
            } //Если есть предложение
            else if (this.offer) {
                this.showOffer = true;
            } else {
                //иначе переход к оформлению заказа
                this.goToOrder();
            }

        },

        /**
         * Клик по "Купить в 1 клик"
         */
        onBuyOneClick() {

            if (this.disabled) {
                return;
            }

            this.mode = "BUY_ONE_CLICK";

            //если платная доставка
            if (this.deliveryPrice > 0) {
                //запросим подтверждение
                this.showPaidDeliveryPopup = true;
            } else {
                //иначе переход к заказу в 1 клик
                this.openBuyOneClick();
            }

        },

        /**
         * Подтверждение оформления платной доставки
         */
        onConfirmPaidDelivery() {
            this.showPaidDeliveryPopup = false;

            //если подтверждение вызвали по кнопке "Оформить заказ"
            if (this.mode == "ORDER") {
                this.goToOrder();
            }
            //иначе переход к заказу в 1 клик
            else {
                this.openBuyOneClick();
            }
        },

        /**
         * Отмена подтверждения оформления платной доставки
         */
        onCancelClick() {
            this.showPaidDeliveryPopup = false;
        },

        /**
         * переход к оформлению заказа
         */
        goToOrder() {

            let vm = this;
            setTimeout(() => {
                vm.$router.push({ path: '/personal/order/make/' });
            }, 400);
        },

        /**
         * переход к оформлению заказа
         */
        openBuyOneClick() {

            let vm = this;
            setTimeout(() => {
                vm.showBuyOneClick = true;
            }, 400);
        },
    },

    mounted() {
        //запускаем таймер обнолвения текущего времени
        //чтобы пересчитывалось св-во buyOneClickEnabled
        this.timer = setInterval(() => {
            this.now = moment();
        }, 1000);
    },

    unmounted() {
        if (this.timer > 0) {
            clearInterval(this.timer);
        }
    }
}

</script>

<style lang="scss">
.okraina-cart-order {

    &.disabled {
        opacity: 0.5;
    }

    .btns-wrap {
        display: grid;
        grid-template-columns: 39% 61%;

        border-radius: 80px;

        .btn-order {
            border-radius: 0px 80px 80px 0px;
        }
    }

    .button {
        padding: 15px 0px;
        text-align: center;
        line-height: 1;
        border: 2px solid var(--color-color1);
        cursor: pointer;
    }

    &.disabled {
        .button {
            cursor: default;
        }
    }

    .btn-one-click {
        font-weight: 600;
        font-size: 14px;
        color: var(--color-color1);
        border-radius: 80px 0px 0px 80px;
    }

    .btn-order {
        background-color: var(--color-btn1-background);
        font-weight: 600;
        font-size: 14px;
        color: var(--color-btn1-text);
        border-radius: 80px;

        .okraina-price {
            color: inherit;
            font-size: inherit;
        }
    }
}

.okraina-cart-order-pd {

    .note {
        margin-bottom: 20px;
    }

    .btn {
        margin-bottom: 15px;
    }
}

.okraina-cart-order-offer {
    .photo-wrap {
        position: relative;
        height: 0px;
        padding-bottom: 95%;
        overflow: hidden;
        border-radius: 5px 5px 0px 0px;

        .image {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .wrap {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .title {
        text-align: center;
    }

}
</style>