import router from '@/router'
import bus from '@/plugins/bus'
import { BarcodeScanner, CameraDirection,SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';


export default {
    qrScannerResolve: false,
    cameraDirection: CameraDirection.BACK,

    init() {

        //подписываемся на событие вызова сканера
        bus.on('OKRAINA_BARCODE_SCANNER_E_OPEN', (params) => {
            this.open(params);
        });

        //подписываемся на событие закрытие сканера
        bus.on('OKRAINA_BARCODE_SCANNER_E_CLOSE', () => {
            this.close();
        });

        //подписываемся на событие вызова показа сканера
        bus.on('OKRAINA_BARCODE_SCANNER_E_SHOW', () => {
            this.showScanner();
        });

        //подписываемся на событие вызова показа сканера
        bus.on('OKRAINA_BARCODE_SCANNER_E_RECIVE_MANUAL', (code) => {
            this.onReceive(code);
        });
    },

    /**
     * Открывает экран  сканера
     */
    async open(params) {
        this.qrScannerResolve = params.resolve;

        //если WEB
        if (!Capacitor.isNativePlatform()) {
            //сразу переходим к экрану сканера
            router.push({ name: "barcode-scanner" });
            this.showScanner();
        } 
        // иначе native
        else {
                 
            //поулчаем информацию о разрешениии
            const status = await BarcodeScanner.checkPermission({ force: true });

            //если разрешение уже предоставлено
            if (status.granted) {
                //переходим к экрану сканера
                router.push({ name: "barcode-scanner" });
                this.showScanner();
            } else {
                //иначе запрашиваем разрешение
                this.permit();
            }

        }

    },

    /**
    * Спрашивает не открыть ли экран настроект разрешений прилоржения
    */
    async permit() {
        await new Promise((resolve) => {
            bus.emit("OKRAINA_CONFIRM_E_SHOW", {
                message: "У приложения нет разрешения на использование камеры. Перейти в настройки разрешений приложения?",
                resolveText: "Да",
                rejectText: "Нет",
                resolve: function (value) {
                    if(value) {
                        BarcodeScanner.openAppSettings();
                    }
                    resolve(value);
                },
                reverse: false,
            });
        });
    },

    /**
     * Показывает сканер 
     */
    showScanner() {

        if (Capacitor.isNativePlatform()) {
            //подготавливаем библиотеки к работе для быстрого включения
            BarcodeScanner.prepare();
        }

        //запустим сигнал о готовности через 400мс
        setTimeout(() => {

            BarcodeScanner.startScan({
                cameraDirection: this.cameraDirection,
                targetedFormats: [SupportedFormat.QR_CODE, SupportedFormat.CODE_128]
            }).then((result) => {
                 this.onReceive(result.content);
            });

            bus.emit("OKRAINA_BARCODE_SCANNER_E_READY");
            if (Capacitor.isNativePlatform()) {
                BarcodeScanner.hideBackground();
                document.querySelector('body').classList.add('scanner-active');
            }

        },600);

        

    },

    onReceive(code) {
        bus.emit("OKRAINA_BARCODE_SCANNER_E_RESULT_RECEIVE");
        this.close();
        router.go(-1);
        this.qrScannerResolve(code);
    },

    /**
     * Закрыает сканер
     */
    close() {
        BarcodeScanner.showBackground();
        document.querySelector('body').classList.remove('scanner-active');
        BarcodeScanner.stopScan();
    },

}