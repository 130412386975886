<template>
    <okraina-view :class="'okraina-v-cr'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Отзывы о нас ({{ pager.TOTAL }})
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError && !loadingCommon">

                <div class="text-center">
                    Ошибка загрузки данных.
                    <br /><br />
                    <div class="btn btn1" @click="retryLoad">Повторить</div>
                </div>

            </template>

            <template v-if="!loading && canShow">

                <!--Выбор темы отзывов-->
                <okraina-tabs-scroll v-if="themes.length > 0" :items="themes" v-model="selectedTheme" />

                <div v-if="reload" class="text-center">
                    <okraina-loader-inline />
                </div>

                <!--Список отзывов-->
                <div class="items" v-if="!reload">
                    <okraina-clients-review-item v-for="item in items" :key="item.FIELDS.ID" :item="item"
                        @complaint="onComplaint" @block="onBlock" />

                    <div ref="pager" class="pager"></div>

                    <!-- Подгрузка следюущей страницы -->
                    <template v-if="loadingPaging">
                        <div class="text-center">
                            <okraina-loader-inline />
                        </div>
                    </template>
                </div>

                <!--Добавить отзыв-->
                <okraina-fixed-panel :isMenuHidden="true">
                    <div class="btn btn-block slim btn1" @click="onAddClick" v-ripple>
                        Оставить свой отзыв</div>
                </okraina-fixed-panel>

            </template>



            <okraina-sheet v-model:visible="showComplaint" :maxHeight="92">
                <template v-slot:body>
                    <okraina-web-form :id="complaintWebFormId" :sheetMode="true" :extValues="complaintHiddenValues" />
                </template>
            </okraina-sheet>

            <okraina-clients-review-add-modal v-model:visible="showAddReview" />

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaClientsReviewItem from "@/components/clients/review.item.vue";
import okrainaClientsReviewAddModal from "@/components/clients/review.add.modal.vue";
import okrainaSheet from "@/components/sheet.vue";
import okrainaWebForm from "@/components/web.form.vue"
import okrainaTabsScroll from "@/components/tabs.scroll.vue";
import okrainaFixedPanel from '@/components/fixed.panel.vue'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/clients.reviews.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-client-reviews",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaLoaderInline,
        okrainaClientsReviewItem,
        okrainaClientsReviewAddModal,
        okrainaSheet,
        okrainaWebForm,
        okrainaTabsScroll,
        okrainaFixedPanel
    },
    data() {
        return {
            loading: true,
            reload: false,
            loadingPaging: false,
            loadingCommon: false,
            loadingError: false,
            form: false,
            items: [],
            selectedTheme: 0,
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 10,
                PAGES_COUNT: 1,
                TOTAL: 1
            },
            authPopupVisible: false,
            showComplaint: false,
            complaintHiddenValues: {},
            showAddReview: false,
            canShow: false
        };
    },

    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings",
            isAuthorized: "isAuthorized"
        }),

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_REVIEWS : "";
        },

        questionTheme() {
            if (!this.form) {
                return false;
            }

            let question = this.form.QUESTIONS.find(q => q.SID == 'THEME');
            return question;

        },

        themes() {
            let list = [];

            if (this.questionTheme) {
                list.push({
                    id: 0,
                    name: "Все"
                });
                this.questionTheme.ANSWERS[0].VALUES.forEach(v => {

                    list.push({
                        id: v.ID,
                        name: v.MESSAGE
                    });
                });
            }

            return list;
        },

        filter() {
            if (this.selectedTheme <= 0) {
                return false;
            }
            return {
                "THEME.ANSWER_ID": this.selectedTheme
            }
        },

        complaintWebFormId() {
            return this.siteSettings ? this.siteSettings.FORM_COMPANY_REVIEWS_COMPLAINTS : "";
        }
    },

    watch: {
        webFormId: function (newVal) {
            if (newVal) {
                this.pager.NUM_PAGE = 1;
                this.loadData();
            }
        },

        selectedTheme() {
            this.pager.NUM_PAGE = 1;
            this.reload = true;
            this.loadData();
        }
    },

    methods: {

        /**
         * Получает данные для запросоы
         */
        getBatchRequest() {

            return {
                form: {
                    CLASS: "form",
                    METHOD: "getbyid",
                    PARAMS: {
                        ID: this.webFormId
                    },
                },
                list: {
                    CLASS: "reviews",
                    METHOD: "shop.getlist",
                    PARAMS: {
                        NAV_PARAMS: this.pager,
                        FILTER: this.filter
                    },
                },

            };
        },

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            if (this.webFormId <= 0) {
                return;
            }

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .batch(this.getBatchRequest())
                .then((results) => {
                    this.onLoadData(results);
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    this.loadingCommon = false;
                    this.reload = false;
                    if (resolve) {
                        resolve();
                    }
                });


        },

        /**
         * Обработка загрузки данных
         */
        onLoadData(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    //данные веб-формы
                    if (key == "form") {
                        this.form = data;
                    }
                    //список отзывов 
                    else if (key == "list") {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                    }
                    this.loadingError = false;
                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    this.loadingError = true;
                    return;
                }
            }
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            if (this.pager.NUM_PAGE == 1) {
                this.loading = true;
            } else {
                this.loadingPaging = true;
            }

            this.loadData();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.pager.NUM_PAGE = 1;

            this.loadData(resolve);
        },

        /**
         * Клик по теме
         */
        onThemeClick(id) {
            this.selectedTheme = id;
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingCommon &&
                    !this.loadingPaging &&
                    !this.reload &&
                    this.canShow &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

        /**
         * Жалоба на отзыв
         */
        onComplaint(data) {

            if (!this.isAuthorized) {
                bus.emit("OKRAINA_AUTH_NEED_E_OPEN", {});
            } else {
                this.showComplaint = true;
            }

            this.complaintHiddenValues = data;
        },

        /**
         * Блокировка пользователя
         */
        onBlock(data) {

            if (!this.isAuthorized) {
                bus.emit("OKRAINA_AUTH_NEED_E_OPEN", {});
            } else {
                this.block(data);
            }

        },

        /**
         * Блокировка пользователя
         */
        block(data) {

            rest
                .call("reviews.block", {
                    method: "post",
                    data: {
                        AUTHOR_ID: data.AUTHOR_ID
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        //показываем сообщение об успехе
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Пользователь заблокирован.", type: "success" });
                        //перезагрузка списка
                        this.refresh(() => { });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                });

        },

        /**
         * Клик по "Добавить отзыв"
         */
        onAddClick() {

            setTimeout(() => {

                if (!this.isAuthorized) {
                    bus.emit("OKRAINA_AUTH_NEED_E_OPEN", {});
                } else {
                    this.showAddReview = true;
                }

            }, 400);

        }
    },


    created() {
        this.loading = true;
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.view.$refs.root
            .querySelector(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.view.$refs.root
            .querySelector(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },

}

</script>

<style lang="scss">
.okraina-v-cr {

    .items {
        background-color: var(--color-block-background);
        padding: 23px 16px 20px;
        border-radius: 20px 20px 0px 0px;
        margin-bottom: 60px;
    }


}

.okraina-c-ri-l {
    .note {
        margin-bottom: 20px;
    }
}
</style>