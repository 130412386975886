
import bus from '@/plugins/bus'
import router from '@/router'

export default {

    /**
     * Инициализирует плагин
     */
    init() {
    
        //window.Telegram.WebApp.setHeaderColor("#56A002");
        //window.Telegram.WebApp.setBottomBarColor("#FFFFFF");
        //window.Telegram.WebApp.setBackgroundColor("#FFFFFF");
        
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', () => {

            //если главный экран
            if (router.currentRoute.value.name == "home") {
                //спросим а нужно ли закрывать приложение
                bus.emit("OKRAINA_CONFIRM_E_SHOW", {
                    message: "Закрыть приложение?",
                    resolveText: "Да",
                    rejectText: "Нет",
                    resolve: function (value) {
                        if(value) {
                            window.Telegram.WebApp.close();
                        }
                    },
                    reverse: false,
                });
                return;
            }

            //если можно по истории назад вернуться
            if(window.history.length > 1) {
                window.history.back();
            } 
        });

        
    }
}