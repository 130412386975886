<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-cancel">

                <form @submit.prevent="cancel" action="">

                    <input type="submit" style="display: none;" />

                    <okraina-control-textarea v-model="reason" label="Причина отмены"
                        :placeholder="'введите причину отмены'" />

                    <div class="btn btn-block btn1" :class="this.loading ? 'wait' : ''" @click="cancel">
                        Отменить
                        <div v-if="this.loading" class="btn-loader"></div>
                    </div>

                </form>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import okrainaControlTextarea from "@/components/controls/textarea.vue";

export default {
    name: "okraina-order-cancel",
    emits: ["canceled", "update:visible"],
    components: {
        okrainaSheet,
        okrainaControlTextarea
    },
    data() {
        return {
            reason: "",
            visibleInternal: this.visible,
            loading: false,
        }
    },

    props: {
        id: {
            type: String,
            default() {
                return null;
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        }
    },

    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
        },
    },

    methods: {
        /**
         * Отменяем заказ
         */
        cancel() {

            if (this.loading) {
                return;
            }
            this.loading = true;
            rest
                .call("order.cancel", {
                    method: "post",
                    data: {
                        ID: this.id,
                        REASON: this.reason
                    },
                })
                .then((data) => {
                    if (!data.SUCCESS) {
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Заказ успешно отменён", type: "success" });

                        bus.emit("OKRAINA_ORDER_E_CANCELED", { id: this.id });

                        //нужно чуть времени, чтобы компонент успел закрыться
                        setTimeout(() => {
                            this.$emit("canceled");
                        }, 1000)
                    }
                })
                .finally(() => {
                    this.visibleInternal = false;
                });
        }

    },
}

</script>

<style lang="scss">
.okraina-order-cancel {
    .btn-block {
        margin-top: 30px;
    }
}
</style>