<template>
  <div class="okraina-radio" :class="{
    checked: checked,
    nolabel: label == '',
    right: right
  }">
    <div class="wrap">
      <label>
        <div class="icon"></div>

        <input type="radio" :class="required ? 'required' : ''" :name="name" v-model="checked" @change="onChange" />

        <template v-if="label != ''">
          <div class="message">
            {{ label }}
          </div>
        </template>
        <template v-else> &nbsp; </template>
      </label>
    </div>
    <div class="description" v-if="description" v-html="description"></div>
  </div>
</template>

<script>
export default {
  name: "okraina-control-radio",
  data() {
    return {
      checked: this.modelValue == this.value ? true : false
    };
  },
  emits: ["update:modelValue", "update:valid", "update:error"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Boolean, Number],
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    description: {
      type: String,
      default() {
        return "";
      },
    },
    right: {
      type: Boolean,
      default() {
        return false
      }
    },
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
    valid: {
      type: Boolean,
      default() {
        return true
      }
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal == this.value) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.checkValid();
    },

    valid: function () {
      if (this.valid) {
        this.$emit("update:error", false);
      }
    }
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.value);
    },

    /**
     * Проверяет валидно ли значение
     */
    checkValid() {
      if (this.required && !(this.modelValue)) {
        this.$emit("update:valid", false);
        return;
      }

      this.$emit("update:valid", true);
    },
  },

  mounted() {
    this.checkValid();
  },
};
</script>

<style lang="scss">
.okraina-radio {
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin: 10px 0px;

  .wrap {
    position: relative;
  }

  &.nolabel {
    width: 20px;
    height: 20px;
    padding-left: 20px;
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: normal;
    ;
  }

  .message {
    color: var(--color-control-label)
  }

  .icon {
    position: absolute;
    top: 0px;
    left: -35px;

    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-radio-off-border);
    transition: border-color ease-in-out .15s;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: var(--color-radio-off-background);
      transition: background-color ease-in-out .15s;
    }
  }

  &.checked {
    .icon {
      border-color: var(--color-radio-on-border);

      &::before {
        background-color: var(--color-radio-on-background);
        ;
      }
    }
  }

  &.right {
    padding-left: 0px;
    padding-right: 35px;

    .icon {
      left: auto;
      right: -35px;
    }
  }

  .description {
    line-height: 1.1;
    font-size: 13px;
    color: var(--color-control-description);
  }

}
</style>