<template>
  <div class="okraina-view" :class="[this.class]" ref="root"
    :style="{ '--view-content-hor-padding': this.contentHorizontalPadding + 'px' }">
    <div class="view-outer">
      <div class="view-top" v-if="this.$slots.top" ref="top">
        <div v-if="!isNative && false" class="view-top-web"></div>
        <slot name="top"></slot>
      </div>
      <div v-else></div>
      <div class="view-inner" :class="{ 'bottom-padding': !hideMenu }">
        <div class="view-header" :class="{ 'no-border': noHeaderBorder }" v-if="this.$slots.header" ref="header">
          <div class="header-bg"></div>

          <div class="header-inner">
            <div>
              <div v-if="showBack" class="back" @click="back()" v-ripple>
                <span class="icon fi fi-arrow-left"></span>
              </div>
            </div>
            <div class="header-center">
              <slot name="header"></slot>
            </div>
            <div>
              <slot name="header-right"></slot>
            </div>
          </div>
        </div>

        <div class="view-content-scroll" @touchmove="onTouchMove()" @scroll="onScroll()" ref="contentScroll"
          v-pull-to-refresh:disabled="refreshDisabled" @refresh="refresh">
          <div class="view-content" :style="{
      paddingTop: this.contentPaddigTop + 'px',
    }">
            <slot name="content"></slot>
          </div>
        </div>

        <div class="view-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "okraina-view",
  components: {},
  emits: ["refresh"],
  data() {
    return {
      contentPaddigTop: 0,
      headerOpacity: 0,
      savedScrollTop: 0,
      active: true
    };
  },
  props: {
    class: {
      type: String,
      default() {
        return "refresh";
      },
    },
    showBack: {
      type: Boolean,
      default() {
        return true;
      },
    },
    refreshDisabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    noHeaderBorder: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hideMenu: {
      type: Boolean,
      default() {
        return false;
      },
    },
    contentHorizontalPadding: {
      type: Number,
      default() {
        return 8;
      }
    }
  },
  computed: {
    ...mapGetters({
      platform: "getPlatform",
      isNative: "isNativePlatform"
    }),

  },
  methods: {
    /**
     * Тач по контенту
     */
    onTouchMove() {
      //this.recalcHeaderOpacity();
    },

    onScroll() {
      //this.recalcHeaderOpacity();
    },

    /**
     * Переобсчёт прозрачности шапки
     */
    recalcHeaderOpacity() {
      let top = this.$refs.contentScroll.scrollTop;
      if (top <= 20) {
        this.headerOpacity = 0;
        return;
      } else if (top > 50) {
        this.headerOpacity = 1;
      } else {
        this.headerOpacity = (top - 50) / 30 + 1;
      }
    },

    /**
     * Перезагрузка экрана
     */
    refresh(e) {
      this.$emit("refresh", e.detail.resolve);
    },

    /**
     * Назад
     */
    back() {
      setTimeout(() => {

        //если переход был с другого домена (например при оплате когда возврат)
        //то back не заполнен, хотя history.length не пустой
        if (window.history.length > 1 && window.history.state && window.history.state.back) {
          this.$router.go(-1)
        } else {
          this.$router.push("/");
        }

      }, 400);

    },

    recalcHeaderHeight() {
      let t = 0;
      if (this.$refs.header) {
        t = this.$refs.header.clientHeight;
      } else if (this.$refs.top) {
        t = 20;
      }

      if (t != this.contentPaddigTop) {
        this.contentPaddigTop = t;
      }

    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    onBeforeRouteLeave() {
      this.savedScrollTop = this.$refs.contentScroll.scrollTop;
    },

    /**
     * Обраотка переход входом на экран
     */
    onBeforeRouteEnter() {
      this.$refs.contentScroll.scrollTop = this.savedScrollTop;
      //this.recalcHeaderOpacity();
    },

    scrollTop() {
      if (this.active) {
        this.$refs.contentScroll.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  },

  mounted() {
    this.recalcHeaderHeight();
  },

  updated() {
    this.recalcHeaderHeight();
  },

  activated() {
    this.active = true;
  },

  deactivated() {
    this.active = false;
  }
};
</script>

<style lang="scss">
.okraina-view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  background-color: var(--color-background);
  transition: background-color ease-in-out 0.15s;

  box-shadow: rgba(1, 0, 61, 0.15) 0px 0px 15px;
  padding-top: var(--safe-area-top);

  .view-outer {
    max-width: 1024px;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    overflow: hidden;
    padding-bottom: var(--safe-area-bottom);

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    background-color: var(--color-view-background);
  }

  .view-top {
    background-color: var(--color-background);
  }

  .view-top-web {
    height: 40px;
    background-color: var(--color-background);
  }

  .view-inner {

    position: relative;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    overflow: hidden;

    &.bottom-padding {
      padding-bottom: 62px;
    }
  }

  .view-header {
    position: absolute;
    max-width: 1024px;
    top: 0px;
    left: 0px;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
    z-index: 200;
    padding: 8px;

    border-bottom: 1px solid rgba(var(--color-text-primary-rgb), 0.1);

    &.no-border {
      border-bottom: 0px;
    }

    .header-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;

      background-color: var(--color-view-background);
      border-radius: 0px;
    }

    .header-inner {
      position: relative;
      z-index: 2;
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: 1fr;
      align-items: center;

      .header-center {
        overflow: hidden;
      }
    }

    .back {

      width: 30px;
      height: 30px;
      position: relative;
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: var(--color-color1);
      }
    }

    h1 {
      font-size: 20px;
      padding: 8px 0px;
      margin: 0px;
      font-weight: 400;
      ;
    }
  }

  .view-content-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: visible;
    padding-bottom: var(--keyboard-height, 0px);
    /**отключаем pull для safari */
    overscroll-behavior: none;
  }

  .view-content {
    position: relative;
    padding: 8px var(--view-content-hor-padding);
    min-height: 100%;
    overflow-x: hidden;
  }

  /*
   Анимация перехода: слайд с лева
  */

  &.slide-left-enter-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    z-index: 999;
  }

  &.slide-left-enter-from {
    transform: translateX(100%);
  }

  &.slide-left-leave-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
  }

  &.slide-left-leave-active:after {
    content: "";
    position: absolute;
    background-color: #000;
    opacity: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 501;

    transition: opacity 0.3s cubic-bezier(.13, .45, .51, .93);
  }

  &.slide-left-leave-to {
    transform: translateX(-30%);
  }

  &.slide-left-leave-to:after {
    opacity: 0.5;
  }

  /*
   Анимация перехода: слайд с права
  */

  &.slide-right-enter-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
  }

  &.slide-right-enter-active:after {
    content: "";
    position: absolute;
    background-color: #000;

    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 500;

    transition: opacity 0.3s cubic-bezier(.13, .45, .51, .93);
  }

  &.slide-right-enter-from {
    transform: translateX(-30%);
  }

  &.slide-right-enter-from:after {
    opacity: 0.5;
  }

  &.slide-right-enter-to:after {
    opacity: 0;
  }

  &.slide-right-leave-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    z-index: 999;
  }

  &.slide-right-leave-to {
    transform: translateX(100%);
  }
}
</style>
