const templates =  {
    "shop.okraina.ru": {
        title: "Актуальная информация для покупателей - Интернет-магазин Окраина",
        tags: [
            {
                name: "description",
                content: "Полезная информация для наших покупателей. Здесь вы можете первыми узнать об акциях и скидках на любимые продукты. Оставляйте свои отзывы и готовьте по нашим рецептам.",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "Покупателям – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Покупателям – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
        ]
    },
}

export default templates;