<template>
    <okraina-sheet v-model:visible="show" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-region-detect">
                <div class="sheet-title text-center">Ваш регион - {{ region.FIELDS.NAME }}?</div>

                <div class="row">
                    <div class="col-5">
                        <div class="btn btn-block btn1" @click="close">Да</div>
                    </div>
                    <div class="col-7">
                        <div class="btn btn-block btn3" @click="selectRegion">Выбрать регион</div>
                    </div>
                </div>

            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import bus from '@/plugins/bus'
import storage from '@/plugins/storage'

import { mapGetters } from "vuex";

export default {
    name: "okraina-region-detect",
    components: {
        okrainaSheet
    },
    emits: ["selectRegion"],
    data() {
        return {
            show: false,
            detected: false
        };
    },

    computed: {
        ...mapGetters({
            regions: "getRegions",
            connectionKey: "getCurrentConnectionKey",
            connection: "getConnection"
        }),

        region() {
            let item = this.regions.find(r => r.PROPS.DOMAIN == this.connection.server);
            return item;
        }
    },
    watch: {
        regions: function () {
            //если регион ещё не выбран ИЛИ список регионов ещё не подгрузился
            if (!this.connectionKey || this.regions.length == 0) {
                return;
            }
            this.detect();
        },
    },

    methods: {

        /**
         * Запускает определение региона
         */
        async detect() {

            //проверку делаем, а то при смене региона перезагружается и this.regions
            //что через watch заставляет ещё раз вызываться detect
            if (this.detected) {
                return;
            }
            this.detected = true;

            let currentTime = Math.floor(Date.now() / 1000);
            //время последнего показа
            let lastShowTime = await storage.get("rd_lst");
            if (lastShowTime == null || parseInt(lastShowTime) + 1 * 60 * 60 < currentTime) {

                storage.set("rd_lst", "" + currentTime);

                bus.emit("OKRAINA_GEO_E_GET_LOCTION", {
                    resolve: (position) => {
                        if (position.coords.latitude && position.coords.longitude) {
                            this.checkDistance(position.coords);
                        }
                    },
                });
            }


        },

        /**
         * Проверяет расстояние по регионам
         */
        checkDistance(coords) {

            let minDistance = 1000000000;
            let key = "";
            this.regions.forEach((reg) => {
                let distance = this.distance(coords.latitude, coords.longitude, parseFloat(reg.PROPS.LAT), parseFloat(reg.PROPS.LONG));
                if (distance < minDistance) {
                    minDistance = distance;
                    key = reg.PROPS.DOMAIN
                }
            });

            //если самый близкий регион не является текущим
            if (key != this.connectionKey) {
                //только если находимся на главном экране или 
                if (this.$route.name == "home" || this.$route.name == "menu") {
                    //то покажем сообщение об этом
                    this.show = true;
                }
            }

        },

        /**
         * Расстояние между двумя точками
         */
        distance(lat1, lon1, lat2, lon2) {
            // distance between latitudes 
            // and longitudes 
            let dLat = (lat2 - lat1) *
                Math.PI / 180.0;
            let dLon = (lon2 - lon1) *
                Math.PI / 180.0;

            // convert to radians 
            lat1 = (lat1) * Math.PI / 180.0;
            lat2 = (lat2) * Math.PI / 180.0;

            // apply formula 
            let a = Math.pow(Math.sin(dLat / 2), 2) +
                Math.pow(Math.sin(dLon / 2), 2) *
                Math.cos(lat1) * Math.cos(lat2);
            let rad = 6371;
            let c = 2 * Math.asin(Math.sqrt(a));
            return rad * c;
        },

        /**
         * Закрывает диалог
         */
        close() {
            this.show = false;
        },

        /**
         * Вызов диалога смены региона
         */
        selectRegion() {

            this.show = false;
            setTimeout(() => {
                bus.emit("OKRAINA_REGION_E_OPEN");
            }, 400)

        }

    },

    created() {
        //если регион ещё не выбран ИЛИ список регионов ещё не подгрузился
        if (!this.connectionKey || this.regions.length == 0) {
            return;
        }
        this.detect();

    },

};
</script>

<style lang="scss"></style>
