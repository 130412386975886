<template>
  <okraina-view class="okraina-v-menu" @refresh="refresh" :showBack="false" ref="view">
    <template v-slot:top>
      <okraina-region @change="onRegionChange" />
    </template>
    <template v-slot:content>

      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <okraina-auth-menu-top v-if="!isAuthorized" />


      <profile-menu v-if="isAuthorized" />

      <personal-menu :items="menu" />

      <div v-if="isAuthorized" class="wrap-logout">
        <div class="btn-block btn btn3 slim" @click="onLogout()"> Выйти из аккаунта </div>
      </div>

      <div v-if="isAuthorized && webFormId > 0" class="wrap-delete">
        <span class="a" @click="openDeleteAccount">Удалить аккаунт</span>
      </div>
      <template v-if="isAuthorized && webFormId > 0">
        <okraina-sheet v-model:visible="showDeleteAccount" :maxHeight="90">
          <template v-slot:body>
            <okraina-web-form :id="webFormId" :sheetMode="true" @onAdd="onAccountDeleteRequestAdd" />
          </template>
        </okraina-sheet>
      </template>

      <contacts-info :socialInfo="socialNetwork" />

      <div class="versions">
        <div class="version">
          Версия веб-сборки: {{ webVersion }}
        </div>
        <div class="version" v-if="isNative && appVersion">
          Версия приложения: {{ appVersion }} ({{ appBuild }})
        </div>
      </div>


    </template>
  </okraina-view>
</template>

<script>
//
import okrainaView from "@/components/view2.vue";
import okrainaAuthMenuTop from "@/components/auth/menu.top.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import profileMenu from "@/components/user/profile.menu.vue";
import personalMenu from "@/components/user/personal.menu.vue";
import contactsInfo from "@/components/user/contacts.info.vue";
import okrainaRegion from "@/components/region.vue";
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"



import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/menu.js";

import { mapGetters } from "vuex";

export default {
  name: "okraina-v-menu",
  components: {
    okrainaView,
    okrainaAuthMenuTop,
    okrainaLoaderPage,
    profileMenu,
    personalMenu,
    okrainaRegion,
    contactsInfo,
    okrainaSheet,
    okrainaWebForm
  },
  data() {
    return {
      loading: true,
      socialNetwork: [],
      menu: [],
      showDeleteAccount: false,
      webVersion: import.meta.env.PACKAGE_VERSION,
    };
  },
  props: {
  },
  computed: {
    ...mapGetters({
      isAuthorized: "isAuthorized",
      platform: "getPlatform",
      isNative: "isNativePlatform",
      appVersion: "getAppVersion",
      appBuild: "getAppBuild",
      siteSettings: "getSiteSettings"
    }),
    webFormId() {
      return this.siteSettings ? this.siteSettings.FORM_ACCOUNT_DELETE_REQUEST : "";
    },
    requests() {
      return {
        socialNetwork: {
          CLASS: "system",
          METHOD: "social.network"
        },
        menu: {
          CLASS: "system",
          METHOD: "menu",
          PARAMS: {
            PLATFORM: this.platform
          }
        },
      }
    }
  },
  methods: {

    /**
     * Обработка выхода пользователя (взял с app.vue)
     */
    onLogout() {
      bus.emit('OKRAINA_APP_E_USER_LOGOUT');
    },


    /**
   * Загрузка данных
   */
    loadData(resolve = false) {
      this.loading = resolve ? false : true;
      //запрашиваем данные
      rest
        .batch(this.requests)
        .then((results) => {
          this.onSuccessLoad(results);
        })
        .finally(() => {
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    onSuccessLoad(results) {
      for (var key in results) {
        let data = results[key];
        if (data.SUCCESS) {
          if (key == "menu") {
            this.menu = data.ITEMS;
          } else if (key == "socialNetwork") {
            this.socialNetwork = data.ITEMS;
          }
        } else {
          //сообщение об ошибке
          bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          return;
        }
      }
    },
    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      this.loadData(resolve);
    },
    /**
     * Обработка смены региона
     */
    onRegionChange() {
      this.loading = true;
      this.refresh();
    },

    /**
     * Открывает диалог удаления аккаунта
     */
    openDeleteAccount() {
      this.showDeleteAccount = true;
    },

    /**
     * Обработка успешного добавления запроса на удаление аккаунта
     */
    onAccountDeleteRequestAdd() {

      this.showDeleteAccount = false;
      setTimeout(() => {
        bus.emit('OKRAINA_APP_E_USER_LOGOUT');

      }, 400);


    }
  },
  created() {
    this.loadData();
  },

  /**
   * Обработка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    //запишем мета данные страницы
    meta.write(metaTemplates);

    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });

  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    if (this && this.$refs) {
      this.$refs.view.onBeforeRouteLeave();
    }
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

};

</script>
<style lang="scss">
.okraina-view.okraina-v-menu {
  .wrap-logout {
    text-align: center;
    padding: 17px 27px 25px 27px;

    .btn-block {
      width: 100%;
    }
  }

  .wrap-delete {
    padding-bottom: 27px;
    text-align: center;
  }

  .versions {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 12px;
    color: var(--color-text-description);
  }

}
</style>