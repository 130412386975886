import { Preferences } from '@capacitor/preferences';
import vkBridge from '@vkontakte/vk-bridge';

const capacitorPreferences = {
    name: "capacitorPreferences",

    set(key, value) {
        Preferences.set({
            key: key,
            value: value,
        });
    },
    async get(key) {
        let v = await Preferences.get({ key: key });
        if (v) {
            return v.value;
        } else {
            return null;
        }
    },
    async keys() {
        return new Promise(resolve => {
            Preferences.keys()
            .then((data) => { 
                if (data.keys) {
                    resolve(data.keys)
                } else{
                    resolve([])
                }
            })
            .catch((error) => {
                // Ошибка
                console.log(error);
                resolve([]);
            });
        });
    },
    remove(key) {
        Preferences.remove({ key: key });
    },
}


const windowLocalStorage = {
    name: "windowLocalStorage",
    set(key, value) {
        window.localStorage.setItem(key, value);
    },
    async get(key) {
        let value = window.localStorage.getItem(key);
        return value;
    },
    async keys() {
        let keys = Object.keys(window.localStorage);
        return keys;
    },
    remove(key) {
        window.localStorage.removeItem(key);
    }
}

const vkStorage = {
    name: "vkStorage",

    set(key, value) {
        vkBridge.send('VKWebAppStorageSet', {
            key: key,
            value: value
        });
    },
    async get(key) {

        let data = await vkBridge.send('VKWebAppStorageGet', { keys: [ key ]});
        let v = data.keys.find(k => k.key == key)
        if (v) {
            return v.value;
        } else {
            return null;
        }
    },
    async keys() {
        return new Promise(resolve => {
            vkBridge.send('VKWebAppStorageGetKeys', {count: 100, offset: 0})
            .then((data) => { 
                if (data.keys) {
                    resolve(data.keys)
                } else{
                    resolve([])
                }
            })
            .catch((error) => {
                // Ошибка
                console.log(error);
                resolve([]);
            });
        });


    },
    remove(key) {
        vkBridge.send('VKWebAppStorageSet', {
            key: key,
            value: ""
        });
    },
}


const storage = {

    transport: capacitorPreferences,
    prefix: "okraina_",
    clearExclude: [
        "last_server",
        "last_login",
        "theme"
    ],

    set(key, value) {

        //если объект, то обязательно конвертируем в строку
        if ((typeof value === 'object') && value !== null) {
            value = JSON.stringify(value);
        }

        this.transport.set(this.prefix + key, value);
    },

    async get(key) {

        let value = await this.transport.get(this.prefix + key);

        if (value !== null) {
            //пытаемся конвертировать из строки в объект
            try {
                value = JSON.parse(value);
            } catch (e) {
                //to do
            }
            if(value == '') {
                value = null;
            }
        }

        return value;
    },

    /**
     * Очищает все данные
     */
    clear() {
        let keys = this.transport.keys();

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key.startsWith(this.prefix)) {

                let k = key.replace(this.prefix, "");

                if (!this.clearExclude.includes(k)) {
                    window.localStorage.removeItem(key);
                }
            }
        }
    },

    /**
     * Перехорд на использование VK
     */
    async migrateToVK() {

        //если тестовый контур (test.app.okraina.ru)
        if(window.location.hostname.includes("test")) {
            this.prefix = "okraina_test_";
        }

        //миграцию не делаем 
        //так как может быть переключение между тестовой средой и продуктивной
        //всё равно из-за другого префика будут другие ключи

        this.transport = vkStorage;
    }

}

export default storage;