<template>
  <div class="okraina-fav-btn" :class="{ in: inFavorites, detail: detail }" @click="onClick">
    <span class="icon fi" :class="inFavorites ? 'fi-heart-filled' : 'fi-heart'"></span>
  </div>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "okraina-catalog-product-favorite-btn",
  components: {},
  data() {
    return {};
  },
  props: {
    productId: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    detail: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    inFavorites() {
      return this.$store.getters.InFavorites(this.productId);
    },
  },

  methods: {
    /**
     * Добавление/удаление из избранного
     */
    onClick() {
      let action = !this.inFavorites ? "add" : "delete";

      //сразу добавляем/удаляем товар в избранное
      this.$store.commit(
        action == "add" ? "addToFavorites" : "removeFromFavorites",
        this.productId
      );

      rest
        .call("favorites." + action, {
          method: "post",
          data: {
            PRODUCT_ID: this.productId,
          },
        })
        .then((data) => {
          //если ошибька
          if (!data.SUCCESS) {
            //отменяем добавление/удаление из избранного
            this.$store.commit(
              action == "add" ? "removeFromFavorites" : "addToFavorites",
              this.productId
            );
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          } else {

            //показываем сообщение об успехе
            if (action == "add" && this.$route.name != "favorites") {

              bus.emit("OKRAINA_MESSAGE_E_SHOW", {
                message: "Товар добавлен в избранное. <a href='/personal/favorites/'>Перейти.</a>",
                type: "success",
                timeout: 5000
              });
            }
          }
        });
    },
  },

  created() { },
};
</script>

<style lang="scss">
.okraina-fav-btn {
  display: inline-block;
  line-height: 1;
  padding: 5px;
  cursor: pointer;

  .icon {
    font-size: 15px;
    color: var(--color-icon-fav);
  }

  &.in {
    .icon {
      color: var(--color-icon-fav-in);
    }
  }

  &.detail {
    .icon {
      font-size: 22px;
    }
  }
}
</style>
