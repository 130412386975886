<template>
    <div class="okraina-cp-raf">

        <template v-if="!showSuccess">

            <div class="product" v-if="product">
                <div>
                    <okraina-catalog-product-photo :item="product" />
                </div>
                <div>
                    <div class="name">{{ product.FIELDS.NAME }}</div>
                </div>
            </div>

            <h2>Ваша оценка</h2>
            <okraina-control-stars label="" :required="true" v-model="mark" v-model:error="markError"
                v-model:valid="markValid" />

            <h2>Поделитесь мнением о товаре</h2>
            <okraina-control-textarea label="" placeholder="Ваш отзыв" :required="true" v-model="text"
                v-model:error="textError" v-model:valid="textValid" />

            <h2>Добавьте фотографии</h2>
            <okraina-control-photos :required="false" v-model="photos" v-model:error="photosError"
                v-model:valid="photosValid" :max="5" />

            <div class="btn-wrap">
                <div class="btn btn-block btn1" v-ripple="'rgba(255, 255, 255, 0.15)'" @click="save"
                    :class="{ wait: saving }">
                    Отправить отзыв
                    <div v-if="saving" class="btn-loader"></div>
                </div>
            </div>

        </template>
        <template v-else>
            <div class="success text-center">
                <div class="title">Спасибо, <br /> Ваш отзыв отправлен</div>

                <div class="stars">
                    <div class="star" v-for="index in 5" :key="index">
                        <span class="fi fi-star"></span>
                    </div>
                </div>

                <div class="description">
                    Мы опубликоуем его после <br /> проверки модератором
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaControlTextarea from "@/components/controls/textarea.vue";
import okrainaControlPhotos from "@/components/controls/photos.vue";
import okrainaControlStars from "@/components/controls/stars.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "okraina-catalog-product-review-add-form",
    components: {
        okrainaCatalogProductPhoto,
        okrainaControlTextarea,
        okrainaControlPhotos,
        okrainaControlStars
    },

    data() {
        return {
            text: "",
            textError: false,
            textValid: false,

            photos: [],
            photosError: false,
            photosValid: false,

            mark: "",
            markError: false,
            markValid: false,

            saving: false,
            showSuccess: false
        };
    },
    inject: ['product'],
    props: {
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
    },

    methods: {

        /**
         * Добавление отзыва
         */
        save() {
            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            rest
                .call("reviews.add", {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.product.FIELDS.ID,
                        TEXT: this.text,
                        PHOTO: this.photos,
                        MARK: this.mark
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.showSuccess = true;
                    } else {

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        /**
         * Проверка данных
         */
        checkValues() {
            this.textError = !this.textValid;
            this.photosError = !this.photosValid;
            this.markError = !this.markValid;

            if (this.textError || this.photosError || this.markError) {
                return false;
            }

            return true;

        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "productId": this.productId,
                "data": this.$data
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name && p.productId == this.productId);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        },
    },

    created() {
        //восстановление данных
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },
}

</script>

<style lang="scss">
.okraina-cp-raf {
    .product {

        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 75px 1fr;
        align-items: center;
        column-gap: 20px;

        .name {
            font-size: 16px;
            color: var(--color-text-title);
            line-height: 1.2;
        }
    }

    .btn-wrap {
        margin-top: 35px;
    }

    .success {
        .title {
            font-size: 18px;
            font-weight: 600;
            color: var(-color-text-title);
        }

        .stars {
            margin-top: 20px;
            margin-bottom: 20px;
            display: inline-block;

            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .star {
            float: left;
            margin-right: 8px;
            font-size: 22px;
            color: var(--color-icon-star);
        }

        .description {
            font-size: 13px;
        }
    }
}
</style>
