import axios from 'axios';
import store from '@/store';
import router from '@/router';
import bus from '@/plugins/bus';
import errorListiner from '@/plugins/error.listiner'
import equal from 'equals';

const rest = {

    timeout: 15000,
    refreshToken: false,

    /**
     * Вызов метода REST API
     * @param {string} method метод, например "auth.login"
     * @param {Object} config конфиг axios (без указания url)
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @returns 
     */
    call(method, config, silent = false) {

        let _config = config;
        _config.timeout = "timeout" in config ? config.timeout : this.timeout;
        //_config.withCredentials = true;
        _config.headers = {};

        if (method != "auth.token") {
            this.addToken(_config);
        }
        this.addSessionData(_config);

        return axios(store.getters.getApiUrl + method, _config)
            .then((response) => {
                return this.processResponse(response, false);
            }).catch((error) => {
                return this.processError(error, method, config, silent);
            })

    },

    /**
     * Выполняет несколько методов REST API
     * @param {Array} requests массив запросов
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     */
    batch(requests, silent = false) {
        let config = {
            method: 'post',
            timeout: this.timeout,
            data: {
                REQUESTS: requests
            },
            //withCredentials: true,
            headers: {}
        };

        this.addToken(config);
        this.addSessionData(config);

        //?XDEBUG_SESSION_START=netbeans-xdebug добавить для дебага
        return axios(store.getters.getApiUrl + "batch", config)
            .then((response) => {
                return this.processResponse(response, true);
            }).catch((error) => {
                return this.processError(error, "batch", config, silent);
            })

    },

    /**
     * Добавляет токен к запросу
     * @param {Object} config конфиг axios (без указания url)
     */
    addToken(config) {

        let token = store.getters.getAccessToken;
        if (token) {
            config.headers["Authorization"] = "Bearer " + store.getters.getAccessToken;
        }
    },

    /**
     * Обрабатывает успешный ответ 
     * @param {Object} response объект axios
     * @param {Boolean} isBatch является ли запрос batch-запросом
     * @returns 
     */
    processResponse(response, isBatch) {

        this.saveSessionData(response);
        this.saveAuthData(response);

        let data = isBatch ? (response.data instanceof Object ? response.data[Object.keys(response.data)[0]] : response.data[0]) : response.data;

        //если API вернул ошибку
        if (!data.SUCCESS) {
            //если требуется авторизация или токен устарел
            if (data.ERROR_CODE === "AUTH_REQUIRED" || data.ERROR_CODE === "TOKEN_EXPIRED") {
                //генерим ошибку:
                //- во первых чтобы обработка ошибок от Axios была централизованная (см. catch ниже), 
                //- а во вторых чтобы ответ не дошёл до компонента-источника вызова (компоненты не должны использовать catch)
                throw this.createError(data.ERROR_TEXT, data.ERROR_CODE, response.config, response.request, response.data);
            }
        }

        return response.data;
    },

    /**
     * Обрабатывает ошибку
     * @param {Error} error 
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @returns 
     * 
     */
    async processError(error, method, config, silent) {

        if (error.response) {

            if (error.code && error.code === "AUTH_REQUIRED") {
                //переход на экран авторзации
                router.replace({ name: 'login' })
            }
            else if (error.code && error.code === "TOKEN_EXPIRED") {
                //возвращаем прамис - обновление токена
                return this.refreshTokenAndContinue(method, config);
            }
            else if (error.response.data && error.response.data.ERROR_TEXT) {
                return error.response.data;
            }
            else {
                //ответ получен, но статус не 200 (4xx,5xx)
                console.log("ответ получен, но статус не 200 (4xx,5xx), статус: " + error.response.status);
            }

        }
        //ответ не получен или запрос вообще не смог отправиться  
        else if (error.request) {

            //если тихий режим
            if (silent) {
                //просто вернём ошибку дальше
                throw error;
            }

            //если браузер имеет доступ к сети
            let isOnline = await this.isOnline();
            if (isOnline) {
                if(method != "system.recoms") {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: "Не удалось подключиться к серверу." });
                }
                //вернём ошибку дальше
                throw error;
            }
            //иначе не удалось выполнить запрос по указанному URL
            else {
                bus.emit("OKRAINA_NETWORK_ERROR_E_SHOW");
                store.commit('setOnline', false);
                return this.waitAndContinue(method, config);
            }

        } else {
            console.log("другая ошибка");
        }

        errorListiner.onError({
            message: error.message,
            name: error.name,
            stack: error.stack,
            config: error.config,
            code: error.code,
            response: error.response && error.response.data ? error.response.data : "",
            status: error.response ? error.response.status : ""
        });
        return error;
    },

    /**
     * Ожидает подключения сети и продолжает выполнения метода REST
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @returns 
     */
    async waitAndContinue(method, config) {

        //создаём прамис, чтобы вызывающий компонент ждал
        let result = await new Promise(resolve => {

            //следим за изменением переменной online
            const watcher = store.watch((state) => { return state.online }, (newVal) => {
                //если поменялся на true
                if (newVal) {
                    //прекращаем отслеживание
                    watcher();

                    //вызываем повторно метод REST API
                    this.call(method, config, false)
                        .then(data => {

                            resolve(data);
                        })
                }
            });
        });

        return result;
    },

    /**
     * Ожидает обновления токена и продолжает выполнения метода REST
     * @param {string} method метод
     * @param {Object} config конфиг axios
     * @returns 
     */
    refreshTokenAndContinue(method, config) {

        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise(resolve => {

            if (this.refreshToken) {

                let onRefresh = () => {
                    //вызываем повторно метод REST API
                    this.call(method, config)
                        .then(data => {
                            resolve(data);
                        });
                    bus.off('OKRAINA_APP_E_REFRESH_TOKEN', onRefresh);
                };

                bus.on('OKRAINA_APP_E_REFRESH_TOKEN', onRefresh);

            } else {

                this.refreshToken = true;

                //вызываем обновление токена
                this.call("auth.token", {
                    method: 'post',
                    data: {
                        REFRESH_TOKEN: store.getters.getRefreshToken
                    }
                })
                    .then(data => {

                        this.refreshToken = false;

                        if (data.SUCCESS) {
                            this.onRefreshToken(data);
                            //передаём приложению данне об успешном обновлении токена
                            bus.emit('OKRAINA_APP_E_REFRESH_TOKEN');

                            //вызываем повторно метод REST API
                            this.call(method, config)
                                .then(data => {
                                    resolve(data);
                                })
                        } else {

                            //передаём приложению факт выхода
                            bus.emit('OKRAINA_APP_E_USER_LOGOUT', data);

                            //показываем сообщение об ошибке
                            bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: "Ваша авторизация истекла. Повторите вход." });

                        }

                    });

            }
        });
    },

    onRefreshToken(data) {
        let arConnection = store.getters.getConnection;

        //меняем в текущем подключени токены
        arConnection["access_token"] = data.ACCESS_TOKEN;
        arConnection["refresh_token"] = data.REFRESH_TOKEN;

        //сохраняем подключение в текущем состоянии
        store.commit("updateConnection", arConnection);
    },

    /**
     * Создаёт ошибкуREST API
     * @param {string} message Текст ошибки
     * @param {string} code код ошибки
     * @param {Object} config конфиг запроса
     * @param {Object} request объект запроса
     * @param {Object} response объект ответа
     * @returns 
     */
    createError(message, code, config, request, response) {
        var error = new Error(message);

        if (code) {
            error.code = code;
        }

        error.request = request;
        error.response = response;
        error.isRestError = true;

        error.toJSON = function toJSON() {
            return {
                // Standard
                message: this.message,
                name: this.name,
                // Microsoft
                description: this.description,
                number: this.number,
                // Mozilla
                fileName: this.fileName,
                lineNumber: this.lineNumber,
                columnNumber: this.columnNumber,
                stack: this.stack,
                // Axios
                config: this.config,
                code: this.code
            };
        };

        return error
    },

    /**
     * Проверяем есть ли подключение к интернету
     */
    async isOnline() {
        if (!window.navigator.onLine) {
            return false;
        }

        // avoid CORS errors with a request to your own origin
        let url = new URL(store.getters.getApiUrl + "app.update");

        // random value to prevent cached responses
        url.searchParams.set('rand', Math.random().toString(36).substring(2, 15))

        try {
            const response = await fetch(url.toString(), { method: 'HEAD' });
            return response.ok;
        } catch {
            return false
        }
    },

    /**
     * Сохраняет сессионные данные (для хранения вспомогательных переменных)
     */
    saveSessionData(response) {
        if(!response || !response.headers || !response.headers["session-data"]) {
            return;
        }

        let data = JSON.parse(response.headers["session-data"]);
        if(!data || data instanceof Array && data.length == 0) {
            return;
        }

        let arConnection = store.getters.getConnection;
        let update = false;
        //меняем в текущем подключени сессионные данные
        if(!arConnection["session"] || arConnection["session"] instanceof Array) {
            arConnection["session"] = {};
            update = true;
        }
        //перезаписываем только те данные, что пришли в запросе
        for (let key in data) {
            if (!equal(arConnection.session[key], data[key])) {
                update = true;
                arConnection.session[key] = data[key];
            }
           
        }

        if(update) {
            //сохраняем подключение в текущем состоянии
            store.commit("updateConnection", arConnection);
        }
  
    },

    /**
     * Отправляет дополнительные заголовки в запросе
     */
    addSessionData(config) {

        let arConnection = store.getters.getConnection;
        if(arConnection.session) {
            config.headers["session-data"] = JSON.stringify(arConnection.session)
        }

    },

    /**
     * Сохраняет данные авторизации
     */
    saveAuthData(response) {

        if(!response || !response.headers || !response.headers["auth-data"]) {
            return;
        }

        let data = JSON.parse(response.headers["auth-data"]);
        if(!data || data instanceof Object && Object.keys(data).length == 0) {
            return;
        }

        let arConnection = store.getters.getConnection;

        //меняем в текущем подключени токены
        arConnection["access_token"] = data.TOKEN;
        arConnection["refresh_token"] = data.REFRESH_TOKEN;
        arConnection["server"] = store.getters.getServer;

        //сохраняем подключение в текущем состоянии
        store.commit("updateConnection", arConnection);

        //устанавливаем подключение текущим
        store.commit("setCurrentConnection", arConnection["server"]);

        //событие входа пользователя
        bus.emit('OKRAINA_APP_E_USER_LOGIN', data);

    }

}

export default rest;