<template>
    <div class="okraina-order-total">

        <h2>Итого</h2>

        <div class="trow" v-if="discountTotal > 0">
            <div class="label">Ваша скидка</div>
            <div class="value discount discount-more" @click="toggleDiscountInfo" v-html="tools.formatPrice(discountTotal, {
                supDecimals: false,
                supCurrency: false,
            })"></div>
        </div>

        <div class="discount-info" v-if="showDiscountInfo">

            <div class="trow" v-if="discount > 0">
                <div class="label">скидка</div>
                <div class="value discount" v-html="tools.formatPrice(discount, {
                    supDecimals: false,
                    supCurrency: false,
                })"></div>
            </div>

            <div class="trow" v-if="couponsApplied.length > 0">
                <div class="label">промокод</div>
                <div class="value" v-html="couponsAppliedValue"></div>
            </div>

            <div class="trow" v-if="price.PAYED_FROM_ACCOUNT > 0">
                <div class="label">оплата бонусами</div>
                <div class="value discount" v-html="tools.formatPrice(price.PAYED_FROM_ACCOUNT, {
                    supDecimals: false,
                    supCurrency: false,
                })"></div>
            </div>

        </div>

        <div class="trow price">
            <div class="label">Сумма без учета скидки</div>
            <div class="value" v-html="tools.formatPrice(price.TOTAL_PRICE, {
                supDecimals: false,
                supCurrency: false,
            })"></div>
        </div>

        <div class="trow">
            <div class="label">Доставка</div>
            <div class="value" v-if="price.DELIVERY_PRICE > 0" v-html="tools.formatPrice(price.DELIVERY_PRICE, {
                supDecimals: false,
                supCurrency: false,
            })"></div>
            <div class="value discount" v-else>Бесплатно</div>
        </div>

        <div class="trow" v-if="cashback > 0">
            <div class="label">Ваш кешбэк</div>
            <div class="value">
                <div class="cashback">
                    +{{ cashback }}
                    <span class="icon fi fi-money"></span>
                </div>

            </div>
        </div>


        <div class="trow sum">
            <div class="label">К оплате</div>
            <div class="value" v-html="tools.formatPrice(price.TOTAL_DISCOUNT_PRICE - price.PAYED_FROM_ACCOUNT, {
                supDecimals: false,
                supCurrency: false,
            })"></div>
        </div>


    </div>
</template>

<script>
import tools from "@/plugins/tools";

export default {
    name: "okraina-order-total",
    data() {
        return {
            tools: tools,
            showDiscountInfo: false
        }
    },
    props: {
        price: {
            type: Object,
            default() {
                return {};
            },
        },
        cashback: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        coupons: {
            type: Array,
            default() {
                return [];
            },
        }


    },

    computed: {

        discount() {
            return this.price.TOTAL_PRICE - this.price.TOTAL_DISCOUNT_PRICE;
        },

        discountTotal() {
            return this.discount + this.price.PAYED_FROM_ACCOUNT;
        },

        couponsApplied() {
            let coupons = this.coupons.filter(coupon => coupon.STATUS == 'APPLYED');
            return coupons;
        },

        couponsAppliedValue() {
            let temp = this.couponsApplied.map((coupon) => {
                return coupon.COUPON
            });

            return temp.join("<br/>");
        }
    },

    methods: {

        /**
         * Показать/скрыть информациб о скидке
         */
        toggleDiscountInfo() {
            this.showDiscountInfo = !this.showDiscountInfo;
        }
    }
}
</script>

<style lang="scss">
.okraina-order-total {

    padding: 15px 20px 80px;

    .trow {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-bottom: 12px;
    }

    .value {
        font-weight: 500;
    }

    .okraina-price {
        font-size: 14px;
        font-weight: 500;
    }

    .sum {

        .label {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text-dark);
        }

        .okraina-price {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .discount {
        color: var(--color-discount);

        .okraina-price {
            color: var(--color-discount);
        }

    }

    .discount-more {
        border-bottom: 1px dashed currentColor;
    }

    .discount-info {
        padding-left: 25px;
    }


    .cashback {
        position: relative;
        display: inline-block;
        padding: 4px 28px 4px 8px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 1;

        background: linear-gradient(281.01deg,
                #2793ca 24.43%,
                #399dad 30.54%,
                #59af79 42.64%,
                #74be4d 54.53%,
                #88c92c 65.96%,
                #97d114 76.79%,
                #a0d605 86.8%,
                #a3d800 95.18%);

        color: #fff;

        .icon {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            font-size: 12px;
        }
    }
}
</style>