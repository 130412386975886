<template>
    <okraina-view :class="'okraina-v-aad'" @refresh="refresh" ref="view" :hideMenu="true" :contentHorizontalPadding="0">

        <template v-slot:header>
            <h1>
                Блог
            </h1>
        </template>

        <template v-slot:header-right>
            <div class="share" @click="share">
                <span class="fi fi-share"></span>
            </div>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="!article">
                    <okraina-not-found caption="Статья не найдена" />
                </template>

                <template v-else>

                    <div class="picture preloaded" v-if="article.FIELDS.PICTURE"
                        v-lazy:background-image="article.FIELDS.PICTURE.SRC">
                        <div class="preload"
                            :style="{ backgroundImage: 'url(\'' + article.FIELDS.PICTURE.PRELOAD + '\')' }">
                        </div>
                        <div class="loader">
                            <okraina-loader-inline />
                        </div>

                        <div class="shadow"></div>
                        <div class="name">{{ article.FIELDS.NAME }}</div>
                    </div>

                    <div class="info">
                        <div class="time" v-if="article.PROPS.READING_TIME">
                            Время прочтения:
                            {{ article.PROPS.READING_TIME }}
                            <template v-if="!article.PROPS.READING_TIME.includes('мин')">минут</template>
                        </div>
                        <div class="description" v-html="article.FIELDS.DETAIL_TEXT" @click="onTextClick"></div>
                    </div>

                </template>

            </template>

        </template>

    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaNotFound from "@/components/not.found.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.articles.detail.js";


import imageNoPhotoPath from "@/assets/images/no-photo.svg";

export default {
    name: "okraina-v-about-articles-detail",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaNotFound,
        okrainaLoaderInline
    },
    data() {
        return {
            loading: true,
            article: false,
            canShow: false,
            imageNoPhoto: imageNoPhotoPath
        };
    },

    props: {
        code: {
            type: String,
            default() {
                return "";
            },
        },
    },
    computed: {

        picture() {
            if (!this.article) {
                return false;
            }

            return this.recipe.FIELDS.PICTURE;
        },
    },


    watch: {
        code: function () {
            this.loadData();
        },
        article: function () {
            this.setMeta();
        },
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            this.loading = resolve ? false : true;


            rest
                .call("blog.getbycode", {
                    method: "post",
                    data: {
                        CODE: this.code,
                        RESIZE_OPTIONS: {
                            WIDTH: 800,
                            HEIGHT: 1000,
                            TYPE: "PROPORTIONAL",
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.article = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                }).finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Повторить загрузку
         */
        retry() {
            this.loadData();
        },

        /**
         * Устанавливает мета данные страницы
         */
        setMeta() {
            if (this.article) {
                meta.write(metaTemplates, this.article.META, this.article);
            }
        },

        /**
        * Поделиться
        */
        share() {
            bus.emit("OKRAINA_APP_E_SHARE", {
                title: 'Поделиться',
                text: this.article.FIELDS.NAME,
                url: 'https://' + this.$store.getters.getServer + this.article.FIELDS.DETAIL_PAGE_URL,
                dialogTitle: 'Поделиться',
            });
        },

        /**
         * Клик в тексте новости
         */
        onTextClick(e) {
            //если клик по ссылке
            if (e.target.tagName == "A") {

                let url = e.target.getAttribute('href');
                //получим относительную ссылку
                url = url.replace("http://" + this.server, "").replace("https://" + this.server, "");

                this.routeClick(e, url);

            }

        },

        /**
         * Маршрутизация клика по ссылке
         */
        routeClick(e, url) {

            let query = this.getUrlQuery(url);

            //куда-то в каталог
            if (url.startsWith("/catalog")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
            //в акции
            else if (url.startsWith("/clients/actions")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
            //в новости
            else if (url.startsWith("/about/news")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
        },

        /**
         * Получает параметры из URL
         */
        getUrlQuery(url) {
            //парсим параметры (например переход к странице поиска)
            const [, paramString] = url.split('?');
            let urlParams = new URLSearchParams(paramString);
            let entries = urlParams.entries();
            let query = {};
            for (let [key, value] of entries) {
                query[key] = value;
            }

            return query;
        }
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setMeta();
        });
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>


<style lang="scss">
.okraina-v-aad {

    h1 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .share {
        padding: 5px 10px;
        font-size: 16px;
        color: var(--color-icon-share);
    }

    .picture {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-bottom: 36.66%;
        padding-top: 0;
        height: 0;

        .shadow {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 100%;

            background: #000;
            opacity: 0.3;
        }

        .name {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            width: 300px;
            transform: translate(-50%, -50%);
            color: var(--color-color8);
            font-size: 18px;
            font-weight: 600;
            line-height: 1.1;
        }
    }

    .info {
        padding: 24px 24px 14px 24px;
        background-color: var(--color-block-background);
        margin-bottom: 8px;
    }

    .time {
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .description {
        color: var(--color-text-primary);

        img[height] {
            height: auto !important;
        }

        table {
            margin-bottom: 20px;

            td {
                padding: 8px 4px;
                vertical-align: top;
                font-size: 12px;

                border-bottom: 1px solid rgba(var(--color-text-primary-rgb), 0.1);
            }



        }

        * {
            text-indent: 0px !important;
            text-align: left !important;
        }
    }
}
</style>