<template>
  <div class="okraina-cp-photo" :class="{ slim: slim, big: big, cover: cover }" @click="onClick">

    <div class="image" v-if="item && item.FIELDS.DETAIL_PICTURE" v-lazy:background-image="item.FIELDS.DETAIL_PICTURE.SRC">
    </div>
    <div class="image" v-else-if="item && item.FIELDS.PREVIEW_PICTURE"
      v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE.SRC">
    </div>
    <div class="image" v-else :style="{ backgroundImage: 'url(' + imageNoPhoto + ')' }"></div>
  </div>
</template>

<script>

import imageNoPhotoPath from "@/assets/images/no-photo.svg";

export default {
  name: "okraina-catalog-product-photo",
  components: {},
  emits: ["click"],
  data() {
    return {
      imageNoPhoto: imageNoPhotoPath
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    slim: {
      type: Boolean,
      default() {
        return false;
      },
    },
    big: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    cover() {
      return (
        this.item &&
        this.item.PROPS.LANDING_PHOTOS &&
        this.item.PROPS.LANDING_PHOTOS.length > 0
      );
    },
  },
  methods: {

    /**
     * Клик по фото
     */
    onClick() {
      this.$emit("click");
    }
  },

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-photo {
  position: relative;
  height: 0px;
  padding-bottom: 95%;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;

  &.big {
    padding-bottom: 65%;
  }

  .image {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.slim {
    border-radius: 5px 5px 0px 0px;

    .image {
      border-radius: 5px 5px 0px 0px;
    }
  }

  &.cover {
    .image {
      background-size: cover;
    }
  }
}
</style>
