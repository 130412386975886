<template>
  <div class="okraina-cp-rating" :class="{ detail: detail }">
    <div class="icon">
      <span class="fi fi-star"></span>
    </div>
    <div class="value">{{ ratingRounded }}</div>
  </div>
</template>

<script>
export default {
  name: "okraina-catalog-product-rating",
  components: {},
  data() {
    return {};
  },
  props: {
    rating: {
      type: [String, Boolean],
      default() {
        return "";
      },
    },
    detail: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    ratingRounded() {
      let value = Math.round(this.rating * 10) / 10;
      if (value > 5) {
        return 5
      }
      return value;
    },
  },
  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-rating {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  font-size: 10px;

  .icon {
    padding-right: 4px;
    color: var(--color-icon-star);
  }

  .value {
    color: var(--color-text-gray);
  }

  &.detail {
    font-size: 15px;

    .icon {
      font-size: 20px;
      padding-right: 10px;
    }
  }
}
</style>
