const templates =  {
    "shop.okraina.ru": {
        title: "Лучшие рецепты от интернет-магазина Окраина",
        tags: [
            {
                name: "description",
                content: "Простые рецепты от «ОКРАИНЫ» – это самые разнообразные блюда, которые готовятся легко и быстро. Вы всегда будете знать, чем порадовать родных или неожиданных гостей. Приятного аппетита!",
            },
            {
                name: "keywords",
                content: "окраина рецепты",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Лучшие рецепты от интернет-магазина Окраина",
        tags: [
            {
                name: "description",
                content: "Простые рецепты от «ОКРАИНЫ» – это самые разнообразные блюда, которые готовятся легко и быстро. Вы всегда будете знать, чем порадовать родных или неожиданных гостей. Приятного аппетита!",
            },
            {
                name: "keywords",
                content: "окраина рецепты",
            }
        ]
    },
}

export default templates;