<template>
    <okraina-sheet-base v-model:visible="visibleInternal" maxWidth="960px" :class="this.class" :noStretch="noStretch"
        :onlyHeaderSwipe="onlyHeaderSwipe" :maxHeight="maxHeight" :minHeight="minHeight">
        <template v-slot:body>
            <slot name="body"></slot>
        </template>

    </okraina-sheet-base>
</template>

<script>
import okrainaSheetBase from '@/components/sheet.base.vue'

export default {
    name: "okraina-sheet",
    emits: ["update:visible"],
    components: {
        okrainaSheetBase,
    },
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        minHeight: {
            type: Number,
            default() {
                return 10;
            },
        },
        maxHeight: {
            type: Number,
            default() {
                return 60;
            },
        },
        class: {
            type: String,
            default() {
                return "";
            },
        },
        noStretch: {
            type: Boolean,
            default() {
                return false
            }
        },
        onlyHeaderSwipe: {
            type: Boolean,
            default() {
                return false
            }
        },
        history: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible && this.history) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        }
    },

    methods: {
        /**
         * Обработка открытия
         */
        onOpen() {

            if(this.history) {

                this.watchVisible = true;

                //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
                let path = window.location.href + (window.location.href.includes("?") ? "&" : "?") + 'sheetOpen';
                window.history.pushState({}, "", path);

                //добавляем закрытие в стэк обработчиков возврата назад
                this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
            }
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },

    },

    /**
     * Обработка создания экземпляра
     */
    created() {
        //если создаётся уже открытым
        if (this.visible) {
            this.onOpen();
        }
    },

}

</script>

<style lang="scss"></style>