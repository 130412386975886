<template>
    <div :class="[form ? 'form-group' : '', error ? 'form-group-error' : '']">
        <div class="okraina-checkbox" :class="{ checked: checked, nolabel: label == '', disabled: disabled }"
            @click.stop="true">
            <div class="wrap">

                <label>
                    <div class="icon">
                        <span class="fi fi-check"></span>
                    </div>

                    <input type="checkbox" :class="required ? 'required' : ''" :value="value" v-model="checked"
                        @change="onChange" :disabled="disabled" />

                    <template v-if="label != ''">
                        <div class="label" v-html="label">
                        </div>
                    </template>
                    <template v-else> &nbsp; </template>

                </label>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "okraina-control-checkbox",
    data() {
        return {
            checked: this.modelValue == this.value
        }
    },
    emits: ["update:modelValue", "update:valid", "update:error"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        value: {
            type: String,
            default() {
                return "";
            },
        },
        defaultValue: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        form: {
            type: Boolean,
            default() {
                return true;
            },
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    watch: {
        modelValue: function (newVal) {
            this.checked = newVal == this.value;
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {

        /**
         * Действие при изменении флага
         */
        onChange() {
            if (this.checked) {
                this.$emit("update:modelValue", this.value);
            } else {
                this.$emit("update:modelValue", this.defaultValue);
            }

        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.checked)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },
    },

    mounted() {
        this.checkValid();
    },
}
</script>

<style lang="scss">
.okraina-checkbox {
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    margin: 5px 0px;

    .wrap {
        position: relative;
    }

    input {
        display: none;
    }

    label {
        margin-bottom: 0px;
        cursor: pointer;
        font-weight: normal;
    }

    .label {
        color: var(--color-control-label);
        margin-bottom: 0px !important;
        font-size: 14px !important;
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -35px;

        width: 20px;
        height: 20px;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-radio-off-border);
        transition: border-color ease-in-out .15s;

        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            color: var(--color-radio-on-background);
        }
    }

    &.checked {
        .icon {
            span {
                opacity: 1;
            }

        }



    }

    &.right {
        padding-left: 0px;
        padding-right: 35px;

        .icon {
            left: auto;
            right: -35px;
        }
    }

    .description {
        line-height: 1.1;
        font-size: 13px;
        color: var(--color-control-description);
    }

    &.disabled {
        opacity: 0.5;
    }

}
</style>