<template>
    <div class="okraina-illustration" :class="{ absolute: absolute }">
        <img class="picture" :src="picture" />

        <div class="caption" v-html="caption"></div>
        <div class="details" v-if="details" v-html="details"></div>

        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "okraina-illustration",
    components: {
    },
    data() {
        return {
        };
    },
    props: {
        picture: {
            type: String,
            default() {
                return "";
            },
        },
        caption: {
            type: String,
            default() {
                return "";
            },
        },
        details: {
            type: String,
            default() {
                return "";
            },
        },
        absolute: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
};
</script>

<style lang="scss">
.okraina-illustration {

    width: 290px;
    text-align: center;
    margin: auto;

    &.absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .picture {
        margin: 0px auto 35px;
    }

    .caption {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
    }

    .details {
        font-size: 13px;
        margin-bottom: 15px;
    }

}
</style>