<template>
  <div class="okraina-amt">
    <div>
      <div class="btn btn3 slim" v-ripple @click="onClick">
        Войти / Регистрация
      </div>
      <div class="text">
        Чтобы совершать покупки, отслеживать заказы и получать персональные
        скидки и баллы
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "okraina-auth-menu-top",
  components: {},
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
.okraina-amt {
  padding: 10px 25px;
  text-align: center;

  .btn {
    margin-bottom: 20px;
    width: 95%;
  }

  .text {
    color: var(--color-color1);
    font-size: 10px;
  }
}
</style>
