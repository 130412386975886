<template>
    <div class="okraina-cp-rt">
        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>
        <template v-else-if="loadingError">

            <div class="text-center">
                Ошибка загрузки данных.
                <br /><br />
                <div class="btn btn1" @click="loadData">Повторить</div>
            </div>
        </template>
        <template v-else>

            <div class="title">
                <h2>
                    <div class="a" @click="onShowClick">
                        Отзывы ({{ total }})
                        <span v-if="total > 0" class="icon fi fi-arrow-right"></span>
                    </div>
                </h2>

            </div>

            <div class="empty" v-if="total == 0">
                Отзывов пока нет, ваш будет первым!</div>

            <div class="items">
                <okraina-catalog-product-review-item v-for="item in items" :key="item.FIELDS.ID" :item="item"
                    @complaint="onComplaint" />
            </div>

            <div class="add-wrap text-center">
                <div class="btn btn-block btn3" @click="openAddReview" v-ripple>Оставить свой отзыв</div>
            </div>

        </template>
    </div>

    <okraina-catalog-product-review-modal v-model:visible="showReviews" :productId="productId" :product="product"
        :total="total" />

    <okraina-catalog-product-review-add-modal v-model:visible="showAddReview" :productId="productId" />

    <okraina-sheet v-model:visible="showComplaint" :maxHeight="90">
        <template v-slot:body>
            <okraina-web-form :id="complaintWebFormId" :sheetMode="true" :extValues="complaintHiddenValues" />
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaCatalogProductReviewItem from "@/components/catalog/product.review.item.vue";
import okrainaCatalogProductReviewModal from "@/components/catalog/product.review.modal.vue";
import okrainaCatalogProductReviewAddModal from "@/components/catalog/product.review.add.modal.vue";
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-review-top",
    components: {
        okrainaLoaderInline,
        okrainaCatalogProductReviewItem,
        okrainaCatalogProductReviewModal,
        okrainaCatalogProductReviewAddModal,
        okrainaSheet,
        okrainaWebForm
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            total: 0,
            items: [],
            showReviews: false,
            showComplaint: false,
            complaintHiddenValues: {},
            showAddReview: false
        };
    },
    props: {
        productId: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        product: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings",
            isAuthorized: "isAuthorized"
        }),

        complaintWebFormId() {
            return this.siteSettings ? this.siteSettings.FORM_PRODUCT_REVIEWS_COMPLAINTS : "";
        }
    },
    watch: {
        productId: function () {
            this.loadData();
        },
    },

    methods: {

        loadData() {
            if (!this.productId) {
                return;
            }
            this.loading = true;

            rest
                .call("reviews.getlist", {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.productId,
                        NAV_PARAMS: {
                            NUM_PAGE: 1,
                            PAGE_SIZE: 2
                        },
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.loadingError = false;
                        this.total = data.PAGER.TOTAL;
                        this.items = data.ITEMS;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;

                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        onShowClick() {
            if (this.total > 0) {
                this.showReviews = true;
            }
        },

        /**
         * Обработка события вызова формы жалобы
         */
        onComplaint(data) {
            this.showComplaint = true;
            this.complaintHiddenValues = data;
        },

        /**
         * Обработка события необходимости обновить данные
         */
        onRefresh() {
            this.loadData();
        },

        /**
        * Открывает окно добавления отзыва
        */
        openAddReview() {

            setTimeout(() => {

                if (!this.isAuthorized) {
                    bus.emit("OKRAINA_AUTH_NEED_E_OPEN");
                } else {
                    this.showAddReview = true;
                }

            }, 400);
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "productId": this.productId,
                "data": {
                    showAddReview: this.showAddReview,
                    showReviews: this.showReviews
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name && p.productId == this.productId);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },

    created() {
        this.loadData();

        //восттановление данных
        this.restoreData();
    },

    mounted() {
        //подписваемся на события
        bus.on("OKRAINA_PRODUCT_REVIEW_E_BLOCK", this.onRefresh);
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },
    unmounted() {
        //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
        bus.off("OKRAINA_PRODUCT_REVIEW_E_BLOCK", this.onRefresh);
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },


}

</script>

<style lang="scss">
.okraina-cp-rt {
    .title {
        position: relative;
        padding-right: 100px;

        .a {
            border-bottom: 0px;
            color: var(--color-text-dark);

            .icon {
                margin-left: 10px;
                font-size: 12px;
                color: var(--color-color1);
            }
        }
    }

    .empty {
        margin-bottom: 15px;
        color: var(--color-color1);
    }
}
</style>