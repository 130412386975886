<template>
    <okraina-illustration :picture="imageNoResults" :caption="caption" :details="details" :absolute="absolute">
        <slot></slot>
    </okraina-illustration>
</template>

<script>
import imageNoResultsPath from "@/assets/images/no-results.svg";
import okrainaIllustration from "@/components/illustration.vue";

export default {
    name: "okraina-not-found",
    components: {
        okrainaIllustration
    },
    data() {
        return {
            imageNoResults: imageNoResultsPath,
        };
    },
    props: {
        caption: {
            type: String,
            default() {
                return "";
            },
        },
        details: {
            type: String,
            default() {
                return "";
            },
        },
        absolute: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
};
</script>

<style lang="scss"></style>