<template>
    <div class="okraina-wf">

        <!--Загружка-->
        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline :sheetMode="sheetMode" />
            </div>
        </template>

        <template v-else-if="form">
            <div class="text-center form-title" v-if="showTitle" v-html="form.FIELDS.NAME"></div>
            <div class="text-center form-description" v-if="form.FIELDS.DESCRIPTION && !showSuccess"
                v-html="form.FIELDS.DESCRIPTION"></div>

            <template v-if="!showSuccess">

                <form @submit.prevent="save" action="">

                    <input type="submit" style="display: none;" />

                    <div class="questions">
                        <template v-for="question in fullQuestions" :key="question.ID">
                            <div class="question"
                                :class="{ 'form-group': question.ANSWERS[0].FIELD_TYPE == 'radio', 'form-group-error': errors[question.ANSWERS[0].FIELD_NAME] }">

                                <div :class="labelClass"
                                    v-if="question.ANSWERS[0].FIELD_TYPE == 'radio' || question.ANSWERS[0].FIELD_TYPE == 'file'">
                                    {{ question.TITLE }}
                                    <span v-if="question.REQUIRED == 'Y'">*</span>
                                </div>

                                <template v-for="(answer, index) in question.ANSWERS" :key="answer.ID">
                                    <template v-if="answer.FIELD_TYPE != 'file' || index == 0">

                                        <!--Текстовое поле-->
                                        <template v-if="answer.FIELD_TYPE == 'text'">
                                            <okraina-control-input :label="question.TITLE" :labelClass="labelClass"
                                                :required="question.REQUIRED == 'Y'" :type="'text'"
                                                v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]"
                                                :mask="getFieldMask(question, answer)" />
                                        </template>

                                        <!--E-mail-->
                                        <template v-else-if="answer.FIELD_TYPE == 'email'">
                                            <okraina-control-input :label="question.TITLE" :labelClass="labelClass"
                                                :required="question.REQUIRED == 'Y'" :type="'text'"
                                                v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" :mask="emailMask" />
                                        </template>

                                        <!-- Текстовая область-->
                                        <template v-else-if="answer.FIELD_TYPE == 'textarea'">
                                            <okraina-control-textarea :label="question.TITLE" :labelClass="labelClass"
                                                :required="question.REQUIRED == 'Y'" v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" />
                                        </template>

                                        <!-- Звезды -->
                                        <template v-else-if="answer.FIELD_TYPE == 'stars'">
                                            <okraina-control-stars :label="question.TITLE" :labelClass="labelClass"
                                                :required="question.REQUIRED == 'Y'" v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]"
                                                :values="getStarsValues(answer)" />
                                        </template>

                                        <!-- Radio-переключатель -->
                                        <template v-else-if="answer.FIELD_TYPE == 'radio'">
                                            <okraina-control-radio :label="answer.MESSAGE"
                                                :required="question.REQUIRED == 'Y'" v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" :value="answer.ID" />
                                        </template>

                                        <!-- Файл -->
                                        <template v-else-if="answer.FIELD_TYPE == 'file'">
                                            <okraina-control-photos :required="false"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" :max="question.ANSWERS.length"
                                                @change="onFilesChanged($event, question)" />
                                        </template>

                                        <!-- Список -->
                                        <template v-else-if="answer.FIELD_TYPE == 'dropdown'">
                                            <okraina-control-select :label="question.TITLE" :labelClass="labelClass"
                                                :required="question.REQUIRED == 'Y'" v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]"
                                                :list="getListValues(answer)" />
                                        </template>

                                        <!-- Дата -->
                                        <template v-else-if="answer.FIELD_TYPE == 'date'">
                                            <okraina-control-input :label="question.TITLE"
                                                :required="question.REQUIRED == 'Y'" :type="'date'"
                                                :labelClass="labelClass" v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" />
                                        </template>

                                        <!-- Чекбокс -->
                                        <template v-else-if="answer.FIELD_TYPE == 'checkbox'">
                                            <okraina-control-checkbox :form="true"
                                                :label="question.TITLE ? question.TITLE : answer.MESSAGE"
                                                :required="question.REQUIRED == 'Y'" :value="answer.ID"
                                                v-model="values[answer.FIELD_NAME]"
                                                v-model:error="errors[answer.FIELD_NAME]"
                                                v-model:valid="valids[answer.FIELD_NAME]" />
                                        </template>


                                    </template>

                                </template>
                            </div>
                        </template>

                        <okraina-control-input v-if="form.FIELDS.USE_CAPTCHA == 'Y'" v-model="captchaWord" type="text"
                            label="Слово на картинке" :captcha="form.CAPTCHA_URL" :required="true"
                            v-model:error="captchaError" v-model:valid="captchaValid" />

                    </div>

                    <div class="btn-wrap">
                        <div class="btn btn-block" v-ripple :class="['btn1', saving ? 'wait' : '']" @click="save">
                            {{ form.FIELDS.BUTTON }}
                            <div v-if="saving" class="btn-loader"></div>
                        </div>
                    </div>

                </form>

            </template>

            <template v-else>

                <slot name="success">
                    <div class="text-center">Ваш запрос успешно отправлен.</div>
                </slot>

            </template>
        </template>

    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaControlTextarea from "@/components/controls/textarea.vue";
import okrainaControlStars from "@/components/controls/stars.vue";
import okrainaControlRadio from "@/components/controls/radio.vue";
import okrainaControlPhotos from "@/components/controls/photos.vue";
import okrainaControlSelect from "@/components/controls/select.vue";
import okrainaControlCheckbox from "@/components/controls/checkbox.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
    name: "okraina-web-form",
    components: {
        okrainaLoaderInline,
        okrainaControlInput,
        okrainaControlTextarea,
        okrainaControlStars,
        okrainaControlRadio,
        okrainaControlPhotos,
        okrainaControlSelect,
        okrainaControlCheckbox
    },
    emits: ["update:values", "onAdd"],
    data() {
        return {
            loading: true,
            form: false,
            values: {},
            errors: {},
            valids: {},
            captchaWord: "",
            captchaValid: false,
            captchaError: false,
            saving: false,
            showSuccess: false
        };
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        sheetMode: {
            type: Boolean,
            default() {
                return false;
            },
        },
        profileFields: {
            type: Object,
            default() {
                return {}
            }
        },
        extData: {
            type: Object,
            default() {
                return {}
            }
        },
        extValues: {
            type: Object,
            default() {
                return {}
            }
        },
        extQuestions: {
            type: Array,
            default() {
                return [];
            },
        },
        showTitle: {
            type: Boolean,
            default() {
                return true;
            }
        },
        labelClass: {
            type: String,
            default() {
                return "label"
            }
        }

    },
    computed: {
        ...mapGetters({
            user: "getUser",
            isAuthorized: "isAuthorized"
        }),
        emailMask() {
            return tools.maskEmail();
        },

        fullQuestions() {
            if (!this.form) {
                return []
            }
            return [].concat.apply(this.extQuestions, this.form.QUESTIONS);
        }
    },

    watch: {
        values: {
            handler() {
                this.$emit("update:values", this.values);
            },
            deep: true
        }
    },


    methods: {

        /**
         * Загрузка данных
         */
        loadData() {
            this.loading = true;

            rest
                .call("form.getbyid", {
                    method: "post",
                    data: {
                        ID: this.id
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.form = data;
                        this.resetValues();
                        this.setProfileFields();
                        this.setExtValues();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        resetValues() {
            this.values = {};

            this.fullQuestions.forEach(q => {
                q.ANSWERS.forEach(a => {

                    this.values[a.FIELD_NAME] = "";
                    this.valids[a.FIELD_NAME] = true;
                    this.errors[a.FIELD_NAME] = false;
                });
            });
        },

        /**
         * Устанавилвает поля из профиля
         */
        setProfileFields() {
            if (!this.isAuthorized || !this.user) {
                return;
            }

            this.form.QUESTIONS.forEach(q => {
                if (this.profileFields[q.SID]) {
                    q.ANSWERS.forEach(a => {
                        this.values[a.FIELD_NAME] = this.user[this.profileFields[q.SID]];
                    })
                }
            })
        },

        /**
         * Уставливает значения по заранее заданому списку
         */
        setExtValues() {

            this.form.QUESTIONS.forEach(q => {
                if (this.extValues[q.SID]) {
                    q.ANSWERS.forEach(a => {
                        this.values[a.FIELD_NAME] = this.extValues[q.SID];
                        if (this.values[a.FIELD_NAME]) {
                            this.valids[a.FIELD_NAME] = true;
                        }
                    })
                }
            })
        },

        /**
         * Добавление резульатат
         */
        save() {

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;


            //берём копию значений, чтобы поменять формат даты
            let values = Object.assign({}, this.values);
            this.prepareValues(values);

            let data = Object.assign({
                ID: this.id,
                VALUES: values,
                CAPTCHA_WORD: this.captchaWord,
                CAPTCHA_SID: this.form.CAPTCHA_SID ? this.form.CAPTCHA_SID : ""
            }, this.extData);

            rest
                .call("form.addresult", {
                    method: "post",
                    data: data,
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.showSuccess = true;
                        this.$emit("onAdd", data);
                    } else {
                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.form.CAPTCHA_SID = data.CAPTCHA_SID;
                            this.form.CAPTCHA_URL = data.CAPTCHA_URL;

                        }
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            //для каждого вопроса
            this.fullQuestions.forEach(q => {
                //для каждого ответа
                q.ANSWERS.forEach(a => {

                    let error = !this.valids[a.FIELD_NAME];
                    //если нужно показать ошибки или если у поля нет ошибок
                    if (showErrors || !error) {
                        this.errors[a.FIELD_NAME] = error;
                    }

                });
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            if (this.form.FIELDS.USE_CAPTCHA == 'Y') {
                let error = !this.captchaValid;
                if (showErrors || !error) {
                    this.captchaError = error;
                }
                if (this.captchaError) {
                    return false;
                }
            }


            return true;
        },

        /**
         * Получает маску для поля
         */
        getFieldMask(question) {
            if (question.SID == "EMAIL") {
                return this.emailMask;
            } else if (question.SID == "PHONE") {
                return tools.maskPhone();
            }

            return null;
        },

        /**
         * Преобразует варианты для типа stars для контрола
         */
        getStarsValues(answer) {

            let values = {};

            answer.VALUES.forEach(v => {
                values[v.ID] = v.MESSAGE
            });
            return values
        },

        /**
         * Обработка измнеения файлов а ответах по вопросу
         */
        onFilesChanged(photos, question) {

            question.ANSWERS.forEach((a, index) => {
                let photo = index < photos.length ? photos[index] : false;
                this.values[a.FIELD_NAME] = photo;
            });
        },

        /**
         * Получает варианты для контрола выбора из списка
         */
        getListValues(answer) {

            let values = [];

            answer.VALUES.forEach(v => {
                values.push({
                    ID: v.ID,
                    NAME: v.MESSAGE
                });
            });
            return values
        },

        /**
         * Подготовка значений перед отправкой
         */
        prepareValues(values) {

            this.form.QUESTIONS.forEach(question => {

                question.ANSWERS.forEach(answer => {

                    //преобразуем даты
                    if (answer.FIELD_TYPE == 'date') {
                        if (values[answer.FIELD_NAME]) {
                            values[answer.FIELD_NAME] = moment(values[answer.FIELD_NAME], "YYYY-MM-DD").format("DD.MM.YYYY");
                        }

                    }
                });
            });

        }

    },

    created() {
        if (this.id > 0) {
            this.loadData();
        }
    }

};
</script>

<style lang="scss">
.okraina-wf {
    .form-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .form-description {
        font-size: 12px;
        margin: 0px 20px 20px;
    }

    .btn-wrap {
        margin-top: 30px;
    }
}
</style>