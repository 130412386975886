<template>
  <div class="okraina-pm" @refresh="refresh">
    <div class="flex-menu row">
      <div class="wrap-block">
        <div class="menu-block">
          <template v-for="(item, index) in itemsFiltered" :key="index">

            <a :href="item.PROPS.HREF" @click.prevent="goto(item.PROPS.HREF)" v-ripple
              :style="{ background: item.PROPS.BACKGROUND_COLOR, color: item.PROPS.TEXT_COLOR }">
              <span class="fi icon" :class="'fi-' + item.PROPS.ICON_SVG + ''"
                :style="{ color: item.PROPS.TEXT_COLOR }"></span>
              <span class="name">{{ item.FIELDS.NAME }}</span>
              <span class="fi arrow fi-arrow-right" :style="{ color: item.PROPS.TEXT_COLOR }"></span>
            </a>
            <div
              v-if="items[index + 1] != null && items[index + 1].FIELDS.IBLOCK_SECTION_ID != item.FIELDS.IBLOCK_SECTION_ID"
              class="line"></div>
            <div v-else-if="items[index + 1] == null" class="line last"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

export default {
  name: "okraina-user-personal-menu",
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      isAuthorized: "isAuthorized",
      manufacturer: "getManufacturer",
    }),

    itemsFiltered() {
      return this.items.filter((item) => {
        return !this.manufacturer.includes('huawei') || item.PROPS.HREF != '/personal/push/'
      })
    }
  },
  methods: {

    goto(url) {

      //задержка, чтобы анимация ripple нормально прошла
      setTimeout(() => {

        //ссылка на сторонний ресурс
        if (url.startsWith("https://")) {
          this.openExternalUrl(url);
        }
        //иначе ссылка внутри приложения
        else {
          this.$router.push({ path: url });
        }


      }, 400);
    },

    openExternalUrl(url) {
      //формируем URL
      if (!url.startsWith("http")) {
        url = "https://" + this.$store.getters.getServer + url;
      }

      //парсим параметры (например переход к странице поиска)
      const [, paramString] = url.split('?');
      let urlParams = new URLSearchParams(paramString);
      let entries = urlParams.entries();
      let query = {};
      for (let [key, value] of entries) {
        query[key] = value;
      }

      //открываем браузер
      this.paymentBrowser = InAppBrowser.create(url, "_system");
    }
  },
};
</script>

<style lang="scss">
.okraina-pm {
  color: #000;
  position: relative;

  .flex-menu {
    padding-top: 15px;

    .label {
      text-align: right;
    }

    .label img {
      padding-right: 10px;
    }

    .wrap-block {
      width: 100%;
    }

    .menu-block {
      display: flex;
      flex-direction: column;

      a {
        background-color: var(--color-block-background);
        color: var(--color-text-dark);
        display: flex;
        border-bottom: none;
        padding: 12px 15px;
        position: relative;
        margin: 1.5px 0px;

        span.fi.arrow {
          position: absolute;
          right: 16px;
          color: var(--color-text-gray);
        }

        span.fi.icon {
          color: var(--color-color3);
          font-size: 18px;
          position: absolute;
        }

        span.name {
          padding-left: 33px;
          font-weight: 500;
        }
      }
    }

    .line {
      margin-bottom: 20px;
      margin: 8px 0 8px 0;

      .last {
        margin-bottom: 0px;
      }
    }
  }
}

.margin-top {
  margin-top: 155px;
}
</style>
  