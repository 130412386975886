const templates =  {
    "shop.okraina.ru": {
        title: "Покупка, доставка и возврат продукции Окраина",
        tags: [
            {
                name: "description",
                content: "Нужна помощь по работе интернет-магазина «ОКРАИНА». Здесь вы найдете всю информацию о том, как купить продукцию, как ее вернуть, а также об условиях доставки и оплаты.",
            },
            {
                name: "keywords",
                content: "окраина доставка, окраина доставка москва, окраина интернет доставка",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "Помощь – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Помощь – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
        ]
    },
}

export default templates;