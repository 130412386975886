<template>
    <div class="okraina-control-select form-group" :class="{ 'form-group-error': error }">
        <template v-if="label != ''">
            <div :class="labelClass">
                {{ label }}
                <span v-if="required && needShowRequired" class="required">*</span>
            </div>
        </template>

        <div class="form-control-wrap no-slot-left" v-on:click="onClick">
            <div class="slot-left"></div>
            <div>
                <div class="form-control" :class="{ required: required }" v-html="valueName"></div>
            </div>
            <div class="slot-right">
                <div class="action">
                    <span class="icon fi fi-dots"></span>
                </div>
            </div>
        </div>

        <okraina-dialog-select :list="list" v-model:visible="showDialog" v-model="valueId"
            v-model:modelName="valueName"></okraina-dialog-select>
    </div>
</template>


<script>
import okrainaDialogSelect from "@/components/dialogs/select.vue";


export default {
    name: "okraina-control-select",
    components: {
        okrainaDialogSelect
    },
    data() {
        return {
            valueId: this.modelValue,
            valueName: this.modelName,
            showDialog: false
        };
    },
    emits: ["update:modelValue", "update:modelName", "update:valid", "update:error", "select"],
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        }
    },
    props: {
        label: {
            type: String,
            default() {
                return "Метка";
            },
        },
        labelClass: {
            type: String,
            default() {
                return "label"
            }
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },

        modelName: {
            type: String,
            default() {
                return "";
            },
        },

        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        method: {
            type: String,
            default() {
                return "";
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return false;
            },
        },
        name: {
            type: String,
            default() {
                return "";
            },
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },

    watch: {
        modelValue: function (newVal) {
            if (newVal != this.valueId) {
                this.valueId = newVal;
            }
        },

        valueId: function () {
            if (this.modelValue != this.valueId) {
                this.checkValid();
                this.$emit("update:modelValue", this.valueId);
                this.$emit("select", this.valueId);
            }

            let selected = this.list.find(i => i.ID == this.valueId);
            if (selected) {
                this.valueName = selected.NAME;
            }
        },
        valueName: function () {
            this.$emit("update:modelName", this.valueName);
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        },
        list: function () {
            if (!this.valueId) {
                return;
            }
            let item = this.list.find(i => i.ID == this.valueId);
            if (!item) {
                this.valueId = "";
                this.valueName = "";
            } else {
                this.valueName = item.NAME;
            }
        }
    },
    methods: {

        onClick() {
            this.showDialog = true;
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.valueId)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

    },

    mounted() {
        this.checkValid();
    },

    created() {
        let selected = this.list.find(i => i.ID == this.valueId);
        if (selected) {
            this.valueName = selected.NAME;
        }
    }
};
</script>

<style lang="scss">
.okraina-control-select {
    .form-control::before {
        content: "\00a0";
    }
}
</style>
