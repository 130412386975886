<template>
    <okraina-view :class="'okraina-v-ai'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                О нас
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError && !loadingCommon">

                <div class="text-center">
                    Ошибка загрузки данных.
                    <br /><br />
                    <div class="btn btn1" @click="retryLoad">Повторить</div>
                </div>

            </template>

            <template v-if="canShow">

                <div class="items">
                    <div class="item" v-for="item in items" :key="item.FIELDS.ID">
                        <div class="title">{{ item.FIELDS.NAME }}</div>

                        <div class="picture preloaded" v-if="item.FIELDS.DETAIL_PICTURE"
                            v-lazy:background-image="item.FIELDS.DETAIL_PICTURE.SRC" @click="openPhoto(item)">

                            <div class="preload" v-if="item.FIELDS.DETAIL_PICTURE.PRELOAD"
                                :style="{ backgroundImage: 'url(\'' + item.FIELDS.DETAIL_PICTURE.PRELOAD + '\')' }"></div>

                            <div class="loader">
                                <okraina-loader-inline />
                            </div>
                        </div>

                        <div class="text" v-html="item.FIELDS.DETAIL_TEXT"></div>
                    </div>
                </div>

            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from '@/plugins/tools.js'
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.index.js";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: "okraina-v-about-contacts",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaLoaderInline
    },
    data() {
        return {
            loading: false,
            loadingCommon: false,
            loadingError: false,
            items: [],
            canShow: false
        };
    },

    computed: {
        gallery() {
            let images = this.items.map((item) => {
                return {
                    caption: item.FIELDS.NAME,
                    src: item.FIELDS.DETAIL_PICTURE.SRC,
                    type: "image",
                    width: item.FIELDS.DETAIL_PICTURE.WIDTH,
                    height: item.FIELDS.DETAIL_PICTURE.HEIGHT,
                    id: item.FIELDS.ID,
                };
            });

            return images;
        },
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .call("company.about", {
                    method: "post",
                    data: {
                        RESIZE_OPTIONS: {
                            WIDTH: 800,
                            HEIGHT: 800,
                            TYPE: "PROPORTIONAL",
                            PRELOAD: "Y"
                        }
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingCommon = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(item) {

            let start = this.gallery.findIndex((i) => i.id == item.FIELDS.ID);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 1,
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "close",
                    ],
                },
                Thumbs: {
                    autoStart: false,
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        tools.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        tools.fancyBoxOnClose();
                    },

                },
            });
        },

    },

    /**
    * Обраотка переход входом на экран
    */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },

    created() {
        this.loading = true;
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-ai {
    .items {
        padding: 0px 11px;
    }

    .item {
        border-radius: 5px;
        background-color: var(--color-block-background);
        margin-bottom: 19px;
        padding: 16px;

        .title {
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 16px;
            color: var(--color-text-title);
        }

        .picture {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            padding-bottom: 44.23%;
            padding-top: 0;
            height: 0;
            margin-bottom: 18px;
            border-radius: 5px;
            overflow: hidden;
        }
    }
}
</style>