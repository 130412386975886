<template>
    <div class="okraina-order-add" :class="{ disabled: disabled }">

        <div class="order-btn btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple="'rgba(255, 255, 255, 0.15)'"
            @click="onClick">
            Оформить заказ
            <div v-if="loading" class="btn-loader"></div>

        </div>
    </div>
</template>

<script>
import tools from "@/plugins/tools";

export default {
    name: "okraina-order-add",
    components: {
    },
    data() {
        return {
            tools: tools
        }
    },
    props: {
        price: {
            type: Object,
            default() {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },

        loading: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },

    methods: {

        onClick() {
            if (!this.loading) {
                this.$emit("add");
            }
        }
    }
}

</script>

<style lang="scss">
.okraina-order-add {

    .order-btn {
        padding: 15px 0px;
    }

    &.disabled {
        .order-btn {
            background-color: var(--color-buy-disabled);
            border-color: var(--color-buy-disabled);
        }
    }
}
</style>