<template>
    <div>

        <okraina-sheet v-model:visible="visibleInternal" :maxHeight="92" :minHeight="92">

            <template v-slot:body>

                <div class="okraina-cp-rm">

                    <div class="title">
                        <h2>
                            Отзывы ({{ total }})
                        </h2>
                    </div>

                    <div class="product" v-if="product">
                        <div>
                            <okraina-catalog-product-photo :item="product" />
                        </div>
                        <div>
                            <div class="name">{{ product.FIELDS.NAME }}</div>
                        </div>
                    </div>

                    <okraina-catalog-product-review-list :productId="productId" @onAuthClick="onAuthClick" />

                    <okraina-fixed-panel :modal="true">
                        <div class="btn btn-block btn1 btn-add" @click="openAddReview" v-ripple>Оставить свой отзыв
                        </div>
                    </okraina-fixed-panel>
                </div>

            </template>

        </okraina-sheet>

        <okraina-catalog-product-review-add-modal v-model:visible="showAddReview" :productId="productId" />
    </div>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaCatalogProductReviewList from "@/components/catalog/product.review.list.vue";
import okrainaCatalogProductReviewAddModal from "@/components/catalog/product.review.add.modal.vue";
import okrainaFixedPanel from '@/components/fixed.panel.vue'
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";

import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-review-modal",
    components: {
        okrainaSheet,
        okrainaCatalogProductReviewList,
        okrainaCatalogProductReviewAddModal,
        okrainaFixedPanel,
        okrainaCatalogProductPhoto
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            showAddReview: false
        };
    },
    inject: ['product'],

    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },

        },
        total: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
    },
    computed: {

        ...mapGetters({
            isAuthorized: "isAuthorized"
        }),

    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
        },
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        }
    },

    methods: {

        /**
         * Открывает окно добавления отзыва
         */
        openAddReview() {

            setTimeout(() => {

                if (!this.isAuthorized) {
                    bus.emit("OKRAINA_AUTH_NEED_E_OPEN", {
                        onClick: () => {
                            this.onAuthClick();
                        }
                    });
                } else {
                    this.showAddReview = true;
                }

            }, 400);


        },

        /**
         * клик по иконке "Закрыть"
         */
        close() {
            this.visibleInternal = false;
        },

        /**
         * Обработка перехода к авторизации
         */
        onAuthClick() {
            this.close();
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "productId": this.productId,
                "data": {
                    showAddReview: this.showAddReview
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name && p.productId == this.productId);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }
    },

    created() {
        //восттановление данных
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },
}

</script>

<style lang="scss">
.okraina-cp-rm {

    .product {

        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 75px 1fr;
        align-items: center;
        column-gap: 20px;

        .name {
            font-size: 16px;
            color: var(--color-text-title);
            line-height: 1.2;
        }
    }

    .btn-add {
        padding: 12px 25px;
    }


}
</style>
