<template>
    <okraina-view :class="'okraina-v-pb'" @refresh="refresh" ref="view" :showBack="false">

        <template v-slot:header>
            <h1>
                Бонусные баллы
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">

                <div class="text-center">
                    Ошибка загрузки данных.
                    <br /><br />
                    <div class="btn btn1" @click="retryLoad">Повторить</div>
                </div>

            </template>

            <template v-if="!loading && !loadingError && canShow">

                <div class="status">

                    <div class="number">{{ status.LEVEL }}</div>
                    <div class="level">
                        Уровень
                    </div>

                    <div class="picture">
                        <img v-lazy="status.PROPS.ICON.SRC" />
                    </div>

                    <div class="bonus-wrap">
                        <div class="inner">
                            <div class="label">Количество бонусов</div>
                            <div>
                                <div class="bonus">
                                    <div class="value">
                                        {{ Math.floor(balance) }}
                                    </div>
                                    <span class="icon fi fi-money"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="barcode-wrap">
                    <div class="barcode" v-if="barcode" v-html="barcode"></div>
                </div>

                <div class="levels">
                    <div class="scale">
                        <div class="bullet"></div>
                        <div class="bullet"></div>
                        <div class="bullet"></div>

                        <div class="progress">
                            <div class="value" :style="{ height: Math.round(cashback.PROGRESS) + '%' }">
                                <div class="bkcolor"
                                    :style="{ background: 'linear-gradient(0deg, ' + this.gradient + ')' }">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="level" v-for="status in reverseStatuses" :key="status.LEVEL">

                        <div class="percent-name" :style="{ color: status.PROPS.COLOR }">
                            <div>
                                <div class="percent">
                                    <div class="icon fi fi-note"></div>
                                    <div class="value">{{ status.PROPS.PERCENT }}%</div>
                                </div>
                            </div>
                            <div>
                                <div class="name">{{ formatLevelName(status) }} уровень</div>
                            </div>

                        </div>

                        <div class="desc">
                            При заказе продукции в интернет-магазине за календарный месяц на сумму от
                            <b>{{ status.PROPS.FROM }} ₽</b>
                        </div>

                    </div>
                </div>

                <div class="history">
                    <okraina-personal-bonus-history v-if="!this.refreshing" />
                </div>
            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaPersonalBonusHistory from '@/components/personal/bonus.history.vue'

import { mapGetters } from "vuex";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/personal.bonuses.js";

export default {
    name: "okraina-v-personal-bonuses",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaPersonalBonusHistory
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            statuses: [],
            refreshing: false,
            canShow: false
        };
    },

    computed: {
        ...mapGetters({
            balance: "getBonus",
            cashback: "getCashback"
        }),

        status() {
            return this.statuses.find(s => s.LEVEL == this.cashback.LEVEL)
        },
        reverseStatuses() {
            let rev = this.statuses.slice(0).reverse();
            return rev;
        },

        gradient() {
            let temp = [];
            this.statuses.forEach((s, i) => {
                temp.push(s.PROPS.COLOR + " " + (i * 50) + "%");
            });

            return temp.join(", ")
        }
    },

    methods: {

        /**
         * Получает данные для запросоы
         */
        getBatchRequest() {

            return {
                statuses: {
                    CLASS: "bonus",
                    METHOD: "statuses"
                },
                status: {
                    CLASS: "bonus",
                    METHOD: "status",
                    PARAMS: {
                        BARCODE: "Y"
                    }
                },
                balance: {
                    CLASS: "bonus",
                    METHOD: "balance",
                },

            };
        },

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {

            if (this.loading) {
                return;
            }
            this.loading = resolve == false ? true : false;

            rest
                .batch(this.getBatchRequest())
                .then((results) => {
                    this.onLoadData(results);
                })
                .finally(() => {
                    this.loading = false;
                    this.refreshing = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Обработка загрузки данных
         */
        onLoadData(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    //список статусов
                    if (key == "statuses") {
                        this.statuses = data.ITEMS;
                    }
                    //текущий статус пользователя
                    else if (key == "status") {
                        this.barcode = data.BARCODE
                        this.$store.commit('setCashback', data);
                    }
                    //текущий баланс пользователя
                    else if (key == "balance") {
                        this.$store.commit('setBonus', data.VALUE);
                    }
                    this.loadingError = false;
                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    this.loadingError = true;
                    return;
                }
            }
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.refreshing = true;
            this.loadData(resolve);
        },

        formatLevelName(status) {
            let str = status.LEVEL;

            if (status.LEVEL == 1) {
                str += "-ый";
            } else if (status.LEVEL == 2) {
                str += "-ой";
            } else if (status.LEVEL == 3) {
                str += "-ий";
            }

            return str;
        }

    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}

</script>

<style lang="scss">
.okraina-v-pb {
    .status {
        margin: 0px 32px;
        position: relative;
        background: var(--color-color3);
        border-radius: 10px;

        color: var(--color-text-top);

        padding: 18px 17px 65px 17px;

        .number {
            font-size: 100px;
            width: 95px;
            text-align: center;

            font-weight: 300;
            line-height: 0.8;
        }

        .level {
            margin-top: 8px;
            font-size: 20px;
            text-transform: uppercase;
        }

        .picture {
            position: absolute;
            top: 18px;
            right: 17px;
            width: 127px;
        }
    }

    .bonus-wrap {
        position: absolute;
        bottom: -6px;
        left: -6px;
        right: -6px;
        height: 59px;
        padding: 10px 23px;

        &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            top: 0px;

            z-index: 1;

            background: rgba(86, 160, 2, 0.15);
            box-shadow: inset 9.46667px -9.46667px 9.46667px rgba(118, 184, 43, 0.15), inset -9.46667px 9.46667px 9.46667px rgba(255, 255, 255, 0.142);
            backdrop-filter: blur(7.952px);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 6px;
        }

        .inner {
            position: relative;
            z-index: 2;
            min-height: 100%;

            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
        }

        .label {
            font-size: 12px;
        }

        .bonus {
            position: relative;
            display: inline-block;
            padding-right: 26px;

            .value {
                font-size: 20px;
            }

            .icon {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);

                font-size: 19px;
            }

        }

    }

    .barcode-wrap {
        margin-top: 30px;
        padding: 0px 32px;
    }

    .barcode {
        padding-top: 24.4%;
        position: relative;


        svg {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;

            height: auto;
            margin: auto;
        }
    }

    .levels {
        position: relative;
        margin-top: 40px;
        padding-left: 60px;
        margin-left: 32px;
        margin-right: 32px;

        .level {
            margin-bottom: 40px;



            .percent-name {
                display: grid;
                grid-template-columns: 70px 1fr;

                align-items: center;
            }

            .percent {
                display: inline-block;
                position: relative;
                text-align: center;
                line-height: 1;
                padding: 5px 0px 5px 8px;

                .icon {
                    position: absolute;
                    bottom: 0px;
                    left: -10px;
                    font-size: 32px;
                }

                .value {
                    font-size: 20px;
                    font-weight: 600;
                }


            }

            .desc {
                margin-top: 9px;
                font-size: 12px;
                color: var(--color-bonus-desc);
                font-weight: 300;
                max-width: 235px;

                b {
                    font-weight: 600;
                    color: var(--color-bonus-desc-b);
                }
            }
        }


    }

    .scale {
        position: absolute;
        top: 0px;
        height: 75%;
        left: 25px;

        &:before {
            content: "";
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);

            border: 4px solid #EDEDED;
        }

        .bullet {
            position: absolute;
            top: 0%;
            left: 50%;
            z-index: 3;

            transform: translate(-50%, -50%);

            background: #FFFFFF;
            box-shadow: 0px 0px 8px rgba(39, 39, 39, 0.2);
            height: 22px;
            width: 22px;
            border-radius: 50%;

            &:nth-of-type(2) {
                top: 50%;
            }

            &:nth-of-type(3) {
                top: 100%;
            }

        }

        .progress {

            position: absolute;
            top: 0px;
            bottom: 0px;

            z-index: 2;

            .value {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);

                width: 12px;
                border-radius: 6px;
                overflow: hidden;

                transition: height 1s ease-out;
            }

            .bkcolor {
                position: absolute;
                left: 0px;
                right: 0px;
                height: 263px;
                bottom: 0px;
            }

        }

    }

    .history {
        padding: 0px 7px;
    }

}
</style>