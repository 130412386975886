<template>
    <okraina-control-input class="okraina-input-time" v-model="value" type="text" :label="label" :name="name"
        :required="required" :readonly="true" :classes="['time']" @click="onClick()">
        <template v-slot:right>
            <div v-if="value == '' || hideClear" class="icon">
                <span class="fi fi-time-outline"></span>
            </div>
            <div v-else-if="!hideClear" class="icon" @click.stop="clear">
                <span class="fi fi-close"></span>
            </div>
        </template>
    </okraina-control-input>

    <okraina-sheet v-model:visible="isOpen" class="okraina-picker-wrap">
        <template v-slot:body>
            <div class="okraina-picker-time">
                <div class="pickers">
                    <VueScrollPicker :options="hours" v-model="hour" />
                    <VueScrollPicker :options="minutes" v-model="minute" />
                </div>
                <div class="buttons">
                    <div class="btn btn1" @click="apply"><span class="fi fi-check"></span></div>
                    <div class="btn btn3" @click="clear"><span class="fi fi-close"></span></div>
                </div>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>

import okrainaControlInput from "@/components/controls/input.vue";
import okrainaSheet from "@/components/sheet.vue"
import { VueScrollPicker } from "vue-scroll-picker";

import moment from "moment";

import "vue-scroll-picker/lib/style.css";

export default {
    name: "sbs-control-time",
    components: {
        okrainaControlInput,
        okrainaSheet,
        VueScrollPicker
    },
    data() {
        return {
            value: this.modelValue,
            isOpen: false,
            hour: this.getHour(),
            minute: this.getMinute(),
        };
    },
    emits: ["update:modelValue"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },

        name: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        hideClear: {
            type: Boolean,
            default() {
                return false;
            },
        },


    },
    computed: {
        hours() {
            return Array.from({ length: 24 }, (_, index) => ("0" + index).slice(-2));
        },
        minutes() {
            return Array.from({ length: 12 }, (_, index) =>
                ("0" + index * 5).slice(-2)
            );
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.$emit("update:modelValue", this.value);
        },
    },
    methods: {
        onClick() {
            this.isOpen = true;
        },

        getHour() {
            if (this.modelValue != undefined && this.modelValue != "") {
                return moment(this.modelValue, "HH:mm").format("HH");
            }
            return "00";
        },

        getMinute() {
            if (this.modelValue != undefined && this.modelValue != "") {
                return moment(this.modelValue, "HH:mm").format("mm");
            }
            return "00";
        },

        clear() {
            this.value = "";
            this.isOpen = false;
        },

        apply() {
            this.value =
                ("0" + this.hour).slice(-2) + ":" + ("0" + this.minute).slice(-2);
            this.isOpen = false;
        }
    },
};
</script>

<style lang="scss">
.okraina-input-time {
    .icon {
        padding: 10px;
    }

    .form-control[readonly] {
        color: var(--color-control-text);
    }
}

.okraina-picker-wrap {
    z-index: 1100 !important;

}

.okraina-picker-time {
    margin-top: 25px;


    .pickers {
        display: flex;
    }

    .vue-scroll-picker {
        height: 170px;
    }

    .vue-scroll-picker-item {
        color: var(--color-block-label);
        font-size: 20px;
        padding: 8px 0px;
        transform: scale(0.8);
    }

    .vue-scroll-picker-item-selected {
        color: var(--color-text-primary);
        transform: scale(1);
        font-weight: 600;
    }

    .vue-scroll-picker-layer-bottom {
        border-top: none;
        background: linear-gradient(to top,
                var(--color-block-background) 0%,
                transparent 26%);
        height: calc(50% - 20px);
    }

    .vue-scroll-picker-layer-top {
        border-bottom: none;
        background: linear-gradient(to bottom,
                var(--color-block-background) 0%,
                transparent 26%);
        height: calc(50% - 20px);
    }

    .vue-scroll-picker-layer-selection {
        top: calc(50% - 20px);
        bottom: calc(50% - 20px);

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 10px;
            right: 10px;
            height: 1px;
            background: var(--color-control-border);
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            left: 10px;
            right: 10px;
            height: 1px;
            background: var(--color-control-border);
        }
    }

    .buttons {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;

        .btn:first-of-type {
            margin-right: 20px;
        }
    }
}
</style>