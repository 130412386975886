const templates =  {
    "shop.okraina.ru": {
        title: "Фирменные магазины",
        tags: [
            {
                name: "description",
                content: "Адреса фирменных магазинов",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "Фирменные магазины",
        tags: [
            {
                name: "description",
                content: "Адреса фирменных магазинов",
            },
            {
                name: "keywords",
                content: "магазины окраина адреса",
            },
        ]
    },
}

export default templates;