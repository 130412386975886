const templates =  {
    "shop.okraina.ru": {
        title: "Способы оплаты",
        tags: [
            {
                name: "description",
                content: "Информаиця об оплате",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Способы оплаты",
        tags: [
            {
                name: "description",
                content: "Информаиця об оплате",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;