import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import storage from '@/plugins/storage'

const settings = {
    state: () => {
        return {
            
            //настройки сайта
            settings: {},

            //список регионов
            regions: []
            
        }
    },
    getters: {

        /**
        * Получает список вариантов сортировки
        */
        getCatalogSorts(state) {
            if (!state.settings.catalogSorts) {
                return [];
            }
            return state.settings.catalogSorts;
        },

        /**
         * Получает сортировку по умолчанию
         */
        getCatalogSortCurrent(state, getters) {
            if (!state.settings.catalogSorts) {
                return false;
            }
            if (getters.getConnection.data.catalogSortId > 0) {
                let item = state.settings.catalogSorts.find(item => item.FIELDS.ID == getters.getConnection.data.catalogSortId);
                if (item) {
                    return item;
                }
            }
            let item = state.settings.catalogSorts.find(item => item.PROPS.DEFAULT);
            return item;
        },

        /**
         * Получает список элементов для поиска по составу
         */
        getCatalogConsist(state) {
            if (!state.settings.catalogConsist) {
                return [];
            }
            return state.settings.catalogConsist;
        },

        /**
         * Получает список соцсетей для авторизации
         */
        getSocialServices(state) {
            if (!state.settings.socialServices) {
                return [];
            }
            return state.settings.socialServices;
        },

        /**
        * Получает настройки сайта
        */
        getSiteSettings(state) {
            if (!state.settings.siteSettings) {
                return false;
            }
            return state.settings.siteSettings;
        },

        /**
         * Получает топик PUSH
         */
        getPushTopic(state, getters) {
            if (!state.settings.siteSettings) {
                return false;
            }

            if (getters.getPlatform == "android") {
                return state.settings.siteSettings.FCM_DEFAULT_TOPIC;
            } else if (getters.getPlatform == "ios") {
                return state.settings.siteSettings.FCM_DEFAULT_TOPIC_IOS;
            }

            return "";

        },

        /**
        * Получает список регионов
        */
        getRegions(state) {
            return state.regions;
        },
 
    },
    mutations: {
        
        /**
         * Устанавилвает список сортировок каталога
         */
        setCatalogSorts: (state, value) => {
            state.settings.catalogSorts = value;
        },

        /**
         * Устанавливает список элементов для поиска по составу
         */
        setCatalogConsist: (state, value) => {
            state.settings.catalogConsist = value;
        },

        /**
         * Устанавливает список элементов для поиска по составу
         */
        setSocialServices: (state, value) => {
            state.settings.socialServices = value;
        },

        /**
         * Устанавливает настройки сайта
         */
        setSiteSettings: (state, value) => {
            state.settings.siteSettings = value;
        },

        /**
         * Устанавливает настройки сайта
         */
        setRegions: (state, value) => {
            state.regions = value;
            storage.set("regions",state.regions);
        },
       
    },

    actions: {

        /**
         * Загружает настройки сайта
         */
        loadSiteSettings(context) {
            //специально обнуляем настройки сайта, чтобы обработчик SUBSCRIBE в push
            //ждал значения настройки FCM_DEFAULT_TOPIC
            context.commit('setSiteSettings', false);
            //запрашиваем данные
            rest
                .batch({
                    catalogSorts: {
                        CLASS: "dictionaries",
                        METHOD: "sorts.getlist",
                    },
                    catalogConsist: {
                        CLASS: "dictionaries",
                        METHOD: "consist.getlist"
                    },
                    settings: {
                        CLASS: "system",
                        METHOD: "settings"
                    },
                    socservices: {
                        CLASS:"auth",
                        METHOD:"soc.services",
                    },
                    regions: {
                        CLASS: "system",
                        METHOD: "regions.getlist"
                    }
                })
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.SUCCESS) {
                            //сортировки каталога
                            if (key == "catalogSorts") {
                                context.commit('setCatalogSorts', data.ITEMS);
                            }
                            //элементы для поиска по составу
                            else if (key == "catalogConsist") {
                                context.commit('setCatalogConsist', data.ITEMS);
                            }
                            //настройки сайта
                            else if (key == "settings") {
                                context.commit('setSiteSettings', data);
                            }
                            //список соцсетей для авториазции
                            else if (key == "socservices") {
                                context.commit('setSocialServices',data.ITEMS);
                            }
                            else if (key == "regions") {
                                context.commit('setRegions',data.ITEMS);
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit('OKRAINA_MESSAGE_E_SHOW', {
                                message: data.ERROR_TEXT
                            });
                            return;
                        }
                    }
                });
        }
    }

}

export default settings;