<template>
    <okraina-view :class="'okraina-v-aj'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Работа в Окраине
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">
                <okraina-illustration :picture="imageError" caption="Ошибка загрузки данных.">
                    <div class="btn btn1 slim" @click="retryLoad">
                        Повторить
                    </div>
                </okraina-illustration>
            </template>

            <template v-if="!loading && !loadingError && canShow">

                <div class="section" v-for="section in sectionsExt" :key="section.ID">
                    <h2>{{ section.NAME }}</h2>

                    <div v-if="section.UF_CONTACT" class="contact">
                        {{ section.UF_CONTACT }}
                    </div>
                    <div v-if="section.UF_PHONE">
                        Телефон:
                        <template v-for="(phone, index) in section.UF_PHONE" :key="index">
                            <a :href="'tel:' + phone" target="_blank">{{ phone }}</a>
                            <template v-if="index < section.UF_PHONE.length - 1">, </template>
                        </template>
                    </div>
                    <div v-if="section.UF_EMAIL">
                        E-Mail:
                        <template v-for="(email, index) in section.UF_EMAIL" :key="index">
                            <a :href="'mailto:' + email" target="_blank">{{ email }}</a>
                            <template v-if="index < section.UF_EMAIL.length - 1">, </template>
                        </template>
                    </div>



                    <div class="items">
                        <template v-for="item in section.ITEMS" :key="item.FIELDS.ID">

                            <okraina-accordion class="item">
                                <template v-slot:title>
                                    {{ item.FIELDS.NAME }}
                                </template>
                                <template v-slot:content>
                                    <div class="property" v-if="item.PROPS.REQUIREMENTS">
                                        <div class="name">Требования:</div>
                                        <div class="value" v-html="item.PROPS.REQUIREMENTS.TEXT"></div>
                                    </div>
                                    <div class="property" v-if="item.PROPS.DUTIES">
                                        <div class="name">Обязанности:</div>
                                        <div class="value" v-html="item.PROPS.DUTIES.TEXT"></div>
                                    </div>
                                    <div class="property" v-if="item.PROPS.TERMS">
                                        <div class="name">Условия:</div>
                                        <div class="value" v-html="item.PROPS.TERMS.TEXT"></div>
                                    </div>
                                </template>
                            </okraina-accordion>

                        </template>

                    </div>

                    <br />
                </div>

                <!--Добавить резюме -->
                <okraina-fixed-panel v-if="webFormId > 0" :isMenuHidden="true">
                    <div class="btn btn-block slim btn1" @click="onAddClick" v-ripple>
                        Заполнить анкету</div>
                </okraina-fixed-panel>

                <okraina-sheet v-model:visible="showForm" :maxHeight="90">
                    <template v-slot:body>
                        <okraina-web-form :id="webFormId" :sheetMode="true"
                            :profileFields="{ PHONE: 'PERSONAL_PHONE' }" />
                    </template>
                </okraina-sheet>

            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaIllustration from "@/components/illustration.vue";
import okrainaAccordion from "@/components/accordion.vue";
import okrainaFixedPanel from '@/components/fixed.panel.vue'
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.job.js";

import imageErrorPath from "@/assets/images/error.svg";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-about-job",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaIllustration,
        okrainaAccordion,
        okrainaFixedPanel,
        okrainaSheet,
        okrainaWebForm
    },
    data() {
        return {
            loading: true,
            loadingError: false,
            items: [],
            sections: [],
            canShow: false,
            showForm: false,

            imageError: imageErrorPath
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_RESUME : "";
        },

        sectionsExt() {
            let list = [];

            this.sections.forEach((section) => {
                let sect = Object.assign({}, section);

                sect.ITEMS = this.items.filter(i => i.FIELDS.IBLOCK_SECTION_ID == section.ID)

                sect.UF_PHONE = sect.UF_PHONE ? sect.UF_PHONE.split(",") : []
                sect.UF_EMAIL = sect.UF_EMAIL ? sect.UF_EMAIL.split(",") : []

                list.push(sect);
            })

            return list;
        }

    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("company.vacancies", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                        this.sections = data.SECTIONS;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },


        onAddClick() {
            this.showForm = true;
        }

    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-aj {

    .view-content {
        padding-bottom: 60px;
    }

    .section {
        padding: 0px 11px;
    }

    .items {
        margin-top: 15px;
    }

    .item {
        .property {
            margin-bottom: 10px;

            .name {
                font-weight: 500;
                font-size: 12px;
                margin-bottom: 5px;
                color: var(--color-text-dark);
            }

            .value {
                br:first-of-type {
                    display: none;
                }
            }

            &:last-of-type {
                margin-bottom: 0px;
            }
        }

    }

}
</style>