<template>
    <div class="item okraina-a-cbm col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="okraina-block">
            <div class="block-title name" v-html="params.NAME"></div>
            <div class="block-description description" v-html="params.DESCRIPTION"></div>


            <div class="map" ref="map"></div>
        </div>


    </div>
</template>

<script>
import ymaps from 'ymaps';

import imagePlacemarkPath from "@/assets/images/placemark.png";

import { mapGetters } from "vuex";

export default {
    name: "okraina-about-contacts-block-map",
    data() {
        return {
            imagePlacemark: imagePlacemarkPath,
        }
    },
    props: {
        params: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        apiKey() {
            return this.siteSettings ? this.siteSettings.YMAP_KEY : "";
        }
    },

    watch: {
        apiKey: function (newVal) {
            if (newVal) {
                this.initMap();
            }
        },
    },

    methods: {

        /**
         * Инициализация карты
         */
        initMap() {

            ymaps
                .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + this.apiKey)
                .then(maps => {
                    let map = new maps.Map(this.$refs.map, {
                        center: [parseFloat(this.params.LAT), parseFloat(this.params.LONG)],
                        zoom: 14,
                        controls: ['geolocationControl', 'typeSelector', 'zoomControl'],
                        behaviors: ['multiTouch', 'dblClickZoom', 'drag']
                    });

                    this.createPlaceMark(maps, map);

                })
                .catch(error => console.log('Failed to load Yandex Maps', error));
        },

        /*
        * Создаёт метки на карте
        */
        createPlaceMark(maps, map) {

            var placemark = new maps.Placemark(
                [parseFloat(this.params.LAT), parseFloat(this.params.LONG)],
                {
                    hintContent: this.params.HINT,
                    balloonContent: this.params.CONTENT
                },
                {
                    balloonShadow: false,
                    iconLayout: 'default#image',
                    iconImageHref: this.imagePlacemark,
                    iconImageSize: [66, 65],
                    iconImageOffset: [-(65 / 2), -65]
                }

            );

            map.geoObjects.add(placemark);
        }
    },

    mounted() {
        if (this.apiKey) {
            this.initMap();
        }
    }
}
</script>

<style lang="scss">
.okraina-a-cbm {
    .okraina-block {
        padding-bottom: 15px !important;
        min-height: 200px !important;
    }

    .block-title {
        margin-bottom: 20px !important;
    }

    @media (min-width: 992px) {
        .map {
            position: absolute;
            top: 50px;
            bottom: 15px;
            left: 23px;
            right: 23px;
        }
    }

    @media (max-width: 991px) {
        .map {
            height: 300px;
        }
    }
}
</style>