<template>
    <div class="okraina-control-dd form-group" :class="{
        'form-group-error': error
    }">

        <div class="label">
            {{ prop.NAME }}
            <span v-if="prop.REQUIED == 'Y'" class="required">*</span>
        </div>

        <div v-if="list.length > 0" class="items">
            <div class="item" :class="{ selected: value == item.ID }" v-for="item in list" :key="item.ID"
                @click="onItemClick(item)">
                {{ item.NAME }}

            </div>
        </div>

        <div v-else-if="isAddressEmpty" class="error">не выбран адрес доставки</div>
        <div v-else class="error">нет доступных дат доставки</div>

    </div>
</template>

<script>

import moment from "moment";

export default {
    name: "okraina-control-delivery-date",
    components: {

    },
    emits: ["update:modelValue", "update:valid", "update:error", "select"],
    data() {
        return {
            value: this.modelValue
        };
    },
    props: {
        prop: {
            type: Object,
            default() {
                return {};
            },
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        isAddressEmpty: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {
        list() {
            let list = [];
            let min = moment(this.prop.MIN_DATE, 'DD/MM/YYYY');
            let max = moment(this.prop.MAX_DATE, 'DD/MM/YYYY');

            for (var m = moment(min); m.diff(max, 'days') <= 0; m.add(1, 'days')) {

                //если дата в списке заблокированных
                if (this.prop.DISABLED_DATES && this.prop.DISABLED_DATES.includes(m.format('DD/MM/YYYY'))) {
                    continue;
                }

                let diff = m.diff(moment().startOf('day'), 'days')
                let name = m.format('DD MMMM  dd');
                if (diff == 0) {
                    name = "сегодня";
                } else if (diff == 1) {
                    name = "завтра";
                }

                list.push({
                    ID: m.format('DD/MM/YYYY'),
                    NAME: name,
                    DAY: m.format('dd'),
                });
            }

            return list;

        },

    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
            if (this.modelValue !== this.value) {
                this.$emit("update:modelValue", this.value);
                this.$emit("select");
            }
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {
        onItemClick(item) {
            this.value = item.ID
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {

            if (this.prop.REQUIED == 'Y' && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },
    },

    mounted() {
        this.checkValid();
    },
}
</script>

<style lang="scss">
.okraina-control-dd {
    .items {
        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            position: relative;
            display: inline-block;
            border-radius: 24px;
            font-weight: 500;
            color: var(--color-text-dark);
            line-height: 1;
            padding: 9px 12px;
            background-color: var(--color-color9);
            margin-right: 8px;
            margin-bottom: 8px;

            &.selected {
                background-color: var(--color-btn1-background);
                color: var(--color-btn1-text);
            }
        }
    }

    .error {
        color: var(--color-error);
        font-weight: 500;
    }
}
</style>

