<template>
  <div class="okraina-cp-rifling" v-if="hasRifling" :class="{ slim: slim }">
    <!--сам товар -->
    <div class="rifling" :class="{ selected: value == item.FIELDS.ID }" @click="onClick(item)">
      <div class="label">упаковка</div>
      <span class="weight">
        <template v-if="isWeight"> ~ </template>

        <template v-if="weight < 1"> {{ weight * 1000 }} г </template>
        <template v-else> {{ weight }} кг </template>
      </span>
    </div>

    <!--нарезка по весу -->
    <template v-if="item.RIFLING && item.RIFLING.length > 0">
      <div class="rifling" :class="{ selected: value == rifling.FIELDS.ID }" v-for="rifling in item.RIFLING"
        :key="rifling.FIELDS.ID" @click="onClick(rifling)">
        <div class="label">нарезка</div>
        <span class="weight">{{ rifling.FIELDS.NAME }}</span>
      </div>
    </template>

    <!--нарезка Как отдельные товары  -->
    <template v-if="item.RIFLING_PRODUCTS && item.RIFLING_PRODUCTS.length > 0">
      <div class="rifling" :class="{ selected: value == rifling.FIELDS.ID }" v-for="rifling in item.RIFLING_PRODUCTS"
        :key="rifling.FIELDS.ID" @click="onClick(rifling)">
        <div class="label">нарезка</div>
        <span class="weight">
          <template v-if="rifling.PROPS.VES < 1">
            {{ rifling.PROPS.VES * 1000 }} г
          </template>
          <template v-else> {{ rifling.PROPS.VES }} кг </template>
        </span>
      </div>
    </template>

    <!--товары другого формата -->
    <template v-if="item.OTHER_FORMAT_PRODUCTS && item.OTHER_FORMAT_PRODUCTS.length > 0">
      <div class="rifling" :class="{ selected: value == rifling.FIELDS.ID }" v-for="rifling in item.OTHER_FORMAT_PRODUCTS"
        :key="rifling.FIELDS.ID" @click="onClick(rifling)">
        <div class="label">упаковка</div>
        <span class="weight">
          <template v-if="rifling.PROPS.CML2_BASE_UNIT && rifling.PROPS.CML2_BASE_UNIT == 'кг'">~</template>
          <template v-if="rifling.PROPS.VES < 1">
            {{ rifling.PROPS.VES * 1000 }} г
          </template>
          <template v-else> {{ rifling.PROPS.VES }} кг </template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "okraina-catalog-product-rifling",
  components: {},
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    modelValue: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    slim: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    hasRifling() {
      return (
        (this.item.RIFLING && this.item.RIFLING.length > 0) ||
        (this.item.RIFLING_PRODUCTS && this.item.RIFLING_PRODUCTS.length > 0) ||
        (this.item.OTHER_FORMAT_PRODUCTS && this.item.OTHER_FORMAT_PRODUCTS.length > 0)
      );
    },
    isWeight() {
      return (
        this.item.PROPS.CML2_BASE_UNIT && this.item.PROPS.CML2_BASE_UNIT == "кг"
      );
    },
    weight() {
      return this.item.PROPS.VES;
    },
  },
  watch: {
    item: function () {
      this.value = this.modelValue;
    },

  },

  methods: {
    onClick(item) {
      this.value = item.FIELDS.ID;
      this.$emit("update:modelValue", this.value);
    },
  },

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-rifling {
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  grid-gap: 1px;
  border: 1px solid var(--color-color5);

  .rifling {

    padding: 5px 5px;
    font-size: 11px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;

    line-height: 1.1;

    outline: 1px solid var(--color-color5);

    color: var(--color-text-dark);
    background-color: var(--color-control-background);

    &:last-of-type {
      border-right-width: 1px;
    }

    &.selected {
      background-color: var(--color-color6);
    }

    .label {
      font-size: 9px;
      font-weight: 600;
    }

    .weight {
      font-size: 11px;
      font-weight: bold;
    }
  }

  &.slim {
    .rifling {
      float: none;
      width: 100%;
    }
  }
}
</style>
