<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-payment">
                <div class="sheet-title">Выберите способ оплаты</div>

                <div class="items">
                    <div class="item" v-for="item in itemsInternal" :key="item.ID">

                        <okraina-radio :label="item.PSA_NAME" :value="item.ID" v-model="value"
                            :description="item.DESCRIPTION" />

                    </div>


                </div>

                <div class="btn btn-block btn1" @click="apply">Принять</div>
            </div>
        </template>
    </okraina-sheet>
</template>


<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaRadio from "@/components/controls/radio.vue";

import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-order-payment",
    components: {
        okrainaSheet,
        okrainaRadio,
    },
    emits: ["update:visible", "apply"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            value: this.modelValue,
            useBonusInternal: this.useBonus,
            sperPayAvailable: true
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        useBonus: {
            type: String,
            default() {
                return "N";
            },
        },

        bonusBalance: {
            type: [Number, String],
            default() {
                return 0;
            },
        },

        price: {
            type: Object,
            default() {
                return {};
            },
        },

    },

    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings",
            isNative: "isNativePlatform",
        }),

        itemsInternal() {

            let list = [];
            this.items.forEach(item => {

                //оплата SberPay
                if (item.PSA_ACTION_FILE == "sberpay") {
                    //доступна ли эта оплата
                    if (!this.sperPayAvailable) {
                        return;
                    }

                }

                list.push(item)
            });

            return list;
        }

    },

    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.value = this.modelValue;
                this.useBonusInternal = this.useBonus;
            }
        },
        modelValue: function () {
            this.value = this.modelValue;
        }
    },

    methods: {

        apply() {
            if (this.value) {
                this.$emit("apply", this.value);
                this.visibleInternal = false;
            }
        }
    },

    created() {

        if (this.isNative) {
            //проверяем доступность мобильного приложения Сбера
            bus.emit("OKRAINA_APP_E_PAYMENT_SBERPAY_AVAILABLE", {
                callback: (result) => {
                    this.sperPayAvailable = result.ready;
                }
            });
        }


    }
}

</script>

<style lang="scss">
.okraina-order-payment {

    .items {
        margin-bottom: 30px;
    }

    .item {
        margin-bottom: 15px;
    }

    .okraina-radio {
        .message {
            font-weight: 600;
        }

        .description {
            color: var(--color-text-dark);
            font-weight: 500;
            margin-top: 5px;

        }
    }
}
</style>
