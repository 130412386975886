<template>
    <okraina-view :class="'okraina-v-ot'" :refreshDisabled="true" ref="view">

        <template v-slot:header>
            <h1>
                Отслеживание заказа
            </h1>
        </template>

        <template v-slot:content>

            <iframe
                :src="origin + '/rest/pages/order/tracking/?ORDER_ID=' + this.orderId + '&trackingid=' + this.hash" />

            <okraina-sheet v-model:visible="showForm" :maxHeight="92" class="okraina-v-ot-web-form">
                <template v-slot:body>
                    <okraina-web-form v-if="webFormId > 0" :id="webFormId" :sheetMode="true"
                        :extData="webFormHiddenValues">

                        <template v-slot:success>

                            <div class="web-form-success">
                                Ваш отзыв успешно отправлен.
                            </div>

                            <div v-if="order.SBER_TIPS" class="btn btn1 btn-block" @click="onShowTipsClick">
                                Оставить чаевые
                            </div>

                        </template>
                    </okraina-web-form>

                </template>
            </okraina-sheet>

            <okraina-sheet v-model:visible="showTips" :maxHeight="92" class="okraina-v-ot-web-form">
                <template v-slot:body>
                    <okraina-sber-tips v-if="order.SBER_TIPS" :min="order.SBER_TIPS.LIMITS.MIN"
                        :max="order.SBER_TIPS.LIMITS.MAX" :orderId="order.FIELDS.ID" />

                </template>
            </okraina-sheet>

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"
import okrainaSberTips from "@/components/personal/sber.tips.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/order.tracking.js";

import { mapGetters } from "vuex";
import { getTransitionRawChildren } from "vue";

export default {
    name: "okraina-v-order-tracking",
    components: {
        okrainaView,
        okrainaSheet,
        okrainaWebForm,
        okrainaSberTips
    },
    data() {
        return {
            showForm: false,
            showTips: false,
            order: false
        };
    },
    props: {
        orderId: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        hash: {
            type: String,
            default() {
                return "";
            },
        }
    },

    computed: {
        ...mapGetters({
            server: "getServer",
            siteSettings: "getSiteSettings"
        }),

        origin() {
            return 'https://' + this.server;
        },

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_DELIVERY_QUALITY : "";
        },

        webFormHiddenValues() {
            return {
                ORDER_ID: this.order ? this.order.TRACKING.ORDER_ID : "",
                GLOBAL_ID: this.order ? this.order.TRACKING.GLOBAL_ID : ""
            };
        }
    },

    methods: {

        /**
         * Загрузка данных 
         */
        loadData() {

            let method = "order.getbyid";
            let data = { ID: this.orderId };
            if (this.hash) {
                method = "order.getbyhash";
                data = { HASH: this.hash };
            }
            //получаем заказ
            rest
                .call(method, {
                    method: "post",
                    data: data,
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.order = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Обработка кросс-сообщений от IFRAME
         */
        onMessage(e) {

            if (!e.origin == this.origin) {
                return;
            }

            if (!(typeof e.data === 'string' || e.data instanceof String)) {
                return;
            }
            let data = JSON.parse(e.data);

            if (data) {
                if (data.action == "SHOW_FORM") {
                    this.showForm = true;
                }

            }

        },

        /**
         * Клик по "Оставить чаевые"
         */
        onShowTipsClick() {

            this.showForm = false;

            //даём время на закрытие окна веб-формы
            setTimeout(() => {
                this.showTips = true;
            }, 400);

        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "data": this.$data
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        },


    },

    /**
     * Обработка создания компонента
     */
    created() {

        //восстановление данных
        this.restoreData();
    },

    mounted() {
        window.addEventListener('message', this.onMessage);
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    beforeUnmount() {
        window.removeEventListener('message', this.onMessage);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {

        next((vm) => {
            //если заказ не загружен или номер заказа изменился
            if (!vm.order || vm.order.FIELDS.ID != vm.orderId) {
                vm.loadData();
            }

        });

        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-ot {

    iframe {
        position: absolute;
        top: 60px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 0px;
    }


}

.okraina-v-ot-web-form {
    .web-form-success {
        font-size: 18px;
        margin-bottom: 40px;
        text-align: center;
    }


}
</style>