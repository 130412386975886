<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-additional">

                <div class="sheet-title">Дополнительные параметры заказа</div>

                <form @submit.prevent="apply" action="">

                    <input type="submit" style="display: none;" />

                    <div class="items">

                        <div class="item" v-for="item in items" :key="item.ID">

                            <!-- Текст -->
                            <template v-if="item.TYPE == 'TEXT'">
                                <okraina-control-input :label="item.NAME" :required="item.REQUIED == 'Y'" :type="'text'"
                                    v-model="values[item.FIELD_NAME]" v-model:error="errors[item.FIELD_NAME]"
                                    v-model:valid="valids[item.FIELD_NAME]" />
                            </template>

                            <!-- Чекбокс -->
                            <template v-else-if="item.TYPE == 'CHECKBOX'">
                                <okraina-control-checkbox :label="item.NAME" :required="item.REQUIED == 'Y'" value="Y"
                                    defaultValue="N" v-model="values[item.FIELD_NAME]"
                                    v-model:error="errors[item.FIELD_NAME]" v-model:valid="valids[item.FIELD_NAME]" />
                            </template>

                            <!-- Список -->
                            <template v-else-if="item.TYPE == 'SELECT'">
                                <okraina-control-select :label="item.NAME" :required="item.REQUIED == 'Y'" :form="true"
                                    v-model="values[item.FIELD_NAME]" v-model:error="errors[item.FIELD_NAME]"
                                    v-model:valid="valids[item.FIELD_NAME]" @select="onValueChange(item)"
                                    :list="getListFromVariants(item)" />
                            </template>

                        </div>

                    </div>

                    <div class="btn btn-block btn1" @click="apply">Принять</div>

                </form>

            </div>
        </template>

    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaControlSelect from "@/components/controls/select.vue";
import okrainaControlCheckbox from "@/components/controls/checkbox.vue";

export default {
    name: "okraina-order-additional",
    components: {
        okrainaSheet,
        okrainaControlInput,
        okrainaControlSelect,
        okrainaControlCheckbox,
    },
    emits: ["apply", "update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            values: {},
            errors: {},
            valids: {}
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        propsValues: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.itemsInternal = this.items;
                //при показе попапа установим значения в те, что на основном экране
                this.setValues();
            }
        },
        valids: {
            handler() {
                //при изменении значений снимим ошибки
                this.checkValues(false);
            },
            deep: true
        }
    },

    methods: {

        /**
         * Устанавилвает значения св-во согласно переданным значениям
         */
        setValues() {
            this.items.forEach((p) => {
                this.valids[p.FIELD_NAME] = false;
                if (p.FIELD_NAME in this.propsValues) {
                    this.values[p.FIELD_NAME] = this.propsValues[p.FIELD_NAME];
                } else {
                    this.values[p.FIELD_NAME] = "";
                }
            });
        },

        /**
         * Применить
         */
        apply() {
            if (!this.checkValues()) {
                return;
            }
            this.$emit("apply", this.values);
            this.visibleInternal = false;
        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            //для каждого свойства
            this.items.forEach((p) => {
                let error = !this.valids[p.FIELD_NAME];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[p.FIELD_NAME] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Получает для св-ва типа "Список" список для передачи в контрол выбора
         */
        getListFromVariants(item) {
            let list = [];
            item.VARIANTS.forEach(v => {
                list.push({
                    ID: v.VALUE,
                    NAME: v.NAME
                });
            });

            return list;
        }
    },

    created() {
        this.setValues();
    }
}
</script>

<style lang="scss">
.okraina-order-additional {

    .items {
        margin-bottom: 30px;
    }
}
</style>