const templates =  {
    "shop.okraina.ru": {
        title: "Новости",
        tags: [

        ]
    },
    "dostavka.okraina.ru": {
        title: "Новости",
        tags: [
        ]
    },
}

export default templates;
