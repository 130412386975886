<template>
  <okraina-view :class="'okraina-v-auth-login'" :refreshDisabled="true" :hideMenu="true" :contentHorizontalPadding="32">

    <template v-slot:header>
      <h1>
        Вход в аккаунт
      </h1>
    </template>

    <template v-slot:content>

      <div class="spacer"></div>

      <template v-if="!isAuthorized">

        <template v-if="mode == 'SMS'">
          <okraina-auth-sms v-model:mode="mode" />
        </template>

        <template v-if="mode == 'LOGIN'">
          <okraina-auth-login />

          <div class="toggle-mode">
            <div class="a" @click="toggleMode">Войти по СМС</div>
          </div>

          <okraina-auth-social-services v-if="platform != 'ios' && launchPlatform != 'vk'" />
        </template>


      </template>

      <template v-else>

        <div class="authorized">

          <div class="title">Вы авторизованы</div>

          <a class="btn btn1 btn-block" href="/catalog/" v-ripple @click.prevent="goToCatalog">Перейти в
            каталог</a>
        </div>

      </template>

    </template>

    <template v-slot:footer v-if="!isAuthorized && mode == 'LOGIN'">

      <div class="register-info">
        У Вас ещё нет профиля?<br />
        <span class="a" @click="goToRegistration">Зарегистрироваться</span>
      </div>

    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaAuthSms from "@/components/auth/auth.sms.vue";
import okrainaAuthLogin from "@/components/auth/login.vue";
import okrainaAuthSocialServices from "@/components/auth/social.services.vue";

import { mapGetters } from "vuex";

export default {
  name: "okraina-v-auth-login",
  components: {
    okrainaView,
    okrainaAuthSms,
    okrainaAuthLogin,
    okrainaAuthSocialServices
  },
  data() {
    return {
      mode: "SMS"
    };
  },
  computed: {
    ...mapGetters({
      isAuthorized: "isAuthorized",
      platform: "getPlatform",
      launchPlatform: "getLaunchPlatform"
    }),
  },
  methods: {

    /**
     * Смена режима
     */
    toggleMode() {
      this.mode = this.mode == "SMS" ? "LOGIN" : "SMS";
    },

    /**
     * Переход в каталог
     */
    goToCatalog() {

      setTimeout(() => {
        this.$router.replace({ name: "catalog" });
      }, 500);

    },

    goToRegistration() {
      this.$router.push({ name: "register" });
    }

  },
};
</script>

<style lang="scss">
.okraina-v-auth-login {
  .view-outer {
    background-color: var(--color-block-background);
  }

  .view-content {
    padding-top: 15px;
  }

  .register-info {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 35px;
  }

  .authorized {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    text-align: center;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-text-title);
      margin-bottom: 20px;
    }

  }

  .toggle-mode {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>