<template>
    <div class="okraina-cso" v-if="visible">

        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>
        <template v-else>

            <okraina-catalog-item-line v-for="item in items" :key="item.FIELDS.ID" :item="item" />

        </template>

    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaCatalogItemLine from "@/components/catalog/item.line.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import tools from "@/plugins/tools";

export default {
    name: "okraina-catalog-search-offers",
    components: {
        okrainaLoaderInline,
        okrainaCatalogItemLine
    },
    data() {
        return {
            loading: false,
            items: []
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData() {
            this.loading = true;

            //запрашивает даные
            rest
                .call("catalog.products.getlist", {
                    method: "post",
                    data: {
                        FILTER: {
                            0: {
                                "LOGIC": "OR",
                                "!PROPERTY_NEWPRODUCT": false,
                                "!PROPERTY_DISPLAY_SEARCH_BOTTOM": false
                            }
                        },
                        ORDER: {
                            "propertysort_DISPLAY_SEARCH_BOTTOM": "DESC",
                            "rand": "ASC"
                        },
                        NAV_PARAMS: {
                            NUM_PAGE: 1,
                            PAGE_SIZE: 5,
                        },

                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },

    created() {
        this.loadData();
    }
};

</script>

<style lang="scss">
.okraina-cso {
    margin-top: 25px;
}
</style>