<template>
  <okraina-view :class="'okraina-v-catalog-sections'" @refresh="refresh" ref="view" :noHeaderBorder="true">
    <template v-slot:header>
      <okraina-catalog-search-input :showBack="false" />
    </template>
    <template v-slot:content>
      <h1>Каталог</h1>

      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <div class="sections">
        <okraina-catalog-sections v-model:loading="loading" :filter="filter" />
      </div>
    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogSections from "@/components/catalog/sections.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/catalog.sections.js";

export default {
  name: "okraina-v-catalog-sections",
  components: {
    okrainaView,
    okrainaCatalogSearchInput,
    okrainaLoaderPage,
    okrainaCatalogSections,
  },
  data() {
    return {
      loading: true,
      items: [],
      filter: {
        DEPTH_LEVEL: "1",
      }
    };
  },
  methods: {
    /**
     * Загружает данные
     */
    loadData(resolve) {
      //покажем экран загрузки
      this.loading = resolve ? false : true;

      //запрашивает даные
      rest
        .call("catalog.sections.getlist", {
          method: "post",
          data: {
            ORDER: {
              SORT: "ASC",
              NAME: "ASC",
            },
            FILTER: {
              DEPTH_LEVEL: "1",
            },
            RESIZE_OPTIONS: {
              WIDTH: "100",
              HEIGHT: "100",
              TYPE: "EXACT",
            },
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            this.items = data.ITEMS;
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
          if (resolve) {
            setTimeout(resolve, 200);
          }
        });
    },

    /**
     * Клик по разделу
     */
    onClick(item) {
      this.$router.push(item.SECTION_PAGE_URL);
    },

    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      this.loadData(resolve);
    },
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    //запишем мета данные страницы
    meta.write(metaTemplates);

    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },
};
</script>

<style lang="scss">
.okraina-v-catalog-sections {
  .sections {
    margin: 0px -8px;
  }
}
</style>
