<template>
  <div class="okraina-al">

    <form @submit.prevent="onSubmit" action="">

      <input type="submit" style="display: none;" />

      <okraina-control-input label="Ваш телефон, логин или E-Mail" v-model="login" :required="true"
        v-model:error="errors.login" v-model:valid="valids.login" />

      <okraina-control-input label="Пароль" :required="true" :type="showPassword ? 'text' : 'password'" v-model="password"
        v-model:error="errors.password" v-model:valid="valids.password">

        <template v-slot:right>
          <div v-if="!showPassword" class="action" @click="onShowPasswordClick">
            <span class="icon fi fi-eye"></span>
          </div>
          <div v-else class="action" @click="onHidePasswordClick">
            <span class="icon fi fi-eye-close"></span>
          </div>
        </template>
      </okraina-control-input>

      <okraina-control-input v-if="needCaptcha" v-model="captchaWord" type="text" label="Слово на картинке"
        :captcha="captchaImg" :required="true" v-model:error="errors.captcha" v-model:valid="valids.captcha" />

      <div class="restore">
        <span class="a" @click="goToRestore">забыли пароль?</span>
      </div>

      <div class="btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple v-on:click="onSubmit">
        Войти
        <div v-if="loading" class="btn-loader"></div>
      </div>
    </form>

  </div>
</template>

<script>
import okrainaControlInput from "@/components/controls/input.vue";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "okraina-auth-login",
  components: {
    okrainaControlInput,
  },
  data() {
    return {
      loading: false,
      login: "",
      password: "",
      needCaptcha: false,
      captchaSid: "",
      captchaImg: "",
      captchaWord: "",
      showPassword: false,

      errors: {
        login: false,
        password: false,
        captcha: false
      },
      valids: {
        login: false,
        password: false,
        captcha: false
      },
    };
  },
  methods: {


    /**
     * Проверяет значения
     */
    checkValues(showErrors = true) {

      Object.keys(this.valids).forEach((field) => {

        let error = !this.valids[field];

        //если нужно показать ошибки или если у поля нет ошибок
        if (showErrors || !error) {
          this.errors[field] = error;
        }
      });

      for (var key in this.errors) {

        //если капча не требуется, то не проверяем её
        if (key == "captcha" && !this.needCaptcha) {
          continue;
        }

        if (this.errors[key]) {
          return false
        }
      }

      return true;
    },

    onSubmit: function () {
      if (!this.checkValues()) {
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      //запрашивает автооризацию
      rest
        .call("auth.login", {
          method: "post",
          data: {
            LOGIN: this.login,
            PASSWORD: this.password,
            CAPTCHA_WORD: this.captchaWord,
            CAPTCHA_SID: this.captchaSid,
          },
        })
        .then((data) => {
          //если ошибка входа
          if (!data.SUCCESS) {
            //если требуется капча
            if (data.CAPTCHA_SID) {
              this.needCaptcha = true;
              this.captchaSid = data.CAPTCHA_SID;
              this.captchaImg = data.CAPTCHA_URL;
            }

            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          } else {
            //обработка успешного входа
            this.onSuccess();
          }

          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Обработка успехного входа
     */
    onSuccess: function () {

      //с токенами здесь не работаем - это делает плагин rest

      this.login = "";
      this.password = "";
      this.needCaptcha = false;

      //дадим время, чтобы сбросились кеши экранов
      setTimeout(() => {

        //перемещаемся по истории назад
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");

      }, 100);
    },

    /**
     * Переход к восстановлению пароля
     */
    goToRestore() {
      this.$router.push({ name: "restore" });
    },

    /**
     * Клик по иконке "показать пароль"
     */
    onShowPasswordClick() {
      this.showPassword = true;
    },

    /**
     * Клик по иконке "скрыть пароль"
     */
    onHidePasswordClick() {
      this.showPassword = false;
    },

    /**
     * Сохраняет данные для восстановления приложения из background
     */
    saveDataForRestore() {
      return {
        "component": this.$options.name,
        "data": this.$data
      }
    },

    /**
     * Восстановление данных при восстановлении приложения из background
     */
    restoreData() {
      let restoreData = this.$store.getters.getRestoreData;
      let params = restoreData.find((p) => p.component == this.$options.name);
      if (params) {
        for (var key in params.data) {
          let value = params.data[key];
          this.$data[key] = value;
        }

        //стираем данные. чтобы при reload не применились опять
        restoreData = restoreData.filter(p => p != params);
        this.$store.commit("setRestoreData", restoreData);
      }
    }


  },

  created() {

    //восстановление данных
    this.restoreData();
  },

  mounted() {
    //подписываемся на сохранение данных для восстановления приложения из background
    this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
  },

  unmounted() {
    //отписываемся от сохранения данных для восстановления приложения из background
    this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
  },


};

</script>


<style lang="scss">
.okraina-al {

  margin-bottom: 25px;

  .restore {
    margin-bottom: 25px;
  }

}
</style>
