import store from '@/store';

const processing = {
    getImage(item) {
        if (item.FIELDS.DETAIL_PICTURE) {
            return item.FIELDS.DETAIL_PICTURE.MIDDLE;
        } else if (item.FIELDS.PREVIEW_PICTURE) {
            return item.FIELDS.PREVIEW_PICTURE.MIDDLE;
        } 
        return false;
    },

    getOgImage(item) {
        let image = this.getImage(item)

        if(image) {
            return image.SRC;
        }
        return "https://"+store.getters.getServer+"/images/logo/logo-share.jpg";
    },

    getOgImageWidth(item) {
        let image = this.getImage(item)
        if(image) {
            return ""+image.WIDTH;
        }
        return "420";
    },

    getOgImageHeight(item) {
        let image = this.getImage(item)
        if(image) {
            return ""+image.HEIGHT;
        }
        return "420";
    },
}

const templates =  {
    "shop.okraina.ru": {
        title: "ELEMENT_META_TITLE",
        tags: [
            {
                name: "description",
                content: "ELEMENT_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "ELEMENT_META_KEYWORDS",
            },
            {
                name: "og:type",
                content: "website"
            },
            {
                name: "og:image",
                content: (item) => {
                    return processing.getOgImage(item);
                }
            },
            {
                name: "og:image:width",
                content: (item) => {
                    return processing.getOgImageWidth(item);
                }
            },
            {
                name: "og:image:height",
                content: (item) => {
                    return processing.getOgImageHeight(item);
                }
            },
            {
                name: "og:title",
                content: "ELEMENT_META_TITLE",
            },
            {
                name: "og:description",
                content: "ELEMENT_META_DESCRIPTION",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "ELEMENT_META_TITLE",
        tags: [
            {
                name: "description",
                content: "ELEMENT_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "ELEMENT_META_KEYWORDS",
            },
            {
                name: "og:type",
                content: "website"
            },
            {
                name: "og:image",
                content: (item) => {
                    return processing.getOgImage(item);
                }
            },
            {
                name: "og:image:width",
                content: (item) => {
                    return processing.getOgImageWidth(item);
                }
            },
            {
                name: "og:image:height",
                content: (item) => {
                    return processing.getOgImageHeight(item);
                }
            },
            {
                name: "og:title",
                content: "ELEMENT_META_TITLE",
            },
            {
                name: "og:description",
                content: "ELEMENT_META_DESCRIPTION",
            },
        ]
    },
}
export default templates;