<template>
  <div class="okraina-update" :class="[visible ? 'show' : '']" @click="hide">
    <div class="icon">
      <span class="fi fi-close"></span>
    </div>
    <div class="message">
      Доступна новая версия приложения.
      <br />
      Она уже загружена и будет применена при следующем запуске.
    </div>
    <div class="btn btn2 slim" @click="apply">Применить сейчас</div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";

export default {
  name: "okraina-update",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    apply() {
      bus.emit("OKRAINA_UPDATE_E_APPLY");
    },
  },
  mounted() {
    //подписваемся на событие показа сообщения
    bus.on("OKRAINA_UPDATE_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("OKRAINA_UPDATEE_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.okraina-update {
  position: fixed;
  bottom: var(--safe-area-bottom, 0px);
  transform: translateY(100%);
  visibility: hidden;
  right: 0px;
  left: 0px;
  padding: 18px 15px;
  text-align: left;
  z-index: 8500;
  font-size: 14px;
  border-radius: 0px;

  opacity: 0;

  transition: transform 0.2s ease-out, opacity 0.2s ease-out,
    visibility 0s linear 200ms;

  background-color: var(--color-message-success-background);
  color: var(--color-message-success-text);

  .icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
  }

  .message {
    margin-bottom: 10px;
  }

  &.show {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out,
      visibility 0s linear;
  }
}
</style>