<template>
  <div class="okraina-loader-page">
    <div class="loader">
      <okraina-loader-inline />
    </div>
  </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";

import { mapGetters } from "vuex";
export default {
  name: "okraina-loader-page",
  components: {
    okrainaLoaderInline,
  },
  computed: {
    ...mapGetters({
      platform: "getPlatform"
    }),

  },
};
</script>

<style lang="scss">
.okraina-loader-page {
  position: fixed;
  top: var(--safe-area-top);
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: var(--color-view-background);
  opacity: 1;
  z-index: 500;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.v-leave-active {
    transition: opacity 0.3s ease;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}
</style>