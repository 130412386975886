const templates =  {
    "shop.okraina.ru": {
        title: "Блог о самом вкусном и полезном от Окраины",
        tags: [
            {
                name: "description",
                content: "С нами не только вкусно, но и познавательно! Полезные и интересные статьи о нашем производстве, продуктах и многом другом на сайте интернет-магазина «ОКРАИНА». Делитесь своим мнением в комментариях.",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Блог о самом вкусном и полезном от Окраины",
        tags: [
            {
                name: "description",
                content: "С нами не только вкусно, но и познавательно! Полезные и интересные статьи о нашем производстве, продуктах и многом другом на сайте интернет-магазина «ОКРАИНА». Делитесь своим мнением в комментариях.",
            }
        ]
    }
}

export default templates;