<template>

    <div class="okraina-sct" v-if="this.active">
        <div class="hours">
        <span class="first-number">{{ this.hours.firstNumber }}</span>
        <span class="second-number">{{ this.hours.secondNumber }}</span>
        </div>
        <span class="separator">:</span>
        <div class="minutes">
            <span class="first-number">{{ this.minutes.firstNumber }}</span>
            <span class="second-number">{{ this.minutes.secondNumber }}</span>
        </div>
        <span class="separator">:</span>
        <div class="seconds">
            <span class="first-number">{{ this.seconds.firstNumber }}</span>
            <span class="second-number">{{ this.seconds.secondNumber }}</span>
        </div>
    </div>

</template>

<script>
import moment from "moment";

export default {
    name:'okraina-sliders-countdown-time',
    components: {},
    data() {
      return {
        active:false,
        timeEnd:0,
        hours: {
            firstNumber:0,
            secondNumber:0
        },

        minutes:{
            firstNumber:0,
            secondNumber:0
        },

        seconds:{
            firstNumber:0,
            secondNumber:0
        }

      };
    },
    props: {
      activeTo: {
        type: String,
        default() {
          return "";
        },
      },
    }, 
    methods: {

        timer() {
            
            let now =  Math.floor(Date.now() / 1000);

            let diff =  this.timeEnd-now;

            //Если разница меньше 0 - значит срок уже вышел, таймер скрываем - повторение прекращаем
            if(diff < 0){
                this.active = false;
                return;
            }

            let hours = Math.floor(diff / 60 / 60);
            let minutes = Math.floor(diff / 60) - (hours * 60);
            let seconds = diff % 60;

            // Делим часы/минуты/секунды на две цифры

            this.hours.firstNumber = Math.floor(hours/10);
            this.hours.secondNumber = hours%10;

            this.minutes.firstNumber = Math.floor(minutes/10);
            this.minutes.secondNumber = minutes%10;

            this.seconds.firstNumber = Math.floor(seconds/10);
            this.seconds.secondNumber = seconds%10;  

            this.active = true;

            //Запускаем по новой
            setTimeout(() =>  this.timer(), 1000);
        },

    },
    created(){
        //Переводим дату окончания в timestamp
        this.timeEnd = moment(moment(this.activeTo, 'DD.MM.YYYY hh.mm.ss').toDate()).unix();
        this.timer();
    }   
}
</script>

<style lang="scss">
.okraina-sct{
    color: var(--countdown-color);
    font-size: 40px;
    border-radius: 20px;
    background-color: var(--countdown-background);
    box-shadow: 0px 4px 103.2px -5px rgba(0, 0, 0, 1);
    padding: 10px 20px;
    display: flex;
    z-index: 1;
    margin-right: auto;
    margin-bottom: auto;
    transform: rotate(-10deg);
    
    .separator,
    .hours,
    .minutes,
    .seconds,
    div span{
        display: flex;
    }

    .separator{
        color: var(--countdown-color-background-numbers);
    }

    div span{
        background-color: var(--countdown-color-background-numbers);
        border-radius: 10px;
        padding: 5px;
        margin: 5px;
    }

    span.first-number,
    span.second-number{
        min-width: 35px;
    }
    .separator,
    span.first-number,
    span.second-number{
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width:1200px){
    .okraina-sct{
        font-size:20px;
        padding: 10px;
        border-radius: 10px;
        div span{
            border-radius: 5px;
            margin:2px;
            padding: 2px 0px;
        }
        span.first-number, 
        span.second-number{
            min-width: 25px;
        }
    }


}

@media screen and (max-width:560px){
    .okraina-sct{
        padding:5px;
        span.first-number, 
        span.second-number{
            min-width: 15px;
        }
    }
}
</style>