<template>
    <div class="okraina-overlay" :class="{ fixed: fixed }">
        <div class="loader">
            <okraina-loader-inline />
        </div>
    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";

import { mapGetters } from "vuex";
export default {
    name: "okraina-overlay",
    components: {
        okrainaLoaderInline,
    },
    props: {
        fixed: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            platform: "getPlatform"
        }),


    },
};
</script>

<style lang="scss">
.okraina-overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;

    background-color: var(--color-view-background);
    opacity: 0.5;
    z-index: 500;

    &.fixed {
        position: fixed;
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.v-leave-active {
        transition: opacity 0.3s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
    }
}
</style>