<template>
    <okraina-view :class="'okraina-v-ad'" @refresh="refresh" ref="view" :hideMenu="true" :contentHorizontalPadding="0">

        <template v-slot:header>
            <h1>
                Акция
            </h1>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="!action">

                    <okraina-not-found caption="Акция не найдена" />


                </template>

                <template v-else>

                    <okraina-actions-detail :action="action" />

                </template>

            </template>


        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaActionsDetail from "@/components/actions/detail.vue";
import okrainaNotFound from "@/components/not.found.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/actions.detail.js";

export default {
    name: "okraina-v-actions-detail",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaActionsDetail,
        okrainaNotFound
    },
    data() {
        return {
            loading: true,
            action: false,
            canShow: false
        };
    },
    props: {
        code: {
            type: String,
            default() {
                return "";
            },
        },
        id: {
            type: [String, Number],
            default() {
                return "";
            },
        },
    },
    watch: {
        code: function () {
            this.loadData();
        },
        id: function () {
            this.loadData();
        },
        action: function () {
            this.setMeta();
        }
    },

    methods: {

        /**
         * Назад
         */
        back() {
            if (window.history.length > 1) {
                this.$router.go(-1)
            } else {
                this.$router.push("/");
            }
        },

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            this.loading = resolve ? false : true;

            let method = this.id > 0 ? "getbyid" : "getbycode";

            rest
                .call("actions." + method, {
                    method: "post",
                    data: {
                        ID: this.id,
                        CODE: this.code,
                        RESIZE_OPTIONS: {
                            WIDTH: 900,
                            HEIGHT: 330,
                            TYPE: "PROPORTIONAL",
                            PRELOAD: "Y"
                        },
                        PRODUCTS_RESIZE_OPTIONS: tools.getProductListResizeOptions()
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.action = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                }).finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Повторить загрузку
         */
        retry() {
            this.loadData();
        },

        /**
         * Устанавливает мета данные страницы
         */
        setMeta() {
            if (this.action) {
                meta.write(metaTemplates, this.action.META, this.action);
            }
        },

    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setMeta();
        });
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss"></style>