const templates =  {
    "shop.okraina.ru": {
        title: "Контакты интернет-магазина \"Окраина\"",
        tags: [
            {
                name: "description",
                content: "Контактные данные ООО «МПЗ Богородский». Контактные телефоны службы качества, приема заказов интернет-магазина и для ваших вопросов. Адреса для отправки заявок, отзывов и резюме.",
            },
            {
                name: "keywords",
                content: "завод окраина ногинск, контакты мпз окраина, окраина мпз телефон",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Контакты – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Контакты – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;