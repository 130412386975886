<template>
    <okraina-view :class="['okraina-v-help']" :refreshDisabled="true" ref="view" :hideMenu="true"
        :contentHorizontalPadding="19">

        <template v-slot:header>
            <h1 class="title">
                Помощь
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <okraina-menu-section :items="items" />


        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaMenuSection from "@/components/menu.section.vue";


import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/help.index.js";

export default {
    name: "okraina-v-help",
    components: {
        okrainaView,
        okrainaMenuSection
    },
    data() {
        return {
            items: [
                {
                    icon: "fi-delivery",
                    name: "Доставка",
                    description: "Ознакомьтесь с территорией и условиями доставки.",
                    path: "/help/delivery/"
                },
                {
                    icon: "fi-methods-payment",
                    name: "Оплата",
                    description: "Доступные способы оплаты",
                    path: "/help/payment/"
                },
            ]
        };
    },

    methods: {

    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss"></style>