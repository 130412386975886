<template>
  <transition :name="allowAnimation ? (!isNowGoBack ? 'slide-menu' : 'slide-menu-back') : ''
    ">
    <div class="okraina-menu" v-if="visible">
      <div class="inner">
        <div class="items">
          <div v-for="item in items" :key="item.id" class="item" :class="{
    current: selected === item.type || item.ext.includes(selected), common: item.type != 'bonuses', card: item.type == 'bonuses'
  }" :style="{ width: 100 / items.length + '%' }" @click.prevent="onItemClick(item)"
            @touchstart.prevent="onItemClick(item)" v-ripple-circle>

            <template v-if="item.type == 'bonuses'">
              <div class="icon-wrap">
                <span class="icon fi" :class="item.icon"></span>
              </div>
            </template>

            <template v-else>

              <div class="icon-wrap">
                <div class="icon-inner">
                  <span class="icon fi" :class="item.icon"></span>
                  <div v-if="item.type == 'favorites' && favCount > 0" class="counter">
                    <div class="cnt">{{ favCount > 99 ? 99 : favCount }}</div>
                  </div>

                  <div v-if="item.type == 'cart' && basketCount > 0" class="counter">
                    <div class="cnt">
                      {{ basketCount > 99 ? 99 : basketCount }}
                    </div>
                  </div>

                  <div v-if="item.type == 'menu' && bonusCountRounded > 0" class="balance">
                    {{ bonusCountRounded }}
                    <span class="ic fi fi-money"></span>
                  </div>

                </div>

              </div>
              <div class="name">{{ item.name }}</div>

            </template>




          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from "@/store";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
  name: "okraina-menu",
  data() {
    return {
      allowAnimation: false,
      selected: this.$route.name
    };
  },
  computed: {
    ...mapGetters({
      items: "getMenu",
      isNowGoBack: "getIsNowGoBack",
      favCount: "getFavoritesCount",
      basketCount: "getBasketCount",
      bonusCount: "getBonus",
      platform: "getPlatform"
    }),
    current() {
      return this.$route.name;
    },
    visible() {
      return !store.getters.isHideMenu;
    },

    bonusCountRounded() {
      return Math.floor(this.bonusCount)
    }
  },
  watch: {
    current: function (newVal) {
      this.selected = newVal;
    },
  },
  methods: {
    /**
     * Клик по пункту
     */
    onItemClick(item) {

      //если пукнкт уже выбран
      if (this.selected === item.type || item.ext.includes(this.selected)) {
        bus.emit("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN");
        return;
      }


      //проверяем список всех экранов
      for (let i = 0; i < this.$router.options.routes.length; i++) {
        let route = this.$router.options.routes[i];
        if (route.name === item.type) {
          this.selected = item.type;
          bus.emit("OKRAINA_PRODUCT_MODAL_E_CLOSE");
          setTimeout(() => {
            this.$router.push({ name: route.name });
          }, 50);
          break;
        }
      }
    },
  },

  /**
   * Монтирование эксзепляра в DOM
   */
  mounted() {
    setTimeout(() => {
      this.allowAnimation = true;
    }, 1000);
  },
};
</script>

<style lang="scss">
.okraina-menu {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;

  padding: 0px 0px;
  padding-bottom: var(--safe-area-bottom);

  transition: background-color ease-in-out 0.15s;

  background: var(--color-menu-background);

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;

  .inner {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;


  }

  .items {
    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .item {
      float: left;
      text-align: center;
      color: var(--color-menu-color);

      position: relative;
      cursor: pointer;

      &.common {

        padding: 10px 0px;

        .icon-wrap {
          line-height: 1;
          margin-bottom: 4px;
          padding: 1px 0px 1px;
        }

        .icon-inner {
          display: inline-block;
          position: relative;
          height: 21px;
          width: 20px;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          display: inline-block;
        }

        .name {
          font-size: 10px;
        }

        &.current {
          color: var(--color-menu-color-current);

          .icon {

            animation: okraina-menu-item-selected 0.3s ease 1;
          }
        }
      }

      &.card {

        line-height: 1;
        padding-top: 6.5px;

        .icon-wrap {

          position: relative;
          width: 60px;
          height: 50px;
          background: linear-gradient(292.02deg, #2793CA 28.15%, #74BE4D 57.22%, #88C92C 68.26%, #97D114 78.72%, #A0D605 88.38%, #A3D800 96.47%);
          border-radius: 20px;
          margin: auto;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          line-height: 1;
          color: #fff;
        }
      }

      .counter {
        position: absolute;
        top: -8px;
        right: -8px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: var(--color-icon-counter);

        .cnt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 8px;
          color: var(--color-icon-counter-text);
        }
      }

      .balance {
        position: absolute;
        top: -8px;
        right: -20px;

        font-size: 8px;
        background: var(--color-icon-counter);
        border-radius: 36px;
        padding: 3px 20px 3px 7px;
        line-height: 1;
        color: var(--color-icon-counter-text);

        .ic {
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 9px;
        }
      }

    }
  }

  /*
   Слайд меню - появление
  */

  &.slide-menu-enter-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    overflow: hidden;
    z-index: 999;
  }

  &.slide-menu-enter-active {

    &:after {
      content: "";
      position: absolute;
      background-color: #000;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 501;

      transition: opacity 0.3s cubic-bezier(.13, .45, .51, .93);
    }
  }

  &.slide-menu-enter-from {
    &:after {
      opacity: 0.5;
    }
  }

  &.slide-menu-enter-to {
    &:after {
      opacity: 0;
    }
  }

  &.slide-menu-enter-from {
    transform: translateX(100%);
    z-index: 999;
  }


  /*
   Слайд меню - покидание
  */

  &.slide-menu-leave-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    overflow: hidden;
    z-index: 998;
  }

  &.slide-menu-leave-active {
    &:after {
      content: "";
      position: absolute;
      background-color: #000;
      opacity: 0;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 501;

      transition: opacity 0.3s cubic-bezier(.13, .45, .51, .93);
    }
  }

  &.slide-menu-leave-to {
    transform: translateX(-30%);
    z-index: 998;
  }

  &.slide-menu-leave-to {
    &:after {
      opacity: 0.5;
    }

  }


  /*
   Слайд меню - появление в режиме возврата к предыдущему экрану
  */

  &.slide-menu-back-enter-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    overflow: hidden;
    z-index: 998;
  }

  &.slide-menu-back-enter-active {

    &:after {
      content: "";
      position: absolute;
      background-color: #000;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 501;

      transition: opacity 0.3s cubic-bezier(.13, .45, .51, .93);
    }

  }

  &.slide-menu-back-enter-from {
    &:after {
      opacity: 0.5;
    }

  }

  &.slide-menu-back-enter-to {
    &:after {
      opacity: 0;
    }

  }

  &.slide-menu-back-enter-from {
    transform: translateX(-30%);
    z-index: 998;
  }

  /*
   Слайд меню - покидание в режиме возврата к предыдущему экрану
  */

  &.slide-menu-back-leave-active {
    transition: transform 0.3s cubic-bezier(.13, .45, .51, .93);
    overflow: hidden;
    z-index: 999;
  }

  &.slide-menu-back-leave-to {
    transform: translateX(100%);
    z-index: 999;
  }

  &.slide-menu-back-leave-to {
    &:after {
      opacity: 0.5;
    }

  }
}

@keyframes okraina-menu-item-selected {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.3, 1.3);
  }

  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}
</style>