<template>
    <div class="item okraina-a-cbr col-12 col-sm-12 col-md-12 col-lg-4">
        <div class="okraina-block">
            <div class="block-title name" v-html="params.NAME"></div>

            <div class="props">
                <div class="prop" v-for="(prop, index) in props" :key="index">
                    <div class="prop-title">{{ prop.title }}</div>
                    <div class="prop-value">{{ prop.value }}</div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>

export default {
    name: "okraina-about-contacts-block-requisites",
    props: {
        params: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        props() {
            let props = [];
            for (let i = 0; i < this.params.COUNT; i++) {
                props.push({
                    title: this.params['TITLE_' + i],
                    value: this.params['VALUE_' + i],
                });
            }

            return props;
        }
    }
}
</script>

<style lang="scss">
.okraina-a-cbr {

    .okraina-block {
        padding-bottom: 15px !important;
    }

    .prop {
        margin-bottom: 10px;
    }

    .prop-title {
        font-size: 12px;
        color: rgba(var(--color-text-title-rgb), 0.5);
        margin-bottom: 3px;
    }

    .prop-value {
        font-size: 13px;
        color: var(--color-text-title);
    }
}
</style>