<template>
    <okraina-sheet v-model:visible="visibleInternal" class="okraina-cp-rifling-select-wrap">
        <template v-slot:body>
            <div class="okraina-cp-rifling-select">

                <div class="sheet-title">Выберите вариант</div>

                <!--сам товар -->
                <div class="item" @click="onClick(item)">

                    <div>
                        упаковка
                    </div>
                    <div>
                        <template v-if="isWeight"> ~ </template>

                        <template v-if="weight < 1"> {{ weight * 1000 }} г </template>
                        <template v-else> {{ weight }} кг </template>
                    </div>
                    <div class="col-price">
                        <okraina-catalog-product-price :item="item" v-if="item.PRICE" :isRifling="false"
                            :showMeasure="false" :showOldPriceTop="true" />
                    </div>
                </div>

                <!--нарезка по весу -->
                <template v-if="item.RIFLING && item.RIFLING.length > 0">
                    <div class="item" v-for="rifling in item.RIFLING" :key="rifling.FIELDS.ID" @click="onClick(rifling)">
                        <div>нарезка</div>
                        <div>{{ rifling.FIELDS.NAME }}</div>
                        <div class="col-price">
                            <okraina-catalog-product-price :item="rifling" :isRifling="true" :showMeasure="false"
                                :showOldPriceTop="true" />
                        </div>
                    </div>
                </template>

                <!--нарезка Как отдельные товары  -->
                <template v-if="item.RIFLING_PRODUCTS && item.RIFLING_PRODUCTS.length > 0">
                    <div class="item" v-for="rifling in item.RIFLING_PRODUCTS" :key="rifling.FIELDS.ID"
                        @click="onClick(rifling)">
                        <div>
                            нарезка
                        </div>
                        <div>
                            <template v-if="rifling.PROPS.VES < 1">
                                {{ rifling.PROPS.VES * 1000 }} г
                            </template>
                            <template v-else> {{ rifling.PROPS.VES }} кг </template>
                        </div>
                        <div class="col-price">
                            <okraina-catalog-product-price :item="rifling" :isRifling="true" :showMeasure="false" />
                        </div>
                    </div>
                </template>

                <!--товары другого формата  -->
                <template v-if="item.OTHER_FORMAT_PRODUCTS && item.OTHER_FORMAT_PRODUCTS.length > 0">
                    <div class="item" v-for="rifling in item.OTHER_FORMAT_PRODUCTS" :key="rifling.FIELDS.ID"
                        @click="onClick(rifling)">
                        <div>
                            упаковка
                        </div>
                        <div>
                            <template
                                v-if="rifling.PROPS.CML2_BASE_UNIT && rifling.PROPS.CML2_BASE_UNIT == 'кг'">~</template>
                            <template v-if="rifling.PROPS.VES < 1">
                                {{ rifling.PROPS.VES * 1000 }} г
                            </template>
                            <template v-else> {{ rifling.PROPS.VES }} кг </template>
                        </div>
                        <div class="col-price">
                            <okraina-catalog-product-price :item="rifling" :isRifling="false" :showMeasure="false" />
                        </div>
                    </div>
                </template>

            </div>
        </template>

    </okraina-sheet>
</template>

<script>

import okrainaSheet from "@/components/sheet.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from "../../plugins/yandex.metrika";

export default {
    name: "okraina-catalog-product-rifling-select",
    components: {
        okrainaSheet,
        okrainaCatalogProductPrice
    },
    emits: ["update:visible"],
    data() {
        return {
            isOpen: false,
            visibleInternal: this.visible,
            selectedId: this.item.FIELDS.ID
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        },

        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        recommendationId: {
            type: [String, Number],
            default() {
                return "";
            }
        },
    },
    computed: {
        isWeight() {
            return (
                this.item.PROPS.CML2_BASE_UNIT && this.item.PROPS.CML2_BASE_UNIT == "кг"
            );
        },
        weight() {
            return this.item.PROPS.VES;
        },

        /**
         * Данные для добавления в корзину
         */
        basketData() {
            //если задана нарезка по весу
            if (this.item.RIFLING) {
                let rifling = this.item.RIFLING.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: this.item.FIELDS.ID,
                        params: [
                            {
                                CODE: "RIFLING",
                                NAME: "Нарезка",
                                VALUE: rifling.FIELDS.NAME,
                            },
                        ],
                    };
                }
            }

            //если задана нарезка как отдельные товары
            if (this.item.RIFLING_PRODUCTS) {
                let rifling = this.item.RIFLING_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: rifling.FIELDS.ID,
                        params: [],
                    };
                }
            }

            //если заданы товары другого формата
            if (this.item.OTHER_FORMAT_PRODUCTS) {
                let rifling = this.item.OTHER_FORMAT_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: rifling.FIELDS.ID,
                        params: [],
                    };
                }
            }

            return {
                productId: this.item.FIELDS.ID,
                params: [],
            };
        },
    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
        },
    },


    methods: {

        onClick(sender) {

            this.selectedId = sender.FIELDS.ID;
            this.visibleInternal = false;

            rest
                .call("basket.add", {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.basketData.productId,
                        QUANTITY: 1,
                        PARAMS: this.basketData.params,
                        RID: this.recommendationId
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        //изменяем кол-во в корзине
                        this.$store.commit("updateBasketItem", {
                            pid: this.basketData.productId,
                            params: this.basketData.params,
                            quantity: 1
                        });

                        //событие для ЯндексМетрики
                        metrika.ecommerceAdd({
                            id: this.basketData.productId,
                            quantity: 1
                        })

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                });

        }
    },
};
</script>

<style lang="scss">
.okraina-cp-rifling-select {
    .item {
        position: relative;
        padding: 12px 16px;
        font-weight: 500;

        background-color: var(--color-color6);
        margin-bottom: 10px;
        border-radius: 5px;

        display: grid;
        grid-template-columns: 70px auto 1fr;
        column-gap: 15px;
        align-items: center;

        .col-price {
            text-align: right;
        }

    }
}
</style>