<template>
  <div class="okraina-csi">

    <okraina-control-input :classes="['slim']" placeholder="Поиск по каталогу" v-model="value" @focused="onFocus"
      ref="input">
      <template v-slot:left>
        <div class="action">
          <span class="icon fi fi-search"></span>
        </div>
      </template>

      <template v-slot:right v-if="isNative || !!value">
        <div class="action" v-if="isNative" @click="speechSearch">
          <span class="icon fi fi-mic"></span>
        </div>
        <div class="action clear" v-if="!!value" @click="clear">
          <span class="icon fi fi-close"></span>
        </div>
      </template>
    </okraina-control-input>

  </div>
</template>

<script>
import okrainaControlInput from "@/components/controls/input.vue";

import bus from "@/plugins/bus";
import { Capacitor } from "@capacitor/core";

export default {
  name: "okraina-catalog-search-input",
  components: {
    okrainaControlInput,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
      isNative: Capacitor.isNativePlatform(),
    };
  },
  props: {
    showBack: {
      type: Boolean,
      default() {
        return true;
      },
    },
    searchMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    value(newVal) {
      this.$emit("update:modelValue", newVal);
    },
    modelValue() {
      this.value = this.modelValue
    }
  },
  methods: {
    /**
     * Назад
     */
    back() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: "home" });
    },

    /**
     * Обработка получения фокуса полем ввода
     */
    onFocus() {
      //если не режим поиска
      if (!this.searchMode) {
        //если текущий экран "Поиск по составу" - то возврат назад
        if (
          this.$route.name == "catalog-consist" &&
          window.history.length > 1
        ) {
          this.back();
        } else {
          //переход на экран поиска
          this.$router.push({ name: "catalog-search", params: { q: "", focus: "Y" } });
        }
      }
    },

    /**
     * Устанавилвает фокус на поле ввода 
     * ВНИМАНИЕ: вызывается из родительского компонента
     */
    focus() {

      //отложено, чтобы анимация перехода между экранами успела отработать без артефактов
      setTimeout(() => {
        this.$refs.input.focus();
      }, 500);
    },

    /**
     * Стереть введённое значение
     */
    clear() {
      this.value = "";
    },

    /**
     * Распознование речи для значения
     */
    speechSearch() {

      bus.emit("OKRAINA_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          //если не режим поиска
          if (!this.searchMode) {
            //переход на экран поиска
            this.$router.push({ name: "catalog-search", params: { q: text, focus: "N" } });
          } else {
            this.value = text;
          }
        },
      });

      //если находимся не на странице поиска
      if (!this.searchMode) {
        //то сразу переходим на страницу поиска, так как push меняет URL только по действиую пользователя (click)
        //если подождать resolve распознования речи, то это уже не считается взаимодействием пользователя
        this.$router.push({ name: "catalog-search", params: { q: '', focus: "N" } });
      }

    },
  },
};
</script>

<style lang="scss">
.okraina-csi {

  .form-group {
    margin-bottom: 0px;
  }

  .form-control-wrap {
    background-color: var(--color-header-control-background);
  }


}
</style>
