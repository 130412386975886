import bus from '@/plugins/bus'
import errorListiner from '@/plugins/error.listiner'

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';

export default {

    init() {
        bus.on('OKRAINA_CAMERA_E_GET_PICTURE', (params) => {
            this.onGetPicture(params)
        });
    },

    async onGetPicture(params) {

        //если WEB
        if (!Capacitor.isNativePlatform()) {
            //сразу вызываем получение фото
            this.getPhoto(params);
        } else {

            //получаем информацию о разрешениии
            let status = await Camera.checkPermissions();

            let key = params.photoLibrary? "photos": "camera";

            //если доступ не дан
            if (status[key] != 'granted') {
                //пробуем запросить доступ
                status = await Camera.requestPermissions({permissions: [key]});
                //если доступ всё равно не дан
                if(status[key] != 'granted') {
                    //запрашиваем разрешение через открытие экрана настроек приложения
                    this.permit(key);
                } else {
                    this.getPhoto(params);
                }

            } else {
                this.getPhoto(params);
            }

        }

    },

    /**
    * Спрашивает не открыть ли экран настроект разрешений прилоржения
    */
    async permit(key) {
        await new Promise((resolve) => {
            bus.emit("OKRAINA_CONFIRM_E_SHOW", {
                message: "У приложения нет разрешения на использование "+(key == "camera"? "камеры":"медиафайлов")+". Перейти в настройки разрешений приложения?",
                resolveText: "Да",
                rejectText: "Нет",
                resolve: function (value) {
                    if(value) {
                        BarcodeScanner.openAppSettings();
                    }
                    resolve(value);
                },
                reverse: false,
            });
        });
    },

    getPhoto(params) {

        Camera.getPhoto({
            quality: 70,
            resultType: CameraResultType.Base64,
            correctOrientation: true,
            source: params.photoLibrary ? CameraSource.Photos : CameraSource.Camera
        }).then((image) => {
            if (params.resolve) {
                params.resolve(image.base64String);
            }
        }).catch((e) => {
            errorListiner.onError(e);
            console.log(e);
        });
    }


}