<template>
    <div class="okraina-cp-photo-slider">

        <swiper :modules="modules" :slidesPerView="1" :loop="false" :spaceBetween="16" :pagination="{
            clickable: true,
            dynamicBullets: false,
            bulletClass: 'bullet',
            bulletActiveClass: 'active',
        }" :autoHeight="false" :cssMode="false" class="mySwiper">

            <swiper-slide v-for="photo in photos" :key="photo.ID">
                <div class="item" @click="openPhoto(photo)">
                    <div class="image" v-lazy:background-image="photo.SRC"></div>

                </div>
            </swiper-slide>

        </swiper>
    </div>
</template>

<script>
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import tools from '@/plugins/tools.js'

export default {
    name: "okraina-catalog-product-photo-slider",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            modules: [Pagination],
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        }
    },
    computed: {
        photos() {
            let photos = [];

            //фото товара
            if (this.item.FIELDS.DETAIL_PICTURE) {
                photos.push(this.item.FIELDS.DETAIL_PICTURE);
            } else if (this.item.FIELDS.PREVIEW_PICTURE) {
                photos.push(this.item.FIELDS.PREVIEW_PICTURE);
            }

            //доп фото
            if (this.item.PROPS.MORE_PHOTO) {
                this.item.PROPS.MORE_PHOTO.forEach((i) => photos.push(i));
            }

            return photos;
        },

        gallery() {
            let images = this.photos.map((item) => {
                return {
                    src: item.SRC_FULL ? item.SRC_FULL : item.SRC,
                    type: "image",
                    width: item.WIDTH,
                    height: item.HEIGHT,
                    id: item.ID,
                };
            });
            return images;
        },
    },
    methods: {
        /**
         * Открыть фото на просмотр
         */
        openPhoto(item) {
            let start = this.gallery.findIndex((i) => i.id == item.ID);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 1,
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "close",
                    ],
                },
                Thumbs: {
                    autoStart: false,
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        tools.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        tools.fancyBoxOnClose();
                    },

                },
            });
        },
    },

    created() { },
};
</script>

<style lang="scss">
.okraina-cp-photo-slider {

    .swiper {

        .bullet {
            display: inline-block;
            width: 11px;
            height: 3px;
            margin-right: 3px;
            background-color: var(--color-swiper-bullet);
            border-radius: 3px;
            transition: width 0.2s ease-out, background-color 0.2s ease-out;

            &.active {
                background-color: var(--color-swiper-bullet-active);
                width: 30px;
            }
        }

        .swiper-pagination-bullets-dynamic {
            .bullet {
                &.active-main {
                    width: 30px;
                }
            }
        }
    }

    .item {
        position: relative;
        height: 0px;
        padding-bottom: 75%;
        overflow: hidden;

        .image {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }

    }
}
</style>
