import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika'

const user = {
    state: () => {
        return {

            //Пользователь
            user: {},
            //уровень кешбэка
            cashback: false,

            bonus: 0
            
        }
    },
    getters: {

        getUser(state) {
            return state.user;
        },

        getCashback(state) {
            return state.cashback;
        },

        getBonus(state) {
            return state.bonus;
        },

        getUserDataBatch(state, getters) {

            let requests = {
                basket: {
                    CLASS: "basket",
                    METHOD: "getlist",
                    PARAMS: {
                        FILTER: {
                            "DELAY": "N",
                            "CAN_BUY": "Y"
                        },
                    },
                },
                cashback: {
                    CLASS: "bonus",
                    METHOD: "status",
                },
                favorites: {
                    CLASS: "favorites",
                    METHOD: "getlist"
                },
            };

            if (getters.isAuthorized) {
                requests.user = {
                    CLASS: "user",
                    METHOD: "get",
                };
                requests.bonus = {
                    CLASS: "bonus",
                    METHOD: "balance",
                };
            }

            return requests;
        },
        
    },
    mutations: {

        setCashback: (state, value) => {
            state.cashback = value;
        },
        setUser: (state, value) => {
            state.user = value;
        },
        setBonus: (state, value) => {
            state.bonus = value;
        },
       
    },

    actions: {
        /**
         * Загружает данные пользователя
         */
        loadUserData(context) {

            //запрашиваем данные
            rest
                .batch(context.getters.getUserDataBatch)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.SUCCESS) {
                            //корзина
                            if (key == "basket") {
                                //убираем отложенные
                                let items = data.ITEMS.filter(i => i.FIELDS.DELAY != 'Y');
                                context.commit('setBasket', items.map((i) => {
                                    return { id: i.FIELDS.PRODUCT_ID, quantity: parseInt(i.FIELDS.QUANTITY), params: i.PARAMS }
                                }));
                            }
                            //профиль пользователя
                            else if (key == "user") {
                                context.commit('setUser', data);
                                //передаём в Яндекс Метрику параметры пользователя
                                metrika.userParams();
                            }
                            //уровень кешбэка
                            else if (key == "cashback") {
                                context.commit('setCashback', data);
                            }
                            //избранное
                            else if (key == "favorites") {
                                context.commit('setFavorites', data.PRODUCTS.map(p => p.FIELDS.ID));
                            }
                            //Кол-во бонусов
                            else if (key == "bonus") {
                                context.commit('setBonus', data.VALUE);
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                            return;
                        }
                    }
                });
        },
    }

}

export default user;