<template>
    <div class="okraina-control-stars form-group" :class="{
        'form-group-error': error
    }">

        <template v-if="label != ''">
            <div :class="labelClass">
                {{ label }}
                <span v-if="required && needShowRequired" class="required">*</span>
            </div>
        </template>

        <div class="stars">
            <div class="star" :class="{ selected: isSelected(i) }" v-for="i in Object.keys(values)" :key="i"
                @click="setValue(i)">
                <span class="fi fi-star"></span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "okraina-control-stars",
    emits: ["update:modelValue", "change", "update:valid", "update:error"],
    data() {
        return {
            value: this.modelValue,
        };
    },
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        labelClass: {
            type: String,
            default() {
                return "label"
            }
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },

        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },

        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },

        values: {
            type: Object,
            default() {
                return {
                    1: 1,
                    2: 2,
                    3: 3,
                    4: 4,
                    5: 5
                };
            },
        }

    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {
        onChange() {
            this.$emit("change", this.value);
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

        setValue(v) {
            this.value = v;
            this.$emit("update:modelValue", this.value);
        },

        isSelected(v) {
            if (this.value == v) {
                return true;
            }

            let keys = Object.keys(this.values);
            let index = keys.indexOf(v)
            for (let i = index + 1; i < keys.length; i++) {
                let k = keys[i];
                if (k == this.value) {
                    return true;
                }
            }


            return false
        }
    },

    mounted() {
        this.checkValid();
    },

}
</script>

<style lang="scss">
.okraina-control-stars {
    .stars {
        position: relative;

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .star {
        position: relative;
        z-index: 10;
        float: left;
        margin-right: 8px;
        font-size: 27px;
        color: var(--color-control-background);

        &.selected {
            color: var(--color-icon-star);
        }
    }

    &.form-group-error {
        .stars {
            padding: 5px 10px;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                border: 1px solid var(--color-control-border-error);
                border-radius: 20px;
            }
        }
    }
}
</style>