<template>
    <okraina-sheet v-model:visible="visible" class="okraina-auth-need-wrap">

        <template v-slot:body>

            <div class="okraina-auth-need">
                <div class="note text-center">Вам нужно авторизоваться, чтобы выполнить это действие.</div>
                <div class="btn btn-block btn1" v-ripple @click="onLoginClick">
                    Войти в профиль
                </div>

                <div class="btn btn-block btn3" v-ripple @click="onRegisterClick">
                    Зарегистрироваться
                </div>
            </div>
        </template>

    </okraina-sheet>
</template>

<script>

import okrainaSheet from '@/components/sheet.vue'

import bus from "@/plugins/bus";

export default {
    name: "okraina-auth-need",
    components: {
        okrainaSheet,
    },
    data() {
        return {
            visible: false,
            params: false
        }
    },
    methods: {

        open(params = false) {
            this.params = params;
            this.visible = true;
        },

        close() {
            this.visible = false;
        },

        /**
         * Клик по Войти в профиль
         */
        onLoginClick() {
            this.visible = false;
            if (this.params && this.params.onClick) {
                setTimeout(() => {
                    this.params.onClick();
                    setTimeout(() => {
                        this.goTo('/login/')
                    }, 400);
                }, 400);
            } else {
                this.goTo('/login/')
            }

        },

        /**
        * Клик по Зарегистрироваться
        */
        onRegisterClick() {
            this.visible = false;
            if (this.params && this.params.onClick) {
                setTimeout(() => {
                    this.params.onClick();
                    setTimeout(() => {
                        this.goTo('/register/')
                    }, 300);
                }, 300);
            } else {
                this.goTo('/register/')
            }
        },

        goTo(url) {
            let vm = this;
            //закроем модальое окно товара (если вдруг открыто)
            bus.emit("OKRAINA_PRODUCT_MODAL_E_CLOSE");

            setTimeout(() => {
                vm.$router.push({ path: url });
            }, 300);
        }
    },
    mounted() {
        bus.on("OKRAINA_AUTH_NEED_E_OPEN", this.open);
    },
    unmounted() {
        bus.off("OKRAINA_AUTH_NEED_E_OPEN", this.open);
    },

}
</script>

<style lang="scss">
.okraina-auth-need-wrap {
    z-index: 1100 !important;
}

.okraina-auth-need {

    .note {
        margin-bottom: 20px;
    }

    .btn {
        margin-bottom: 15px;
    }
}
</style>