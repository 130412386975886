<template>
    <div class="okraina-control-photos form-group" :class="{
        'form-group-error': error
    }">

        <div class="items" v-if="photos.length > 0">

            <div class="item" v-for="photo in photos" :key="photo.ID" :style="{
                backgroundImage: photo.DATA
                    ? 'url(' + photo.DATA + ')'
                    : 'url(' + photo.SRC + ')',
            }">
                <div class="delete" @click="deletePhoto(photo.ID)">
                    <span class="ic fi fi-close"></span>
                </div>
            </div>

        </div>

        <div class="add-wrap" v-if="photos.length <= max" @click="selectType">

            <div>
                <div class="blank">
                    <span class="icon fi fi-photo-add"></span>
                </div>
            </div>
            <div>
                <div class="notice">
                    <template v-if="max > 1"> До {{ max }} фотографий <br /></template>
                    в формате PNG, JPEG
                </div>
            </div>

        </div>

    </div>

    <okraina-sheet v-model:visible="showSelectType">
        <template v-slot:body>

            <div class="okraina-control-photos-ts">

                <div class="items">
                    <div class="item-wrap">
                        <div class="item" @click="selectPhotoGallery">
                            <div class="picture">
                                <span class="icon fi fi-photo-library"></span>
                            </div>

                            <div class="name">Выбрать фото</div>
                        </div>
                    </div>

                    <div class="item-wrap">
                        <div class="item" @click="selectCamera">
                            <div class="picture">
                                <span class="icon fi fi-camera"></span>
                            </div>

                            <div class="name">Сделать фото</div>
                        </div>
                    </div>
                </div>

            </div>

        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue"

import bus from "@/plugins/bus";
import resizebase64 from "@/plugins/resizebase64";
import moment from "moment";

export default {
    name: "okraina-control-photos",
    emits: ["update:modelValue", "update:valid", "update:error", "change"],
    components: {
        okrainaSheet,
    },
    data() {
        return {
            photos: this.modelValue,
            showSelectType: false
        };
    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        max: {
            type: Number,
            default() {
                return 1
            }
        }
    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
    },
    watch: {
        photos: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        },
    },
    methods: {

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && this.photos.length == 0) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

        /**
         * Выбор способа получения фото
         */
        selectType() {
            this.showSelectType = true;
        },

        /**
         * Выбор - Получить фото с камеры
         */
        selectCamera() {
            this.showSelectType = false;
            setTimeout(() => {
                this.getPictureFromCamera();
            }, 300);
        },

        /**
         * Выбор - Получить фото из галлереи
         */
        selectPhotoGallery() {
            this.showSelectType = false;
            setTimeout(() => {
                this.getPictureFromGallery();
            }, 300);
        },

        /**
        * Получает фото с камеры
        */
        getPictureFromCamera() {
            bus.emit("OKRAINA_CAMERA_E_GET_PICTURE", {
                resolve: this.onGetPicture,
            });
        },

        /**
         * Получает фото с галереи
         */
        getPictureFromGallery() {
            bus.emit("OKRAINA_CAMERA_E_GET_PICTURE", {
                resolve: this.onGetPicture,
                photoLibrary: true,
            });
        },


        /**
         * Обработка получения фото с камеры
         */
        async onGetPicture(imageData) {
            var newImg = await resizebase64(
                "data:image/jpeg;base64," + imageData,
                1200,
                1200
            );

            let item = {
                ID: moment().format("x"),
                DATA: newImg,
            };
            this.photos.push(item);
            this.$emit("update:modelValue", this.photos);
            this.$emit("change", this.photos);
            this.checkValid();
        },

        /**
         * Удаление фото
         */
        deletePhoto(id) {
            this.photos = this.photos.filter((p) => p.ID != id);
            this.$emit("update:modelValue", this.photos);
            this.$emit("change", this.photos);
        },

        /**
         * Восстановление результата работы плагина фото
         */
        restorePluginResult() {

            let pluginResult = this.$store.getters.getRestorePluginResult;
            if (!pluginResult) {
                return;
            }

            if (pluginResult.pluginId == 'Camera' && pluginResult.methodName == 'getPhoto' && pluginResult.success) {
                //добавляем фото
                this.onGetPicture(pluginResult.data.base64String);
                //удаляем из общего состояния результат работы плагина, чтобы повторно не сработало
                this.$store.commit('setRestorePluginResult', false);
            }

        }
    },

    mounted() {
        this.checkValid();
    },

    created() {
        //восстарновление результата работы плагина фото при восстановлении приложения
        this.restorePluginResult();
    }
}
</script>

<style lang="scss">
.okraina-control-photos {
    .add-wrap {
        display: grid;
        grid-template-columns: 76px 1fr;
        column-gap: 23px;
        align-items: center;

        .blank {
            position: relative;
            width: 76px;
            height: 76px;
            border-radius: 20px;
            background-color: var(--color-control-background);

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 21px;
                color: var(--color-color1);
            }
        }

        .notice {
            font-size: 13px;
        }
    }

    .items {
        margin-bottom: 20px;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            position: relative;
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 76px;
            height: 76px;

            background-color: #000;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            .delete {
                position: absolute;
                top: -15px;
                right: -15px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: var(--color-color1);

                .ic {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--color-btn1-text);
                }
            }
        }
    }

    &.form-group-error {
        .add-wrap {
            border: 1px solid var(--color-control-border-error);
            border-radius: 20px;
        }
    }
}

.okraina-control-photos-ts {
    .items {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 16px;
    }

    .item {
        max-width: 150px;
        margin: 0px auto;

        .picture {
            height: 70px;
            position: relative;

            .icon {
                font-size: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1;
                color: var(--color-color1);

            }
        }

        .name {
            text-align: center;
            font-weight: 500;
        }

    }
}
</style>