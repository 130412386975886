<template>
    <div class="okraina-menu-section">
        <div class="row">
            <div class="col-12 col-sm-6" v-for="item in items" :key="item.path">
                <a class="item" :href="item.path" @click.prevent="onClick(item)" v-ripple>

                    <div class="inner">
                        <div>
                            <span class="icon fi" :class="item.icon"></span>
                        </div>
                        <div>
                            <div class="name">{{ item.name }}</div>
                            <div class="description">{{ item.description }}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "okraina-menu-sections",
    data() {
        return {
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {

        onClick(item) {
            setTimeout(() => {
                this.$router.push({ path: item.path });
            }, 300);
        }
    },

};
</script>

<style lang="scss">
.okraina-menu-section {

    .item {
        display: block;
        text-decoration: none;
        border-bottom: 0px;

        border-radius: 5px;
        background-color: var(--color-block-background);
        margin-bottom: 16px;
        padding: 15px;

        .inner {
            display: grid;
            grid-template-columns: 40px auto;
            column-gap: 15px;
        }

        .icon {
            font-size: 36px;
            color: var(--color-color3);
        }

        .name {
            color: var(--color-text-dark);
            font-weight: 600;
            margin-bottom: 5px;
        }

        .description {
            font-size: 12px;
            line-height: 1.2;
            color: var(--color-text-description);
        }
    }
}
</style>