<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-profiles">
                <div class="sheet-title">Выберите профиль</div>

                <div class="items">
                    <div class="item" v-for="item in items" :key="item.ID">
                        <okraina-radio :label="item.NAME" :value="item.ID" v-model="value" />

                    </div>
                </div>

                <div class="btn btn-block btn1" @click="apply">Принять</div>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaRadio from "@/components/controls/radio.vue";

export default {
    name: "okraina-order-profiles",
    components: {
        okrainaSheet,
        okrainaRadio
    },
    emits: ["update:visible", "update:modelValue"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            value: this.modelValue
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [Number, String],
            default() {
                return 0;
            },
        },

    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.value = this.modelValue;
            }
        },
        modelValue: function () {
            this.value = this.modelValue;
        }
    },

    methods: {

        apply() {
            this.$emit("update:modelValue", this.value);
            this.visibleInternal = false;
        }
    }
}

</script>

<style lang="scss">
.okraina-order-profiles {
    .item {
        margin-bottom: 15px;
    }

    .okraina-radio {
        .message {
            font-weight: 600;
        }
    }

    .items {
        margin-bottom: 30px;
    }
}
</style>
