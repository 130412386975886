<template>
    <div class="okraina-tabs-scroll">

        <div class="tabs" ref="items">

            <a class="tab" :class="{ selected: modelValue == item.id }" v-for="item in items" :key="item.id"
                :ref="(el) => { this.setCurrentRef(item, el) }" :href="item.url ? item.url : 'javascript:void(0)'"
                @click.prevent="onClick(item.id)">
                {{ item.name }}
            </a>

        </div>
    </div>
</template>

<script>

export default {
    name: "okraina-tabs-scroll",
    emits: ["update:modelValue"],
    components: {

    },
    data() {
        return {
            currentRef: false,
            scrollLeft: 0
        }
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },

    },

    watch: {
        currentRef: function () {
            //this.restoreScroll();
        },
    },


    methods: {

        setCurrentRef(item, el) {
            if (item.id == this.modelValue) {
                this.currentRef = el;
            }
        },

        /**
         * Скрол элементов 
         */
        onScroll() {
            this.scrollLeft = this.$refs.items.scrollLeft;
        },

        /**
         * Восстанавливает скрол
         */
        restoreScroll() {

            if (!this.$refs.items) {
                return;
            }

            //еслои сохранена прокрутка
            if (this.scrollLeft > 0) {
                //восстанавливаем прокрутку
                this.$refs.items.scrollLeft = this.scrollLeft;
            }
            //иначе если известен текущий раздел
            else if (this.currentRef) {
                //то прокручиваем к нему
                let left = this.currentRef.offsetLeft;
                this.$refs.items.scrollLeft = left - 40;
                this.scrollLeft = left - 40;

            }
        },

        onClick(id) {
            this.$emit("update:modelValue", id);
        }

    },

    /**
     * Активация при кешировании router'ом
     */
    activated() {
        this.restoreScroll();
    },

    mounted() {
        this.restoreScroll();
        //подписываемся на скролинг
        this.$refs.items.addEventListener("scroll", this.onScroll);

    },

    beforeUnmount() {
        //отписываесмся от скролинга 
        this.$refs.items.removeEventListener("scroll", this.onScroll);
    },



}

</script>

<style lang="scss">
.okraina-tabs-scroll {

    margin-bottom: 15px;

    .tabs {
        margin: 0px -8px;
        padding-left: 8px;
        overflow-x: auto;
        display: flex;
        box-sizing: content-box;
        min-height: 27px; //пока подгружается чтобы высота не скакала

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }


        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .tab {
            flex-shrink: 0;
            margin-right: 8px;
            white-space: nowrap;

            background-color: var(--color-block-background);
            padding: 4px 10px;
            border-radius: 16px;
            color: var(--color-brand-dark);
            border-bottom: 0px;

            &.selected {
                background-color: var(--color-color6);
            }

        }
    }


}
</style>