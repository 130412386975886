/* eslint-disable no-undef */

import store from '@/store';

const metrika = {

    stack: [],

    counters: {

        //Продуктивы

        "shop.okraina.ru": {
            id: 25412549
        },
        "dostavka.okraina.ru": {
            id: 30783783
        },

        //Тестовые стенды

        "test.shop.okraina.ru": {
            id: 95343333
        },
        "test.dostavka.okraina.ru": {
            id: 95343311
        },
        
        //Разработка

        "shop.okraina.loc": {
            id: 95343333
        },
        "dostavka.okraina.loc": {
            id: 95343311
        }
    },

    counter: false,

    setCounter() {
        let server = store.getters.getServer;
        for (let domain in this.counters) {
            if(server == domain) {
                this.counter = this.counters[domain];
                return;
            }
        }
    },

    
    includeScript(m,e,t,r,i,k,a) {

        m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
    },


    /**
     * Инициализация
     */
    init() {

        this.setCounter();
        this.includeScript(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(this.counter.id, "init", {
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:false,
            ecommerce:"dataLayer_"+this.counter.id
        })

        ym(this.counter.id, 'params', {
            'type' : 'app',
            'platform': store.getters.getPlatform
        })

        window["dataLayer_"+this.counter.id] = window.dataLayer || [];

        this.callStack();
    },

    /**
     * Хит страницы
     */
    hit(url, options = false) {
        ym(this.counter.id, "hit","https://"+store.getters.getServer+ url, options)
    },

    /**
     * Достижение цели
     */
    reachGoal(target) {
        //если счётчик ещё не инициализирован
        if(window.ym === undefined) {
            //запомним действие в стэке
            this.stack.push({
                method:  "reachGoal",
                target: target
            })
        } else {
            //иначе сразу выполняем действие
            ym(this.counter.id, "reachGoal",target);
        }
    },

    /**
     * Отправка данных о пользователе
     */
    userParams() {
         ym(this.counter.id, "userParams",{
            UserID: store.getters.getUser.ID
         });
    },

    /**
     * Ecommerce - просмотр товара
     */
    ecommerceDetail(product) {

        var arEvent = {
            'event': 'detail',
            'ecommerce': {
                'detail': {
                    'products': [
                        {
                            id: product.FIELDS.ID,
                            name: product.FIELDS.NAME,
                            price: product.PRICE ?  product.PRICE.DISCOUNT_VALUE: 0
                        }
                    ]
                }
            }
        };
        window["dataLayer_"+this.counter.id].push(arEvent);
    },

    /**
     * Ecommerce - добавление товара в корзину
     */
    ecommerceAdd(product) {

        var arEvent = {
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': [
                        product
                    ]
                }
            }
        };
        window["dataLayer_"+this.counter.id].push(arEvent);
    },

    /**
     * Ecommerce - удаление товара из корзины
     */
    ecommerceRemove(product) {

        var arEvent = {
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': [
                        product
                    ]
                }
            }
        };
        window["dataLayer_"+this.counter.id].push(arEvent);
    },

    /**
     * Ecommerce - покупка
     */
    ecommercePurchase(order, arProducts) {

        var arEvent = {
            'event': 'purchase',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': order.FIELDS.ID,
                        'affiliation': 'OKRAINA',
                        'revenue': order.FIELDS.PRICE
                    },
                    'products': arProducts
                }
            }
        };

        window["dataLayer_"+this.counter.id].push(arEvent);
    },

    /**
     * Выполнение действий из сохранённого стэка
     */
    callStack() {
        this.stack.forEach(element => {
            if(element.method == 'reachGoal') {
                this.reachGoal(element.target);
            }
        });
        this.stack = [];
    }



}

export default metrika;