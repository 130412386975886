<template>
    <div class="okraina-ass">

        <div class="title">{{ title }}</div>
        <div class="items">
            <div class="item" :class="item.ID.toLowerCase()" v-for="item in items" :key="item.ID">
                <span class="icon fi " :class="getIcon(item)" @click="onSocServiceClick(item.ID)"></span>
            </div>
        </div>

    </div>
</template>

<script>

import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

export default {
    name: "okraina-auth-social-services",
    components: {
    },
    data() {
        return {
            browser: false
        };
    },
    props: {
        title: {
            type: String,
            default() {
                return "Войти через соцсети";
            },
        },
    },

    computed: {
        ...mapGetters({
            server: "getServer",
            items: "getSocialServices",
            isNative: "isNativePlatform",
        }),

        origin() {
            return 'https://' + this.server;
        }
    },
    methods: {

        /**
         * Определяет иконку для соцсети
         */
        getIcon(item) {
            let icon = "";
            if (item.ID == "VKontakte") {
                icon = "fi-vk";
            } else if (item.ID == "Odnoklassniki") {
                icon = "fi-ok";
            }

            return icon;
        },

        /**
         * Обработка успехного входа
         */
        onSuccess: function (data) {

            let connection = this.$store.getters.getConnection;
            connection.access_token = data.ACCESS_TOKEN;
            connection.refresh_token = data.REFRESH_TOKEN;
            connection.server = this.$store.getters.getServer;

            //сохраняем подключение в текущем состоянии
            this.$store.commit("updateConnection", connection);

            //устанавливаем подключение текущим
            this.$store.commit("setCurrentConnection", connection.server);

            //событие завершения входа пользователя
            bus.emit("OKRAINA_APP_E_USER_LOGIN");

            this.login = "";
            this.password = "";
            this.needCaptcha = false;
        },

        /**
         * Клик по иконке соцсети
         */
        onSocServiceClick(id) {

            let url = this.origin + "/rest/pages/auth/socserv.php?auth_service_id=" + id;

            if (this.isNative) {
                this.openInAppBrowser(url);
            } else {
                window.open(url, '', 'status=no,scrollbars=yes,resizable=no,width=' + screen.width + ',height=' + screen.height + ',top=0,left=0');
            }


        },

        /**
         * Открывает свтроенный браузер для Native
         */
        openInAppBrowser(url) {

            let root = document.documentElement;
            var styles = window.getComputedStyle(root);
            var color = styles.getPropertyValue('--color-background');
            color = color.trim();

            this.browser = InAppBrowser.create(url, "_blank", {
                location: "yes",
                toolbarcolor: tools.convertHexColor(color)
            });

            //подписываемся на сообщение браузера
            this.browser.on("message").subscribe((event) => {
                this.onBrowserMessage(event.data);
            });

            //подписываемся на окончание загрузки URL
            this.browser.on("loadstop").subscribe(() => {
                this.onLoadStop(this.browser);
            });

        },

        /**
         * Обработка окончания загрузки страницы
         */
        onLoadStop(browser) {

            //внедяем объект для взаимодействия
            browser.executeScript({
                code: "" +
                    "window.MobileApp = {" +
                    "   postMessage: function(message) {" +
                    "        console.log(message);   " +
                    "        webkit.messageHandlers.cordova_iab.postMessage(message);" +
                    "   }" +
                    "};"

            });

        },


        /**
         * Обработка получения уведолмения от дочернего окна
         */
        onMessage(e) {

            if (!e.origin == this.origin) {
                return;
            }

            if (!(typeof e.data === 'string' || e.data instanceof String)) {
                return;
            }

            let data = false;
            try {
                data = JSON.parse(e.data);
            } catch (e) {
                return;
            }

            if (data) {
                //входе через созсети
                if (data.action == "AUTH_SERVICE_LOGIN") {

                    this.onSuccess({
                        ACCESS_TOKEN: data.data.TOKEN,
                        REFRESH_TOKEN: data.data.REFRESH_TOKEN
                    });
                }

            }

        },

        /**
         * Обработка получения сообщения от встроенного браузера
         */
        onBrowserMessage(message) {
            if (message.action == "AUTH_SERVICE_LOGIN") {
                this.browser.close();
                this.onSuccess({
                    ACCESS_TOKEN: message.data.TOKEN,
                    REFRESH_TOKEN: message.data.REFRESH_TOKEN
                });
            }

        },

    },

    mounted() {
        window.addEventListener('message', this.onMessage);
    },

    beforeUnmount() {
        window.removeEventListener('message', this.onMessage);
    },
};
</script>

<style lang="scss">
.okraina-ass {

    .title {
        color: var(--color-a);
        text-align: center;
        margin-bottom: 12px;
    }

    .items {
        text-align: center;

    }

    .item {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 6px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }

        &.vkontakte {
            background-color: #4D76A1;

            .icon {
                color: #fff;
            }
        }

        &.odnoklassniki {
            background-color: #F96600;

            .icon {
                color: #fff;
            }
        }
    }

}
</style>
