<template>
    <div class="okraina-cp-sb" v-ripple @click="onClick" :class="{ detail: detail, small: small }">

        <span v-if="!subscribed">Подписаться</span>
        <span v-if="subscribed">Отписаться</span>

    </div>
</template>

<script>
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-subscribe-btn",
    components: {},
    emits: ["update:modelValue", "change", "focused", "update:valid", "update:error"],
    data() {
        return {
            subscribed: this.modelValue
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        modelValue: {
            type: Boolean,
            default() {
                return false
            }
        },
        detail: {
            type: Boolean,
            default() {
                return false
            }
        },
        small: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {

        ...mapGetters({
            isAuthorized: "isAuthorized",
        }),
    },

    methods: {

        /**
         * Клик по кнопке
         */
        onClick() {

            if (!this.isAuthorized) {
                bus.emit("OKRAINA_AUTH_NEED_E_OPEN");
            } else if (!this.subscribed) {
                this.save("Y");
            } else {
                this.save("N");
            }
        },


        save(action) {
            rest
                .call("basket.subscribe", {
                    method: "post",
                    data: {
                        ID: this.productId,
                        SUBSCRIBE: action
                    },
                })
                .then((data) => {
                    //если ошибька
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {

                        this.subscribed = action == "Y";
                        this.$emit("update:modelValue", this.subscribed);

                        //показываем сообщение об успехе
                        if (action == "Y") {

                            bus.emit("OKRAINA_MESSAGE_E_SHOW", {
                                message: "Вы успешно подписаны на появление товара. Вам придёт E-Mail уведомление.",
                                type: "success",
                                timeout: 10000
                            });
                        } else {
                            bus.emit("OKRAINA_MESSAGE_E_SHOW", {
                                message: "Вы успешно отписались от появления товара.",
                                type: "success"
                            });
                        }


                    }
                });
        }
    },

    created() { },
};
</script>

<style lang="scss">
.okraina-cp-sb {
    border-radius: 8px;
    text-align: center;
    padding: 7px 7px;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--color-btn3-background);
    color: var(--color-btn3-text);
    border: 1px solid var(--color-btn3-border);

    &.detail {
        font-size: 14px;
        padding: 12px 25px;
        border-radius: 80px;
        border-width: 2px;
    }

    &.small {
        font-size: 12px;
    }

}
</style>