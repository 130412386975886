
const templates =  {
    "shop.okraina.ru": {
        title: "Мой заказ № ORDER_ID",
        tags: [
            {
                name: "description",
                content: "",
            },
            {
                name: "keywords",
                content: "",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "Мой заказ № ORDER_ID",
        tags: [
            {
                name: "description",
                content: "",
            },
            {
                name: "keywords",
                content: "",
            },
        ]
    },
}
export default templates;