<template>
    <okraina-view :class="['okraina-v-scanner-barcode', scanerShown && isNative ? 'scan' : '']" :refreshDisabled="true"
        :showBack="false" ref="view" :hideMenu="true">

        <template v-slot:header>
            <div class="header">
                <div>
                    <div class="back-btn" @click="back()">
                        <span class="icon fi fi-arrow-left"></span>
                    </div>
                </div>
                <div>
                    <div class="title">
                        <div class="text">Наведите камеру на PIC код</div>
                    </div>
                </div>
                <div></div>
            </div>
        </template>
        <template v-slot:content>

            <okraina-loader-page v-show="!scanerShown" />

            <template v-if="scanerShown">

                <svg class="frame" :class="{ 'result-received': resultReceived }" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <mask id="mask" x="0" y="0" width="100%" height="100%">
                            <rect x="0" y="0" width="100%" height="100%" fill="#8F8F8F" />
                            <rect width="280" height="320" x="50%" y="50%" transform="translate(-140,-160)" rx="30" />

                        </mask>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" stroke="none" mask="url(#mask)" fill="#8F8F8F"
                        fill-opacity="0.5" />

                    <svg x="50%" y="50%" width="286" height="324" viewBox="0 0 286 324" fill="none" overflow="visible"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M72.5 2H32C15.4315 2 2 15.4315 2 32V82" stroke="white" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M284 242V292C284 308.569 270.569 322 254 322H213.5" stroke="white" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M72.5 322H32C15.4315 322 2 308.569 2 292V242" stroke="white" stroke-width="3"
                            stroke-linecap="round" />
                        <path d="M213.5 2H254C270.569 2 284 15.4315 284 32V82" stroke="white" stroke-width="3"
                            stroke-linecap="round" />
                    </svg>

                </svg>

                <div class="picture" v-if="photoMode" :style="{ backgroundImage: 'url(' + this.photoUri + ')' }"></div>


                <div class="btn btn1 toggle-photo" v-if="!photoMode">
                    <input type="file" ref="file" @change="onFileChange" />
                    Выбрать фото из галереи
                </div>

                <div class="btn btn1 toggle-photo" v-if="photoMode" @click="disablePhotoMode">
                    Сканировать камерой
                </div>

            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";

import bus from "@/plugins/bus";
import { BarcodeFormat, DecodeHintType } from '@zxing/library';
import { BrowserMultiFormatReader } from '@zxing/browser';

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-scanner-barcode",
    components: {
        okrainaView,
        okrainaLoaderPage
    },
    data() {
        return {
            scanerShown: false,
            resultReceived: false,
            photoMode: false,
            photoUri: "",
            file: null
        };
    },
    computed: {
        ...mapGetters({
            isNative: "isNativePlatform"
        }),
    },


    methods: {

        /**
         * Обоаботка готовности сканера
         */
        onReady() {
            this.scanerShown = true;
        },

        /**
         * Назад
         */
        back() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
        },

        /**
         * Обработка получения результата
         */
        onReceive() {
            this.resultReceived = true;
            this.scanerShown = true;

        },

        /**
         * Обработка выбора файла
         */
        onFileChange(event) {
            if (event.target.files.length == 0) {
                return;
            }
            let file = event.target.files[0];
            let isImage = file.type.includes("image");
            if (!isImage) {
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Выбранный файл не является изображением. Попробуйте другой файл." });
                return;
            }

            let url = URL.createObjectURL(file);
            this.photoMode = true;
            this.photoUri = url;
            this.file = file;
            bus.emit("OKRAINA_BARCODE_SCANNER_E_CLOSE");

            this.scanFromPhoto();

        },

        /**
         * Переключение в режим сканировая камерой
         */
        disablePhotoMode() {
            this.photoMode = false;
            bus.emit("OKRAINA_BARCODE_SCANNER_E_SHOW");
        },

        /**
         * Скнирование с фото
         */
        scanFromPhoto() {

            const hints = new Map();
            const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128];
            hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

            const codeReader = new BrowserMultiFormatReader(hints);
            codeReader.decodeFromImageUrl(URL.createObjectURL(this.file)).then((result) => {

                this.resultReceived = true;

                bus.emit("OKRAINA_BARCODE_SCANNER_E_RECIVE_MANUAL", result.text);


            }).catch(() => {
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Не удалось распознать код. Попробуйте другой файл." });
            });



        }

    },

    mounted() {
        //подписваемся на события
        bus.on("OKRAINA_BARCODE_SCANNER_E_READY", this.onReady);
        bus.on("OKRAINA_BARCODE_SCANNER_E_RESULT_RECEIVE", this.onReceive);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("OKRAINA_BARCODE_SCANNER_E_READY", this.onLogin);
        bus.off("OKRAINA_BARCODE_SCANNER_E_RESULT_RECEIVE", this.onReceive);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.scanerShown = false;
            vm.resultReceived = false;
        });
    },

    beforeRouteLeave() {
        if (this.scanerShown) {
            //выключим сканер
            bus.emit("OKRAINA_BARCODE_SCANNER_E_CLOSE");
            this.scanerShown = false;
            this.photoMode = false;
        }
    },


}
</script>

<style lang="scss">
.okraina-v-scanner-barcode {

    .view-header {
        border-bottom: 0px;

        .header-bg {
            background-color: transparent !important;
        }

    }

    &.scan {
        background: transparent;

        .view-outer {
            background: transparent;
        }
    }

    .header {
        display: grid;
        grid-template-columns: auto auto 1fr;
        column-gap: 7px;
        align-items: center;

        .title {
            position: relative;
            padding: 5px 10px;

            overflow: hidden;
            border-radius: 50px;
            color: #fff;


            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background: #000000;
                opacity: 0.2;

            }

            .text {
                position: relative;
            }
        }

        .back-btn {
            width: 30px;
            height: 30px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background: #000000;
                opacity: 0.2;

            }

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 11px;
                color: #FFF;
            }
        }

    }

    .frame {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 100;

        svg {
            overflow: visible;
        }

        path {
            transform: translate(-142px, -162px);
        }

        &.result-received {
            path {
                stroke: red;
            }

        }

    }

    .toggle-photo {
        position: absolute;
        z-index: 110;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        overflow: hidden;


        input[type="file"] {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            opacity: 0;
            filter: alpha(opacity=0);
            transform: translate(-300px, 0) scale(4);
            font-size: 23px;
            direction: ltr;
            cursor: pointer;
            z-index: 2;
        }

    }

    .picture {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: #000;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
}

body.scanner-active {
    background-color: transparent;
}
</style>