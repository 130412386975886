<template>
  <okraina-view :class="'okraina-v-home'" @refresh="refresh" ref="view" :showBack="false" :noHeaderBorder="true">
    <template v-slot:top>
      <okraina-region @change="onRegionChange" />
    </template>
    <template v-slot:header>
      <okraina-catalog-search-input :showBack="false" />
    </template>
    <template v-slot:content>
      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <template v-if="!loading">

        <okraina-slider-main-banners-static :items="banners" v-if="banners.length > 0" />

        <okraina-slider-main-banners :items="slides" v-if="slides.length > 0" />

        <div class="tabs">
          <div class="row">
            <div class="col">
              <a class="tab" href="/catalog/newproduct/" v-ripple="'rgba(255, 255, 255, 0.15)'"
                @click.prevent="onTabClick('/catalog/newproduct/', 'new')">Новинки</a>
            </div>
            <div class="col">
              <a class="tab" href="/catalog/discount/" v-ripple="'rgba(255, 255, 255, 0.15)'"
                @click.prevent="onTabClick('/catalog/discount/', 'discount')">Скидки</a>
            </div>
            <div class="col">
              <a class="tab" href="/catalog/saleleader/" v-ripple="'rgba(255, 255, 255, 0.15)'"
                @click.prevent="onTabClick('/catalog/saleleader/', 'sale')">Хиты</a>
            </div>
            <div class="col">
              <a class="tab" href="/personal/favorites/" v-ripple="'rgba(255, 255, 255, 0.15)'"
                @click.prevent="onTabClick('/personal/favorites/', 'favorites')">
                Избранное
                <div v-if="favCount > 0" class="counter">
                  <div class="cnt">{{ favCount > 99 ? 99 : favCount }}</div>
                </div>
              </a>
            </div>


          </div>
        </div>

        <okraina-slider-products :items="saleProducts" :title="'Лучшие скидки'" :pathToAll="'/catalog/discount/'"
          :textToAll="'Больше'" v-if="saleProducts.length > 0" />

        <okraina-catalog-sections-tabs :items="catalogSections" :title="'Каталог'" :pathToAll="'/catalog/'"
          :textToAll="'Больше'" />

        <okraina-slider-products :items="hitProducts" :title="'Хиты продаж'" :pathToAll="'/catalog/saleleader/'"
          :textToAll="'Больше'" v-if="hitProducts.length > 0" />

        <okraina-slider-products :items="newProducts" :title="'Новинки'" :pathToAll="'/catalog/newproduct/'"
          :textToAll="'Больше'" v-if="newProducts.length > 0" />

        <template v-if="productOfDay">
          <h2>Товар дня</h2>
          <div class="product-of-day">
            <okraina-catalog-item :item="productOfDay" :big="true" />
          </div>
        </template>

        <okraina-catalog-recommendations :refreshIndex="refreshIndex" />
      </template>
    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaRegion from "@/components/region.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaSliderMainBanners from "@/components/sliders/main.banners.vue";
import okrainaSliderMainBannersStatic from "@/components/sliders/main.banners.static.vue";
import okrainaSliderProducts from "@/components/sliders/products.vue";
import okrainaCatalogItem from "@/components/catalog/item.vue";
import okrainaCatalogRecommendations from "@/components/catalog/recommendations.vue";
import okrainaCatalogSectionsTabs from "@/components/catalog/sections.tabs.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/home.js";
import store from '@/store'
import moment from "moment";
import tools from "@/plugins/tools";
import metrika from '@/plugins/yandex.metrika'

import { mapGetters } from "vuex";

export default {
  name: "okraina-v-home",
  components: {
    okrainaView,
    okrainaRegion,
    okrainaCatalogSearchInput,
    okrainaLoaderPage,
    okrainaSliderMainBanners,
    okrainaSliderMainBannersStatic,
    okrainaSliderProducts,
    okrainaCatalogItem,
    okrainaCatalogRecommendations,
    okrainaCatalogSectionsTabs,
  },
  data() {
    return {
      loading: true,
      slides: [],
      banners: [],
      saleProducts: [],
      hitProducts: [],
      newProducts: [],
      actionsProductOfDay: [],
      catalogSections: [],
      productOfDay: false,
      refreshIndex: 0,
    };
  },
  props: {
    affiliate: {
      type: [String, Number],
      default() {
        return "";
      },
    },
  },
  computed: {
    ...mapGetters({
      bonus: "getBonus",
      isAuthorized: "isAuthorized",
      termsOfUseAccepted: "getTermsOfUseAccepted",
      isNative: "isNativePlatform",
      pushClicked: "getPushClicked",
      favCount: "getFavoritesCount",
    }),

    requests() {

      //рассчитываем множитель для ресайза баннеров
      let multiply = 3;
      if (window.screen.width > 400 && window.screen.width < 700) {
        multiply = 4;
      } else if (window.screen.width > 700) {
        multiply = 5;
      }

      return {
        slides: {
          CLASS: "slides",
          METHOD: "main.getlist",
          PARAMS: {
            RESIZE_OPTIONS: {
              TYPE: "PROPORTIONAL",
              WIDTH: 300 * multiply,
              HEIGHT: 110 * multiply
            }
          }
        },
        banners: {
          CLASS: "slides",
          METHOD: "banners.getlist",
          PARAMS: {
            RESIZE_OPTIONS: {
              WIDTH: 143 * multiply,
              HEIGHT: 190 * multiply,
              TYPE: "EXACT"
            }
          }
        },
        saleProducts: {
          CLASS: "catalog",
          METHOD: "products.getlist",
          PARAMS: {
            FILTER: {
              CATALOG_AVAILABLE: "Y",
              PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
              "!PROPERTY_ACTION": false,
            },
            ORDER: {
              rand: "asc",
            },
            NAV_PARAMS: {
              NUM_PAGE: 1,
              PAGE_SIZE: 8,
              PAGES_COUNT: 1,
            },
            RESIZE_OPTIONS: tools.getProductListResizeOptions(),
          },
        },
        hitProducts: {
          CLASS: "catalog",
          METHOD: "products.getlist",
          PARAMS: {
            FILTER: {
              CATALOG_AVAILABLE: "Y",
              PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
              "!PROPERTY_SALELEADER": false,
            },
            ORDER: {
              rand: "asc",
            },
            NAV_PARAMS: {
              NUM_PAGE: 1,
              PAGE_SIZE: 8,
              PAGES_COUNT: 1,
            },
            RESIZE_OPTIONS: tools.getProductListResizeOptions(),
          },
        },
        newProducts: {
          CLASS: "catalog",
          METHOD: "products.getlist",
          PARAMS: {
            FILTER: {
              CATALOG_AVAILABLE: "Y",
              PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
              "!PROPERTY_NEWPRODUCT": false,
            },
            ORDER: {
              rand: "asc",
            },
            NAV_PARAMS: {
              NUM_PAGE: 1,
              PAGE_SIZE: 8,
              PAGES_COUNT: 1,
            },
            RESIZE_OPTIONS: tools.getProductListResizeOptions(),
          },
        },
        actionsProductOfDay: {
          CLASS: "actions",
          METHOD: "getlist",
          PARAMS: {
            FILTER: {
              "<PROPERTY_DSTART": moment().format("YYYY-MM-DD HH:mm:ss"),
              1: {
                LOGIC: "OR",
                ">PROPERTY_DSTOP": moment().format("YYYY-MM-DD HH:mm:ss"),
                PROPERTY_DSTOP: moment().format("YYYY-MM-DD 00:00:00"),
              },
              "!PROPERTY_SHOW_PRODUCT_OF_DAY": false,
            },
          },
        },
        catalogSections: {
          CLASS: "catalog",
          METHOD: "sections.getlist",
          PARAMS: {
            ORDER: {
              NAME: "ASC",
            },
            FILTER: {
              DEPTH_LEVEL: "1",
            },
            RESIZE_OPTIONS: {
              WIDTH: "100",
              HEIGHT: "100",
              TYPE: "EXACT",
            },
          },
        },
      };
    },
  },
  methods: {
    /**
     * Загрузка данных
     */
    loadData(resolve = false) {

      //запрашиваем данные
      rest
        .batch(this.requests)
        .then((results) => {
          this.onSuccessLoad(results);
        })
        .finally(() => {
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    /**
     * Обработка загрузки данных
     */
    onSuccessLoad(results) {
      for (var key in results) {
        let data = results[key];
        if (data.SUCCESS) {
          //слайдер баннеров
          if (key == "slides") {
            this.slides = data.ITEMS;
          }
          //статичные баннеры
          else if (key == "banners") {
            this.banners = data.ITEMS;
          }
          //товары по скидке
          else if (key == "saleProducts") {
            this.saleProducts = data.ITEMS;
          }
          //товары хиты
          else if (key == "hitProducts") {
            this.hitProducts = data.ITEMS;
          }
          //товары новинки
          else if (key == "newProducts") {
            this.newProducts = data.ITEMS;
          }
          //акции "Товар дня"
          else if (key == "actionsProductOfDay") {
            this.actionsProductOfDay = data.ITEMS;
            //загружаем товар дня
            this.loadProductOfDay();
          }
          //разделы каталога
          else if (key == "catalogSections") {
            this.catalogSections = data.ITEMS;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          return;
        }
      }
    },

    /**
     * Зашружает товар дня
     */
    loadProductOfDay() {
      this.productOfDay = false;

      //получаем ИД товаров из акций товара дня
      let ids = [];
      this.actionsProductOfDay.forEach((item) => {
        if (item.PROPS.ACGOODS.length > 0) {
          ids = ids.concat(item.PROPS.ACGOODS);
        }
      });
      if (ids.length == 0) {
        return;
      }

      //запрашивает даные
      rest
        .call("catalog.products.getlist", {
          method: "post",
          data: {
            FILTER: {
              ID: ids,
              CATALOG_AVAILABLE: "Y",
              PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
            },
            ORDER: {
              rand: "asc",
            },
            NAV_PARAMS: {
              NUM_PAGE: 1,
              PAGE_SIZE: 1,
              PAGES_COUNT: 1,
            },
            RESIZE_OPTIONS: tools.getProductListResizeOptions(),
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            if (data.ITEMS.length > 0) {
              this.productOfDay = data.ITEMS[0];
            }
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          }
        });
    },

    /**
     * Перезагрузка экрана
     */
    refresh(resolve = false) {
      this.refreshIndex++;
      this.loadData(resolve);
    },

    /**
     * Переход на указанный URL
     */
    onTabClick(url, code) {

      metrika.reachGoal("app-home-click-" + code);

      setTimeout(() => {
        this.$router.push({ path: url });
      }, 400);
    },

    /**
     * Обработка смены региона
     */
    onRegionChange() {
      this.loading = true;
      this.refresh();
    },

    /**
     * Обработка выхода (только если находимся на главном экране)
     */
    onLogout() {
      this.loading = true;
      this.refresh();
    }
  },

  created() {
    this.loading = true;
    this.loadData(() => {

      //либо Web либо соглашение с политикой обработки данных принято
      //И
      //сейчас не обрабатывается клик по Push
      if ((!this.isNative || this.termsOfUseAccepted) && !this.pushClicked) {
        //вызываем показ fullscreen баннеров
        bus.emit("OKRAINA_FULLSCREEN_BANERS_E_SHOW");
      }
    });
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    //запишем мета данные страницы
    meta.write(metaTemplates);

    //отправляем хит в BigData, чтобы получить хэш посетителя
    store.dispatch("bigdata/hit");

    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },

  mounted() {
    bus.on("OKRAINA_APP_E_USER_LOGOUT", this.onLogout);
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  unmounted() {
    bus.off("OKRAINA_APP_E_USER_LOGOUT", this.onLogout);
  },
};
</script>

<style lang="scss">
.okraina-v-home {
  .tabs {
    margin-bottom: 25px;

    .row {
      margin: 0px -4px;

      >.col {
        padding: 0px 4px;
      }
    }

    .tab {
      position: relative;
      display: block;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      border-radius: 80px;
      padding: 10px 10px;

      color: var(--color-btn1-text);
      border-bottom: 0px;

      background: linear-gradient(90.07deg,
          var(--color-color1) 0.06%,
          var(--color-color3) 98.88%),
        var(--color-color1);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

      &.cashback {
        position: relative;
        background: linear-gradient(281.01deg,
            #2793ca 24.43%,
            #399dad 30.54%,
            #59af79 42.64%,
            #74be4d 54.53%,
            #88c92c 65.96%,
            #97d114 76.79%,
            #a0d605 86.8%,
            #a3d800 95.18%);

        .balance {
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          background: var(--color-icon-counter);
          border-radius: 36px;
          padding: 3px 20px 3px 7px;
          line-height: 1;
          color: var(--color-icon-counter-text);

          .icon {
            position: absolute;
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 9px;
          }
        }
      }

      .counter {
        position: absolute;
        top: -8px;
        right: 8px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: var(--color-icon-counter);

        .cnt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 8px;
          color: var(--color-icon-counter-text);
        }
      }
    }
  }

  .product-of-day {
    margin-bottom: 30px;
  }
}
</style>
