<template>
    <okraina-view :class="'okraina-v-favorites'" @refresh="refresh" ref="view">

        <template v-slot:header>
            <h1>
                Любимые товары
            </h1>
        </template>
        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>
            <template v-if="!loading">

                <!--Если список избранного не пуст-->
                <template v-if="items.length > 0">

                    <div class="list">
                        <div class="item-wrap" v-for="item in items" :key="item.FIELDS.ID">
                            <okraina-catalog-item :item="item" />
                        </div>
                    </div>

                </template>

                <!--Иначе список избранного пуст пуста-->
                <template v-else>
                    <div class="favorites-empty">
                        <div class="caption">Пусто</div>
                        <div class="details">
                            Вы ещё не успели добавить любимые товары
                        </div>
                    </div>
                </template>

                <okraina-catalog-recommendations :slider="true" :refreshIndex="refreshIndex" />

            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogRecommendations from "@/components/catalog/recommendations.vue";
import okrainaCatalogItem from "@/components/catalog/item.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/favorites.js";
import store from '@/store'
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-favorites",
    components: {
        okrainaView,
        okrainaLoaderPage,

        okrainaCatalogRecommendations,
        okrainaCatalogItem,
    },
    data() {
        return {
            loading: true,
            items: [],
            timer: 0,
            refreshIndex: 0,
            active: true,
        }
    },
    computed: {
        ...mapGetters({
            isAuthorized: "isAuthorized",
            favorites: "getFavorites",
        }),

        filter() {
            let ids = this.items.map((item) => {
                return item.FIELDS.ID;
            });

            return {
                "ID": ids
            }
        }
    },
    watch: {
        favorites: {
            handler() {

                //смотрим может добавился какой-то новый товар, которого в текущем списке нет
                let ids = this.items.map(i => i.FIELDS.ID);
                let difference = this.favorites.filter(x => !ids.includes(x));

                if (!this.active || difference.length > 0) {

                    if (this.timer > 0) {
                        clearTimeout(this.timer);
                    }

                    //ставим таймер, чтобы запрос на изменение избранного по факту успел выполниться
                    this.timer = setTimeout(() => {
                        this.timer = 0;
                        this.loading = !this.active;
                        this.loadData(() => { });
                    }, 400);

                }

            },
            deep: true
        },
    },
    methods: {

        /**
         * Получает данные для запроса
         */
        getBatchRequest() {
            return {
                "favorites": {
                    CLASS: "favorites",
                    METHOD: "getlist",
                    PARAMS: {
                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    }
                },
            };
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }
            rest
                .batch(this.getBatchRequest()).then((results) => {
                    if (this.timer == 0) {
                        this.onSuccessLoad(results);
                    }
                })
                .finally(() => {
                    if (this.timer == 0) {
                        this.loading = false;
                    }
                    if (resolve) {
                        resolve();
                    }
                });
        },
        /**
         * Обработка загрузки данных
         */
        onSuccessLoad(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    if (key == "favorites") {
                        this.items = data.PRODUCTS;
                    }
                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    return;
                }
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.refreshIndex++;
            this.loadData(resolve);
        },
    },
    created() {
        this.loadData();
    },

    activated() {
        this.active = true;
    },

    deactivated() {
        this.active = false;
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);

        //отправляем хит в BigData, чтобы получить хэш посетителя
        store.dispatch("bigdata/hit");
    },

    mounted() {
        bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
    },

    beforeUnmount() {
        bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
    },
}
</script>

<style lang="scss">
.okraina-v-favorites {

    .favorites-empty {
        text-align: center;
        height: 100%;
        background-color: #fff;
        border-radius: 50%;
        padding: 100px 0px;
        margin-top: 70px;
        margin-bottom: 25px;

        .caption {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #505050;
        }

        .details {
            font-size: 13px;
            line-height: 140%;
            color: rgba(51, 51, 51, 0.8);
        }
    }

    .title {
        position: relative;
        font-size: 20px;
        line-height: 1;
        color: var(--color-text-title);
        padding: 20px 0px;
    }

    .delimiter {
        margin-bottom: 10px;
        height: 1px;
        background-color: rgba(var(--color-text-primary-rgb), 0.1);
    }

    .list {
        display: grid;
        grid-template-columns: calc(50% - 4px) calc(50% - 4px);
        grid-gap: 8px;
        margin-bottom: 45px;
        margin-top: 30px;
    }

    @media (min-width: 600px) {
        .list {
            grid-template-columns: calc(33.333% - 4px) calc(33.333% - 4px) calc(33.333% - 4px);
        }
    }

    @media (min-width: 800px) {
        .list {
            grid-template-columns: calc(25% - 4px) calc(25% - 4px) calc(25% - 4px) calc(25% - 4px);
        }
    }

}
</style>