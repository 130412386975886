<template>
    <okraina-view :class="'okraina-v-order-confirm'" :refreshDisabled="true" ref="view">
        <template v-slot:header>

            <h1 class="title">
                Заказ № {{ id }}
            </h1>

        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <template v-if="!loading">

                <template v-if="isBuyOneClick">

                    <div class="order-new">
                        <div class="picture boc" :style="{ 'background-image': 'url(' + imageOrderBuyOneClick + ')' }">
                        </div>
                        <div class="caption">Ваш заказ <br /> принят в обработку</div>

                        <div class="details">
                            Ожидайте звонка оператора для уточнения адреса
                            и времени доставки
                        </div>
                    </div>

                </template>
                <template v-else-if="order">
                    <div class="order-new">

                        <div class="picture" :style="{ 'background-image': 'url(' + imageOrderNew + ')' }"></div>
                        <div class="caption">Ваш заказ <br /> успешно оформлен!</div>
                        <div class="details">

                            <template v-if="order.DELIVERY.NOTES">
                                <div v-html="order.DELIVERY.NOTES"></div>
                            </template>

                            <template v-else>

                                Ожидайте доставку
                                <template v-if="isDeliveryDateToday">сегодня</template>
                                <template v-else-if="deliveryDate">
                                    {{ moment(deliveryDate, 'DD/MM/YYYY').format("DD MMMM") }}
                                </template>
                                &nbsp;
                                <div v-if="deliveryTime"> {{ deliveryTime }}</div>

                            </template>

                        </div>
                        <div class="notes" v-if="order.DATA.CAN_REPAY != 'Y' && order.DATA.SHOW_NOTICE == 'Y'"
                            v-html="order.DATA.NOTICE"></div>

                    </div>
                </template>

                <template v-else>
                    Заказ не найден.
                </template>

            </template>

        </template>

        <template v-slot:footer>

            <template v-if="!loading && order && isAuthorized">
                <div class="btn-wrap">
                    <a v-if="order.DATA.CAN_REPAY == 'Y'" class="btn btn1 btn-block"
                        :href="'/personal/order/payment/?ORDER_ID=' + id" v-ripple @click.prevent="onPayClick">Оплатить</a>

                    <a v-else class="btn btn1 btn-block" :href="'/personal/order/detail/' + id + '/'" v-ripple
                        @click.prevent="onDetailClick">Перейти к заказу</a>
                </div>
            </template>

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import metrika from '@/plugins/yandex.metrika'

import imageOrderNewPath from "@/assets/images/order-new.svg";
import imageOrderBuyOneClickPath from "@/assets/images/order-boc.svg";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-order-confirm",
    components: {
        okrainaView,
        okrainaLoaderPage
    },
    data() {
        return {
            loading: false,
            order: false,
            imageOrderNew: imageOrderNewPath,
            imageOrderBuyOneClick: imageOrderBuyOneClickPath,
            moment: moment
        };
    },
    props: {
        id: {
            type: String,
            default() {
                return "";
            },
        },
    },
    computed: {

        ...mapGetters({
            user: "getUser",
            isAuthorized: "isAuthorized",
            platform: "getPlatform",
            exportPurchase: "getExportPurchase"
        }),

        deliveryDate() {
            if (!this.order) {
                return false;
            }
            let prop = this.order.PROPS.find(p => p.CODE == 'DELIVERY_DATE');
            if (!prop || !prop.VALUE) {
                return false;
            }

            return prop.VALUE;
        },

        isDeliveryDateToday() {
            if (!this.deliveryDate) {
                return false;
            }

            let date = moment(this.deliveryDate, 'DD/MM/YYYY')
            return date.isSame(moment(), 'day');
        },

        deliveryTime() {
            if (!this.order) {
                return false;
            }
            let prop = this.order.PROPS.find(p => p.CODE == 'DELIVERY_TIME');
            if (!prop || !prop.VALUE) {
                return false;
            }

            let tmp = prop.VALUE.split('-');
            return "с " + tmp[0] + " до " + tmp[1]
        },

        isBuyOneClick() {

            //если пользователь не авторизован - то скорее вссего это заказ в 1 клик
            if (!this.isAuthorized) {
                return true;
            }

            if (!this.order) {
                return false;
            }

            let prop = this.order.PROPS.find(p => p.CODE == 'FROM_BUY_ONE_CLICK');
            if (prop && prop.VALUE == 'Y') {
                return true;
            }

            return false;
        }

    },
    methods: {

        /**
         * Назад
         */
        back() {
            if (window.history.length > 1) {
                this.$router.go(-1)
            } else {
                this.$router.push("/");
            }
        },

        /**
         * Загрузка данных 
         */
        loadData() {

            //если не авторизован 
            if (!this.isAuthorized) {
                //скорее всего это заказ в 1 клик
                return;
            }

            this.loading = true;

            //запрашивает автооризацию
            rest
                .call("order.getbyid", {
                    method: "post",
                    data: {
                        ID: this.id,
                    },
                })
                .then((data) => {
                    //если ошибка входа
                    if (data.SUCCESS) {

                        this.order = data;

                        //если нужно выгрузить данные в Метрику
                        if (this.exportPurchase) {
                            this.sendEcommerceData();
                        }

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }

                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Клик по кнопке оплатить
         */
        onPayClick() {

            //переход на экран оплаты заказа
            this.$router.replace({
                name: "order-pay",
                query: { ORDER_ID: this.id },
            });
        },

        /**
         * Клик по кнопке оплатить
         */
        onDetailClick() {

            //переход на экран заказа
            this.$router.replace({
                name: "order-detail",
                params: { id: this.id },
            });
        },

        /**
         * Отправка данных о покупке в ЯндексМетрику
         */
        sendEcommerceData() {

            let arProducts = [];

            for (var i in this.order.ITEMS) {
                let item = this.order.ITEMS[i];

                if (item.PRICE.PRICE <= 0) {
                    continue;
                }

                var arProduct = {
                    id: item.FIELDS.PRODUCT_ID,
                    name: item.FIELDS.NAME,
                    price: item.PRICE.DISCOUNT_VALUE,
                    quantity: item.FIELDS.QUANTITY
                };
                arProducts.push(arProduct);
            }

            metrika.ecommercePurchase(this.order, arProducts);

            //пометим, что больше не нужно выгружать заказ в Метрику
            //чтобы отправка была только 1 раз и не было дублирование если F5 на экране нажимать
            this.$store.commit("setExportPurchase", false);
        }

    },

    created() {
        this.loadData();
    },
}

</script>

<style lang="scss">
.okraina-v-order-confirm {


    .order-new {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        text-align: center;

        .picture {
            margin: 0px auto 35px;
            width: 152px;
            height: 149px;
            background-size: contain;
            background-position: center center;
            transform: translateX(-10px);

            &.boc {
                width: 131px;
                height: 164px;
            }
        }

        .caption {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 15px;
            color: var(--color-text-title);
        }

        .details {
            font-size: 13px;
        }

        .notes {
            font-size: 13px;
            margin-top: 5px;

        }
    }

    .btn-wrap {

        margin-bottom: 20px;
        text-align: center;

        .btn {
            letter-spacing: 0.75px;
            max-width: 295px;
            margin: 0px auto;
        }


    }
}
</style>