const templates =  {
    "shop.okraina.ru": {
        title: "Оформление заказа",
        tags: [
            {
                name: "description",
                content: "",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Оформление заказа",
        tags: [
            {
                name: "description",
                content: "",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;