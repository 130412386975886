<template>
    <okraina-sheet v-model:visible="visibleInternal" class="okraina-cp-ram-wrap" :maxHeight="92">

        <template v-slot:body>
            <div class="okraina-cp-ram">
                <okraina-catalog-product-review-add-form :productId="productId" />
            </div>

        </template>

    </okraina-sheet>
</template>

<script>
import okrainaCatalogProductReviewAddForm from "@/components/catalog/product.review.add.form.vue";
import okrainaSheet from "@/components/sheet.vue"

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-review-add-modal",
    components: {
        okrainaSheet,
        okrainaCatalogProductReviewAddForm
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
    },
    computed: {
        ...mapGetters({
            platform: "getPlatform",
        }),
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        }
    },

    methods: {

        /**
         * Обработка открытия
         */
        onOpen() {

            this.watchVisible = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?sheetOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },

        /**
         * клик по иконке "Закрыть"
         */
        close() {
            this.visibleInternal = false;
        },


    }
}

</script>

<style lang="scss"></style>
