<template>
    <div class="okraina-control-switch" :class="{ checked: checked, right: right }" @click="onClick">
        <div class="wrap">
            <div class="icon">
                <div class="icon-wrap">
                    <div class="bullet"></div>
                </div>
            </div>
            <div class="label" v-if="label != ''">
                {{ label }}
            </div>
        </div>
        <div class="description" v-if="description" v-html="description"></div>
    </div>
</template>

<script>

export default {
    name: "okraina-control-switch",
    data() {
        return {
        };
    },
    emits: ["update:modelValue"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        value: {
            type: [String, Boolean, Number],
            default() {
                return "";
            },
        },
        modelValue: {
            type: [String, Boolean, Number],
            default() {
                return "";
            },
        },
        right: {
            type: Boolean,
            default() {
                return false
            }
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    computed: {

        checked() {
            return this.modelValue == this.value;
        },
    },
    watch: {

    },
    methods: {

        onClick() {
            if (this.disabled) {
                return;
            }

            if (this.checked) {
                this.$emit("update:modelValue", "");
            } else {
                this.$emit("update:modelValue", this.value);
            }
        }
    }
}

</script>

<style lang="scss">
.okraina-control-switch {
    position: relative;
    padding-left: 55px;
    cursor: pointer;

    .wrap {
        position: relative;
    }

    .icon {
        position: absolute;
        left: -55px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 20px;
        border: 1px solid var(--color-radio-off-border);
        border-radius: 20px;
        transition: border-color ease-in-out .15s;

        .icon-wrap {
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px
        }

        .bullet {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: translateX(0%);
            border-radius: 50%;
            width: 14px;
            height: 14px;
            background-color: var(--color-radio-off-background);
            transition: background-color ease-in-out .15s, left ease-in-out .15s, transform ease-in-out .15s;
        }
    }

    .label {
        color: var(--color-control-label);
    }

    &.checked {
        .icon {
            border-color: var(--color-radio-on-border);

            .bullet {
                background-color: var(--color-radio-on-background);
                left: 100%;
                transform: translate(-100%, 0);
            }
        }
    }

    &.right {
        padding-left: 0px;
        padding-right: 55px;

        .icon {
            left: auto;
            right: -55px;
        }
    }

    .description {
        line-height: 1.1;
        font-size: 13px;
        color: var(--color-control-description);
    }

}
</style>