const templates =  {
    "shop.okraina.ru": {
        title: "Каталог | С Вами поделились корзиной",
        tags: [

        ]
    },
    "dostavka.okraina.ru": {
        title: "Каталог | С Вами поделились корзиной",
        tags: [
            
        ]
    },
}

export default templates;