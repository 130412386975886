<template>
  <okraina-view :class="'okraina-v-catalog-search'" @refresh="refresh" :noHeaderBorder="true" ref="view">
    <template v-slot:header>
      <okraina-catalog-search-input :showBack="true" :searchMode="true" v-model="search" ref="search" />
    </template>
    <template v-slot:content>
      <transition v-if="loading">
        <okraina-loader-page v-show="loading" />
      </transition>

      <okraina-catalog-search-offers :visible="!searchCurrent" />

      <template v-if="searchCurrent">
        <div class="title">
          <h1>Результаты поиска</h1>
          <div class="tools">

            <div>
              <div class="filter-btn" :class="{ filled: Object.keys(filter).length > 0 }" @click="showFilter">
                <span class="icon fi fi-filter"></span>
              </div>
            </div>
            <div>

            </div>

          </div>
        </div>

        <okraina-catalog-filter v-model="filter" v-model:visible="filterVisible" :preFilter="preFilter" />

        <okraina-catalog-list v-model:loading="loading" :filter="resultFilter" :sort="sort" :isSearch="true"
          :refreshIndex="refreshIndex" />
      </template>
    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogSearchOffers from "@/components/catalog/search.offers.vue";
import okrainaCatalogList from "@/components/catalog/list.vue";
import okrainaCatalogFilter from "@/components/catalog/filter.vue";

import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/catalog.sections.js";
import { mapGetters } from "vuex";

export default {
  name: "okraina-v-catalog-search",
  components: {
    okrainaView,
    okrainaCatalogSearchInput,
    okrainaLoaderPage,
    okrainaCatalogSearchOffers,
    okrainaCatalogList,
    okrainaCatalogFilter
  },
  data() {
    return {
      loading: false,
      search: this.q,
      searchCurrent: this.q,
      timer: false,
      refreshIndex: 0,
      filter: {},
      filterVisible: false,
    };
  },
  props: {
    q: {
      type: String,
      default() {
        return "";
      },
    },
    focus: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentSort: "getCatalogSortCurrent",
    }),

    preFilter() {
      return {
        "NAME": this.searchCurrent,
      };
    },

    /**
     * Результирующий фильтр
     */
    resultFilter() {
      return Object.assign({}, this.preFilter, this.filter)
    },


    productFilter() {
      return {
        "NAME": this.searchCurrent,
      };
    },
    sort() {
      return { NAME: "ASC" };
    },
  },
  watch: {
    q() {
      this.search = this.q;
    },
    search(newVal) {
      if (newVal) {
        this.searchStartTimer();
      } else {
        this.searchCurrent = newVal;
      }
    },
  },
  methods: {
    /**
     * Запускает таймер поиска
     */
    searchStartTimer() {
      if (this.timer > 0) {
        clearTimeout(this.timer);
      }

      let vm = this;
      this.timer = setTimeout(function () {
        vm.searchCurrent = vm.search;
      }, 500);
    },

    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      if (!this.searchCurrent) {
        setTimeout(resolve, 200);
      } else {
        this.refreshIndex++;
        setTimeout(resolve, 200);
      }
    },

    /**
     * Показать фильтр
     */
    showFilter() {
      this.filterVisible = true;
    }

  },

  created() { },

  beforeRouteEnter(to, from, next) {
    //запишем мета данные страницы
    meta.write(metaTemplates);

    next((vm) => {
      if (vm.$refs.search && vm.focus) {
        vm.$refs.search.focus();
      }
    });
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },
};
</script>

<style lang="scss">
.okraina-v-catalog-search {

  .title {
    position: relative;
    padding-right: 75px;
  }

  .tools {
    position: absolute;
    top: 0px;
    right: 0px;

    display: flex;
    align-items: center;
    column-gap: 10px;

    .filter-btn {
      color: var(--color-color1);
      font-size: 18px;
      line-height: 0px;

      width: 28px;
      height: 28px;
      border-radius: 50%;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.filled {
        background-color: var(--color-btn1-background);
        color: var(--color-btn1-text);
      }

    }

  }
}
</style>
