import rest from "@/plugins/rest";

import base64 from 'base-64'
import utf8 from 'utf8'

const bigdata = {
    namespaced: true,
    state: () => {
        return {
            //ИД пользователя BigData
            userId: ""
        }
    },
    getters: {
        /**
         * ИД пользователя BigData
         */
        userId(state) {
            return state.userId;
        },
    },
    mutations: {
        /**
         * Устанавливает ИД пользователя BigData
         */
        setUserId:(state,value) => {
            state.userId = value;
        },
    },

    actions: {

        /**
         * Загружает данные пользователя
         */
        hit(context,counterData = false, title = false) {

            let title64 = base64.encode(utf8.encode(title ? title : document.title));

            let data = {
                ru: encodeURIComponent(window.location.pathname),
                sr: window.screen.width + "x" + window.screen.height,
                tmz:(new Date).getTimezoneOffset(),
                xts: (new Date).getTime(),
                ptitle_64: title64
            };

            if(counterData) {
                Object.keys(counterData).forEach(key => {
                    data["ad[ct]["+key+"]"] = counterData[key];
                });
            }

            rest
            .call("system.bxstat", {
                method: "post",
                data: {
                    DATA: data,
                    USER_AGENT: window.navigator.userAgent
                    
                },
            },true) //тихий режим - вместо отображения сообщения об ошибки генерит ошибку
            .then((data) => {
                if(data.SUCCESS && data.uid) {
                    //сохраним в текущем состоянии ИД пользователя BigData
                    context.commit('setUserId', data.uid);
                } else {
                    //сохраним в текущем состоянии ИД пользователя BigData
                    context.commit('setUserId', "");
                }
            }).catch( () => {
                //ничего не делаем, бывает что сервис тормозит или не работает
            });
        },

        /**
         * Фиксируем хит товара в BigData
         */
        hitProduct(context,{product, recommendationId}) {
            if (product && product.PRICE) {
                let path = {};
                product.PATH.forEach(p => {
                    path[p.ID] = utf8.encode(p.NAME);
                });
                let item = {
                    'product_id': product.FIELDS.ID,
                    'iblock_id': product.FIELDS.IBLOCK_ID,
                    'product_title': utf8.encode(product.META.ELEMENT_META_TITLE ? product.META.ELEMENT_META_TITLE : product.FIELDS.NAME),
                    'category_id': product.FIELDS.IBLOCK_SECTION_ID,
                    'category': path,
                    'price': product.PRICE.DISCOUNT_VALUE,
                    'currency': 'RUB'
                }
                let counterData = {
                    'item': base64.encode(JSON.stringify(item)),
                    'user_id': context.rootGetters.getUser ? context.rootGetters.getUser.ID : "",
                    'recommendation': recommendationId,
                    'v': '2'
                };

                context.dispatch("hit",counterData,product.META.ELEMENT_META_TITLE ? product.META.ELEMENT_META_TITLE : product.FIELDS.NAME);
            }
        }
    }

}

export default bigdata;