<template>
    <div class="okraina-cp-pkg" v-if="isWeight && price">

        <div class="old-price-wrap" v-if="price.DISCOUNT_VALUE < price.VALUE">
            <span class="old-price" v-html="tools.formatPrice(price.VALUE, {
                supDecimals: false,
                supCurrency: false,
            })
                "></span>
        </div>

        <div class="price" :class="{ discount: price.DISCOUNT_VALUE < price.VALUE }"
            v-html="tools.formatPrice(price.DISCOUNT_VALUE)">
        </div>


    </div>
</template>


<script>
import tools from "@/plugins/tools";

export default {
    name: "okraina-catalog-product-price-kg",
    components: {},
    data() {
        return {
            tools: tools,
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        },
        isRifling: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        isWeight() {
            return this.item.PROPS.CML2_BASE_UNIT == 'кг'
        },
        price() {
            return this.item.PRICE_KG;
        },
    },
    methods: {
        format(price) {
            return tools.formatPrice(price);
        },
    },

    created() { },
};
</script>

<style lang="scss">
.okraina-cp-pkg {
    position: relative;

    .old-price {
        position: absolute;
        bottom: 100%;


        .okraina-price {
            font-size: 10px;
            color: var(--color-text-gray);
            text-decoration: line-through;
            line-height: 1.2;
        }
    }

    .price.discount {
        .okraina-price {
            color: var(--color-color4);
        }

    }

}
</style>
