import store from '@/store';

import defaults from "@/plugins/meta/defaults.js";

const meta = {

    /**
     * Получает для экрана шаблон мета
     */
    getSiteTemplate(templates) {

        if(!templates) {
            return false;
        }

        let server = store.getters.getServer;
        for (var domain in templates) {
            server = server.replace(".loc",".ru");
            if(server.includes(domain) || server == domain) {
                return templates[domain];
            }
        }

        return false;
    },

    /**
     * Записывает мета теги по шаблону
     */
    write(templates = false, replacements = false, object = false) {

        let template = this.getSiteTemplate(templates);

        Array.from(document.querySelectorAll('[data-vue-meta]')).map(el => el.parentNode.removeChild(el));

        let rendered = [];
        if(template) {
            document.title = this.replace(template.title,replacements);
            rendered.push("title");
            template.tags.forEach((tag)=> {

                let meta = document.createElement('meta');
                Object.keys(tag).forEach(key => {
                    let value = "";
                    if(typeof tag[key]=== 'function') {
                        value = tag[key](object);
                    } else {
                        value = tag[key];
                    }
                    meta.setAttribute(key, this.replace(value,replacements));
                });

                meta.setAttribute('data-vue-meta', '');
                document.head.appendChild(meta);


                rendered.push(tag.name);
            });
        } 

        this.writeDefault(rendered,replacements,object);

    },

    /**
     * Записывает мета по умолчанию
     */
    writeDefault(rendered, replacements = false, object = false) {

        let defaultTemplate = this.getSiteTemplate(defaults);
        if(!defaultTemplate) {
            return;
        }

        if(!rendered.includes("title")) {
            document.title = this.replace(defaultTemplate.title, replacements);
        }

        defaultTemplate.tags.forEach((tag)=> {

            if(rendered.includes(tag.name)) {
                return;
            }

            let meta = document.createElement('meta');
            Object.keys(tag).forEach(key => {
                let value = "";
                if(typeof tag[key]=== 'function') {
                    value = tag[key](object);
                } else {
                    value = tag[key];
                }
                meta.setAttribute(key, this.replace(value,replacements));
            });

            meta.setAttribute('data-vue-meta', '');
            document.head.appendChild(meta);


            rendered.push(tag.name);
        });

    },

    /**
     * Производит замены в строке
     */
    replace(str, replacements) {
        if(!replacements) {
            return  str;
        }

        Object.keys(replacements).forEach(key => {
            str = str.replace(key,replacements[key])
        });

        return str;
    }




}

export default meta;