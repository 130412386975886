<template>
    <div class="okraina-fp" :class="{ 'menu-mode': !modal && !this.isMenuHidden }">
        <div class="wrap">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "okraina-fixed-panel",
    components: {},
    data() {
        return {};
    },
    props: {
        modal: {
            type: Boolean,
            default() {
                return false;
            },
        },
        isMenuHidden: {
            type: Boolean,
            default() {
                return false
            }
        }

    },
    computed: {
        ...mapGetters({
            safeArea: "getSafeArea",
            platform: "getPlatform",
            siteSettings: "getSiteSettings"
        }),

        bottom() {
            let bottom = 0;
            if (!this.modal && !this.isMenuHidden) {
                //прибавляем отступ для учёта меню и опускаем чуть ниже, чтобы скругления меню учесть
                bottom += 62 - 15;
            }

            return bottom;
        },

        paddingBottom() {
            let padding = 0;
            if (!this.modal && !this.isMenuHidden) {
                padding = 15;
            }

            return padding;
        },

    }
};
</script>

<style lang="scss">
.okraina-fp {

    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;

    z-index: 999;


    background: var(--color-color8);
    border-top: 1px solid var(--color-color7);

    bottom: 0px;
    padding-bottom: var(--safe-area-bottom);

    &.menu-mode {
        bottom: 47px;

        padding-bottom: calc(var(--safe-area-bottom) + 15px);
    }

    .wrap {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        padding: 6px 24px;

        min-height: 40px;
    }


}
</style>
