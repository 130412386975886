<template>
  <okraina-view :class="'okraina-v-catalog-product'" @refresh="refresh" ref="view" :noHeaderBorder="true">
    <template v-slot:header>
      <okraina-catalog-search-input />
    </template>
    <template v-slot:content>

      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <template v-if="!loading">

        <template v-if="!product">
          Товар не найден
        </template>

        <template v-else>

          <okraina-catalog-product :product="product" />

        </template>

      </template>

    </template>
  </okraina-view>
</template>

<script>
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaView from "@/components/view2.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaCatalogProduct from "@/components/catalog/product.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/catalog.product.js";
import tools from "@/plugins/tools";

export default {
  name: "okraina-v-catalog-product",
  components: {
    okrainaLoaderPage,
    okrainaView,
    okrainaCatalogSearchInput,
    okrainaCatalogProduct
  },
  data() {
    return {
      loading: true,
      id: false,
      product: false
    };
  },
  props: {
    code: {
      type: String,
      default() {
        return "";
      },
    },
  },

  watch: {
    code: function () {
      this.loadData();
    },
    product: function () {
      this.setMeta();
      this.$store.dispatch("bigdata/hitProduct", { product: this.product })
    }
  },
  methods: {

    /**
      * Загрузка данных
      */
    loadData(resolve = false) {
      this.loading = resolve ? false : true;
      this.loadProductByCode(resolve);
    },

    /**
     * Загрузка товара по коду
     */
    loadProductByCode(resolve = false) {

      //запрашивает даные
      rest
        .call("catalog.products.getlist", {
          method: "post",
          data: {
            FILTER: {
              CODE: this.code,
              ACTIVE: "Y"
            },
          },
        })
        .then((data) => {

          if (data.SUCCESS) {
            if (data.ITEMS.length > 0) {
              this.id = data.ITEMS[0].FIELDS.ID;
              this.loadFullData(resolve);
            } else {
              //скроем экран загрузки
              this.loading = false;
              if (resolve) {
                setTimeout(resolve, 200);
              }
            }

          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            //скроем экран загрузки
            this.loading = false;
            if (resolve) {
              setTimeout(resolve, 200);
            }
          }
        });
    },

    /**
     * Полная загрузка данных
     */
    loadFullData(resolve) {

      rest
        .call("catalog.products.getbyid", {
          method: "post",
          data: {
            ID: this.id,
            RESIZE_OPTIONS: tools.getProductDetailResizeOptions(),
          },
        })
        .then((data) => {

          if (data.SUCCESS) {
            this.product = data;
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

          }
        }).finally(() => {
          this.loading = false;
          if (resolve) {
            resolve();
          }
        });
    },

    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      this.loadData(resolve);
    },

    /**
     * Устанавливает мета данные страницы
     */
    setMeta() {
      if (this.product) {
        meta.write(metaTemplates, this.product.META, this.product);
      }
    },

  },

  created() {
    this.loadData();
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setMeta();
      vm.$refs.view.onBeforeRouteEnter();
    });
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },
}

</script>

<style lang="scss">
.okraina-v-catalog-product {

  .okraina-cp {
    padding-top: 10px;
  }

}
</style>