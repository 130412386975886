<template>
    <okraina-view :class="'okraina-v-pp'" :refreshDisabled="true" ref="view" :hideMenu="true"
        :contentHorizontalPadding="32">

        <template v-slot:header>
            <h1>
                Настройки профиля
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">

                <div class="text-center">
                    Ошибка загрузки данных.
                    <br /><br />
                    <div class="btn btn1" @click="retryLoad">Повторить</div>
                </div>

            </template>

            <template v-if="!loading && !loadingError">

                <h2>Личные данные</h2>

                <template v-for="field in fieldsCommon" :key="field.CODE">
                    <div class="field"
                        :class="{ 'form-group': field.TYPE == 'radio' || field.TYPE == 'file', 'form-group-error': errors[field.CODE] }">

                        <div class="label" v-if="field.TYPE == 'radio' || field.TYPE == 'file'">
                            {{ field.NAME }}
                            <span v-if="field.REQUIED == 'Y'">*</span>
                        </div>

                        <!--Текстовое поле-->
                        <template v-if="field.TYPE == 'text'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'" :type="'text'"
                                v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                                v-model:valid="valids[field.CODE]" :mask="getFieldMask(field)"
                                :description="getFieldDescription(field)" :maxLength="30" />

                        </template>

                        <!--Дата-->
                        <template v-if="field.TYPE == 'date'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'" :type="'date'"
                                v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                                v-model:valid="valids[field.CODE]" />

                        </template>

                        <!-- Radio-переключатель -->
                        <template v-if="field.TYPE == 'radio'">
                            <div class="values">
                                <div class="value" v-for="value in field.VALUES" :key="value.ID">
                                    <okraina-control-radio :label="value.NAME" :required="field.REQUIED == 'Y'"
                                        v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                                        v-model:valid="valids[field.CODE]" :value="value.ID" />
                                </div>
                            </div>

                        </template>

                    </div>
                </template>

                <template v-if="fieldsPassword.length > 0">
                    <h2>Изменить пароль</h2>
                    <template v-for="field in fieldsPassword" :key="field.CODE">
                        <!--Пароль-->
                        <template v-if="field.TYPE == 'password'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'"
                                :type="'password'" v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                                v-model:valid="valids[field.CODE]"
                                :description="field.CODE == 'CONFIRM_PASSWORD' ? this.passwordRequirements : ''"
                                :maxLength="50" />
                        </template>
                    </template>


                </template>


            </template>
        </template>

        <template v-slot:footer v-if="!loading && !loadingError">

            <div class="btn-wrap">
                <div class="btn btn-block btn1" :class="saving ? 'wait' : ''" v-ripple="'rgba(255, 255, 255, 0.15)'"
                    @click="save">
                    Сохранить изменения
                    <div v-if="saving" class="btn-loader"></div>
                </div>
            </div>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaControlRadio from "@/components/controls/radio.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/personal.profile.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-personal-profile",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaControlInput,
        okrainaControlRadio,
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            saving: false,
            fields: [],
            passwordRequirements: "",
            values: {},
            errors: {},
            valids: {},
        };
    },

    computed: {
        ...mapGetters({
            user: "getUser"
        }),

        fieldsCommon() {
            return this.fields.filter(f => f.CODE != 'PASSWORD' && f.CODE != 'CONFIRM_PASSWORD')
        },
        fieldsPassword() {
            return this.fields.filter(f => f.CODE == 'PASSWORD' || f.CODE == 'CONFIRM_PASSWORD')
        }
    },

    watch: {
        user: function () {
            this.setProfileFields();
        }
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData() {

            if (this.loading) {
                return;
            }

            this.loading = true;

            rest
                .call("user.update.info", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    this.loadingError = !data.SUCCESS;
                    if (data.SUCCESS) {
                        this.fields = data.FIELDS;
                        this.passwordRequirements = data.PASSWORD_REQUIREMENTS;
                        this.resetValues();
                        this.setProfileFields();
                        this.restoreData();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Сброс полей
         */
        resetValues() {
            this.values = {};

            this.fields.forEach(f => {

                this.values[f.CODE] = "";
                this.valids[f.CODE] = true;
                this.errors[f.CODE] = false;

            });
        },

        /**
         * Устанавилвает поля из профиля
         */
        setProfileFields() {
            if (!this.user) {
                return;
            }

            this.fields.forEach(f => {
                if (this.user[f.CODE]) {
                    let value = this.user[f.CODE];
                    if (f.CODE == 'PERSONAL_BIRTHDAY') {
                        value = moment(value, "DD.MM.YYYY").format('YYYY-MM-DD')
                    }
                    else if (f.CODE == 'EMAIL' && this.user.UF_NEW_EMAIL) {
                        value = this.user.UF_NEW_EMAIL;
                    }
                    this.values[f.CODE] = value;

                }
            })
        },

        /**
         * Получает маску для поля
         */
        getFieldMask(field) {
            if (field.CODE == "EMAIL") {
                return tools.maskEmail();
            } else if (field.CODE == "PERSONAL_PHONE") {
                return tools.maskPhone();
            }

            return null;
        },

        /**
         * Получает примечание к полю
         */
        getFieldDescription(field) {
            if (field.CODE == "EMAIL") {
                if (this.user.UF_NEW_EMAIL) {
                    return "E-Mail не подтверждён";
                } else {
                    return "При изменении необходимо подтверждение.";
                }

            }

            return "";

        },

        /**
         * Сохранение
         */
        save() {

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            //берём копию значений, чтобы поменять формат даты
            let values = Object.assign({}, this.values);
            if (values.PERSONAL_BIRTHDAY) {
                values.PERSONAL_BIRTHDAY = moment(this.values.PERSONAL_BIRTHDAY, "YYYY-MM-DD").format("DD.MM.YYYY");
            }

            if (this.saving) {
                return;
            }

            this.saving = true;

            rest
                .call("user.update", {
                    method: "post",
                    data: values,
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Профиль успешно изменён.", type: "success" });
                        //перезгрузим данные пользователя
                        this.$store.dispatch("loadUserData");
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            this.fields.forEach(f => {

                let error = !this.valids[f.CODE];
                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[f.CODE] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "data": {
                    values: this.values
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
        */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }

        }


    },

    created() {
        this.loadData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-pp {
    .view-outer {
        background-color: var(--color-block-background);
    }

    .field {
        .values {
            &::after {
                content: "";
                display: block;
                clear: both;
            }

            .value {
                float: left;
                margin: 0px 15px 10px 0px;
            }
        }
    }

    .btn-wrap {
        padding: 10px 32px 10px;
    }
}
</style>