const templates =  {
    "shop.okraina.ru": {
        title: "Оценка качества услуг интернет-магазина",
        tags: [
            {
                name: "description",
                content: "У нас можно купить натуральную колбасу и мясные деликатесы прямо с производства, с бесплатной доставкой в день заказа",
            },
        ]
    },
}

export default templates;