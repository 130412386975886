<template>
    <div class="okraina-c-bs">
        <div v-if="!isAuthorized" class="auth">
            <div class="text">
                Авторизуйтесь и получайте бонусы за заказы!
            </div>
            <div>
                <a class="btn-more" :href="actionUrl" @click.prevent="goto(actionUrl)">Узнать</a>
            </div>
        </div>
        <div v-else class="user">
            <div class="text">
                Ваш уровень кешбэка:
            </div>
            <div class="level">
                {{ cashback.LEVEL }}
            </div>
            <div>
                <a class="btn-more" :href="actionUrl" @click.prevent="goto(actionUrl)">Подробнее</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "okraina-cart-bonus-status",
    computed: {
        ...mapGetters({
            isAuthorized: "isAuthorized",
            siteSettings: "getSiteSettings",
            cashback: "getCashback",
        }),

        actionUrl() {
            return this.siteSettings.BONUS_PROGRAM_ACTION_URL ? this.siteSettings.BONUS_PROGRAM_ACTION_URL : '';
        }
    },

    methods: {

        /**
         * Переход на указанный URL
         */
        goto(url) {
            let vm = this;
            setTimeout(() => {
                vm.$router.push({ path: url });
            }, 500);
        },
    }
}

</script>


<style lang="scss">
.okraina-c-bs {
    padding: 13px 24px;
    border-radius: 10px;
    border: 2px solid var(--color-color1);
    margin-bottom: 18px;

    .auth {
        padding: 8px 0px;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
    }

    .user {
        padding: 0px 0px;
        display: grid;
        grid-template-columns: 1fr auto auto;
        align-items: center;
    }

    .text {
        color: var(--color-color1);
        font-size: 13px;
        font-weight: 500;
    }

    .btn-more {
        display: inline-block;
        padding: 8px 16px;
        margin-left: 16px;
        border-radius: 20px;
        font-weight: 500;

        background: linear-gradient(281.01deg,
                #2793ca 24.43%,
                #399dad 30.54%,
                #59af79 42.64%,
                #74be4d 54.53%,
                #88c92c 65.96%,
                #97d114 76.79%,
                #a0d605 86.8%,
                #a3d800 95.18%);

        color: #fff;
        letter-spacing: 0.75px;
    }

    .level {
        font-weight: 600;
        color: var(--color-color1);
        font-size: 38px;
        padding: 0px 20px;
    }
}
</style>

