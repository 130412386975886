<template>
    <div class="okraina-cp-nmb" v-ripple @click="onClick" :class="{ detail: detail, small: small }">
        <span v-if="!inList">Привозите ещё</span>
        <span v-else>Отправлено</span>
    </div>
</template>

<script>
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-needmore-btn",
    components: {},
    emits: ["update:modelValue"],
    data() {
        return {
            inList: this.modelValue
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        modelValue: {
            type: Boolean,
            default() {
                return false
            }
        },
        detail: {
            type: Boolean,
            default() {
                return false
            }
        },
        small: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {

        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_NEED_MORE : "";
        }
    },

    methods: {

        /**
         * Клик по кнопке
         */
        onClick() {

            if (!this.inList) {
                this.save();
            }
        },


        save() {
            rest
                .call("form.addresult", {
                    method: "post",
                    data: {
                        ID: this.webFormId,
                        PRODUCT_ID: this.productId,
                    },
                })
                .then((data) => {
                    //если ошибька
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {

                        this.inList = true;
                        this.$emit("update:modelValue", this.inList);

                        //показываем сообщение об успехе
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", {
                            message: "Спасибо, мы примем во внимание Ваш запрос.",
                            type: "success"
                        });


                    }
                });
        }
    },

};
</script>

<style lang="scss">
.okraina-cp-nmb {
    border-radius: 8px;
    text-align: center;
    padding: 8.5px 7px;
    font-weight: 600;
    font-size: 12px;
    background-color: var(--color-btn3-background);
    color: var(--color-btn3-text);
    border: 1px solid var(--color-btn3-border);

    &.detail {
        font-size: 14px;
        padding: 12px 25px;
        border-radius: 80px;
        border-width: 2px;
    }

    &.small {
        font-size: 12px;
    }

}
</style>