const templates =  {
    "shop.okraina.ru": {
        title: "О нас",
        tags: [
            {
                name: "description",
                content: "Мы создаём качественные мясные продукты, колбасы из лучшего свежего сырья, не применяя в их производстве заменителей ненатурального происхождения. Это позволяет нам предлагать покупателям действительно вкусные и качественные продукты и задавать, таким образом, новый для рынка стандарт",
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "О нас – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "О нас – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
        ]
    },
}

export default templates;