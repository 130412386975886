const templates = {
    "shop.okraina.ru": {
        title: "Интернет-магазин мяса в Москве, купить говядину и свинину с доставкой, мясной магазин",
        tags: [
            {
                name: "description",
                content: "Мясной интернет-магазин «Окраина» — бесплатная доставка, выгодные цены, натуральные и качественные мясные изделия, произведенные в день доставки заказа! Скидки постоянным покупателям!"
            }
        ]
    },
    "dostavka.okraina.ru": {
        "title": "Интернет-магазин Окраина Мурманск | Купить колбасу | Бесплатная доставка продуктов на дом | Мясная продукция",
        tags: [
            {
                name: "description",
                content: "Мясной интернет-магазин колбасы и деликатесов Окраина. У нас можно купить натуральную колбасу и мясные деликатесы прямо с производства, с бесплатной доставкой в день заказа."
            }
        ]
    },
}

export default templates;