<template>
    <div class="okraina-ad">

        <div class="picture preloaded" v-if="action.FIELDS.DETAIL_PICTURE"
            v-lazy:background-image="action.FIELDS.DETAIL_PICTURE.SRC">
            <div class="preload" :style="{ backgroundImage: 'url(\'' + action.FIELDS.DETAIL_PICTURE.PRELOAD + '\')' }">
            </div>
            <div class="loader">
                <okraina-loader-inline />
            </div>
            <div class="countdown-time">
                <okraina-sliders-countdown-time v-if="action.PROPS.COUNTDOWN_COUNTER && action.FIELDS.ACTIVE_TO" :activeTo="action.FIELDS.ACTIVE_TO"/>
            </div>
        </div>

        <div class="info">

            <div class="prop">
                <div class="title">Акция длится</div>
                <div class="value">
                    <span class="icon fi fi-calendar"></span>
                    <template
                        v-if="moment(action.PROPS.DSTART, 'DD.MM.YYYY ') == moment(action.PROPS.DSTOP, 'DD.MM.YYYY ')">
                        {{ formatDate(action.PROPS.DSTART) }}
                    </template>
                    <template v-else>
                        с {{ formatDate(action.PROPS.DSTART) }} по {{ formatDate(action.PROPS.DSTOP) }}
                    </template>

                </div>
            </div>

            <div class="prop">
                <div class="title">До окончания акции осталось</div>
                <div class="value">
                    <span class="icon fi fi-time"></span>
                    <template v-if="!finished">
                        {{ left }}
                    </template>
                    <template v-else>
                        завершена
                    </template>


                </div>
            </div>

        </div>

        <div class="info">
            <h1 v-html="action.FIELDS.NAME"></h1>
            <div class="description" v-html="text"></div>
        </div>

        <div class="products" v-if="!finished && action.PROPS.ACGOODS && action.PROPS.ACGOODS.length > 0 && !checkValentines">
            <okraina-catalog-list :filter="productFilter" title="Товары по акции" />
        </div>
    </div>
</template>

<script>
import okrainaCatalogList from "@/components/catalog/list.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaSlidersCountdownTime from "@/components/sliders/countdown.time.vue";

import moment from "moment";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-actions-detail",
    components: {
        okrainaCatalogList,
        okrainaLoaderInline,
        okrainaSlidersCountdownTime
    },
    data() {
        return {
            moment: moment
        };
    },
    props: {
        action: {
            type: [Object, Boolean],
            default() {
                return false;
            },
        },

    },
    computed: {
        ...mapGetters({
            server: "getServer"
        }),

        left() {
            let today = moment();
            let end = moment(this.action.PROPS.DSTOP, 'DD.MM.YYYY hh:mm:ss');

            let diff = end.diff(today, 'seconds');
            let d = Math.floor(diff / 60 / 60 / 24);
            let h = Math.floor((diff - d * 24 * 60 * 60) / 60 / 60);
            let str = "";
            if (d > 0) {
                str = "" + d + " " + tools.countWord(d, ['день', 'дня', 'дней']);
            }
            if (h > 0) {
                str += " " + h + " " + tools.countWord(h, ['час', 'часа', 'часов']);
            }

            return str;
        },
        finished() {
            return moment(this.action.PROPS.DSTOP, 'DD.MM.YYYY hh:mm:ss').diff(moment()) < 0;
        },
        productFilter() {
            return {
                ID: this.action.PROPS.ACGOODS,
                CATALOG_AVAILABLE: "Y",
                PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
            };
        },
        
        checkValentines(){
            return this.action.PROPS.ACCRUE_VALENTINES ? true : false;
        },

        text() {
            let str = this.action.FIELDS.DETAIL_TEXT;
            //относительные ссылки меняем на абсолютные
            str = str.replace(/href=['"]\/([^'"]+)['"]/gi, "href='https://" + this.server + "/$1'");
            //меняем относительные пути к картинкам на абсолютные
            str = str.replace(/src=['"]\/([^'"]+)['"]/gi, "src='https://" + this.server + "/$1'");
            return str;
        }
    },


    methods: {

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, 'DD.MM.YYYY').format("DD MMMM");
        },

    },


}

</script>

<style lang="scss">
.okraina-ad {
    .picture {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-bottom: 36.66%;
        padding-top: 0;
        height: 0;
        .countdown-time{
          position: absolute;
          right: 5%;
          bottom: 15%;
        }
    }

    .info {
        padding: 24px 24px 14px 24px;
        background-color: var(--color-block-background);
        margin-bottom: 8px;

        .prop {
            margin-bottom: 10px;

            .title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }

            .value {
                position: relative;
                padding-left: 20px;
                line-height: 1;

                .icon {
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 13px;
                    color: var(--color-icon-prop);
                }
            }
        }

        .description {
            font-weight: 300;
            color: var(--color-text-primary);

            img[height] {
                height: auto !important;
            }
        }
    }

    .products {
        margin-top: 40px;
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>