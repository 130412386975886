<template>
    <div class="hidden"></div>
</template>

<script>


export default {
    name: "okraina-control-hidden",
    data() {
        return {
            value: this.modelValue,
        };
    },
    emits: ["update:valid", "update:error"],
    props: {

        modelValue: {
            type: String,
            default() {
                return "";
            },
        },

        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },

    },

    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },
    methods: {

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {

            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

    },

    mounted() {
        this.checkValid();
    },
};
</script>


