<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-comment">

                <div class="sheet-title">Комментарий к заказу</div>

                <form @submit.prevent="apply" action="">

                    <input type="submit" style="display: none;" />

                    <div class="items">

                        <!-- Комментарий -->
                        <div class="item">
                            <okraina-control-textarea label="" v-model="comment" />
                        </div>
                    </div>

                    <div class="btn btn-block btn1" @click="apply">Принять</div>

                </form>

            </div>
        </template>

    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlTextarea from "@/components/controls/textarea.vue";

export default {
    name: "okraina-order-comment",
    components: {
        okrainaSheet,
        okrainaControlTextarea
    },
    emits: ["apply", "update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            comment: ""
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        commentValue: {
            type: String,
            default() {
                return "";
            },
        }
    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.itemsInternal = this.items;
                //при показе попапа установим значения в те, что на основном экране
                this.setValues();
            }
        },
        valids: {
            handler() {
                //при изменении значений снимим ошибки
                this.checkValues(false);
            },
            deep: true
        }
    },

    methods: {

        /**
         * Устанавилвает значения св-во согласно переданным значениям
         */
        setValues() {
            this.comment = this.commentValue;
        },

        /**
         * Применить
         */
        apply() {
            if (!this.checkValues()) {
                return;
            }
            this.$emit("apply", this.comment);
            this.visibleInternal = false;
        },

        /**
         * Проверяет значения
         */
        checkValues() {
            return true;
        },
    },

    created() {
        this.setValues();
    }
}
</script>

<style lang="scss">
.okraina-order-comment {

    .items {
        margin-bottom: 30px;
    }
}
</style>