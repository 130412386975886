<template>
  <div class="okraina-cp-ed" v-if="visible">Годен до {{ formatDate }}</div>
</template>

<script>
import moment from "moment";

export default {
  name: "okraina-catalog-product-experation-date",
  components: {},
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    visible() {
      return (
        this.date &&
        (this.date.isAfter(moment().startOf("day")) ||
          this.date.isSame(moment().startOf("day")))
      );
    },

    date() {
      if (!this.item.PROPS.BEST_BEFORE) {
        return false;
      }

      return moment(this.item.PROPS.BEST_BEFORE, "DD.MM.YYYY");
    },

    formatDate() {
      return this.date.format("DD MMMM");
    },
  },
  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-ed {
  display: inline-block;
  border-radius: 13px;
  padding: 3px 5px;
  line-height: 1;
  font-size: 9px;
  text-align: center;

  background: var(--color-color6);
  color: var(--color-text-dark);
}
</style>
