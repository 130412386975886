const templates =  {
    "shop.okraina.ru": {
        title: "SECTION_META_TITLE",
        tags: [
            {
                name: "description",
                content: "SECTION_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "SECTION_META_KEYWORDS",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "SECTION_META_TITLE",
        tags: [
            {
                name: "description",
                content: "SECTION_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "SECTION_META_KEYWORDS",
            }
        ]
    },
}
export default templates;