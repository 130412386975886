<template>
  <div class="okraina-cp-stickers">

    <okraina-catalog-product-cashback :item="item" class="cashback" />

    <div class="sticker" v-for="sticker in stickersReduced" :key="sticker.code" :style="{
      'background-image': 'url(' + sticker.icon + ')',
    }"></div>
  </div>
</template>

<script>
import okrainaCatalogProductCashback from "@/components/catalog/product.cashback.vue";
import stickerSale from "@/assets/images/stickers/sale.svg";
import stickerNew from "@/assets/images/stickers/new.svg";
import stickerHit from "@/assets/images/stickers/hit.svg";
import stickerRifling from "@/assets/images/stickers/rifling.svg";
import stickerExclusive from "@/assets/images/stickers/exclusive.svg";
import stickerExpress from "@/assets/images/stickers/express-delivery.svg";

import { mapGetters } from "vuex";

export default {
  name: "okraina-catalog-product-stickers",
  components: {
    okrainaCatalogProductCashback
  },
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    }
  },
  computed: {
    ...mapGetters({
      siteSettings: "getSiteSettings"
    }),

    showExclusive() {
      return this.siteSettings ? this.siteSettings.STICKER_EXCLUSIVE_SHOW == "Y" : false;
    },

    stickers() {
      let stickers = [];

      //скидка
      if (this.item.PRICE && this.item.PRICE.DISCOUNT_DIFF_PERCENT > 0) {
        stickers.push({
          code: "discount",
          icon: stickerSale,
        });
      }

      //хит
      if (
        this.item.PROPS.SALELEADER &&
        this.item.PROPS.SALELEADER.XML_ID == "Y"
      ) {
        stickers.push({
          code: "hit",
          icon: stickerHit,
        });
      }

      //новинка
      if (
        this.item.PROPS.NEWPRODUCT &&
        this.item.PROPS.NEWPRODUCT.XML_ID == "Y"
      ) {
        stickers.push({
          code: "new",
          icon: stickerNew,
        });
      }

      //нарезка
      if (
        this.item.PROPS.HAS_RIFLING &&
        this.item.PROPS.HAS_RIFLING.XML_ID == "Y"
      ) {
        stickers.push({
          code: "rifling",
          icon: stickerRifling,
        });
      }

      //эксклюзив
      if (
        this.item.PROPS.EXCLUSIVE &&
        this.item.PROPS.EXCLUSIVE.XML_ID == "Y" &&
        this.showExclusive
      ) {
        stickers.push({
          code: "exclusive",
          icon: stickerExclusive,
        });
      }

      //экспресс доставка
      if (this.item.DELIVERY &&
        this.item.DELIVERY.EXPRESS_AVAILABLE == "Y"
      ) {
        stickers.push({
          code: "express",
          icon: stickerExpress,
        });
      }

      return stickers;
    },

    stickersReduced() {
      if (this.stickers.length > 3) {
        return this.stickers.slice(0, 2);
      }
      return this.stickers;
    }
  },
  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-stickers {

  .cashback {
    margin-right: 2px;
    float: left;
  }

  .sticker {
    position: relative;
    width: 30px;
    height: 30px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 2px;
    float: left;
    width: 18px;
    height: 18px;
  }
}
</style>
