<template>
  <div class="okraina-cp-cashback" :class="[!showLabel ? 'no-label' : '', themeStickCashback]" v-if="percent > 0">
    <span class="percent">{{ percent }}%</span>
    <span v-if="showLabel" class="text-inline">Кешбэк</span>

    <span v-if="themeStickCashback == 'valentines'" class="fi fi-heart"></span>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "okraina-catalog-product-cashback",
  components: {},
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    showLabel: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      cashback: "getCashback",
    }),

    percent() {
      //если есть скидка
      if (this.item.PRICE && this.item.PRICE.DISCOUNT_DIFF_PERCENT > 0) {
        return 0;
      }
      //если кешбэк по товару недоступен
      else if (!this.item.PROPS.CASHBACK_AVAILABLE) {
        return 0;
      }
      //если у товара выставлен собственный процент кешбэка
      else if (this.item.PROPS.CASHBACK > 0) {
        return this.item.PROPS.CASHBACK;
      }

      return this.cashback ? this.cashback.PERCENT : 0;
    },

    themeStickCashback() {
      if (this.item.PROPS.THEME_STICK_CASHBACK) {
        return this.item.PROPS.THEME_STICK_CASHBACK.XML_ID;
      } else {
        return 'default'
      }
    },

  },
  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-cashback {

  display: inline-flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
  padding: 2px 4px 2px 2px;

  border-radius: 20px;

  font-size: 9px;
  font-weight: 400;
  line-height: 1;

  .percent {
    background-color: #fff;
    color: #E83F3F;

    border-radius: 16px;

    padding: 2px 3px 2px;


    font-weight: bold;
    font-size: 10px;
    line-height: 1;

  }

  &.default {
    background: linear-gradient(281.01deg,
        #2793ca 24.43%,
        #399dad 30.54%,
        #59af79 42.64%,
        #74be4d 54.53%,
        #88c92c 65.96%,
        #97d114 76.79%,
        #a0d605 86.8%,
        #a3d800 95.18%);

    color: white;

    .percent {
      color: #414141;
      background-color: white;
    }
  }

  &.valentines {
    background: -webkit-linear-gradient(90deg, rgb(232, 63, 63), rgb(255, 200, 200));
    background: -moz-linear-gradient(90deg, rgb(232, 63, 63), rgb(255, 200, 200));
    background: linear-gradient(90deg, rgb(232, 63, 63), rgb(255, 200, 200));

    color: white;

    span.fi {
      font-size: 12px;
    }

    .percent {
      color: #E83F3F;
      background-color: white;
    }

  }

  &.increased {

    background: linear-gradient(281.01deg, #F4BA27 26.93%, #E83F3F 75.46%);
    color: white;

    .percent {
      color: #E83F3F;
      background-color: #fff;
    }

  }

}
</style>
