<template>
    <okraina-view :class="'okraina-v-aal'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Блог
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError && !loadingCommon">

                <div class="text-center">
                    Ошибка загрузки данных.
                    <br /><br />
                    <div class="btn btn1" @click="retryLoad">Повторить</div>
                </div>

            </template>

            <template v-if="!loading && canShow">

                <!--Выбор тега -->
                <okraina-tabs-scroll v-if="tagsFormatted.length > 0" :items="tagsFormatted" v-model="selectedTag" />

                <div v-if="reload" class="text-center">
                    <okraina-loader-inline />
                </div>

                <div class="list" v-if="!reload">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4" v-for="item in items" :key="item.FIELDS.ID">
                            <a class="item" :href="item.FIELDS.DETAIL_PAGE_URL"
                                @click.prevent="goto(item.FIELDS.DETAIL_PAGE_URL)" v-ripple>

                                <div class="picture preloaded" v-if="item.FIELDS.PICTURE"
                                    v-lazy:background-image="item.FIELDS.PICTURE.SRC">

                                    <div class="preload"
                                        :style="{ backgroundImage: 'url(\'' + item.FIELDS.PICTURE.PRELOAD + '\')' }"></div>

                                    <div class="loader">
                                        <okraina-loader-inline />
                                    </div>
                                </div>

                                <div class="inner">
                                    <div class="date" v-if="item.FIELDS.ACTIVE_FROM">
                                        {{ formatDate(item.FIELDS.ACTIVE_FROM) }}
                                    </div>
                                    <div class="name" v-html="item.FIELDS.NAME"></div>
                                </div>

                            </a>
                        </div>
                    </div>

                    <div ref="pager" class="pager"></div>

                    <!-- Подгрузка следюущей страницы -->
                    <template v-if="loadingPaging">
                        <div class="text-center">
                            <okraina-loader-inline />
                        </div>
                    </template>


                </div>

            </template>



        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaTabsScroll from "@/components/tabs.scroll.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.articles.list.js";


export default {
    name: "okraina-v-about-articles-list",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaLoaderInline,
        okrainaTabsScroll
    },
    data() {
        return {
            loading: true,
            reload: false,
            loadingPaging: false,
            loadingCommon: false,
            loadingError: false,

            tags: [],
            selectedTag: 0,

            items: [],
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 12,
                PAGES_COUNT: 1,
            },
            canShow: false
        };
    },

    computed: {

        /**
         * Подготовленный список тегов
         */
        tagsFormatted() {
            let list = [];

            if (this.tags) {
                list.push({
                    id: 0,
                    name: "Все"
                });
                this.tags.forEach(v => {

                    list.push({
                        id: v.ID,
                        name: v.VALUE
                    });
                });
            }

            return list;
        },

        filter() {
            if (this.selectedTag <= 0) {
                return false;
            }
            return {
                "PROPERTY_TAGS": this.selectedTag
            }
        },

    },

    watch: {

        selectedTag() {
            this.pager.NUM_PAGE = 1;
            this.reload = true;
            this.loadData();
        }
    },


    methods: {

        /**
         * Получает данные для запросоы
         */
        getBatchRequest() {

            return {
                tags: {
                    CLASS: "blog",
                    METHOD: "tags",
                },
                list: {
                    CLASS: "blog",
                    METHOD: "getlist",
                    PARAMS: {
                        FILTER: this.filter,
                        NAV_PARAMS: this.pager,
                        RESIZE_OPTIONS: {
                            WIDTH: 370,
                            HEIGHT: 200,
                            TYPE: "EXACT",
                            PRELOAD: "Y"
                        }
                    },
                },

            };
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .batch(this.getBatchRequest())
                .then((results) => {
                    this.onLoadData(results);
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    this.loadingCommon = false;
                    this.reload = false;
                    if (resolve) {
                        resolve();
                    }
                });


        },

        /**
         * Обработка загрузки данных
         */
        onLoadData(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    //теги
                    if (key == "tags") {
                        this.tags = data.ITEMS;
                    }
                    //список отзывов 
                    else if (key == "list") {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                    }
                    this.loadingError = false;
                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    this.loadingError = true;
                    return;
                }
            }
        },

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, 'DD.MM.YYYY').format("DD MMMM YYYY");
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.pager.NUM_PAGE = 1;
            this.loadData(resolve);
        },

        /**
       * Скрол экрана
       */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingCommon &&
                    !this.loadingPaging &&
                    !this.reload &&
                    this.canShow &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData(() => { });
                }
            }
        },

        /**
         * Переход на указанный URL
         */
        goto(url) {
            setTimeout(() => {
                this.$router.push({ path: url });
            }, 400);
        },
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.view.$refs.root
            .querySelector(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.view.$refs.root
            .querySelector(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        //запишем мета данные страницы
        meta.write(metaTemplates);

        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

    created() {
        this.loading = true;
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}
</script>


<style lang="scss">
.okraina-v-aal {
    .view-content {
        padding-bottom: 30px;
    }

    .item {
        border-radius: 5px;
        background-color: var(--color-block-background);
        overflow: hidden;
        margin-bottom: 16px;
        border-bottom: 0px;
        display: block;

        .picture {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            padding-bottom: 54%;
            padding-top: 0;
            height: 0;
        }

        .inner {
            padding: 12px 16px 12px 16px;
        }

        .date {
            font-size: 11px;
            color: var(--color-text-title);
            margin-bottom: 6px;
        }

        .name {
            font-weight: 600;
            font-size: 15px;

            color: var(--color-text-title);
        }
    }

}
</style>