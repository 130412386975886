const favorites = {
    state: () => {
        return {
            //избранное
            favorites: [],
        }
    },
    getters: {

        /**
         * Получает избранное
         */
        getFavorites(state) {
            return state.favorites;
        },

        /**
         * Получает количетсво избранного
         */
        getFavoritesCount(state) {
            return state.favorites.length;
        },

        /**
         * Проверяет если товар в избранном
         */
        InFavorites: state => pid => {
            return state.favorites.find(id => id == pid) !== undefined;
        },
        
    },
    mutations: {
        
        /**
         * Устанавливает список избранного
         */
        setFavorites: (state, value) => {
            state.favorites = value;
        },

        /**
         * Добавляет товар в избранное
         */
        addToFavorites: (state, pid) => {
            if (!state.favorites.find(id => id == pid)) {
                state.favorites.push(pid);
            }
        },

        /**
         * Удаляет товар из избранного
         */
        removeFromFavorites: (state, pid) => {
            state.favorites = state.favorites.filter(id => id != pid)
        },
    }

}

export default favorites;