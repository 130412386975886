const templates =  {
    "shop.okraina.ru": {
        title: "Акции и скидки в интернет-магазине Окраина",
        tags: [
            {
                name: "description",
                content: "Все актуальные акции интернет-магазина «ОКРАИНА». Здесь мы собрали все самые выгодные акции и предложения на ваши любимые продукты. Экономить с нами – просто!",
            },
            {
                name: "keywords",
                content: "окраина акции, окраина скидки",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Акции – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Акции – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;