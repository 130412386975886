<template>
    <teleport to="body">
        <transition>
            <div class="okraina-dialog-select" v-if="visible">

                <div class="overlay"></div>

                <div class="inner">
                    <div class="close" @click="close">
                        <span class="icon fi fi-close"></span>
                    </div>
                    <div class="header">
                        <div class="search" v-if="items.length > 9">
                            <okraina-control-input label="" :type="'text'" v-model="search" :placeholder="'Поиск'">
                                <template v-slot:right v-if="!!search">
                                    <div class="action clear" @click="clearSearch">
                                        <span class="icon fi fi-close"></span>
                                    </div>
                                </template>

                            </okraina-control-input>
                        </div>
                    </div>
                    <div class="content">
                        <div class="options">
                            <template v-for="item in searchItems" :key="item.ID">
                                <div class="option" :class="{ selected: this.value == item.ID }"
                                    @click="onOptionClick(item.ID)">
                                    <span v-html="formatName(item)"></span>

                                    <div class="control">
                                        <div class="icon fi fi-radio"></div>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                    <div class="footer"></div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>
import okrainaControlInput from "@/components/controls/input.vue";

export default {
    name: "okraina-dialog-select",
    components: {
        okrainaControlInput
    },
    emits: ["update:modelValue", "update:modelName", "update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            value: this.modelValue,
            search: "",
            items: this.list
        };
    },
    props: {

        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },

        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },

        modelName: {
            type: String,
            default() {
                return "";
            },
        },

        list: {
            type: Array,
            default() {
                return [];
            },
        },
        method: {
            type: String,
            default() {
                return "";
            },
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        searchItems() {
            return this.searchByName(this.items);
        },
    },

    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        },

        modelValue: function (newVal) {
            if (newVal != this.value) {
                this.value = newVal;
            }
        },
        list: function () {
            this.items = this.list;
        },

    },
    methods: {

        /**
         * Обработка открытия
         */
        onOpen() {

            this.watchVisible = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?selectOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрытие по клику на иконку
         */
        close() {
            this.visibleInternal = false;
        },

        /**
         * Производит поиск
         */
        searchByName(items) {
            if (this.search == "") {
                return items;
            }

            return items.filter(
                (item) =>
                    item.NAME.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
            );
        },

        /**
         * Форматирование название варианта
         */
        formatName(item) {
            return item.NAME;
        },

        /**
         * Сбросить поле поиска
         */
        clearSearch() {
            this.search = "";
        },

        /**
         * Клик по опции
         */
        onOptionClick(id) {
            this.value = id;
            this.apply();
        },

        /**
         * Применение
         */
        apply() {

            let item = this.items.find((i) => i.ID == this.value);
            if (item) {
                this.$emit("update:modelValue", item.ID);
                this.$emit("update:modelName", item.NAME);
                this.$emit("update:visible", false);
            }
        },
    },

    /**
     * Обработка создания экземпляра
     */
    created() {
        //если создаётся уже открытым
        if (this.visible) {
            this.onOpen();
        }
    }
}

</script>

<style lang="scss">
.okraina-dialog-select {
    position: fixed;
    z-index: 1100;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }

    .inner {
        position: absolute;
        height: 85%;
        top: 50%;
        left: 8px;
        right: 8px;
        transform: translateY(-50%);
        background-color: var(--color-view-background);
        border-radius: 30px;
        padding: 40px 25px 32px 25px;

        display: grid;
        grid-template-rows: auto 1fr auto;

        transition: transform 0.3s ease;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 23px;

        background-color: var(--color-icon-circle-background);
        opacity: 0.5;
        width: 25px;
        height: 25px;

        border-radius: 50%;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 11px;
            color: var(--color-icon-circle);
        }
    }

    .content {
        padding: 15px 0px 15px;
        overflow: auto;
    }

    .option {
        position: relative;
        padding: 13px 45px 13px 0px;
        color: var(--color-control-text);
        margin-bottom: 2px;
        border-bottom: 1px solid rgba(var(--color-text-primary-rgb), 0.2);

        .control {
            position: absolute;
            top: 50%;
            right: 0px;

            transform: translateY(-50%);

            .icon {
                position: relative;

                width: 20px;
                height: 20px;
                border-radius: 50%;
                border-width: 1px;
                border-style: solid;
                border-color: var(--color-radio-off-border);

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    width: 14px;
                    height: 14px;
                    background-color: var(--color-radio-off-background);
                }

            }
        }

        &.selected {
            .control {
                .icon {
                    border-color: var(--color-radio-on-border);

                    &::before {
                        background-color: var(--color-radio-on-background);
                        ;
                    }
                }

            }
        }

    }

    /*
     Анимация появления:
    */

    &.v-enter-active {
        transition: opacity 0.3s ease;
    }

    &.v-enter-from {
        .overlay {
            opacity: 0;
        }

        .inner {
            transform: translateY(100%);
        }
    }

    &.v-leave-active {
        transition: opacity 0.3s ease;
    }

    &.v-leave-to {
        .overlay {
            opacity: 0;
        }

        .inner {
            transform: translateY(100%);
        }
    }

}
</style>