<template>
  <div class="okraina-cp-na" :class="{ detail: detail }">

    <div class="bk"></div>

    <div v-if="notAvailableDate && !detail" class="text">
      Ожидается <br /> {{ notAvailableDate }}
    </div>
    <div v-else class="text">нет в наличии</div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "okraina-catalog-product-notavailable",
  components: {},
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    detail: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    notAvailableDate() {
      if (!this.item.PROPS.NOT_AVAILABLE_DATE) {
        return false;
      }

      return moment(this.item.PROPS.NOT_AVAILABLE_DATE, 'DD.MM.YYYY').format("DD MMMM YYYY");

    }
  },

  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-na {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  .bk {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    background-color: var(--color-block-background);
    opacity: 0.5;
  }

  .text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 2px;
    background-color: rgba(var(--color-color7-rgb), 0.6);

    color: var(--color-text-dark);
    font-size: 12px;
    font-weight: 500;

    padding: 2px 5px;
    white-space: nowrap;
    text-align: center;

  }

  &.detail {
    .text {
      br {
        display: none;
      }
    }
  }

}
</style>
