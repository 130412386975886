<template>
    <div class="okraina-pih" ref="root">

        <!-- Загрузка изначальная -->
        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>

        <template v-if="!loading">

            <div v-if="items.length == 0">
                Вы еще не пригласили ни одного друга.
            </div>

            <div class="items">
                <div class="item" v-for="item in itemsExt" :key="item.id">

                    <div class="date">Приглашение от {{ item.date }}</div>
                    <div class="user">{{ item.email ? item.email : item.login }}</div>

                    <div class="indicators">

                        <div v-if="item.status == 'SENT'" class="ind">
                            не зарегистрирован
                        </div>
                        <div v-else class="ind active">зарегистрирован</div>

                        <div v-if="item.status != 'ORDER'" class="ind">
                            заказ не сделан
                        </div>
                        <div v-else class="ind active">заказ сделан</div>

                        <div v-if="!item.bonusAccrued" class="ind">
                            бонус не начислен
                        </div>
                        <div v-else class="ind active">бонус начислен</div>


                    </div>

                </div>
            </div>

        </template>


        <div ref="pager" class="pager"></div>

        <!-- Подгрузка следюущей страницы -->
        <template v-if="loadingPaging">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>

        <!-- Ошибка загрузки -->
        <template v-if="loadingError && !loadingCommon">

            <div class="text-center">
                Ошибка загрузки данных.
                <br /><br />
                <div class="btn btn1" @click="retryLoad">Повторить</div>
            </div>

        </template>

    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";


export default {
    name: "okraina-personal-invite-history",
    components: {
        okrainaLoaderInline
    },
    data() {
        return {
            loading: false,
            loadingPaging: false,
            loadingCommon: false,
            loadingError: false,
            items: [],
            users: [],
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 15,
                PAGES_COUNT: 1,
            },
        };
    },

    computed: {
        itemsExt() {
            let items = [];
            this.items.forEach((item) => {
                let user = this.users.find(u => u.ID == item.PROPS.INVITED_USER)
                let email = user && user.EMAIL ? user.EMAIL : item.PROPS.EMAIL
                let login = user ? user.LOGIN : false

                items.push({
                    id: item.FIELDS.ID,
                    date: moment(item.PROPS.DATE, 'DD.MM.YYYY').format("DD MMMM YYYY"),
                    status: item.PROPS.STATUS.XML_ID,
                    bonusAccrued: !!item.PROPS.BONUS_ACCRUED,
                    email: this.trimValue(email),
                    login: this.trimValue(login)
                });
            });

            return items;
        }
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData() {

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .call("invites.getlist", {
                    method: "post",
                    data: {

                        NAV_PARAMS: this.pager,
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                            this.users = data.USERS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                            this.users = this.users.concat(data.USERS);
                        }
                        this.pager = data.PAGER;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;

                    }
                }).finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    this.loadingCommon = false;
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadingPaging = true;
            this.loadData();
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingCommon &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

        trimValue(value) {
            if (!value) {
                return "";
            }
            let em = value.split("@");
            let name = em[0];
            let len = Math.floor(name.length / 2);

            let displayValue = "";
            if (value.includes("@")) {
                displayValue = name.substring(0, len) + "*".repeat(len) + "@" + em[1];
            } else {
                displayValue = "*".repeat(len) + name.substring(len);
            }

            return displayValue;
        },

        /**
         * Перезагрузка (вызывается в вышестоящем компоненте)
         */
        reload() {
            this.pager.NUM_PAGE = 1;
            this.loading = true;
            this.loadData();

        }

    },

    created() {
        this.loading = true;
        this.loadData();
    },


    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },
}

</script>

<style lang="scss">
.okraina-pih {

    padding-bottom: 50px;

    .item {
        padding: 12px 8px;
        border-radius: 8px;
        background-color: var(--color-block-background);
        margin-bottom: 8px;

        .date {
            color: var(--color-color1);
            font-weight: 500;
            margin-bottom: 5px;
        }

        .user {
            font-weight: 500;
            color: var(--color-text-dark);
            margin-bottom: 5px;
        }

        .indicators {
            &::after {
                content: "";
                display: block;
                clear: both;
            }

            .ind {
                float: left;
                margin-right: 4px;
                font-size: 10px;
                font-weight: 500;
                padding: 4px;
                border-radius: 4px;
                background-color: var(--color-text-gray);
                color: var(--color-text-dark);
                margin-bottom: 4px;

                &.active {
                    background-color: var(--color-btn1-background);
                    color: var(--color-btn1-text);
                }

                &:last-of-type {
                    margin-right: 0px;

                }
            }
        }
    }

}
</style>