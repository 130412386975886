<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-cart-boc">
                <div class="sheet-title">Закажите в 1 клик</div>

                <div class="note">
                    Укажите номер телефона
                    и менеджер перезвонит вам
                    для уточнения деталей заказа
                </div>

                <form @submit.prevent="onSaveClick" action="">

                    <input type="submit" style="display: none;" />

                    <okraina-control-input label="Телефон" :required="true" :type="'text'" v-model="fields.phone"
                        :mask="phoneMask" v-model:error="errors.phone" v-model:valid="valids.phone" />

                    <okraina-control-input label="Имя" :required="true" :type="'text'" v-model="fields.name"
                        v-model:error="errors.name" v-model:valid="valids.name" />

                    <div class="btn btn-block btn1 btn-save" :class="saving ? 'wait' : ''" @click="onSaveClick" v-ripple>
                        Заказать в 1 клик
                        <div v-if="saving" class="btn-loader"></div>
                    </div>

                </form>

            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlInput from "@/components/controls/input.vue";

import tools from "@/plugins/tools";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika'

import { mapGetters } from "vuex";

export default {
    name: "okraina-cart-buy-one-click",
    components: {
        okrainaSheet,
        okrainaControlInput
    },
    data() {
        return {
            visibleInternal: this.visible,
            fields: {
                phone: "",
                name: ""
            },
            errors: {
                phone: false,
                name: false,
            },
            valids: {
                phone: false,
                name: false,
            },
            saving: false

        }
    },
    props: {

        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },


    },
    computed: {

        ...mapGetters({
            user: "getUser",
            isAuthorized: "isAuthorized",
            platform: "getPlatform",
            bonusUse: "getBonusUse",
        }),

        phoneMask() {
            return tools.maskPhone();
        }

    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
        },
        user: function () {
            this.setProfileFields();
        }
    },


    methods: {

        /**
        * Устанавилвает поля из профиля
        */
        setProfileFields() {
            if (!this.isAuthorized || !this.user) {
                return;
            }

            this.fields.phone = this.user.PERSONAL_PHONE;
            this.fields.name = this.user.NAME
        },

        /**
         * клик по кнопке "Сохранить"
         */
        onSaveClick() {
            if (!this.checkValues()) {
                return;
            }

            this.save();
        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            Object.keys(this.fields).forEach((field) => {
                let error = !this.valids[field];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[field] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Сохранение заказа
         */
        save() {
            if (this.saving) {
                return;
            }

            this.saving = true;

            rest
                .call("order.buyoneclick", {
                    method: "post",
                    data: {
                        PHONE: this.fields.phone,
                        NAME: this.fields.name,
                        PLATFORM: this.platform,
                        PAY_CURRENT_ACCOUNT: this.bonusUse.use ? "Y" : "N",
                        PAY_COUNT: this.bonusUse.value
                    },
                })
                .then((data) => {
                    //если ошибка
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        this.onSuccess(data);
                    }

                    this.loading = false;
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        /**
         * Обработка успешного сохранениея заказа
         */
        onSuccess(data) {

            this.visibleInternal = false;

            //событие в ЯндексМетрику
            metrika.reachGoal("oneclick");

            setTimeout(() => {

                //сбрасываем данные об использовании бонусов
                this.$store.commit("setUseBonus", {
                    use: false,
                    value: 0
                });

                //пометим, что нужно выгрузить заказ в Метрику
                this.$store.commit("setExportPurchase", true);

                //перезагуржаем данные пользователя (чтобы корзина и кол-во бонусов обновились)
                this.$store.dispatch("loadUserData");

                //переход на экран завершения оформления заказа
                this.$router.replace({
                    name: "order-confirm",
                    params: { id: data.ID },
                });

            }, 300);

        }
    },

    created() {
        this.setProfileFields();
    }
}

</script>

<style lang="scss">
.okraina-cart-boc {
    .note {
        margin-bottom: 20px;
    }

    .btn-save {
        margin-top: 30px;
    }
}
</style>