<template>
    <okraina-view :class="'okraina-v-order'" @refresh="refresh" ref="view" :contentHorizontalPadding="0">

        <template v-slot:header>

            <h1 class="title">
                Оформление заказа
            </h1>

        </template>
        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <template v-if="!loading">

                <div class="props">

                    <!--Профиль-->
                    <div class="property" v-if="profile">
                        <div class="label">
                            Профиль доставки
                        </div>
                        <div class="display-value" @click="showProfiles()">
                            <div class="value" v-html="profile.NAME"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-profiles v-model:visible="showProfilesPopup" :items="profiles"
                        v-model="order.fields.PROFILE_ID" />

                    <!--Контакты-->
                    <div class="property" :class="{ error: errors.contacts }" v-if="contactsProps">
                        <div class="label">
                            <span class="required" v-if="contactsPropsRequired">*</span>
                            Контакты
                        </div>
                        <div class="display-value" @click="showContacts()">
                            <div class="value" v-html="contactsDisplayValue"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-contacts v-model:visible="showContactsPopup" :items="contactsProps"
                        :propsValues="order.props" @apply="applyContacts" />

                    <!--Адрес-->
                    <div class="property" :class="{ error: errors.address }" v-if="addressProps.length > 0">
                        <div class="label">
                            <span class="required" v-if="addressPropsRequired">*</span>
                            Адрес доставки
                        </div>
                        <div class="display-value" @click="showAddress()">
                            <div class="value" v-html="addressDisplayValue"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-address v-model:visible="showAddressPopup" :items="addressProps"
                        :propsValues="order.props" @apply="applyAddress" :order="order" />

                    <!--Способ доставки-->
                    <div class="property" v-if="shipments.length > 1">
                        <div class="label">
                            <span class="required">*</span>
                            Способ доставки
                        </div>
                        <div class="display-value" @click="showShipment()">
                            <div class="value" v-if="shipment" v-html="shipment.NAME"></div>
                            <div class="value" v-else>Выбрать</div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-shipment v-model:visible="showShipmentPopup" :items="shipments"
                        v-model="order.fields.DELIVERY_ID" />

                    <!--Дата и время-->
                    <div class="property" :class="{ error: errors.delivery }" v-if="deliveryProps.length > 0">
                        <div class="label">
                            <span class="required" v-if="deliveryPropsRequired">*</span>
                            Дата и время доставки
                        </div>
                        <div class="display-value" @click="showDeliveryProps()">
                            <div class="value" v-html="deliveryDisplayValue"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-delivery v-model:visible="showDeliveryPopup" :items="deliveryProps"
                        :addressItems="addressProps" :propsValues="order.props" @apply="applyDelivery" :order="order" />

                    <!--Способ оплаты-->
                    <div class="property" :class="{ error: errors.payment }">
                        <div class="label">
                            <span class="required">*</span>
                            Способ оплаты
                        </div>
                        <div class="display-value" @click="showPayment()">
                            <div class="value" v-if="paySystem">
                                <span v-html="paySystem.PSA_NAME"></span>
                                <span v-if="order.fields.PAY_CURRENT_ACCOUNT == 'Y'">, бонусы</span>
                            </div>
                            <div class="value" v-else>не указано</div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>

                        <div v-if="paySystem && paySystem.NOTICE" class="notice">
                            <div class="bg"></div>
                            <div class="inner">{{ paySystem.NOTICE }}</div>

                        </div>
                    </div>
                    <okraina-order-payment v-model:visible="showPaymentPopup" :items="paySystems"
                        v-model="order.fields.PAY_SYSTEM_ID" :price="price" @apply="applyPayment" />

                    <!--Комментарий-->
                    <div class="property">
                        <div class="label">
                            Комментарий
                        </div>
                        <div class="display-value" @click="showComment()">
                            <div class="value small" v-html="comment"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-comment v-model:visible="showCommentPopup" :commentValue="order.fields.ORDER_DESCRIPTION"
                        @apply="applyComment" />

                    <!--Дополнительно-->
                    <div class="property" :class="{ error: errors.additional }" v-if="additionalProps">
                        <div class="label">
                            <span class="required" v-if="additionalPropsRequired">*</span>
                            Дополнительно
                        </div>
                        <div class="display-value" @click="showAdditionalProps()">
                            <div class="value small" v-html="additionalDisplayValue"></div>
                            <div>
                                <span class="icon fi fi-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <okraina-order-additional v-model:visible="showAdditionalPopup" :items="additionalProps"
                        :propsValues="order.props" @apply="applyAdditional" />

                </div>

                <!--Мин сумма-->
                <div class="sum-error" v-if="orderMinSum > 0 && sum < orderMinSum">
                    <div class="bg"></div>
                    <div class="inner">
                        До минимального заказа товаров в корзине должно быть минимум на
                        <span v-html="tools.formatPrice(this.orderMinSum, {
                            includeDecimals: false,
                            supCurrency: false,
                        })"></span>
                    </div>
                </div>

                <!--Итого-->
                <okraina-order-total :price="price" :cashback="cashback" :coupons="coupons" />

                <!--Оформить заказ-->
                <okraina-fixed-panel>
                    <okraina-order-add :price="price" :disabled="!canAdd" :loading="addRequest" @add="onAddClick()" />
                </okraina-fixed-panel>

            </template>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaOrderProfiles from "@/components/order/profiles.vue";
import okrainaOrderContacts from "@/components/order/contacts.vue";
import okrainaOrderAddress from "@/components/order/address.vue";
import okrainaOrderDelivery from "@/components/order/delivery.vue";
import okrainaOrderPayment from "@/components/order/payment.vue";
import okrainaOrderShipment from "@/components/order/shipment.vue";
import okrainaOrderComment from "@/components/order/comment.vue";
import okrainaOrderAdditional from "@/components/order/additional.vue";
import okrainaOrderTotal from "@/components/order/total.vue";
import okrainaOrderAdd from "@/components/order/add.vue";
import okrainaFixedPanel from '@/components/fixed.panel.vue'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/order.js";
import metrika from '@/plugins/yandex.metrika'

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-order",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaOrderProfiles,
        okrainaOrderContacts,
        okrainaOrderAddress,
        okrainaOrderDelivery,
        okrainaOrderPayment,
        okrainaOrderShipment,
        okrainaOrderComment,
        okrainaOrderAdditional,
        okrainaOrderTotal,
        okrainaOrderAdd,
        okrainaFixedPanel
    },
    data() {
        return {
            loading: false,
            tools: tools,
            personTypes: [],
            profiles: [],
            props: [],
            paySystems: [],
            shipments: [],
            coupons: [],
            price: {},
            cashback: 0,
            saleCouponSum: 0,
            order: {
                fields: {},
                props: {}
            },
            action: false,
            showProfilesPopup: false,
            showContactsPopup: false,
            showAddressPopup: false,
            showDeliveryPopup: false,
            showPaymentPopup: false,
            showShipmentPopup: false,
            showAdditionalPopup: false,
            showCouponsPopup: false,
            showCommentPopup: false,
            addRequest: false,
            errors: {
                contacts: false,
                address: false,
                delivery: false,
                additional: false,
                payment: false
            }
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings",
            bonusUse: "getBonusUse",
            platform: "getPlatform"
        }),

        /**
         * Купоны включены
         */
        isCouponsEnabled() {
            return this.siteSettings.COUPONS_ENABLED == "Y";
        },

        /**
         * Оплата бонусами включена
         */
        isBonusEnabled() {
            return this.siteSettings.ORDER_PAY_FROM_ACCOUNT == "Y";
        },

        /**
         * Минимальная сумма заказа
         */
        orderMinSum() {
            let min = this.siteSettings ? parseInt(this.siteSettings.ORDER_MIN_SUM) : 0
            //Учёт оплаты сертикатом правила работы с корзиной
            return min - this.saleCouponSum;
        },

        /**
         * Выбранный профиль
         */
        profile() {
            if ('PROFILE_ID' in this.order.fields) {
                let profile = this.profiles.find(p => p.ID == this.order.fields.PROFILE_ID);
                return profile;
            }
            return false;
        },

        /**
         * ИД выбранного профиля
         */
        profileId() {
            return 'PROFILE_ID' in this.order.fields ? this.order.fields.PROFILE_ID : false
        },

        /**
         * Свойства группы "Контакты"
         */
        contactsProps() {
            let props = this.props.filter(p => p.GROUP_CODE == "CONTACTS");
            return props;
        },

        /**
         * Есть ли обязательные св-ва в "Контактах"
         */
        contactsPropsRequired() {
            let prop = this.contactsProps.find(p => p.REQUIED == 'Y');
            return prop ? true : false
        },

        /**
         * Отображаемое значение для группы "Контакты"
         */
        contactsDisplayValue() {
            let tmp = [];

            this.contactsProps.forEach((p) => {
                if (p.CODE != 'EMAIL') {
                    let value = this.order.props[p.FIELD_NAME];
                    if (value) {
                        tmp.push(value);
                    }
                }
            });

            if (tmp.length == 0) {
                tmp.push("<div class='item'>не указано</div>");
            }

            return tmp.join("<br/>");
        },

        /**
         * Свойства группы "Адрес"
         */
        addressProps() {
            let props = this.props.filter(p => p.GROUP_CODE == "ADDRESS");
            return props;
        },

        /**
         * Есть ли обязательные св-ва в "Адресе"
         */
        addressPropsRequired() {
            let prop = this.addressProps.find(p => p.REQUIED == 'Y');
            return prop ? true : false
        },

        /**
         * Отображаемое значение для группы "Адрес"
         */
        addressDisplayValue() {
            let tmp = [];

            let allowed = { 'LOCATION': '', 'FULL_ADDRESS': "", 'HOUSE': 'д. ', 'CORPUS': 'кор. ', 'APARTMENT': 'кв. ', };

            Object.keys(allowed).forEach(code => {
                let prop = this.addressProps.find(p => p.CODE == code);
                if (prop) {
                    let value = this.order.props[prop.FIELD_NAME];
                    let pre = allowed[code];

                    //если тип свойства - местоположение
                    if (prop.TYPE == 'LOCATION') {

                        let values = [];
                        prop.LOCATIONS_LEVELS.forEach(level => {
                            let selected = level.find(i => i.SELECTED == 'Y');
                            if (selected) {
                                values.push(selected.NAME)
                            }
                        });

                        if (values.length > 0) {
                            tmp.push(pre + values.join(', '));
                        }
                    } else {
                        if (value) {
                            tmp.push(pre + value);
                        }
                    }

                }
            });

            if (tmp.length == 0) {
                tmp.push("<div class='item'>не указано</div>");
            }

            return tmp.join(", ");
        },

        /**
         * Свойства группы "Доставка"
         */
        deliveryProps() {
            let props = this.props.filter(p => p.GROUP_CODE == "DELIVERY");
            return props;
        },

        /**
         * Есть ли обязательные св-ва в "Доставке"
         */
        deliveryPropsRequired() {
            let prop = this.deliveryProps.find(p => p.REQUIED == 'Y');
            return prop ? true : false
        },

        /**
         * Отображаемое значение для группы "Доставка"
         */
        deliveryDisplayValue() {
            let tmp = [];

            this.deliveryProps.forEach((p) => {
                let value = this.order.props[p.FIELD_NAME];
                if (value) {
                    if (p.TYPE == "DATE") {
                        tmp.push(moment(value, 'DD/MM/YYYY').format('DD MMMM'));
                    } else if (p.TYPE == "TIME_INTERVAL") {

                        let parts = value.split('-');
                        tmp.push("с " + parts[0] + " до " + parts[1]);
                    } else if (p.CODE == "DESIRED_TIME") {
                        let parts = value.split('-');
                        tmp.push("<br/>желательно с " + parts[0] + " до " + parts[1]);
                    }

                }
            });

            if (tmp.length == 0) {
                tmp.push("<div class='item'>не указано</div>");
            }

            return tmp.join(" ");
        },

        /**
         * Выбранный способ оплаты
         */
        paySystem() {
            if ('PAY_SYSTEM_ID' in this.order.fields) {
                let paySystem = this.paySystems.find(p => p.ID == this.order.fields.PAY_SYSTEM_ID);
                return paySystem;
            }
            return false;
        },

        /**
         * Выбранный способ доставки
         */
        shipment() {
            if ('DELIVERY_ID' in this.order.fields) {
                let shipment = this.shipments.find(s => s.ID == this.order.fields.DELIVERY_ID);
                return shipment;
            }
            return false;
        },

        /**
         * ИД выбранного профиля
         */
        shipmentId() {
            return 'DELIVERY_ID' in this.order.fields ? this.order.fields.DELIVERY_ID : false
        },

        /**
         * Свойства группы "Долполнительно"
         */
        additionalProps() {
            let props = this.props.filter(p => p.GROUP_CODE == "ADDITIONAL");
            return props;
        },

        /**
         * Есть ли обязательные св-ва в "Долполнительно"
         */
        additionalPropsRequired() {
            let prop = this.additionalProps.find(p => p.REQUIED == 'Y');
            return prop ? true : false
        },

        /**
         * Отображаемое значение для группы "Дополнительно"
         */
        additionalDisplayValue() {
            let tmp = [];

            this.additionalProps.forEach((p) => {
                let value = this.order.props[p.FIELD_NAME];
                if (p.TYPE == 'CHECKBOX') {
                    if (value == 'Y') {
                        tmp.push("<div class='item'>" + p.NAME + ".</div>");
                    }
                } else if (p.TYPE == 'TEXT') {
                    if (value) {
                        tmp.push("<div class='item'>" + p.NAME + ' - ' + value + ".</div>");
                    }
                }
            });

            if (tmp.length == 0) {
                tmp.push("<div class='item'>не указано</div>");
            }

            return tmp.join("");
        },

        /**
         * Комментарий
         */
        comment() {
            if (this.order.fields.ORDER_DESCRIPTION) {
                return tools.escapeHtml(this.order.fields.ORDER_DESCRIPTION);
            }

            return "<div class='item'>не указано</div>";
        },

        /**
         * Стоимость заказа (со скидками)
         */
        sum() {
            return this.price.TOTAL_DISCOUNT_PRICE ? this.price.TOTAL_DISCOUNT_PRICE : 0;
        },

        /**
         * Можно ли оформить заказ
         */
        canAdd() {

            let error = false;
            this.props.forEach(p => {
                if (p.REQUIED == 'Y' && !this.order.props[p.FIELD_NAME]) {
                    error = true;
                }
            });

            if (this.orderMinSum > 0 && this.sum < this.orderMinSum) {
                error = true;
            }

            if (!this.order.fields.PAY_SYSTEM_ID) {
                return false;
            }

            return !error;
        }
    },
    watch: {
        personTypes: function () {
            let selected = this.personTypes.find(p => p.CHECKED == 'Y')
            if (selected) {
                this.order.fields.PERSON_TYPE = selected.ID;
            }
        },
        profiles: function () {
            let selected = this.profiles.find(p => p.CHECKED == 'Y')
            if (selected) {
                this.order.fields.PROFILE_ID = selected.ID;
            } else {
                this.order.fields.PROFILE_ID = 0;
            }
        },
        profileId: function (newVal) {
            if (newVal !== false && !this.loading) {
                this.action = "PROFILE_CHANGE";
                this.loadData(() => {
                    this.action = false;
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }
        },

        paySystems: function () {
            //если способ оплаты не выбран самим покуптаелем
            if (!this.order.fields.PAY_SYSTEM_ID) {
                //то не пытаемся выставить автоматически тем, что указано с севера
                //так как покупатели не обращают внимание что какой-то способ оплаты уже выбран.
                return;
            }

            let selected = this.paySystems.find(p => p.CHECKED == 'Y')
            if (selected) {
                this.order.fields.PAY_SYSTEM_ID = selected.ID;
            } else {
                this.order.fields.PAY_SYSTEM_ID = 0;
            }
        },

        shipments: function () {
            let selected = this.shipments.find(p => p.CHECKED == 'Y')
            if (selected) {
                this.order.fields.DELIVERY_ID = selected.ID;
            } else {
                this.order.fields.DELIVERY_ID = 0;
            }
        },

        shipmentId: function (newVal) {
            if (newVal !== false && !this.loading) {
                this.loadData(() => { });
            }
        },
    },

    methods: {

        /**
         * Назад
         */
        back() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
        },

        /**
         * Получает данные для запросоы
         */
        getBatchRequest() {

            let prepareParams = {};
            if (Object.keys(this.order.fields).length > 0) {
                prepareParams.FIELDS = this.order.fields;
            }
            if (Object.keys(this.order.props).length > 0) {
                prepareParams.PROPS = this.order.props;
            }
            if (this.action) {
                prepareParams.ACTION = this.action;
            }

            let requests = {
                "prepare": {
                    CLASS: "order",
                    METHOD: "prepare",
                    PARAMS: prepareParams,
                }
            }

            if (this.isCouponsEnabled) {
                requests.coupons = {
                    CLASS: "coupon",
                    METHOD: "getlist",
                };
            }

            if (this.isBonusEnabled) {
                requests.bonus = {
                    CLASS: "bonus",
                    METHOD: "balance",
                };
            }

            return requests;
        },

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .batch(this.getBatchRequest()).then((results) => {
                    this.onSuccessLoad(results);
                })
                .finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Обработка загрузки данных
         */
        onSuccessLoad(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    //данные для оформления
                    if (key == "prepare") {
                        this.personTypes = data.PERSON_TYPES;
                        this.profiles = data.PROFILES;
                        this.props = data.PROPS;
                        this.paySystems = data.PAY_SYSTEMS;
                        this.price = data.PRICE;
                        this.shipments = data.DELIVERIES;
                        this.cashback = data.CASHBACK;
                        this.saleCouponSum = data.SALE_COUPON_SUM ? data.SALE_COUPON_SUM : 0;
                        this.syncOrderProps();
                        setTimeout(() => {
                            this.restoreData();
                        });

                    }
                    //купоны
                    else if (key == "coupons") {
                        this.coupons = data.ITEMS;
                    }
                    //баланс бонусов
                    else if (key == "bonus") {
                        this.$store.commit('setBonus', data.VALUE);
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    return;
                }
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Синхронизирует текущие значения св-в заказа с значниями из списка св-в
         */
        syncOrderProps() {

            this.props.forEach((p) => {
                if (p.TYPE == 'CHECKBOX') {
                    this.order.props[p.FIELD_NAME] = p.CHECKED == "Y" ? "Y" : "N"
                } else {
                    this.order.props[p.FIELD_NAME] = p.VALUE
                }

            });

            //удалим св-ва, которых уже нет
            for (var code in this.order.props) {
                let prop = this.props.find(p => p.FIELD_NAME == code);
                if (!prop) {
                    delete this.order.props[code];
                }
            }

            //записываем в соответствующее св-во заказа тип платформы
            let propAppInfo = this.props.find(p => p.CODE == 'APP_INFO');
            if (propAppInfo) {
                this.order.props[propAppInfo.FIELD_NAME] = this.platform;
            }

        },

        /**
         * Показывает попап для выбора профиля
         */
        showProfiles() {
            this.showProfilesPopup = true;
        },

        /**
         * Показывает попап для ввода контактов
         */
        showContacts() {
            this.showContactsPopup = true;
        },

        /**
         * Показывает попап для ввода адреса
         */
        showAddress() {
            this.showAddressPopup = true;
        },

        /**
         * Показывает попап для ввода данных доставки (дата, время)
         */
        showDeliveryProps() {
            this.showDeliveryPopup = true;
        },

        /**
         * Показывает попап для выбора способа оплаты
         */
        showPayment() {
            this.showPaymentPopup = true;
        },

        /**
         * Показывает попап для выбора способа доставки
         */
        showShipment() {
            this.showShipmentPopup = true;
        },

        /**
         * Показывает попап для доп данных
         */
        showAdditionalProps() {
            this.showAdditionalPopup = true;
        },

        /**
         * Показывает попап для ввода комментария
         */
        showComment() {
            this.showCommentPopup = true;
        },

        /**
         * Показывает попап для Купонов
         */
        showCoupons() {
            this.showCouponsPopup = true;
        },

        /**
         * Применить значения для контактов
         */
        applyContacts(values) {
            let changed = [];
            for (var key in values) {
                let value = values[key];
                if (this.order.props[key] != value) {
                    this.order.props[key] = value;
                    changed.push(key);
                }
            }

            //перепроверим есть ли ошибки заполнения
            this.checkValues(false);

            //если среди свойств есть те, что требуют перезагрузки при изменении
            let refreshProp = this.contactsProps.filter(p => p.REFRESH == 'Y').find(p => changed.includes(p.FIELD_NAME));
            if (refreshProp) {
                this.loadData(() => {
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }

        },

        /**
         * Применить значения для адреса
         */
        applyAddress(values) {
            let changed = [];
            for (var key in values) {
                let value = values[key];
                if (this.order.props[key] != value) {
                    this.order.props[key] = value;
                    changed.push(key);
                }
            }

            //перепроверим есть ли ошибки заполнения
            this.checkValues(false);

            //если среди свойств есть те, что требуют перезагрузки при изменении
            let refreshProp = this.addressProps.filter(p => p.REFRESH == 'Y').find(p => changed.includes(p.FIELD_NAME));
            if (refreshProp) {
                this.loadData(() => {
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }
        },

        /**
         * Применить значения для доставки (дата, время)
         */
        applyDelivery(values) {
            let changed = [];
            for (var key in values) {
                let value = values[key];
                if (this.order.props[key] != value) {
                    this.order.props[key] = value;
                    changed.push(key);
                }
            }

            //перепроверим есть ли ошибки заполнения
            this.checkValues(false);

            //если среди свойств есть те, что требуют перезагрузки при изменении
            let refreshProp = this.deliveryProps.filter(p => p.REFRESH == 'Y').find(p => changed.includes(p.FIELD_NAME));
            if (refreshProp) {
                this.loadData(() => {
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }
        },

        /**
         * Применить способ оплаты
         */
        applyPayment(value) {
            let changed = [];
            if (value != this.order.fields.PAY_SYSTEM_ID) {
                this.order.fields.PAY_SYSTEM_ID = value;
                changed.push("PAY_SYSTEM_ID");
            }

            //перепроверим есть ли ошибки заполнения
            this.checkValues(false);

            if (changed.length > 0) {
                this.loadData(() => {
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }

        },

        /**
         * Применить доп данные
         */
        applyAdditional(values) {
            let changed = [];
            for (var key in values) {
                let value = values[key];
                if (this.order.props[key] != value) {
                    this.order.props[key] = value;
                    changed.push(key);
                }
            }

            //если среди свойств есть те, что требуют перезагрузки при изменении
            let refreshProp = this.additionalProps.filter(p => p.REFRESH == 'Y').find(p => changed.includes(p.FIELD_NAME));
            if (refreshProp) {
                this.loadData(() => {
                    //перепроверим есть ли ошибки заполнения
                    this.checkValues(false);
                });
            }
        },

        /**
         * Применить изменение комментария к заказу
         */
        applyComment(comment) {
            this.order.fields.ORDER_DESCRIPTION = comment;
        },

        /**
         * Применение купонов
         */
        applyCoupons() {
            this.loadData(() => { });
        },

        /**
         * Проверяет есть ли ошибки
         */
        checkValues(showErrors = true) {

            //контакты
            let prop = this.contactsProps.find((p) => {
                return p.REQUIED == 'Y' && !this.order.props[p.FIELD_NAME]
            });
            if (showErrors || !prop) {
                this.errors.contacts = !!prop
            }

            //адрес
            prop = this.addressProps.find((p) => {
                return p.REQUIED == 'Y' && !this.order.props[p.FIELD_NAME]
            });
            if (showErrors || !prop) {
                this.errors.address = !!prop
            }

            //доставка (дата, вермя)
            prop = this.deliveryProps.find((p) => {
                return p.REQUIED == 'Y' && !this.order.props[p.FIELD_NAME]
            });
            if (showErrors || !prop) {
                this.errors.delivery = !!prop
            }

            //способ оплаты
            if (showErrors || !!this.order.fields.PAY_SYSTEM_ID) {
                this.errors.payment = !this.order.fields.PAY_SYSTEM_ID
            }

            //дополнительно
            prop = this.additionalProps.find((p) => {
                return p.REQUIED == 'Y' && !this.order.props[p.FIELD_NAME]
            });
            if (showErrors || !prop) {
                this.errors.additional = !!prop
            }

            //там и так роверяются все условия
            return this.canAdd
        },

        /**
         * клик по кнопке "оформить"
         */
        onAddClick() {

            if (!this.checkValues()) {
                return;
            }

            this.addRequest = true;

            let params = {};
            if (Object.keys(this.order.fields).length > 0) {
                params.FIELDS = this.order.fields;
            }
            if (Object.keys(this.order.props).length > 0) {
                params.PROPS = this.order.props;
            }

            //добавление азказа
            rest
                .call("order.add", {
                    method: "post",
                    data: params,
                })
                .then((data) => {
                    if (data.SUCCESS) {

                        //событие в ЯндексМетрику
                        metrika.reachGoal("sale");

                        //сбрасываем данные об использовании бонусов
                        this.$store.commit("setUseBonus", {
                            use: false,
                            value: 0
                        });

                        //пометим, что нужно выгрузить заказ в Метрику
                        this.$store.commit("setExportPurchase", true);

                        //перезагуржаем данные пользователя (чтобы корзина и кол-во бонусов обновились)
                        this.$store.dispatch("loadUserData");

                        //генерим событие создания нового заказа
                        bus.emit("OKRAINA_ORDER_E_NEW_ORDER", { id: data.ORDER_ID });

                        //переход на экран завершения оформления заказа
                        this.$router.replace({
                            name: "order-confirm",
                            params: { id: data.ORDER_ID },
                        });

                        //сбрасываем поля после того как произойдёт переход на экарна завершения оформления заказа
                        setTimeout(() => {
                            this.order = {
                                fields: {},
                                props: {}
                            }
                        }, 400);


                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }

                })
                .finally(() => {
                    this.addRequest = false;
                });
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "data": {
                    order: this.order
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                let data = JSON.parse(JSON.stringify(params.data));
                for (var key in data) {
                    let value = data[key];
                    this.$data[key] = value
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        },

        /**
         * Применение бонусов
         */
        applyBonusUse() {
            //если в корзине указали, что нужно использовать бонусы
            if (this.bonusUse.use) {
                this.order.fields.PAY_CURRENT_ACCOUNT = 'Y';
                this.order.fields.PAY_COUNT = this.bonusUse.value;
            }
            //иначе бонусы не используем
            else {

                //удаляем поля, отвечающие за использование бонусов
                if (this.order.fields.PAY_CURRENT_ACCOUNT) {
                    delete this.order.fields.PAY_CURRENT_ACCOUNT;
                }
                if (this.order.fields.PAY_COUNT) {
                    delete this.order.fields.PAY_COUNT;
                }

            }
        }

    },

    created() {

        //учтём применение бонусов
        this.applyBonusUse();
        //загрузка данных
        this.loadData();

    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {

        //запишем мета данные страницы
        meta.write(metaTemplates);

        next((vm) => {

            //учтём применение бонусов
            vm.applyBonusUse();

            if (!vm.loading) {
                vm.loadData(() => { });
            }
        });
    },

}

</script>


<style lang="scss">
.okraina-v-order {


    .props {
        padding: 20px;
        background-color: var(--color-block-background) !important;
    }

    .property {
        margin-bottom: 20px;

        .label {
            font-size: 11px;
            margin-bottom: 0px;
            color: var(--color-text-primary);

            .required {
                color: var(--color-control-required);
            }
        }

        &.error {
            .label {
                color: var(--color-control-label-error);
                font-weight: bold;
            }
        }

        .display-value {
            display: grid;
            grid-template-columns: 1fr auto;

            .value {
                font-size: 14px;
                font-weight: 600;
                color: var(--color-text-title);
                padding-right: 15px;

                .item {
                    margin-bottom: 0px;
                }
            }

            .icon {
                font-size: 12px;
                opacity: 0.3;
            }
        }

        .notice {
            position: relative;
            margin: 20px 0px;
            padding: 12px 20px;

            overflow: hidden;
            text-align: center;
            border-radius: 24px;

            .bg {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background: rgba(var(--color-error-rgb), 0.05);
            }

            .inner {
                position: relative;
                z-index: 2;
                font-weight: 500;
                font-size: 12px;
                color: var(--color-error-rgb);
                line-height: 1.2;
            }

        }
    }

    .sum-error {
        position: relative;
        margin: 20px 20px;
        padding: 12px 20px;

        overflow: hidden;
        text-align: center;
        border-radius: 24px;

        .bg {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: rgba(var(--color-error-rgb), 0.05);
        }

        .inner {
            position: relative;
            z-index: 2;
            font-weight: 500;
            font-size: 12px;
            color: var(--color-error-rgb);
            line-height: 1.2;
        }


        .okraina-price {
            color: inherit;
        }
    }
}
</style>
