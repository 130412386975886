<template>
    <okraina-sheet v-model:visible="visible" class="okraina-cp-m-wrap" :maxHeight="92" :minHeight="92"
        :onlyHeaderSwipe="false" :noStretch="true" :history="false">
        <template v-slot:body>
            <div class="okraina-cp-m">

                <!-- Подгрузка -->
                <template v-if="loading">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>
                </template>
                <template v-else>
                    <template v-if="!product">
                        Товар не найден
                    </template>
                    <template v-else>
                        <okraina-catalog-product :product="product" :recommendationId="recommendationId" :modal="true" />
                    </template>
                </template>

            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaCatalogProduct from "@/components/catalog/product.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import metrika from '@/plugins/yandex.metrika'

export default {
    name: "okraina-catalog-product-modal",
    components: {
        okrainaSheet,
        okrainaLoaderInline,
        okrainaCatalogProduct
    },
    data() {
        return {
            loading: false,
            visible: false,
            product: false,
            closeInternal: false,
            id: false,
            code: "",
            recommendationId: "",
        };
    },

    watch: {
        visible: function (newVal) {
            //Если закрывается и это не внутреннее закрытие
            if (newVal == false && !this.closeInternal) {
                //эмитируем переход назад
                history.back();
            }
        },
    },
    methods: {

        open(data) {

            //если модалка уже открыта
            //значит переход к товару из другого товара
            if (this.visible) {
                this.close();
                setTimeout(() => {
                    this.open(data);
                }, 400);
                return;
            }

            this.visible = true;
            this.closeInternal = false;
            this.id = data.id;
            this.code = data.code;
            this.recommendationId = data.recommendationId ? data.recommendationId : "";

            this.loadData();

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", "/catalog/product/" + this.code + "/");
            //хит в Яндекс Метрику
            metrika.hit(window.location.pathname + window.location.search, {});

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Клик по иконке закрытия
         */
        close() {
            if (this.visible) {
                //эмитируем переход назад
                history.back();
            }
        },


        /**
         * Обработка закрытия при возврате назад в браузере
         */
        closeFromBaсkBtn() {
            this.closeInternal = true;
            this.visible = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);

            //хит в Яндекс Метрику
            metrika.hit(window.location.pathname + window.location.search);
        },

        /**
         * Загрузка данных
         */
        loadData() {
            this.loading = true;

            rest
                .call("catalog.products.getbyid", {
                    method: "post",
                    data: {
                        ID: this.id,
                        RESIZE_OPTIONS: tools.getProductDetailResizeOptions(),
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.product = data;
                        this.$store.dispatch("bigdata/hitProduct", { product: this.product, recommendationId: this.recommendationId })
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

    },

    mounted() {
        bus.on("OKRAINA_PRODUCT_MODAL_E_OPEN", this.open);
        bus.on("OKRAINA_PRODUCT_MODAL_E_CLOSE", this.close);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("OKRAINA_PRODUCT_MODAL_E_OPEN", this.open);
        bus.off("OKRAINA_PRODUCT_MODAL_E_CLOSE", this.close);
    },
}
</script>

<style lang="scss">
.okraina-cp-m-wrap {
    --color-sheet-bakground: var(--color-view-background) !important;

    .sheet-body {
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.okraina-cp-m {
    padding: 0px 8px 0px;
}
</style>

