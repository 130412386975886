const templates =  {
    "shop.okraina.ru": {
        title: "Отзывы",
        tags: [
            {
                name: "description",
                content: "Отзывы покупателей интернет-магазина Окраина о продукции, сервисе и доставке. Окраина - мы привозим самые свежие и вкусные продукты прямо с производства.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Отзывы – информация, МПЗ Окраина",
        tags: [
            {
                name: "description",
                content: "Отзывы – информация, необходимая пользователям при сотрудничестве с нашей компанией. Подробности на нашем сайте.",
            },
            {
                name: "keywords",
                content: "",
            }
        ]
    },
}

export default templates;