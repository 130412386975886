<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transitionName" :css="route.meta.transitionName ? true : false">
      <keep-alive :include="include">
        <component :is="Component" :key="getComponentKey()" />
      </keep-alive>
    </transition>
  </router-view>

  <okraina-menu />
  <okraina-message />
  <okraina-confirm />
  <okraina-update />
  <okraina-network-error />
  <okraina-catalog-product-modal />
  <okraina-speech />
  <okraina-auth-need />
  <okraina-fullscreen-banners />
  <okraina-region-detect />
  <okraina-share />
</template>


<script>
import okrainaMenu from "@/components/menu.vue";
import okrainaMessage from "@/components/message.vue";
import okrainaConfirm from "@/components/confirm.vue";
import okrainaUpdate from "@/components/update.vue";
import okrainaNetworkError from "@/components/network.error.vue";
import okrainaCatalogProductModal from '@/components/catalog/product.modal.vue';
import okrainaSpeech from '@/components/speech.vue';
import okrainaAuthNeed from '@/components/auth/auth.need.vue';
import okrainaFullscreenBanners from '@/components/sliders/fullscreen.banners.vue'
import okrainaRegionDetect from '@/components/region.detect.vue'
import okrainaShare from '@/components/share.vue'

import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika';
import storage from '@/plugins/storage'

import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    okrainaMenu,
    okrainaMessage,
    okrainaConfirm,
    okrainaUpdate,
    okrainaNetworkError,
    okrainaCatalogProductModal,
    okrainaSpeech,
    okrainaAuthNeed,
    okrainaFullscreenBanners,
    okrainaRegionDetect,
    okrainaShare
  },
  data() {
    return {
      lastKey: "/",
      textScale: 1
    };
  },

  computed: {
    ...mapGetters({
      isAuthorized: "isAuthorized",
      connectionSwitching: "getConnectionSwitching"
    }),
    include() {
      //при переключении региона сбрасываем кеш по всем экранам
      if (this.connectionSwitching) {
        return [];
      }

      let routes = this.$router
        .getRoutes()
        .filter((r) => r.meta.noCache !== true);
      let components = routes.map((r) => r.components ? r.components.default.name : "").filter(r => r);
      return components;
    },
  },

  methods: {

    getComponentKey() {
      let path = this.$route.fullPath;

      //если путь в каталоге
      if (path.startsWith("/catalog/") && this.lastKey.startsWith("/catalog/")) {

        //если поиск
        if (path.startsWith("/catalog/?q") && this.lastKey.startsWith("/catalog/?q")) {
          return this.lastKey;
        }
        if (path.startsWith("/catalog/?q") && !this.lastKey.startsWith("/catalog/?q")) {
          return path;
        }

        let tmpOld = this.lastKey.split("/").filter(element => element);
        let tmpNew = path.split("/").filter(element => element);
        if (tmpOld.length == tmpNew.length) {
          return this.lastKey;
        }

      }
      this.lastKey = path;
      return path;
    },

    /**
     * Обработка входа пользователя
     */
    onLogin() {

      //сохраняем FCM токен на сервере
      bus.emit("OKRAINA_PUSH_E_SAVE_TOKEN_TO_SERVER");

      //загрузим данные пользователя
      this.$store.dispatch("loadUserData");

      //сброс кеша экранов
      this.clearCachedViews();
    },

    /**
     * Обработка регистрации пользователя
     */
    onRegister() {

      //сохраняем FCM токен на сервере
      bus.emit("OKRAINA_PUSH_E_SAVE_TOKEN_TO_SERVER");

      //загрузим данные пользователя
      this.$store.dispatch("loadUserData");

      //сброс кеша экранов
      this.clearCachedViews();
    },

    /**
     * Обработка выхода пользователя
     */
    onLogout() {

      //сначала удаляем сессионные данные (чтобы запрос удлаения PUSH токена не вернул обратно сессионные переменные)
      let arConnection = this.$store.getters.getConnection;
      if (arConnection.session.B1T_OKRAINA_FAVARITE_USER_CODE) {
        delete arConnection.session.B1T_OKRAINA_FAVARITE_USER_CODE;
      }
      if (arConnection.session.SALE_USER_ID) {
        delete arConnection.session.SALE_USER_ID;
      }
      this.$store.commit("updateConnection", arConnection);

      //удаляем FCM токен с сервера
      bus.emit("OKRAINA_PUSH_E_DELETE_TOKEN_FROM_SERVER");

      //удаляем в текущем подключени токены
      arConnection.access_token = "";
      arConnection.refresh_token = "";
      this.$store.commit("updateConnection", arConnection);

      //сброс данных, зависящих от текущего аккаунта
      this.$store.dispatch("reset");

      //загрузим данные пользователя, даже если он не авторизован
      this.$store.dispatch("loadUserData");
      //загрузим настройки сайта (мало ли при первых запросах авторизация слетела)
      this.$store.dispatch("loadSiteSettings");

      //сброс кеша экранов
      this.clearCachedViews();

      //переход на главный экран
      this.$router.replace({ name: "home" });
    },

    /**
     * Сброс кеша экранов
     */
    clearCachedViews() {
      this.$store.commit("setConnectionSwitching", true);
      setTimeout(() => {
        this.$store.commit("setConnectionSwitching", false);
      }, 100);
    },

    /**
     * Меняет регион на указанный
     */
    changeRegion(params) {

      //отпишемся от рассылок на старом домене
      bus.emit("OKRAINA_PUSH_E_UNSUBSCRIBE", true);
      //удаляем FCM токен с сервера
      bus.emit("OKRAINA_PUSH_E_DELETE_TOKEN_FROM_SERVER");

      //устанавливаем подключение текущим
      this.$store.commit("setCurrentConnection", params.domain);

      //загрузим данные пользователя, даже если он не авторизован
      this.$store.dispatch("loadUserData");
      //загрузим настройки сайта
      this.$store.dispatch("loadSiteSettings");

      //подпишемся на рассылок на новом домене
      bus.emit("OKRAINA_PUSH_E_SUBSCRIBE", true);
      //сохраняем FCM токен на сервере
      bus.emit("OKRAINA_PUSH_E_SAVE_TOKEN_TO_SERVER");

      if (params.showFullScreenBaners) {
        //вызываем показ fullscreen баннеров
        bus.emit("OKRAINA_FULLSCREEN_BANERS_E_SHOW", true);
      }

      //для сброса кеша экранов
      this.$store.commit("setConnectionSwitching", true);
      setTimeout(() => {
        this.$store.commit("setConnectionSwitching", false);
      }, 100);

      //яндекс метрика
      metrika.init();

    }

  },

  created() {
    //загрузим данные пользователя, даже если он не авторизован
    this.$store.dispatch("loadUserData");

    //загрузим настройки сайта
    this.$store.dispatch("loadSiteSettings");

    //восстановление страницы после перезагрузки из-за обновления веб-сборки
    let routes = this.$router;
    storage.get("saved_url").then(function (url) {
      if (url) {
        storage.set("saved_url", "");

        //парсим параметры 
        const [, paramString] = url.split('?');
        let urlParams = new URLSearchParams(paramString);
        let entries = urlParams.entries();
        let query = {};
        for (let [key, value] of entries) {
          query[key] = value;
        }
        routes.push({ path: url, query: query });
      }
    })

    //узанём коэффициент увеличения шрифта (для мобильника, где в настройках изменён размер шрифта)
    let realSize = parseInt(window.getComputedStyle(document.body).getPropertyValue('font-size'));
    this.textScale = realSize / 14;
    document.getElementById("app").style.setProperty('--text-scale', this.textScale);

  },

  mounted() {
    //подписваемся на событие
    bus.on("OKRAINA_APP_E_USER_LOGIN", this.onLogin);
    bus.on("OKRAINA_APP_E_USER_LOGOUT", this.onLogout);
    bus.on("OKRAINA_APP_E_USER_REGISTER", this.onRegister);
    bus.on("OKRAINA_APP_E_CHANGE_REGION", this.changeRegion);

    //вызываем событие готовности приложения (для закрытия SplashScreen)
    bus.emit("OKRAINA_APP_E_READY");

    //в общем состонии выставим флаг готовности приложения
    this.$store.commit("setAppReady", true);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("OKRAINA_APP_E_USER_LOGIN", this.onLogin);
    bus.off("OKRAINA_APP_E_USER_LOGOUT", this.onLogout);
    bus.off("OKRAINA_APP_E_USER_REGISTER", this.onRegister);
    bus.off("OKRAINA_APP_E_CHANGE_REGION", this.changeRegion);
  },
};
</script>

<style lang="scss">
#app {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
