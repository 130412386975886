<template>
    <okraina-view :class="'okraina-v-news-list'" @refresh="refresh" ref="view" :hideMenu="true">
        <template v-slot:header>
            <h1>
                Новости
            </h1>
        </template>

        <template v-slot:content>

            <div ref="root">

                <div class="spacer"></div>

                <!--Индикатор загрузки-->
                <transition>
                    <okraina-loader-page v-show="loading || !canShow" />
                </transition>

                <template v-if="canShow">


                    <div class="list">
                        <a class="item" v-for="item in items" :key="item.ID" :href="item.FIELDS.DETAIL_PAGE_URL"
                            @click.prevent="goto(item.FIELDS.DETAIL_PAGE_URL)" v-ripple>

                            <div class="picture preloaded" v-if="item.FIELDS.PREVIEW_PICTURE"
                                v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE.SRC">

                                <div class="preload"
                                    :style="{ backgroundImage: 'url(\'' + item.FIELDS.PREVIEW_PICTURE.PRELOAD + '\')' }">
                                </div>

                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>
                            </div>

                            <div class="inner">
                                <div class="date">
                                    {{ dateTransformViews(item.FIELDS.ACTIVE_FROM) }}
                                </div>
                                <div class="name" v-html="item.FIELDS.NAME"></div>
                                <div class="text" v-html="item.FIELDS.PREVIEW_TEXT">
                                </div>
                            </div>
                        </a>
                    </div>

                </template>

                <div ref="pager" class="pager"></div>

                <!-- Подгрузка следюущей страницы -->
                <template v-if="loadingPaging">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>
                </template>

            </div>
        </template>
    </okraina-view>
</template>

<script>
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaView from "@/components/view2.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/news.list.js";
import moment from "moment";

export default {
    name: "okraina-v-news-list",
    components: {
        okrainaLoaderPage,
        okrainaView,
        okrainaLoaderInline,
    },
    data() {
        return {
            loading: true,
            loadingPaging: false,
            items: [],
            moment: moment,
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 6,
                PAGES_COUNT: 1,
            },
            canShow: false
        }
    },

    methods: {
        /**
         * Загружает данные
        */
        loadData(resolve = false) {
            if (!this.loadingPaging) {
                this.loading = resolve ? false : true;
            }
            rest
                .call("news.getlist", {
                    method: "post",
                    data: {
                        NAV_PARAMS: this.pager,
                        RESIZE_OPTIONS: {
                            WIDTH: "900",
                            HEIGHT: "330",
                            TYPE: "PROPORTIONAL",
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        dateTransformViews(date) {
            return moment(date, 'DD.MM.YYYY').format("DD MMMM YYYY");
        },

        /**
         * Переход на указанный URL
         */
        goto(url) {
            let vm = this;
            setTimeout(() => {
                vm.$router.push({ path: url });
            }, 500);
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            if (!this.$refs.pager) {
                return;
            }
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingPaging &&
                    !this.loading &&
                    this.canShow &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData(() => { });
                }
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.pager.NUM_PAGE = 1;
            this.loadData(resolve);
        },

    },
    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.root
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        //запоминаем скрол
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        //запишем мета данные страницы
        meta.write(metaTemplates);

        //восстанавилваем скрол
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}
</script>

<style lang="scss">
.okraina-v-news-list {

    .view-content {
        padding-bottom: 30px;
    }

    .list {
        padding: 0px 11px;
    }

    .item {
        border-radius: 5px;
        background-color: var(--color-block-background);
        overflow: hidden;
        margin-bottom: 19px;
        border-bottom: 0px;
        display: block;

        .picture {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            padding-bottom: 65.66%;
            padding-top: 0;
            height: 0;
        }

        .inner {
            padding: 12px 16px 12px 16px;
        }

        .date {
            color: var(--color-text-description);
            font-size: 12px;
        }

        .name {
            margin-top: 10px;

            font-weight: 600;
            font-size: 15px;
            color: var(--color-text-title);
        }

        .text {
            margin-top: 15px;
            font-size: 14px;
            color: var(--color-text-primary);
        }
    }

}
</style>
