import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from '@capgo/capacitor-updater'

import semver from 'semver'
import bus from "@/plugins/bus";
import storage from '@/plugins/storage'

const updater = {

    data: false,

    /**
     * Инициализация
     */
    init() {

        //если не нативное устройство
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        //оповещаем систему обновления, что приложение готово
        CapacitorUpdater.notifyAppReady();

        let vm = this;
        //подписваемся на событие прмиенения обновления
        bus.on("OKRAINA_UPDATE_E_APPLY", () => {
            vm.apply();
        });

        //проверяем обновления
        this.checkUpdates();
    },

    /**
     * Проверка обновления
     */
    async checkUpdates() {
        //получаем последнюю версию
        let latest = await this.getLatest();
        if (!latest || !latest.url) {
            return;
        }

        //если версия меньше или равна текущей
        if (semver.lte(latest.version, import.meta.env.PACKAGE_VERSION)) {
            return;
        }

        //загружаем новую версию
        let data = await this.downloadLatest(latest);
        if (!data) {
            return;
        }

        CapacitorUpdater.next({ id: data.id })
        this.data = data;

        bus.emit("OKRAINA_UPDATE_E_SHOW");
    },

    /**
     * Запрос данных о последней версии
     */
    async getLatest() {
        const latest = await CapacitorUpdater.getLatest();
        return latest;
    },

    /**
     * Загружает последнюю версию
     */
    async downloadLatest(latest) {
        let data = await CapacitorUpdater.download({
            url: latest.url,
            version: latest.version,
        });

        return data;
    },

    /**
     * Применяет последнюю версию
     */
    apply() {
        this.apply_(this.data);
    },

    /**
     * Применяет последнюю версию
     */
    async apply_(data) {

        //сохраняем текущий url 
        await storage.set("saved_url", window.location.pathname + window.location.search);
        
        try {
            await CapacitorUpdater.set({ id: data.id })
        }
        catch (err) {
            console.log(err)
        }

    }
}


export default updater;
