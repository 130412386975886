import storage from '@/plugins/storage'

const connection = {
    state: () => {
        return {
            //список подключений
            connections: {},
            //ключ текущего подключения
            curentConnection: "",
            defaulConnettion: import.meta.env.VITE_DEFAULT_CONNECTION,
            //флаг переключения на другой домен
            connectionSwitching: false,
            //версия rest
            version:'10',
        }
    },
    getters: {

        getConnections(state) {
            return state.connections;
        },
        getCurrentConnectionKey(state) {
            return state.curentConnection;
        },
        getConnection(state) {
            let key = state.curentConnection ? state.curentConnection : state.defaulConnettion;
            return state.connections[key] ? state.connections[key] : {
                access_token: false,
                refresh_token: false,
                server: key,
                data: {},
                session: {}
            };
        },
        getAccessToken(state, getters) {
            let connection = getters.getConnection;
            return connection.access_token ? connection.access_token : false;
        },
        getRefreshToken(state, getters) {
            let connection = getters.getConnection;
            return connection.refresh_token ? connection.refresh_token : false;
        },
        getApiUrl(state, getters) {
            let connection = getters.getConnection;
            return "https://" + connection.server + "/rest/v"+state.version+"/";
        },
        getServer(state, getters) {
            let connection = getters.getConnection;
            return connection.server;
        },
        isAuthorized(state, getters) {
            return getters.getAccessToken ? true : false;
        },

        getSessionData(state, getters) {
            let connection = getters.getConnection;
            return connection.session ? connection.session: {};
        },

        getConnectionSwitching(state) {
            return state.connectionSwitching;
        },

        getSiteCode(state, getters) {

            let connection = getters.getConnection;

            if(connection.server.includes("shop.okraina")) {
                return "msk";
            } else if (connection.server.includes("dostavka.okraina")) {
                return "mur";
            }

            return "";

        }

        
    },
    mutations: {
        
        setConnections: (state, value) => {
            state.connections = value;
        },
        setCurrentConnection: (state, value) => {
            state.curentConnection = value;
            storage.set("current_connection", value);
        },
        updateConnection: (state, value) => {
            let key = state.curentConnection ? state.curentConnection : state.defaulConnettion
            state.connections[key] = value;
            storage.set("connections", state.connections);
        },

        setConnectionSwitching: (state, value) => {
            state.connectionSwitching = value;
        },

       
    },

    actions: {
        /**
         * Устанавливает переменную в данные сесиии
         */
        setSessionParam(context, {key, value}) {
            let arConnection =  context.getters.getConnection

            if(!arConnection["session"] || arConnection["session"] instanceof Array) {
                arConnection["session"] = {};
            }

            arConnection.session[key] = value;

            //сохраняем подключение в текущем состоянии
            context.commit('updateConnection', arConnection);

        }
    }


   

}

export default connection;