<template>
    <okraina-view :class="'okraina-v-tou'" :refreshDisabled="true" ref="view" :hideMenu="true">
        <template v-slot:content>

            <iframe :src="origin + '/rest/pages/privacy_policy.php'" />

        </template>

        <template v-slot:footer>
            <div class="btn-wrap">
                <div class="btn btn1" @click="accept">Принять и продолжить</div>
            </div>
        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-terms-of-use",
    components: {
        okrainaView
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            server: "getServer"
        }),

        origin() {
            return 'https://' + this.server;
        }
    },

    methods: {

        /**
         * Принять 
         */
        accept() {

            this.$store.commit("setTermsOfUseAccepted", true);

            //переход на главный экран
            this.$router.replace({ name: "home" });
        }

    },

}

</script>

<style lang="scss">
.okraina-v-tou {

    iframe {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 0px;
    }

    .btn-wrap {
        padding: 15px 20px;
        text-align: center;

    }
}
</style>