<template>
    <okraina-view :class="'okraina-v-hd'" :refreshDisabled="true" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Доставка
            </h1>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <div class="iframe-wrap">
                <iframe :src="origin + '/rest/pages/delivery/'" :class="{ hidden: !canShow }" ref="frame" />
            </div>

            <okraina-sheet v-model:visible="showDeliverTimeInfo">
                <template v-slot:body>
                    <div class="okraina-hd-dts" v-if="deliveryTimeData">
                        <span class="fi fi-location icon"></span>
                        <div class="name">{{ deliveryTimeData.NAME }}</div>
                        <div class="props">
                            <div class="prop">{{ deliveryTimeData.PROPS.TIME.VALUE }}</div>
                            <div class="prop">{{ deliveryTimeData.PROPS.DAY.VALUE }}</div>

                        </div>
                    </div>
                </template>
            </okraina-sheet>


        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaSheet from "@/components/sheet.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";

import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/help.delivery.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-help-delivery",
    components: {
        okrainaView,
        okrainaSheet,
        okrainaLoaderPage
    },
    data() {
        return {
            loading: true,
            showDeliverTimeInfo: false,
            deliveryTimeData: false,
            canShow: false
        };
    },
    computed: {
        ...mapGetters({
            server: "getServer"
        }),

        origin() {
            return 'https://' + this.server;
        }
    },

    methods: {

        onMessage(e) {

            if (!e.origin == this.origin) {
                return;
            }

            if (!(typeof e.data === 'string' || e.data instanceof String)) {
                return;
            }

            let data = false;
            try {
                data = JSON.parse(e.data);
            } catch (e) {
                return;
            }

            if (data) {
                if (data.action == "SHOW_DELIVER_TIME_SHEET") {
                    this.deliveryTimeData = data.data;
                    this.showDeliverTimeInfo = true;
                } else if (data.action == "GO_TO_SCREEN") {
                    this.$router.push({ path: data.url });
                }

            }

        }

    },

    created() {
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    mounted() {
        window.addEventListener('message', this.onMessage);

        //подписываемся на завершение загрузки IFRAME
        this.$refs.frame.onload = () => {
            this.loading = false;
        }

    },

    beforeUnmount() {
        window.removeEventListener('message', this.onMessage);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}

</script>

<style lang="scss">
.okraina-v-hd {

    .iframe-wrap {
        position: absolute;
        top: 60px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 0px;
    }
}

.okraina-hd-dts {
    text-align: center;

    .icon {
        font-size: 44px;
        color: var(--color-color1)
    }

    .name {
        margin: 15px 0px;

        opacity: 0.6;
        font-size: 16px;
    }

    .props {
        line-height: 1.1;
    }

    .prop {
        font-weight: 500;
        font-size: 20px;
    }
}
</style>