<template>
  <okraina-view :class="'okraina-v-catalog-consist'" @refresh="refresh" :noHeaderBorder="true">
    <template v-slot:header>
      <okraina-catalog-search-input />
    </template>
    <template v-slot:content>
      <transition v-if="loading">
        <okraina-loader-page v-show="loading" />
      </transition>

      <template v-if="selectedConsist">
        <div class="title">
          <h1>Поиск: {{ selectedConsist.FIELDS.NAME.toLowerCase() }}</h1>
          <div class="sort-btn">
            <okraina-catalog-sort-btn />
          </div>
        </div>
        <okraina-catalog-list v-model:loading="loading" :filter="productFilter" :sort="sort"
          :refreshIndex="refreshIndex" />
      </template>
    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogList from "@/components/catalog/list.vue";
import okrainaCatalogSortBtn from "@/components/catalog/sort.btn.vue";

import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/catalog.consist.js";
import { mapGetters } from "vuex";

export default {
  name: "okraina-v-catalog-consist",
  components: {
    okrainaView,
    okrainaCatalogSearchInput,
    okrainaLoaderPage,
    okrainaCatalogList,
    okrainaCatalogSortBtn,
  },
  data() {
    return {
      loading: true,
      refreshIndex: 0,
    };
  },
  props: {
    code: {
      type: String,
      default() {
        return "";
      },
    },
  },
  computed: {
    ...mapGetters({
      currentSort: "getCatalogSortCurrent",
      consist: "getCatalogConsist",
    }),
    selectedConsist() {
      let item = this.consist.find((i) => i.FIELDS.CODE == this.code);
      return item;
    },
    productFilter() {
      if (this.selectedConsist) {
        return {
          CONSIST: this.selectedConsist.FIELDS.ID,
        };
      }
      return {};
    },
    sort() {
      let item = this.currentSort;
      if (!item) {
        return false;
      }

      let sort = {};
      sort[item.PROPS.BY] = item.PROPS.ORDER;
      return sort;
    },
  },
  watch: {
    selectedConsist: function () {
      this.setMeta();
    }
  },
  methods: {
    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      this.refreshIndex++;
      if (resolve) {
        setTimeout(resolve, 200);
      }
    },

    /**
     * Устанавливает мета данные страницы
     */
    setMeta() {
      if (this.selectedConsist) {
        meta.write(metaTemplates, { NAME: this.selectedConsist.FIELDS.NAME });
      }
    }
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      vm.setMeta();
    });
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },
};
</script>

<style lang="scss">
.okraina-v-catalog-consist {
  .title {
    position: relative;
    padding-right: 35px;

    .sort-btn {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}
</style>