<template>
  <div class="okraina-loader-inline" :class="{ 'sheet-mode': sheetMode }"></div>
</template>

<script>
export default {
  name: "okraina-loader-inline",
  props: {
    sheetMode: {
      type: Boolean,
      default() {
        return false;
      },
    },

  }
};
</script>

<style lang="scss">
.okraina-loader-inline {
  display: inline-block;
  width: 42px;
  height: 42px;

  z-index: 10;
  transform-origin: 50%;
  animation: b1t-okraina-loader-inline-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid var(--color-color1);
  border-radius: 50%;
  border-top-color: transparent;
}

@keyframes b1t-okraina-loader-inline-spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>