<template>
    <div class="form-group" :class="{
        'form-group-error': error
    }">

        <template v-if="label != ''">
            <div :class="labelClass">
                {{ label }}
                <span v-if="required && needShowRequired" class="required">*</span>
            </div>
        </template>

        <div class="form-control-wrap" :class="{
            'no-slot-left': !this.$slots.left,
            'no-slot-right': !this.$slots.right,
        }">
            <div class="slot-left">
                <slot name="left"></slot>
            </div>
            <div>
                <textarea class="form-control" :class="required ? 'required' : ''" v-model="value" @input="handleInput"
                    :rows="rows" ref="input" autocomplete="off" :placeholder="placeholder" />
            </div>
            <div class="slot-right">
                <slot name="right"></slot>
            </div>
        </div>

        <div v-if="description" v-html="description" class="description"></div>
    </div>
</template>

<script>

export default {
    name: "okraina-control-texarea",
    emits: ["update:modelValue", "update:valid", "update:error"],
    data() {
        return {
            value: this.modelValue
        };
    },
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        labelClass: {
            type: String,
            default() {
                return "label"
            }
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            },
        },
        error: {
            type: Boolean,
            default() {
                return false;
            },
        },
        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
        placeholder: {
            type: String,
            default() {
                return "";
            },
        },
        rows: {
            type: [Number, String],
            default() {
                return 3;
            },
        },
    },
    computed: {
        needShowRequired() {
            return this.required && this.showRequired;
        },
    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },
    methods: {
        handleInput(e) {
            this.value = e.target.value;
            this.$emit("update:modelValue", this.value);
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {
            if (this.required && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },

    },

    mounted() {
        this.checkValid();
    },
}

</script>