import store from '@/store';

const templates = {
    "shop.okraina.ru": {
        title: "Интернет-магазин мяса в Москве, купить говядину и свинину с доставкой, мясной магазин",
        tags: [
            {
                name: "google-site-verification",
                content: "wk9WmdPIQxJfEDKrzFNNrbl8vpU_wPbIqmSW5R5xdnI"
            },
            {
                name: "og:type",
                content: "website"
            },
            {
                name: "og:url",
                content: () => {
                    return "https://"+store.getters.getServer+window.location.pathname;
                }
            },
            {
                name: "og:image",
                content: () => {
                    return "https://"+store.getters.getServer+"/images/logo/logo-share.jpg";
                }
            },
            {
                name: "og:image:width",
                content: "420"
            },
            {
                name: "og:image:height",
                content: "420"
            },
            {
                name: "og:title",
                content: "Мясной интернет-магазин колбасы и деликатесов Окраина"
            },
            {
                name: "og:description",
                content: "У нас можно купить натуральную колбасу и мясные деликатесы прямо с производства, с бесплатной доставкой в день заказа"
            },
        ]                
    },
    "dostavka.okraina.ru": {
        "title": "Интернет-магазин Окраина Мурманск | Купить колбасу | Бесплатная доставка продуктов на дом | Мясная продукция",
        tags: [
            {
                name: "description",
                content: "Мы создаём качественные мясные продукты, колбасы из лучшего свежего сырья, не применяя в их производстве заменителей ненатурального происхождения. Это позволяет нам предлагать покупателям действительно вкусные и качественные продукты и задавать, таким образом, новый для рынка стандарт"
            },
            {
                name: "keywrods",
                content: "Доставка окраина, Доставка окраина Мурманск, Колбаса на дом, Цены производителя, Колбаса дешево, Купить колбасу Мурманск, Колбаса скидки Мурманск, Пельмени скидки, Полуфабрикаты недорого Мурманск, Продукты на дом Мурманск, Доставка продуктов Мурманск, Доставка мяса Мурманск, Шашлык с доставкой Мурманск, Колбаса Окраина Мурманск, Пельмени Окраина  Мурманск, Вареники Окраина Мурманск, Сэкономить на продуктах Мурманск"
            },
            {
                name: "google-site-verification",
                content: "VTf9rvl15jol0AM4NSzzaBxpplG35Dvk21aJvoSN2RI"
            },
            {
                name: "yandex-verification",
                content: "7b4b8eca777b9706"
            },
            {
                name: "geo.placename",
                content: "ул. Траловая, д. 3, Мурманск, Россия"
            },
            {
                name: "geo.position",
                content: "33.047834;68.958929"
            },
            {
                name: "geo.region",
                content: "RU-Мурманская область"
            },
            {
                name: "ICBM",
                content: "33.047834, 68.958929"
            },
            {
                name: "og:type",
                content: "website"
            },
            {
                name: "og:url",
                content: () => {
                    return "https://"+store.getters.getServer+window.location.pathname;
                }
            },
            {
                name: "og:image",
                content: () => {
                    return "https://"+store.getters.getServer+"/images/logo/logo-share.jpg";
                }
            },
            {
                name: "og:image:width",
                content: "420"
            },
            {
                name: "og:image:height",
                content: "420"
            },
            {
                name: "og:title",
                content: "Мясной интернет-магазин колбасы и деликатесов Окраина"
            },
            {
                name: "og:description",
                content: "У нас можно купить натуральную колбасу и мясные деликатесы прямо с производства, с бесплатной доставкой в день заказа"
            },
        ]
    },
}

export default templates;