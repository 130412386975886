<template>
    <div class="okraina-cp" ref="root">

        <div class="product-data">
            <div class="present-block">
                <div class="stickers">
                    <okraina-catalog-product-stickers :item="product" />
                </div>

                <div class="share" @click="share">
                    <span class="fi fi-share"></span>
                </div>

                <div class="photo">
                    <okraina-catalog-product-photo-slider
                        :item="selected.FIELDS.IBLOCK_ID == product.FIELDS.IBLOCK_ID ? selected : product" />

                    <okraina-catalog-product-not-available v-if="!canBuy && product.PRICE" :item="product" />
                </div>

                <div v-if="product.VALENTINES" class="valentines" @click="goToAction">

                    <div class="wrap" v-if="product.VALENTINES.COUNT">
                        <span class="count">{{ product.VALENTINES.COUNT }}</span>
                        <span class="icon fi fi-heart-filled"></span>
                    </div>

                </div>

                <div class="rat-fav">

                    <div>
                        <okraina-catalog-product-rating :rating="product.PROPS.rating" />
                    </div>
                    <div>
                        <div class="fav">
                            <okraina-catalog-product-favorite-btn :productId="product.FIELDS.ID" />
                        </div>
                    </div>

                    <div class="experation-date" v-if="canBuy">
                        <okraina-catalog-product-experation-date :item="product" />
                    </div>
                </div>

                <h1 class="name" v-html="product.FIELDS.NAME"></h1>

            </div>

        </div>

        <div class="product-inner">

            <div class="product-data">


                <div class="price-article">
                    <div ref="price">
                        <okraina-catalog-product-price :item="selected" v-if="product.PRICE"
                            :isRifling="isRiflingSelected" :detail="true" :showDiscountPercent="true"
                            :showOldPriceTop="true" />
                    </div>
                    <div>
                        <div class="article" v-if="product.PROPS.CML2_ARTICLE">арт. {{ product.PROPS.CML2_ARTICLE }}
                        </div>
                        <div class="rating">
                            <okraina-catalog-product-rating :rating="product.PROPS.rating" :detail="true" />
                        </div>
                    </div>
                </div>

                <div class="price-kg" v-if="selected.PRICE_KG">
                    <div>
                        Стоимость за кг
                    </div>
                    <div>
                        <div class="dots"></div>
                    </div>
                    <div>
                        <okraina-catalog-product-price-kg :item="selected" />
                    </div>

                </div>

                <div class="product-rifling">
                    <okraina-catalog-product-rifling :item="product" v-model="selectedId" />
                </div>

                <div class="nut-value">
                    <okraina-catalog-product-nutritional-value :item="product" />
                </div>

                <div class="consist" v-if="consist" @click="expandConsist">
                    <h2>Состав</h2>
                    <div class="value" v-html="consist">
                    </div>
                </div>

                <div class="shelf-life" v-if="weightPerPiece">
                    <h2>Вес 1 шт</h2>
                    <div class="value">{{ weightPerPiece }}г</div>
                </div>

                <div class="shelf-life" v-if="product.PROPS.SROK_HRAN">
                    <h2>Срок хранения</h2>
                    <div class="value" v-html="product.PROPS.SROK_HRAN"></div>
                </div>

                <div class="standard" v-if="standard">
                    <h2>Стандарт</h2>
                    <div class="value" v-html="standard">
                    </div>
                </div>

                <div class="description" v-if="description" @click="expandDescription">
                    <h2>Описание</h2>
                    <div class="value" v-html="description"></div>
                </div>

                <div class="cooking-method" v-if="cookingMethod" @click="expandCookingMethod">
                    <h2>Способ приготовления</h2>
                    <div class="value" v-html="cookingMethod"></div>
                </div>

            </div>

            <div class="recipes" v-if="product.PROPS.RECIPE && this.product.PROPS.RECIPE.length > 0">
                <h2>Рецепты</h2>

                <template v-if="loadingRecipes">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>
                </template>
                <template v-else>
                    <okraina-slider-recipes :items="recipes" />
                </template>
            </div>

            <div class="recomendations">
                <okraina-catalog-recommendations :slider="true" title="Рекомендуем" />
            </div>

            <okraina-catalog-product-review-top v-if="reviewsEnabled" :productId="product.FIELDS.ID" />

        </div>

        <okraina-fixed-panel v-if="product.PRICE" :modal="modal">
            <template v-if="canBuy">

                <okraina-catalog-product-buy-btn-detail :productId="basketData.productId" :params="basketData.params"
                    :checkQuantity="checkQuantity" :maxQuantity="maxQuantity" :maxQuantityMessage="maxQuantityMessage"
                    :recommendationId="recommendationId" :modal="modal" :showPrice="showBottomPrice">
                    <template v-slot:price>
                        <okraina-catalog-product-price :item="selected" v-if="product.PRICE"
                            :isRifling="isRiflingSelected" :inBuyBtn="true" :showOldPriceTop="true"
                            :disableYellowPrice="true" />
                    </template>
                </okraina-catalog-product-buy-btn-detail>

            </template>
            <template v-else-if="canNeedMore">
                <okraina-catalog-product-needmore-btn :productId="product.FIELDS.ID" v-model="inNeedMoreList"
                    :detail="true" />
            </template>
            <template v-else-if="canSubscribe">
                <okraina-catalog-product-subscribe-btn :productId="product.FIELDS.ID" v-model="subscribed"
                    :detail="true" />
            </template>
            <template v-else>
                <okraina-catalog-product-not-available v-if="!canBuy" :item="product" :detail="true" />
            </template>
        </okraina-fixed-panel>


    </div>
</template>

<script>
import okrainaCatalogProductPhotoSlider from "@/components/catalog/product.photo.slider.vue";
import okrainaCatalogProductStickers from "@/components/catalog/product.stickers.vue";
import okrainaCatalogProductExperationDate from "@/components/catalog/product.experation.date.vue";
import okrainaCatalogProductFavoriteBtn from "@/components/catalog/product.favorite.btn.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";
import okrainaCatalogProductPriceKg from "@/components/catalog/product.price.kg.vue";
import okrainaCatalogProductRating from "@/components/catalog/product.rating.vue";
import okrainaCatalogProductRifling from "@/components/catalog/product.rifling.vue";
import okrainaCatalogProductNutritionalValue from "@/components/catalog/product.nutritional.value.vue";
import okrainaCatalogProductBuyBtnDetail from "@/components/catalog/product.buy.btn.detail.vue";
import okrainaCatalogProductNotAvailable from "@/components/catalog/product.notavailable.vue";
import okrainaCatalogProductReviewTop from "@/components/catalog/product.review.top.vue";
import okrainaCatalogRecommendations from "@/components/catalog/recommendations.vue";
import okrainaCatalogProductSubscribeBtn from "@/components/catalog/product.subscribe.btn.vue";
import okrainaCatalogProductNeedmoreBtn from "@/components/catalog/product.needmore.btn.vue";
import okrainaFixedPanel from '@/components/fixed.panel.vue'
import okrainaSliderRecipes from "@/components/sliders/recipes.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika'


import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product",
    components: {
        okrainaCatalogProductPhotoSlider,
        okrainaCatalogProductStickers,
        okrainaCatalogProductExperationDate,
        okrainaCatalogProductFavoriteBtn,
        okrainaCatalogProductPrice,
        okrainaCatalogProductPriceKg,
        okrainaCatalogProductRating,
        okrainaCatalogProductRifling,
        okrainaCatalogProductNutritionalValue,
        okrainaCatalogProductBuyBtnDetail,
        okrainaCatalogProductNotAvailable,
        okrainaCatalogProductReviewTop,
        okrainaCatalogRecommendations,
        okrainaCatalogProductSubscribeBtn,
        okrainaCatalogProductNeedmoreBtn,
        okrainaFixedPanel,
        okrainaSliderRecipes,
        okrainaLoaderInline
    },
    data() {
        return {
            selectedId: this.product.FIELDS.ID,
            collapseConsist: true,
            collapseDescription: true,
            collapseCookingMethod: true,
            showBottomPrice: false,
            subscribed: this.product.CATALOG_SUBSCRIBE.SUBSCRIBED == "Y",
            inNeedMoreList: this.product.NEED_MORE && this.product.NEED_MORE.IN_LIST == "Y",
            loadingRecipes: false,
            recipes: []
        };
    },
    props: {
        product: {
            type: Object,
            default() {
                return {};
            },
        },
        recommendationId: {
            type: [String, Number],
            default() {
                return "";
            }
        },
        modal: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },
    provide() {
        return {
            product: this.product
        }
    },
    watch: {
        product: function () {
            this.selectedId = this.product.FIELDS.ID;
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        reviewsEnabled() {
            return this.siteSettings ? this.siteSettings.REVIEWS_ENABLED == "Y" : false;
        },

        canBuy() {
            //если нет цены
            if (!this.product.PRICE || this.product.PRICE.DISCOUNT_VALUE == 0) {
                return false;
            }

            //если временно недоступен
            if (
                this.product.PROPS.TEMPORARILY_NOT_AVAILABLE &&
                this.product.PROPS.TEMPORARILY_NOT_AVAILABLE.XML_ID == "Y"
            ) {
                return false;
            }

            //если включен кол-венный учёт и остаток 0
            if (
                this.product.CATALOG.CHECK_QUANTITY == "Y" &&
                this.product.CATALOG.QUANTITY <= 0
            ) {
                return false;
            }

            return true;
        },

        /**
         * Св-во `Стандарт`
         */
        standard() {
            let standard = "";

            if (this.product.PROPS.TU) {
                standard = "ТУ " + this.product.PROPS.TU;
                return standard;
            }

            if (this.product.PROPS.GOST) {
                standard = "ГОСТ " + this.product.PROPS.GOST;
                return standard;
            }

            if (this.product.PROPS.STO) {
                standard = "СТО " + this.product.PROPS.STO;
                return standard;
            }
            return standard;
        },

        /**
         * Можно ли подписаться при отсутствии товара
         */
        canSubscribe() {
            return this.product.CATALOG_SUBSCRIBE && this.product.CATALOG_SUBSCRIBE.ENABLED == "Y";
        },

        /**
         * Можно использовать функционал "Привозите больше"
         */
        canNeedMore() {
            return this.siteSettings && this.siteSettings.NEED_MORE_ENABLED == "Y" && this.siteSettings.FORM_NEED_MORE > 0 && this.product.PRICE;
        },

        /**
         * Текущая выбранная нарезка ии сам товар
         */
        selected() {
            //если задана нарезка по весу
            if (this.product.RIFLING) {
                let rifling = this.product.RIFLING.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return rifling;
                }
            }

            //если задана нарезка как отдельные товары
            if (this.product.RIFLING_PRODUCTS) {
                let rifling = this.product.RIFLING_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return rifling;
                }
            }

            //если задана нарезка как отдельные товары
            if (this.product.OTHER_FORMAT_PRODUCTS) {
                let rifling = this.product.OTHER_FORMAT_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return rifling;
                }
            }

            return this.product;
        },

        /**
         * Выбрана ли сейчас именно нарезка
         */
        isRiflingSelected() {

            //если задана нарезка по весу
            if (this.product.RIFLING) {
                let rifling = this.product.RIFLING.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return true;
                }
            }

            //если задана нарезка как отдельные товары
            if (this.product.RIFLING_PRODUCTS) {
                let rifling = this.product.RIFLING_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return true;
                }
            }
            return false;
        },

        /**
         * Данные для добавления в корзину
         */
        basketData() {
            //если задана нарезка по весу
            if (this.product.RIFLING) {
                let rifling = this.product.RIFLING.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: this.product.FIELDS.ID,
                        params: [
                            {
                                CODE: "RIFLING",
                                NAME: "Нарезка",
                                VALUE: rifling.FIELDS.NAME,
                            },
                        ],
                    };
                }
            }

            //если задана нарезка как отдельные товары
            if (this.product.RIFLING_PRODUCTS) {
                let rifling = this.product.RIFLING_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: rifling.FIELDS.ID,
                        params: [],
                    };
                }
            }

            //если задана нарезка как отдельные товары
            if (this.product.OTHER_FORMAT_PRODUCTS) {
                let rifling = this.product.OTHER_FORMAT_PRODUCTS.find(
                    (r) => r.FIELDS.ID == this.selectedId
                );
                if (rifling) {
                    return {
                        productId: rifling.FIELDS.ID,
                        params: [],
                    };
                }
            }

            return {
                productId: this.product.FIELDS.ID,
                params: [],
            };
        },

        checkQuantity() {
            return this.selected.CATALOG
                ? this.selected.CATALOG.CHECK_QUANTITY
                : this.product.CATALOG.CHECK_QUANTITY;
        },

        maxQuantity() {
            if (this.selected.CATALOG) {
                return this.selected.CATALOG.QUANTITY > 0
                    ? parseInt(this.selected.CATALOG.QUANTITY)
                    : 0;
            }

            return this.product.CATALOG.QUANTITY > 0
                ? parseInt(this.product.CATALOG.QUANTITY)
                : 0;
        },

        maxQuantityMessage() {
            if (this.selected.CATALOG) {
                return this.selected.CATALOG.MAX_QUANTITY_MESSAGE
                    ? this.selected.CATALOG.MAX_QUANTITY_MESSAGE
                    : "";
            }

            return this.product.CATALOG.MAX_QUANTITY_MESSAGE
                ? this.product.CATALOG.MAX_QUANTITY_MESSAGE
                : "";
        },

        /**
         * Состав, по уомлчанию свёрнут
         */
        consist() {

            if (!this.product.PROPS.SOSTAV) {
                return "";
            }

            if (this.collapseConsist) {

                if (this.product.PROPS.SOSTAV.length > 120) {
                    return this.product.PROPS.SOSTAV.substring(0, 120) + " ... <span class='a'>развернуть</span>";
                }

            }

            return this.product.PROPS.SOSTAV;
        },

        /**
         * Описание без html
         */
        descriptionText() {
            return this.product.FIELDS.DETAIL_TEXT.replace(/(<([^>]+)>)/gi, "").trim();
        },

        /**
         * Описание, по умолчанию свёрнуто
         */
        description() {

            if (!this.descriptionText) {
                return "";
            }

            if (this.collapseDescription) {

                if (this.descriptionText.length > 120) {
                    return this.descriptionText.substring(0, 120) + " ... <span class='a'>развернуть</span>";
                }

            }

            return this.product.FIELDS.DETAIL_TEXT;
        },

        /**
         * Способ приготовления без html
         */
        cookingMethodText() {

            return this.product.PROPS.COOKING_METHOD
                ? this.product.PROPS.COOKING_METHOD.replace(/(<([^>]+)>)/gi, "").trim()
                : "";

        },

        /**
         * Способ приготовления, по умолчанию свёрнуто
         */
        cookingMethod() {

            if (!this.cookingMethodText) {
                return "";
            }

            if (this.collapseCookingMethod) {

                if (this.cookingMethodText.length > 120) {
                    return this.cookingMethodText.substring(0, 120) + " ... <span class='a'>развернуть</span>";
                }

            }

            return this.product.PROPS.COOKING_METHOD;
        },

        /**
         * Вес 1шт
         */
        weightPerPiece() {
            if (!this.product.PROPS.WEIGHT_PER_PIECE) {
                return false;
            }

            let weight = Math.round(parseFloat(this.product.PROPS.WEIGHT_PER_PIECE) * 1000);
            return weight;
        }

    },

    methods: {

        /**
         * Переход к детальной страницы акции
         */
        goToAction() {
            if (this.product.VALENTINES.URL) {
                setTimeout(() => {
                    this.$router.push({ path: this.product.VALENTINES.URL });
                }, 50);
            }
            //

            //
        },

        /**
         * Поделиться
         */
        share() {
            bus.emit("OKRAINA_APP_E_SHARE", {
                title: 'Поделиться',
                text: this.product.FIELDS.NAME,
                url: 'https://' + this.$store.getters.getServer + "/catalog/product/" + this.product.FIELDS.CODE + "/",
                dialogTitle: 'Поделиться',
            });
        },

        /**
         * Развернуть состав
         */
        expandConsist() {
            this.collapseConsist = false;
        },

        /**
         * Развернуть описание
         */
        expandDescription() {
            this.collapseDescription = false;
        },

        /**
         * Развернуть способ приготовления
         */
        expandCookingMethod() {
            this.collapseCookingMethod = false;
        },


        /**
         * Скрол экрана
         */
        onScroll(e) {
            if (!this.$refs.price) {
                return;
            }
            let scroll = e.target.scrollTop;
            let offsetTop = this.$refs.price.offsetTop;

            this.showBottomPrice = false;
            //если экран прокрутился до цены
            if (scroll > offsetTop) {
                this.showBottomPrice = true;
            }
        },

        /**
         * Подгружает рецепты рецепты
         */
        loadRecipes() {
            if (!this.product.PROPS.RECIPE || this.product.PROPS.RECIPE.length == 0) {
                return;
            }

            this.loadingRecipes = true;

            rest
                .call("recipes.getlist", {
                    method: "post",
                    data: {
                        FILTER: {
                            ID: this.product.PROPS.RECIPE
                        },
                        RESIZE_OPTIONS: {
                            WIDTH: 550,
                            HEIGHT: 400,
                            TYPE: "EXACT",
                            PRELOAD: "Y"
                        }
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.recipes = data.ITEMS;
                    }
                })
                .finally(() => {
                    this.loadingRecipes = false;
                });
        }
    },

    mounted() {
        //подписываемся на скролинг экрана
        let viewContentScroll = this.$refs.root.closest(".view-content-scroll");
        if (viewContentScroll) {
            viewContentScroll.addEventListener("scroll", this.onScroll);
        }

        let sheetScroll = this.$refs.root.closest(".sheet-body");
        if (sheetScroll) {
            sheetScroll.addEventListener("scroll", this.onScroll);
        }

        //событие просмотра товара в ЯндексМетрику
        metrika.ecommerceDetail(this.product);

    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        let viewContentScroll = this.$refs.root.closest(".view-content-scroll");
        if (viewContentScroll) {
            viewContentScroll.removeEventListener("scroll", this.onScroll);
        }

        let sheetScroll = this.$refs.root.closest(".sheet-body");
        if (sheetScroll) {
            sheetScroll.removeEventListener("scroll", this.onScroll);
        }

    },

    created() {
        //подгружаем рецепты
        this.loadRecipes();
    }

}


</script>

<style lang="scss">
.okraina-cp {
    padding-bottom: 80px;

    .present-block {
        position: relative;
        background-color: var(--color-block-background);
        border-radius: 4px;

        .valentines {
            position: absolute;
            z-index: 5;
            left: 5px;
            bottom: 85px;

            .wrap {
                position: relative;

                .count {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #fff;
                    font-size: 26px;
                    font-weight: 600;
                }

                .icon.fi {
                    font-size: 55px;
                    color: #e31e25;
                }
            }
        }

        .stickers {
            position: absolute;
            top: -5px;
            left: 4px;
            z-index: 20;
        }

        .share {
            position: absolute;
            z-index: 20;
            top: -10px;
            right: 0px;
            padding: 5px;
            font-size: 22px;
            color: var(--color-icon-share);
        }

        .photo {
            position: relative;
            margin-bottom: 4px;
            border-radius: 4px 4px 0px 0px;
            overflow: hidden;
        }



        .rat-fav {
            padding: 0px 4px 0px 8px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            align-items: center;
            margin-bottom: 4px;
            position: relative;

            .fav {
                float: right;
                line-height: 1;
            }

            .experation-date {
                position: absolute;

                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
            }
        }

        .name {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.2;
            padding: 0px 8px 10px;
            margin-top: 0px;
            color: var(--color-text-title);
        }
    }

    .product-inner {
        padding: 0px 16px;
    }

    /** класс, чтобы отгродить внутренни стили от стилей дочерних компонентов */
    .product-data {

        .price-article {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            align-items: center;
            margin-bottom: 35px;

            .rating {
                text-align: right;
            }
        }

        .price-kg {
            margin-bottom: 35px;
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: end;

            .dots {
                border-bottom: 2px dotted currentColor;
                position: relative;
                bottom: 5px;
                color: var(--color-text-gray);
            }
        }

        .article {
            font-size: 13px;
            color: var(--color-text-description);
            text-align: right;
            margin-bottom: 5px;
        }

        .product-rifling {
            margin-bottom: 25px;
        }


        .nut-value,
        .consist,
        .standard,
        .shelf-life,
        .description,
        .cooking-method {
            margin-bottom: 35px;

            h2 {
                margin-bottom: 8px;
            }

            .value {
                color: rgba(var(--color-text-title-rgb), 0.7);
                font-size: 11px;
            }

            .a {
                border-bottom: 0px;
            }
        }

        .shelf-life {
            margin-bottom: 35px;
        }


    }

    .recomendations {
        .slider {
            margin: 0px -24px;
            padding-left: 24px;
        }
    }

    .recipes {
        .slider {
            margin: 0px -24px;
            padding-left: 24px;
        }
    }



}
</style>
