<template>
    <okraina-sheet v-model:visible="visible">
        <template v-slot:body>

            <div class="okraina-share">

                <div class="text-center title">{{ params.dialogTitle }}</div>

                <div class="input-wrap">
                    <div>
                        <okraina-control-input :type="'text'" v-model="params.url" :readonly="true"
                            :noBottomMargin="true" />
                    </div>
                    <div>
                        <div class="copy" @click="copy">
                            <span class="icon fi fi-copy"></span>
                        </div>
                    </div>
                </div>

            </div>
        </template>

    </okraina-sheet>
</template>

<script>
import okrainaSheet from '@/components/sheet.vue'
import okrainaControlInput from "@/components/controls/input.vue";

import bus from "@/plugins/bus";
import { mapGetters } from "vuex";

export default {
    name: "okraina-share",
    components: {
        okrainaSheet,
        okrainaControlInput
    },
    data() {
        return {
            visible: false,
            params: {
                title: 'Поделиться',
                text: 'Поделиться ссылкой',
                url: 'https://' + this.$store.getters.getServer,
                dialogTitle: 'Поделиться',
            },
            timer: 0,
            error: false
        }
    },

    computed: {
        ...mapGetters({
            isNative: "isNativePlatform",
        }),

    },

    methods: {

        open(params) {

            //для натива будет работать Capacitor
            if (this.isNative) {
                return;
            }

            this.params = params;
            this.visible = true;
        },

        /**
         * Скопировать в буфер
         */
        copy() {

            bus.emit("OKRAINA_CLIPBOARD_E_WRITE", {
                text: this.params.url,
                successMessage: 'Ссылка скопирована.',
            });


        },


    },
    mounted() {
        //работает только для WEB
        bus.on("OKRAINA_APP_E_SHARE", this.open);
    },
    unmounted() {
        bus.off("OKRAINA_APP_E_SHARE", this.open);
    },

}
</script>

<style lang="scss">
.okraina-share {
    .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .description {
        font-size: 12px;
        margin: 0px 20px 20px;
    }

    .input-wrap {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 20px;
        align-items: center;

        .copy {
            position: relative;

            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba(var(--color-color1-rgb), 0.05);

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 17px;
                color: var(--color-color1);
            }
        }
    }


}
</style>