<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-contacts">
                <div class="sheet-title">Контактные данные</div>

                <form @submit.prevent="apply" action="">

                    <input type="submit" style="display: none;" />

                    <div class="items">
                        <div class="item" v-for="item in items" :key="item.ID">
                            <template v-if="item.TYPE == 'TEXT'">
                                <okraina-control-input :label="item.NAME" :required="item.REQUIED == 'Y'" :type="'text'"
                                    v-model="values[item.FIELD_NAME]" :mask="masks[item.FIELD_NAME]"
                                    v-model:error="errors[item.FIELD_NAME]" v-model:valid="valids[item.FIELD_NAME]" />
                            </template>

                        </div>
                    </div>
                    <div class="btn btn-block btn1" @click="apply">Принять</div>

                </form>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlInput from "@/components/controls/input.vue";

import tools from "@/plugins/tools";

export default {
    name: "okraina-order-contacts",
    components: {
        okrainaSheet,
        okrainaControlInput
    },
    emits: ["apply", "update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false,
            values: {},
            errors: {},
            valids: {}
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        propsValues: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        masks() {
            let list = {};
            this.items.forEach((p) => {
                let mask = null;
                if (p.CODE == 'PHONE') {
                    mask = tools.maskPhone();
                }
                /*
                else if (p.CODE == 'EMAIL') {
                    mask = tools.maskEmail();
                }*/
                list[p.FIELD_NAME] = mask;
            });

            return list;
        }
    },
    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                //при показе попапа установим значения в те, что на основном экране
                this.setValues();
            }
        },
        items: function () {
            //при изменении списка св-в контактов
            //инициализируем внутренний список значений 
            this.init();
        },
        valids: {
            handler() {
                //при изменении значений снимим ошибки
                this.checkValues(false);
            },
            deep: true
        }

    },

    methods: {

        /**
         * инициализация
         */
        init() {
            this.values = {};
            this.errors = {};
            this.valids = {};
            this.items.forEach((p) => {
                this.values[p.FIELD_NAME] = "";
                this.errors[p.FIELD_NAME] = false;
                this.valids[p.FIELD_NAME] = false;
            });
        },

        /**
         * Устанавилвает значения св-во согласно переданным значениям
         */
        setValues() {
            this.items.forEach((p) => {
                if (p.FIELD_NAME in this.propsValues) {
                    this.values[p.FIELD_NAME] = this.propsValues[p.FIELD_NAME];
                }
            });

        },

        /**
         * Применить
         */
        apply() {
            if (!this.checkValues()) {
                return;
            }
            this.$emit("apply", this.values);
            this.visibleInternal = false;
        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            //для каждого свойства
            this.items.forEach((p) => {
                let error = !this.valids[p.FIELD_NAME];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[p.FIELD_NAME] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        }
    },

    created() {
        this.init();
    }
}
</script>

<style lang="scss">
.okraina-order-contacts {
    .items {
        margin-bottom: 30px;
    }
}
</style>