<template>
  <div class="okraina-smb">
    <swiper :modules="modules" :slidesPerView="1.1" :loop="true" :spaceBetween="8" :pagination="{
      clickable: true,
      dynamicBullets: false,
      bulletClass: 'bullet',
      bulletActiveClass: 'active',
    }" :autoHeight="true" :cssMode="false" class="mySwiper" :autoplay="{ delay: 4000 }" :breakpoints="breakpoints">
      <swiper-slide v-for="item in items" :key="item.FIELDS.ID">
        <div class="item" @click="onItemClickDelayed(item)" v-ripple>
          <div class="image preloaded" v-if="item.FIELDS.DETAIL_PICTURE"
            v-lazy:background-image="item.FIELDS.DETAIL_PICTURE.SRC" :style="{
      paddingTop:
        (parseInt(item.FIELDS.DETAIL_PICTURE.HEIGHT) /
          parseInt(item.FIELDS.DETAIL_PICTURE.WIDTH)) *
        100 +
        '%',
    }">
            <div class="loader">
              <okraina-loader-inline />
            </div>
          </div>
          <div class="image preloaded" v-else-if="item.FIELDS.PREVIEW_PICTURE"
            v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE.SRC" :style="{
      paddingTop:
        (parseInt(item.FIELDS.PREVIEW_PICTURE.HEIGHT) /
          parseInt(item.FIELDS.PREVIEW_PICTURE.WIDTH)) *
        100 +
        '%',
    }">
            <div class="loader">
              <okraina-loader-inline />
            </div>
          </div>

          <div class="countdown-time">
            <okraina-sliders-countdown-time v-if="item.PROPS.COUNTDOWN_COUNTER && item.FIELDS.ACTIVE_TO"
              :activeTo="item.FIELDS.ACTIVE_TO" />
          </div>


        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaSlidersCountdownTime from "@/components/sliders/countdown.time.vue";

import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import metrika from '@/plugins/yandex.metrika'

import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

export default {
  name: "okraina-sliders-main-banners",
  components: {
    Swiper,
    SwiperSlide,
    okrainaLoaderInline,
    okrainaSlidersCountdownTime,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      breakpoints: {
        600: {
          slidesPerView: 2.1
        }
      }
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {

    /**
     * Клик по баннеру с откладываение исполнения
     */
    onItemClickDelayed(item) {
      setTimeout(() => {
        this.onItemClick(item);
      }, 400);
    },

    /**
     * Клик по слайду
     */
    onItemClick(item) {

      //событие в ЯндексМетрику
      metrika.reachGoal("banner-" + item.FIELDS.ID);
      metrika.reachGoal("app-home-banner-click");

      //акция
      if (item.PROPS.TYPE.XML_ID == 'ACTION') {
        this.$router.push({ name: "actions-detail", params: { code: item.PROPS.ACTION_CODE } });
      }
      //товар 
      else if (item.PROPS.TYPE.XML_ID == 'CATALOG_PRODUCT') {
        bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
          id: item.PROPS.CATALOG_PRODUCT_ID,
          code: item.PROPS.CATALOG_PRODUCT_CODE
        });
      }
      //раздел каталога
      else if (item.PROPS.TYPE.XML_ID == 'CATALOG_SECTION') {
        this.$router.push({ path: item.PROPS.CATALOG_SECTION_URL });
      }
      //новость
      else if (item.PROPS.TYPE.XML_ID == 'NEWS') {
        this.$router.push({ path: "/about/news/" + item.PROPS.NEWS_CODE + "/" });
      }
      //пункт меню (новый)
      else if (item.PROPS.TYPE.XML_ID == "MENU_ITEM" && item.PROPS.NEW_MENU_ITEM_ID > 0) {
        this.$router.push({ path: item.PROPS.URL });
      }
      //иначе переход куда-то
      else {
        this.openExternalUrl(item);
      }
    },

    openExternalUrl(item) {
      //формируем URL
      let url = item.PROPS.URL;
      if (!url.startsWith("http")) {
        url = "https://" + this.$store.getters.getServer + url;
      }

      //парсим параметры (например переход к странице поиска)
      const [, paramString] = url.split('?');
      let urlParams = new URLSearchParams(paramString);
      let entries = urlParams.entries();
      let query = {};
      for (let [key, value] of entries) {
        query[key] = value;
      }

      //открываем браузер
      let color = tools.getCSSVariable('--color-background');
      this.paymentBrowser = InAppBrowser.create(url, "_system", {
        location: "yes",
        toolbarcolor: tools.convertHexColor(color)
      });

    }
  }
};
</script>

<style lang="scss">
.okraina-smb {
  padding-bottom: 30px;


  .swiper {
    margin: 0px calc(0px - var(--view-content-hor-padding));
    padding: 0px var(--view-content-hor-padding);

    --swiper-pagination-bottom: -20px;

    .bullet {
      display: inline-block;
      width: 11px;
      height: 3px;
      margin-right: 3px;
      background-color: var(--color-swiper-bullet);
      border-radius: 3px;
      transition: width 0.2s ease-out, background-color 0.2s ease-out;

      &.active {
        background-color: var(--color-swiper-bullet-active);
        width: 30px;
      }
    }

    .swiper-pagination-bullets-dynamic {
      .bullet {
        &.active-main {
          width: 30px;
        }
      }
    }
  }

  .item {
    cursor: pointer;

    .image {
      border-radius: 20px;
      background-size: cover;
      height: 0px;
      padding-top: 50%;
    }

    .countdown-time {
      position: absolute;
      right: 5%;
      bottom: 15%;
    }
  }
}
</style>
