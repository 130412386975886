<template>
    <div class="okraina-sr">

        <swiper :slidesPerView="'auto'" :loop="false" :cssMode="false" :spaceBetween="16">
            <swiper-slide v-for="item in items" :key="item.FIELDS.ID">
                <a class="item" v-ripple :href="item.FIELDS.DETAIL_PAGE_URL" @click.prevent="onItemClick(item)">

                    <div class="picture">

                        <div class="image preloaded" v-if="item.FIELDS.PREVIEW_PICTURE || item.FIELDS.DETAIL_PICTURE"
                            v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE ? item.FIELDS.PREVIEW_PICTURE.SRC : item.FIELDS.DETAIL_PICTURE.SRC">

                            <div class="preload"
                                :style="{ backgroundImage: 'url(\'' + (item.FIELDS.PREVIEW_PICTURE ? item.FIELDS.PREVIEW_PICTURE.PRELOAD : item.FIELDS.DETAIL_PICTURE.PRELOAD) + '\')' }">
                            </div>

                            <div class="loader">
                                <okraina-loader-inline />
                            </div>
                        </div>

                        <div class="shadow"></div>
                        <div class="time" v-if="item.PROPS.COOKING_TIME">{{ item.PROPS.COOKING_TIME }}</div>
                    </div>

                    <div class="inner">
                        <div class="name" v-html="item.FIELDS.NAME"></div>
                    </div>

                </a>
            </swiper-slide>
        </swiper>

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
    name: "okraina-sliders-recipes",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        onItemClick(item) {

            this.$router.push({ path: item.FIELDS.DETAIL_PAGE_URL });
        }
    }
}

</script>

<style lang="scss">
.okraina-sr {
    padding-top: 10px;
    margin-bottom: 30px;

    .swiper {
        margin: 0px calc(0px - var(--view-content-hor-padding, 24px));
        padding: 0px var(--view-content-hor-padding, 24px);
    }

    .swiper-slide {
        width: 160px;
    }

    .item {
        border-bottom: 0px;
        display: block;


        .picture {
            position: relative;
            height: 0px;
            padding-bottom: 72.22%;
            border-radius: 10px;
            overflow: hidden;

            .image {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;


                &.nophoto {
                    background-size: contain;
                }
            }

            .shadow {
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                height: 50%;

                background: linear-gradient(to top, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
                background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
                background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
            }

            .time {
                position: absolute;
                bottom: 10px;
                left: 15px;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0px;
            }

        }

        .inner {
            padding: 12px 0px 12px 0px;
        }

        .name {
            font-weight: 600;
            font-size: 13px;
            line-height: 1.1;

            color: var(--color-text-title);
        }

    }
}
</style>
