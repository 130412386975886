<template>
    <div class="okraina-accordion" :class="{ open: open }">
        <div class="clickable" @click="onClick">
            <div class="title">
                <slot name="title"></slot>
            </div>

            <div class="icon fi fi-arrow-down"></div>
        </div>

        <div class="collapsable">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "okraina-accordion",
    components: {

    },
    data() {
        return {
            open: false
        }
    },


    methods: {

        onClick() {
            this.open = !this.open;
        }

    },



}

</script>

<style lang="scss">
.okraina-accordion {

    background-color: var(--color-block-background);
    border-radius: 5px;

    margin-bottom: 10px;


    .clickable {
        position: relative;
        padding: 15px;

        padding-right: 50px;

        .title {
            font-weight: 600;
            font-size: 16px;
            color: var(--color-text-dark);
        }


        .icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            color: var(--color-text-gray);
            font-size: 12px;
        }
    }

    .collapsable {
        display: none;
        padding: 0px 15px 15px;
    }

    &.open {
        .collapsable {
            display: block;
        }
    }
}
</style>