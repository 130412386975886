<template>
    <okraina-view :class="'okraina-v-rd'" @refresh="refresh" ref="view" :hideMenu="true" :contentHorizontalPadding="16">

        <template v-slot:header>
            <h1>
                <span v-if="recipe" v-html="recipe.FIELDS.NAME"></span>
                <span v-else>Рецепт</span>

            </h1>
        </template>

        <template v-slot:header-right>
            <div class="share" @click="share">
                <span class="fi fi-share"></span>
            </div>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="!recipe">

                    <okraina-not-found caption="Акция не найдена" />
                </template>

                <template v-else>
                    <div class="row">
                        <div class="col-md-7 picture-col">

                            <!-- Фото-->
                            <div class="picture">
                                <div class="image preloaded" v-if="picture" v-lazy:background-image="picture.MIDDLE.SRC"
                                    @click="openDetailPhoto">
                                    <div class="preload"
                                        :style="{ backgroundImage: 'url(\'' + picture.MIDDLE.PRELOAD + '\')' }">
                                    </div>

                                    <div class="loader">
                                        <okraina-loader-inline />
                                    </div>
                                </div>
                                <div class="image nophoto" v-else
                                    :style="{ backgroundImage: 'url(' + imageNoPhoto + ')' }">
                                </div>
                            </div>

                            <!-- Время/порций-->
                            <div class="cooking-ports">
                                <div class="item" v-if="recipe.PROPS.COOKING_TIME">
                                    <span class="icon fi fi-fast"></span>
                                    <span class="label">
                                        Время
                                    </span>

                                    <span class="value">
                                        {{ recipe.PROPS.COOKING_TIME }}
                                    </span>
                                </div>
                                <div class="item" v-if="recipe.PROPS.NUMBER_OF_PORTS">
                                    <span class="icon fi fi-salver"></span>

                                    <span class="label">
                                        Порций
                                    </span>

                                    <span class="value">
                                        {{ recipe.PROPS.NUMBER_OF_PORTS }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <!-- Ингридиенты-->
                            <div class="okraina-block ingredients" v-if="recipe.PROPS.INGREDIENTS">

                                <h3 class="title">Ингредиенты</h3>
                                <template v-for="(item, index) in recipe.PROPS.INGREDIENTS" :key="index">

                                    <div class="item">
                                        <div class="name-wrapper">
                                            <span class="name">{{ item.VALUE }}</span>
                                            <div class="dots"></div>
                                        </div>
                                        <div>
                                            {{ item.DESCRIPTION }}
                                        </div>

                                    </div>

                                </template>
                            </div>
                        </div>
                    </div>

                    <!-- Способ приготовления-->

                    <template v-if="steps">
                        <h2>Способ приготовления</h2>
                        <div class="steps row">
                            <div class="col-sm-6 col-md-4 item-wrap" v-for="(step, index) in steps" :key="index">
                                <div class="item">
                                    <div class="photo" @click="openStepPhoto(step)">
                                        <div class="image preloaded" v-if="step.photo"
                                            v-lazy:background-image="step.photo.PREVIEW.SRC">
                                            <div class="preload"
                                                :style="{ backgroundImage: 'url(\'' + step.photo.PREVIEW.PRELOAD + '\')' }">
                                            </div>

                                            <div class="loader">
                                                <okraina-loader-inline />
                                            </div>
                                        </div>
                                        <div class="image nophoto" v-else
                                            :style="{ backgroundImage: 'url(' + imageNoPhoto + ')' }">
                                        </div>
                                    </div>
                                    <div class="description">
                                        <div v-html="step.description"></div>
                                        <div class="n">{{ index + 1 }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Товары -->

                    <template v-if="loadingProducts">
                        <div class="text-center">
                            <okraina-loader-inline />
                        </div>
                    </template>

                    <template v-else-if="products.length > 0">

                        <template v-for="group in productGroups" :key="group.name">

                            <okraina-slider-products v-if="group.products.length > 0" :items="group.products"
                                :title="group.name" />

                        </template>

                    </template>



                </template>

            </template>


        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaNotFound from "@/components/not.found.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaSliderProducts from "@/components/sliders/products.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from '@/plugins/tools.js'
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/clients.recipes.detail.js";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import imageNoPhotoPath from "@/assets/images/no-photo.svg";

export default {
    name: "okraina-v-recipes-detail",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaNotFound,
        okrainaLoaderInline,
        okrainaSliderProducts
    },
    data() {
        return {
            loading: true,
            loadingProducts: true,
            recipe: false,
            canShow: false,
            imageNoPhoto: imageNoPhotoPath,
            products: []
        };
    },

    props: {
        code: {
            type: String,
            default() {
                return "";
            },
        },
    },
    computed: {

        picture() {
            if (!this.recipe) {
                return false;
            }

            if (this.recipe.FIELDS.DETAIL_PICTURE) {
                return this.recipe.FIELDS.DETAIL_PICTURE;
            } else if (this.recipe.FIELDS.PREVIEW_PICTURE) {
                return this.recipe.FIELDS.PREVIEW_PICTURE;
            }

            return false;
        },

        /**
         * Шаги приготовления
         */
        steps() {
            let list = [];

            if (!this.recipe) {
                return list;
            }


            for (let i = 1; i <= 10; i++) {

                //если шага с таким номером нет
                if (!this.recipe.PROPS["STEP_" + i]) {
                    //то заканчиваем обход шагов
                    break;
                }

                //если описания шага нет
                if (!this.recipe.PROPS["STEP_" + i]["TEXT"]) {
                    continue;
                }

                let step = {
                    description: this.recipe.PROPS["STEP_" + i]["TEXT"],
                    photo: false,
                    n: list.length + 1
                }

                //фото
                if (this.recipe.PROPS["STEP_" + i + "_PHOTO"]) {
                    step.photo = this.recipe.PROPS["STEP_" + i + "_PHOTO"];
                }

                list.push(step);

            }

            return list;
        },

        /**
         * список фото для отображения в просмотре
         */
        gallery() {
            let images = this.steps.filter(item => item.photo).map((item) => {
                return {
                    caption: item.n + ". " + item.description,
                    src: item.photo.FULL.SRC,
                    type: "image",
                    width: item.photo.FULL.WIDTH,
                    height: item.photo.FULL.HEIGHT,
                    id: item.n,
                };
            });

            if (this.picture) {
                images.unshift({
                    caption: this.recipe.FIELDS.NAME,
                    src: this.picture.FULL.SRC,
                    type: "image",
                    width: this.picture.FULL.WIDTH,
                    height: this.picture.FULL.HEIGHT,
                    id: 0
                })
            }
            return images;
        },

        /**
         * Группы товаров 
         */
        productGroups() {
            if (!this.recipe || !this.recipe.PROPS.PRODUCT_GROUPS) {
                return [];
            }
            let list = this.recipe.PROPS.PRODUCT_GROUPS.map((item) => {
                return {
                    name: item.NAME,
                    ids: item.ROWS.map(row => row.PRODUCT),
                    products: []
                }
            });

            return list;
        },

        /**
         * Полный перечень ИД товаров
         */
        productIds() {
            let ids = [];
            this.productGroups.forEach(group => {
                ids = ids.concat(group.ids)
            });

            return ids;
        }
    },


    watch: {
        code: function () {
            this.loadData();
        },
        id: function () {
            this.loadData();
        },
        recipe: function () {
            this.setMeta();
        },
        productIds: function () {
            if (this.productIds.length > 0) {
                this.loadProducts();
            } else {
                this.loadingProducts = false;
            }
        }
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            this.loading = resolve ? false : true;


            rest
                .call("recipes.getbycode", {
                    method: "post",
                    data: {
                        CODE: this.code,
                        RESIZE_OPTIONS: {
                            PICTURE_MIDDLE: {
                                WIDTH: 660,
                                HEIGHT: 440,
                                TYPE: "EXACT",
                                PRELOAD: "Y"
                            },
                            PICTURE_FULL: {
                                WIDTH: 1000,
                                HEIGHT: 1000,
                                TYPE: "PROPORTIONAL",
                            },
                            STEP_PREVIEW: {
                                WIDTH: 450,
                                HEIGHT: 290,
                                TYPE: "EXACT",
                                PRELOAD: "Y"
                            },
                            STEP_FULL: {
                                WIDTH: 1000,
                                HEIGHT: 1000,
                                TYPE: "PROPORTIONAL",
                            },
                        },
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.recipe = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                }).finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Загрузка товаров
         */
        loadProducts() {
            this.loadingProducts = true;

            rest
                .call("catalog.products.getlist", {
                    method: "post",
                    data: {
                        FILTER: {
                            ID: this.productIds
                        },
                        ORDER: {
                            sort: "asc",
                        },
                        NAV_PARAMS: {
                            NUM_PAGE: 1,
                            PAGE_SIZE: this.productIds.length,
                            PAGES_COUNT: 1,
                        },
                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.products = data.ITEMS;
                        this.setProductsToGroups();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                }).finally(() => {
                    this.loadingProducts = false;
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Повторить загрузку
         */
        retry() {
            this.loadData();
        },

        /**
         * Устанавливает мета данные страницы
         */
        setMeta() {
            if (this.recipe) {
                meta.write(metaTemplates, this.recipe.META, this.recipe);
            }
        },

        /**
         * Открыть фото самого рецепта на просмотр
         */
        openDetailPhoto() {
            this.openPhoto(0);
        },

        /**
         * Открыть фото шага на просмотр
         */
        openStepPhoto(item) {
            let start = this.gallery.findIndex((i) => i.id == item.n);
            this.openPhoto(start);
        },

        /**
         * Открыть фотона просмотр
         */
        openPhoto(start) {

            let toolbar = [
                { id: "prev", position: "center" },
                { id: "counter", position: "center" },
                { id: "next", position: "center" },
            ]
            //увеличение для ПК версии не показываем
            if (window.innerWidth < 768) {
                toolbar.push("zoom");
            }
            toolbar.push("close");

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 1,
                infinite: false,
                Toolbar: {
                    display: toolbar,
                },
                Thumbs: {
                    autoStart: false,
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        tools.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        tools.fancyBoxOnClose();
                    },

                },
            });
        },

        /**
         * Распределяет товары по группам
         */
        setProductsToGroups() {

            this.products.forEach(item => {
                let group = this.productGroups.find(g => g.ids.includes(item.FIELDS.ID));
                if (group) {
                    group.products.push(item);
                }
            })
        },

        /**
        * Поделиться
        */
        share() {
            bus.emit("OKRAINA_APP_E_SHARE", {
                title: 'Поделиться',
                text: this.recipe.FIELDS.NAME,
                url: 'https://' + this.$store.getters.getServer + this.recipe.FIELDS.DETAIL_PAGE_URL,
                dialogTitle: 'Поделиться',
            });
        },
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setMeta();
        });
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-rd {

    h1 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .share {
        padding: 5px 10px;
        font-size: 16px;
        color: var(--color-icon-share);
    }


    /* Фото*/

    .picture {
        position: relative;
        height: 0px;
        padding-top: 66.66%;
        border-radius: 5px;
        margin-bottom: 15px;

        .image {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border-radius: 5px;

            &.nophoto {
                background-size: contain;
            }
        }
    }

    /* Ингридиенты*/

    .ingredients {
        border-radius: 5px;

        .title {
            margin-bottom: 20px;
        }

        .item {
            margin-bottom: 15px;
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: end;
            column-gap: 5px;

            .name-wrapper {
                position: relative;
            }

            .name {
                background-color: var(--color-block-background);
                padding-right: 5px;
                position: relative;
                z-index: 2;
            }

            .dots {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 4px;
                z-index: 1;
                height: 1px;
                background-image: linear-gradient(to left, rgba(102, 102, 102, 0) 80%, rgba(102, 102, 102, 1) 0%);
                background-position: bottom;
                background-size: 7px 1px;
                background-repeat: repeat-x;
            }
        }
    }

    /* Время/порций*/

    .cooking-ports {
        background: var(--color-color9);
        border-radius: 5px;
        padding: 30px 28px;
        margin: 15px 0px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            position: relative;
            float: left;
            margin-right: 50px;
            padding-left: 50px;

            &:last-of-type {
                margin-right: 0px;
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 0px;

                color: var(--color-color3);
                font-size: 35px;
                transform: translateY(-50%);
            }

            .label {
                color: var(--color-text-dark);
                margin-right: 10px;
            }

            .value {
                font-size: 16px;
                font-weight: 500;
                color: var(--color-text-dark);
            }
        }


        @media (min-width: 768px) and (max-width: 991px) {

            padding: 25px 20px;

            .item {
                margin-right: 25px;
                padding-left: 35px;

                .icon {
                    font-size: 25px;
                }

                .label {
                    margin-right: 5px;
                }

                .value {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 767px) {

            padding: 15px 15px;

            .item {
                margin-right: 15px;
                padding-left: 35px;


                .icon {
                    font-size: 25px;
                }

                .label {
                    margin-right: 5px;
                }

                .value {
                    font-size: 14px;
                }
            }


        }


    }


    /* Шаги приготовления */

    .steps {
        .item-wrap {
            margin-bottom: 16px;
        }

        .item {
            border-radius: 5px;
            background-color: var(--color-block-background);
            min-height: 100%;

            .photo {
                position: relative;
                height: 0px;
                padding-top: 63.88%;
                overflow: hidden;
                border-radius: 5px 5px 0px 0px;

                .image {

                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    border-radius: 5px;

                    &.nophoto {
                        background-size: contain;
                    }
                }
            }

            .description {
                padding: 15px 15px 15px 45px;
                position: relative;

                .n {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--color-text-dark);
                }
            }


        }
    }

}
</style>