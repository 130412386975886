import store from '@/store';

const processing = {
    getImage(product) {
        //фото товара
        if (product.FIELDS.DETAIL_PICTURE) {
            return product.FIELDS.DETAIL_PICTURE;
        } else if (product.FIELDS.PREVIEW_PICTURE) {
            return product.FIELDS.PREVIEW_PICTURE;
        }

        //доп фото
        if (product.PROPS.MORE_PHOTO && product.PROPS.MORE_PHOTO.length > 0) {
            
            return product.PROPS.MORE_PHOTO[0];
        }

        return false;
    },

    getOgImage(product) {
        let image = this.getImage(product)

        if(image) {
            return image.SRC;
        }
        return "https://"+store.getters.getServer+"/images/logo/logo-share.jpg";
    },

    getOgImageWidth(product) {
        let image = this.getImage(product)
        if(image) {
            return ""+image.WIDTH;
        }
        return "420";
    },

    getOgImageHeight(product) {
        let image = this.getImage(product)
        if(image) {
            return ""+image.HEIGHT;
        }
        return "420";
    },

    getOgDescription(product) {
        
        if(product.FIELDS.PREVIEW_TEXT && product.FIELDS.PREVIEW_TEXT.trim().length > 0) {
            return this.stripTags(product.FIELDS.PREVIEW_TEXT);
        }

        if(product.FIELDS.DETAIL_TEXT && product.FIELDS.DETAIL_TEXT.trim().length > 0) {
            return this.stripTags(product.FIELDS.DETAIL_TEXT);
        }

        return "";
    },

    stripTags(str) {
        return str.replace(/<\/?[^>]+>/gi, '')
    }
}

const templates =  {
    "shop.okraina.ru": {
        title: "ELEMENT_META_TITLE",
        tags: [
            {
                name: "description",
                content: "ELEMENT_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "ELEMENT_META_KEYWORDS",
            },
            {
                name: "og:type",
                content: "product"
            },
            {
                name: "og:image",
                content: (product) => {
                    return processing.getOgImage(product);
                }
            },
            {
                name: "og:image:width",
                content: (product) => {
                    return processing.getOgImageWidth(product);
                }
            },
            {
                name: "og:image:height",
                content: (product) => {
                    return processing.getOgImageHeight(product);
                }
            },
            {
                name: "og:title",
                content: (product) => {
                    return product.FIELDS.NAME;
                }
            },
            {
                name: "og:description",
                content: (product) => {
                    return processing.getOgDescription(product);
                }
            },
        ]
    },
    "dostavka.okraina.ru": {
        title: "ELEMENT_META_TITLE",
        tags: [
            {
                name: "description",
                content: "ELEMENT_META_DESCRIPTION",
            },
            {
                name: "keywords",
                content: "ELEMENT_META_KEYWORDS",
            },
            {
                name: "og:type",
                content: "product"
            },
            {
                name: "og:image",
                content: (product) => {
                    return processing.getOgImage(product);
                }
            },
            {
                name: "og:image:width",
                content: (product) => {
                    return processing.getOgImageWidth(product);
                }
            },
            {
                name: "og:image:height",
                content: (product) => {
                    return processing.getOgImageHeight(product);
                }
            },
            {
                name: "og:title",
                content: (product) => {
                    return product.FIELDS.NAME;
                }
            },
            {
                name: "og:description",
                content: (product) => {
                    return processing.getOgDescription(product);
                }
            },
        ]
    },
}
export default templates;