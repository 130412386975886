<template>
    <div class="okraina-cil">
        <div class="cashback">
            <okraina-catalog-product-cashback :item="item" />
        </div>
        <div class="fav">
            <okraina-catalog-product-favorite-btn :productId="item.FIELDS.ID" />
        </div>

        <div class="photo-wrap">
            <okraina-catalog-product-photo :item="item" @click="goToProduct" :big="true" />
            <okraina-catalog-product-not-available v-if="!canBuy && item.PRICE" :item="item" />

        </div>
        <div class="right-cell">

            <div class="name" @click="goToProduct">
                {{ item.FIELDS.NAME }}
            </div>

            <div class="price-basket" v-if="item.PRICE">
                <div class="item-price">
                    <okraina-catalog-product-price :item="selected" v-if="item.PRICE" :isRifling="isRiflingSelected" />
                </div>
                <div class="basket">

                    <template v-if="canBuy">
                        <okraina-catalog-product-buy-btn :productId="basketData.productId" :params="basketData.params"
                            :checkQuantity="checkQuantity" :maxQuantity="maxQuantity"
                            :maxQuantityMessage="maxQuantityMessage" :recommendationId="recommendationId"
                            :listMode="true" :hasRifling="hasRifling" @openRiflingSelect="openRiflingSelect" />
                    </template>
                    <template v-else-if="canNeedMore">
                        <okraina-catalog-product-needmore-btn :productId="item.FIELDS.ID" v-model="inNeedMoreList" />
                    </template>
                    <template v-else-if="canSubscribe">
                        <okraina-catalog-product-subscribe-btn :productId="item.FIELDS.ID" v-model="subscribed"
                            :small="true" />
                    </template>

                </div>
            </div>

        </div>
    </div>

    <okraina-catalog-product-rifling-select v-if="hasRifling" v-model:visible="showRiflingSelect" :item="item"
        :recommendationId="recommendationId" />
</template>

<script>
import okrainaCatalogProductCashback from "@/components/catalog/product.cashback.vue";
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";
import okrainaCatalogProductBuyBtn from "@/components/catalog/product.buy.btn.vue";
import okrainaCatalogProductSubscribeBtn from "@/components/catalog/product.subscribe.btn.vue";
import okrainaCatalogProductNotAvailable from "@/components/catalog/product.notavailable.vue";
import okrainaCatalogProductRiflingSelect from "@/components/catalog/product.rifling.select.vue";
import okrainaCatalogProductFavoriteBtn from "@/components/catalog/product.favorite.btn.vue";
import okrainaCatalogProductNeedmoreBtn from "@/components/catalog/product.needmore.btn.vue";

import okrainaCatalogItemMixin from "@/mixins/catalog.item.js"

import bus from "@/plugins/bus";

export default {
    name: "okraina-catalog-item-line",
    mixins: [okrainaCatalogItemMixin],
    components: {
        okrainaCatalogProductCashback,
        okrainaCatalogProductPhoto,
        okrainaCatalogProductPrice,
        okrainaCatalogProductBuyBtn,
        okrainaCatalogProductSubscribeBtn,
        okrainaCatalogProductNotAvailable,
        okrainaCatalogProductRiflingSelect,
        okrainaCatalogProductFavoriteBtn,
        okrainaCatalogProductNeedmoreBtn
    },
    data() {
        return {
            subscribed: this.item.CATALOG_SUBSCRIBE.SUBSCRIBED == "Y",
            inNeedMoreList: this.item.NEED_MORE && this.item.NEED_MORE.IN_LIST == "Y",
            showRiflingSelect: false
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        },

        recommendationId: {
            type: [String, Number],
            default() {
                return "";
            }
        }
    },
    computed: {
        //см миксин okrainaCatalogItemMixin
    },
    methods: {
        goToProduct() {
            bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                id: this.item.FIELDS.ID,
                code: this.item.FIELDS.CODE,
                recommendationId: this.recommendationId
            });

        },

        openRiflingSelect() {
            this.showRiflingSelect = true;
        }

    },

    created() { },
};
</script>

<style lang="scss">
.okraina-cil {
    position: relative;
    margin-bottom: 8px;
    background-color: var(--color-block-background);
    border-radius: 5px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 29vw 1fr;
    align-items: center;

    .cashback {
        position: absolute;
        top: -2px;
        left: 0px;
        z-index: 20;
    }

    .photo-wrap {
        position: relative;
        cursor: pointer;
    }

    .right-cell {
        padding: 8px 8px 8px 0px;
    }

    .fav {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .name {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-text-dark);
        margin-right: 35px;
        cursor: pointer;
    }

    .price-basket {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 100px;
        align-items: center;
    }

    .basket {
        text-align: right;
    }



}
</style>
