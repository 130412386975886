<template>
    <okraina-view :class="'okraina-v-pi'" @refresh="refresh" :hideMenu="true" :contentHorizontalPadding="0">
        <template v-slot:header>
            <h1>
                Пригласить друга
            </h1>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">
                <okraina-illustration :picture="imageError" caption="Ошибка загрузки данных.">
                    <div class="btn btn1 slim" @click="retryLoad">
                        Повторить
                    </div>
                </okraina-illustration>
            </template>

            <template v-if="!loading && !loadingError && canShow">

                <div class="block">

                    <div class="spacer"></div>

                    <div class="description">
                        Разместите вашу ссылку в социальных сетях или в любом другом месте, отправьте в мессенджер или на эл
                        почту. Каждый раз, когда приглашенные вами пользователи делают заказ, Вы получаете бонусные баллы.
                        Подробные условия читайте в <a href="/personal/invite/terms/" @click.prevent="goToTerms">Условиях
                            программы.</a>
                    </div>

                    <div class="links-wrap">
                        <h2>Поделиться ссылкой</h2>

                        <div class="links">
                            <div class="link" @click="copy">
                                <span class="icon fi fi-copy"></span>
                            </div>
                            <div class="link" @click="share">
                                <span class="icon fi fi-share"></span>
                            </div>
                        </div>
                    </div>

                    <h2>Пригласить друзей по электронной почте</h2>

                    <okraina-control-textarea label="Кому" :required="true" v-model="values.EMAILS"
                        v-model:error="errors.EMAILS" v-model:valid="valids.EMAILS" :rows=2 placeholder="E-mail"
                        description="E-mail адреса друзей через запятую (не более 10 адресатов)" />

                    <div class="btn btn-block btn1 btn-send" v-ripple :class="[saving ? 'wait' : '']" @click="send">
                        Отправить
                        <div v-if="saving" class="btn-loader"></div>
                    </div>

                </div>

                <div class="history">
                    <h2>Приглашённые друзья</h2>

                    <okraina-personal-invite-history v-if="!this.refreshing" ref="history" />
                </div>


            </template>

        </template>
    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaIllustration from "@/components/illustration.vue";
import okrainaControlTextarea from "@/components/controls/textarea.vue";
import okrainaPersonalInviteHistory from '@/components/personal/invite.history.vue'

import imageErrorPath from "@/assets/images/error.svg";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/personal.invite.js";

export default {
    name: "okraina-v-personal-invite",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaIllustration,
        okrainaControlTextarea,
        okrainaPersonalInviteHistory
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            refreshing: false,
            saving: false,
            link: "",
            values: {
                TEXT: "",
                EMAILS: ""
            },
            errors: {},
            valids: {},

            imageError: imageErrorPath,
            canShow: false
        };
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            if (this.loading) {
                return;
            }
            this.loading = resolve == false ? true : false;

            rest
                .call("invites.send.info", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    this.loadingError = !data.SUCCESS;
                    if (data.SUCCESS) {

                        this.values.TEXT = data.LETTER_TEXT;
                        this.values.EMAILS = "";
                        this.link = data.LINK;

                        this.resetValues();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.refreshing = false;
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });

        },

        /**
         * Сброс полей
         */
        resetValues() {

            for (var code in this.values) {
                this.valids[code] = true;
                this.errors[code] = false;
            }
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.refreshing = true;
            this.loadData(resolve);
        },

        /**
         * Переход к правилам
         */
        goToTerms() {
            this.$router.push({ path: "/personal/invite/terms/" });
        },

        /**
         * Поделиться ссылкой
         */
        share() {
            bus.emit("OKRAINA_APP_E_SHARE", {
                title: 'Поделиться',
                text: this.values.TEXT,
                url: this.link,
                dialogTitle: 'Поделиться',
            });

        },

        /**
         * Скопировать в буфер
         */
        copy() {

            bus.emit("OKRAINA_CLIPBOARD_E_WRITE", {
                text: this.link,
                successMessage: 'Ссылка скопирована.',
            });


        },

        /**
         * Отправка приглашений
         */
        send() {

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            rest
                .call("invites.send", {
                    method: "post",
                    data: {
                        EMAILS: this.values.EMAILS,
                    },
                })
                .then((data) => {
                    if (data.ERROR_TEXT) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Приглашения отправлены.", type: "success" });

                        //перезагружаем историю
                        this.$refs.history.reload();

                    }
                })
                .finally(() => {
                    this.saving = false
                });


        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            for (var code in this.values) {
                let error = !this.valids[code];
                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[code] = error;
                }
            }

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-pi {

    .block {
        padding: 0px 19px 20px;
        margin-bottom: 25px;
        background-color: var(--color-block-background);
    }

    .description {
        font-size: 12px;
        margin-bottom: 20px;
        color: var(--color-text-description);
    }

    .links {
        margin-bottom: 25px;

        &::after {
            content: "";
            display: block;
            clear: both;
        }


        .link {
            position: relative;
            float: left;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            background-color: rgba(var(--color-color1-rgb), 0.05);

            &:last-of-type {
                margin-right: 0px;
            }

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 17px;
                color: var(--color-color1);
            }
        }
    }

    .history {
        padding: 0px 19px;
    }


}
</style>