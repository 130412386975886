<template>
    <div class="okraina-control-ddt form-group" :class="{
        'form-group-error': error
    }">

        <div class="label">
            {{ prop.NAME }}
            <span v-if="prop.REQUIED == 'Y'" class="required">*</span>
        </div>

        <div class="items">
            <div class="item" :class="{ selected: value == item.ID }" v-for="item in list" :key="item.ID"
                @click="onItemClick(item)">
                {{ item.NAME }}

            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "okraina-control-delivery-desired-time",
    components: {
    },
    emits: ["update:modelValue", "update:valid", "update:error", "select"],
    data() {
        return {
            value: this.modelValue,
        };
    },
    props: {
        prop: {
            type: Object,
            default() {
                return {};
            },
        },
        modelValue: {
            type: String,
            default() {
                return "";
            },
        },

        error: {
            type: Boolean,
            default() {
                return false;
            },
        },

        valid: {
            type: Boolean,
            default() {
                return true
            }
        },
    },
    computed: {
        list() {
            let list = [];

            this.prop.VARIANTS.forEach(v => {
                list.push({
                    ID: v.VALUE,
                    NAME: v.NAME
                });
            });

            return list;
        },

    },
    watch: {
        modelValue: function (newVal) {
            if (newVal !== this.value) {
                this.value = newVal;
            }
        },
        value: function () {
            this.checkValid();
            if (this.modelValue !== this.value) {
                this.$emit("update:modelValue", this.value);
                this.$emit("select");
            }
        },
        valid: function () {
            if (this.valid) {
                this.$emit("update:error", false);
            }
        }
    },

    methods: {
        onItemClick(item) {
            this.value = item.ID
        },

        /**
         * Проверяет валидно ли значение
         */
        checkValid() {

            if (this.prop.REQUIED == 'Y' && !(this.value)) {
                this.$emit("update:valid", false);
                return;
            }

            this.$emit("update:valid", true);
        },
    },

    mounted() {
        this.checkValid();
    },
}
</script>


<style lang="scss">
.okraina-control-ddt {
    .items {
        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            position: relative;
            display: inline-block;
            border-radius: 24px;
            font-weight: 500;
            color: var(--color-text-dark);
            line-height: 1;
            padding: 9px 12px;
            background-color: var(--color-color9);
            margin-right: 8px;
            margin-bottom: 8px;

            &.selected {
                background-color: var(--color-btn1-background);
                color: var(--color-btn1-text);
            }
        }
    }
}
</style>

