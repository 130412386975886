<template>
  <div class="okraina-cp-price"
    :class="{ detail: detail, 'in-buy-btn': inBuyBtn, 'show-old-price-top': showOldPriceTop }">
    <div class="old-price-wrap" v-if="price.DISCOUNT_VALUE < price.VALUE">
      <span class="old-price" v-html="tools.formatPrice(price.VALUE * quantity, {
        supDecimals: false,
        supCurrency: false,
      })
        "></span>
      <span v-if="showDiscountPercent && price.DISCOUNT_DIFF_PERCENT > 0" class="percent">
        -{{ price.DISCOUNT_DIFF_PERCENT
        }}%
      </span>
    </div>

    <div class="back-wrap" :class="{ yellow: price.YELLOW_PRICE && !disableYellowPrice }">
      <div class="price" :class="{ discount: price.DISCOUNT_VALUE < price.VALUE }"
        v-html="tools.formatPrice(price.DISCOUNT_VALUE * quantity)"></div>
      <div class="measure" v-if="showMeasure">
        <template v-if="isRifling">за вес</template>
        <template v-else>за шт</template>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/plugins/tools";

export default {
  name: "okraina-catalog-product-price",
  components: {},
  data() {
    return {
      tools: tools,
    };
  },
  props: {
    disableYellowPrice: {
      type: Boolean,
      default() {
        return false;
      }
    },
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    isRifling: {
      type: Boolean,
      default() {
        return false;
      },
    },
    detail: {
      type: Boolean,
      default() {
        return false;
      },
    },
    inBuyBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showMeasure: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showDiscountPercent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showOldPriceTop: {
      type: Boolean,
      default() {
        return false;
      },
    },
    quantity: {
      type: Number,
      default() {
        return 1;
      },
    }
  },
  computed: {
    price() {
      return this.item.PRICE;
    },
  },
  methods: {
    format(price) {
      return tools.formatPrice(price);
    },
  },

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-price {
  position: relative;
  display: inline-block;

  .old-price-wrap {
    position: absolute;
    left: 100%;
    padding-left: 5px;
    white-space: nowrap;

    .okraina-price {
      font-weight: 500;
      font-size: 9px;
      color: var(--color-text-gray);
      text-decoration: line-through;
    }

    .percent {
      font-weight: 600;
      margin-left: 10px;
      color: var(--color-color4);
    }
  }

  .back-wrap.yellow {
    background-color: var(--color-icon-star);
    padding: 2px 5px;

    .measure {
      position: relative;
    }

    .price {
      .okraina-price {
        color: var(--color-text-dark);
      }
    }

  }

  .price.discount {
    .okraina-price {
      color: var(--color-color4);
    }

  }

  .measure {
    position: absolute;
    top: 100%;
    margin-top: -5px;
    font-size: 9px;
  }

  &.detail {

    .okraina-price {
      font-size: 26px;
    }

    .old-price-wrap {
      .okraina-price {
        font-size: 14px;
      }
    }

    .measure {
      font-size: 12px;
    }
  }

  &.in-buy-btn {
    .old-price-wrap {
      .okraina-price {
        font-size: 12px;
      }
    }
  }

  &.show-old-price-top {

    .old-price-wrap {
      left: 0px;
      padding-left: 0px;
      bottom: 100%;
      line-height: 1;
    }

  }
}
</style>
