<template>
    <okraina-view :class="'okraina-v-region'" :refreshDisabled="true" ref="view" :hideMenu="true">
        <template v-slot:content>

            <div class="region-wrap">

                <template v-if="loading">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>


                </template>
                <template v-else>

                    <h1 class="text-center">Выберите Ваш регион</h1>

                    <div class="items">
                        <div class="item-wrap" v-for="item in regions" :key="item.FIELDS.ID">
                            <div class="item" @click="onItemClick(item)">
                                <div class="picture"
                                    :style="{ 'background-image': 'url(\'' + item.FIELDS.PREVIEW_PICTURE.SRC + '\')' }">
                                </div>
                                <div class="name">{{ item.FIELDS.NAME }}</div>
                            </div>
                        </div>

                    </div>

                </template>

            </div>

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import { mapGetters } from "vuex";

import tools from "@/plugins/tools";
import bus from "@/plugins/bus";
import metrika from '@/plugins/yandex.metrika'

export default {
    name: "okraina-v-region",
    components: {
        okrainaView,
        okrainaLoaderInline
    },
    data() {
        return {
            loading: true
        };
    },
    computed: {
        ...mapGetters({
            regions: "getRegions"
        }),

    },
    watch: {
        regions: function () {
            this.preloadPictures();
        },
    },

    methods: {

        /**
         * Подгружает фото регионов заранее
         */
        preloadPictures() {

            let images = this.regions.map(r => r.FIELDS.DETAIL_PICTURE.SRC);

            tools.preloadImages(images).then(() => {
                this.loading = false;
            });
        },

        /**
         * Клик по региону
         */
        onItemClick(item) {
            this.show = false;

            //отпишемся от рассылок на старом домене
            bus.emit("OKRAINA_PUSH_E_UNSUBSCRIBE", true);

            //устанавливаем подключение текущим
            this.$store.commit("setCurrentConnection", item.PROPS.DOMAIN);

            //загрузим данные пользователя, даже если он не авторизован
            this.$store.dispatch("loadUserData");
            //загрузим настройки сайта
            this.$store.dispatch("loadSiteSettings");

            //подпишемся на рассылок на новом домене
            bus.emit("OKRAINA_PUSH_E_SUBSCRIBE", true);

            //для сброса кеша экранов
            this.$store.commit("setConnectionSwitching", true);
            setTimeout(() => {
                this.$store.commit("setConnectionSwitching", false);
                //переход на главный экран
                this.$router.replace({ name: "home" });
            }, 100);

            //яндекс метрика
            metrika.init();
        },


    },

    created() {
        if (this.regions && this.regions.length > 0) {
            this.preloadPictures();
        }
    }

}

</script>

<style lang="scss">
.okraina-v-region {

    .region-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 280px;
    }

    h1 {
        margin-bottom: 20px;
    }

    .items {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 16px;
    }

    .item {
        max-width: 150px;
        margin: 0px auto;
        margin-bottom: 10px;

        background-color: var(--color-block-background);
        padding: 10px;
        border-radius: 8px;



        .picture {
            height: 70px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin-bottom: 10px;


        }

        .name {
            text-align: center;
            font-weight: 500;
            min-height: 40px;
        }

    }

}
</style>