<template>
    <div class="okraina-upm">
        <div class="user-info">
            <div class="group-personal-data" @click="goToProfile">
                <div class="data">
                    <div class="greeting">Привет,</div>
                    <div v-if="user.NAME || user.LAST_NAME" class="user-name">{{ user.NAME }} {{ user.LAST_NAME }}<span
                            class="editing icon fi fi-pencil"></span></div>
                    <div v-else class="user-name">{{ user.LOGIN }}<span class="editing icon fi fi-pencil"></span></div>
                </div>
            </div>
            <div class="wrapper-level-bonus">
                <div class="bonus-level-line"></div>

                <div class="bonus-level-line-filled" v-if="cashback.PROGRESS != 0 && cashback.PROGRESS < 90"
                    v-bind:style="{ width: cashback.PROGRESS + '%' }"></div>

                <div class="bonus-level-line-filled start"></div>

                <div class="bonus-level-line-filled finish" v-if="cashback.PROGRESS >= 90"></div>

                <div class="level-radius" v-for="n in cashback.LEVELS" :key="n.LEVELS"
                    :style="{ width: cashback.WIDTH_LINE + '%' }"><span>{{ n }}</span>
                </div>

            </div>
            <div class="user-bonus">
                <div class="get-bonus">
                    <span>Баланс:</span>
                    <span class="bonus">{{ Math.floor(bonus) }}</span>
                    <!-- <div class="icon-inner"> -->
                    <span class="icon fi fi-money"></span>
                    <!-- </div> -->
                </div>
                <div class="cashback-procent">
                    <div class="wrapper-procent">
                        <div class="text-block">
                            <span><span class="min-xs">Мой</span> кешбэк:</span>
                            <span class="cashback"><span class="value">{{ cashback.PERCENT }}</span>%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "okraina-user-profile-menu",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            bonus: "getBonus",
            user: "getUser",
            cashback: "getCashback",
        }),
    },

    methods: {

        /**
         * Переход к настройкам профиля
         */
        goToProfile() {
            this.$router.push({ name: "profile" });
        }
    }

}
</script>
<style lang="scss">
.okraina-upm {
    .user-info {
        padding: 8px;
        z-index: 1;
        position: relative;

        .group-personal-data {
            display: flex;
            align-items: center;
            position: relative;

            .img {
                width: 50px;
                height: 50px;
                background-size: auto 100%;
                border-radius: 50%;
            }

            .data {

                .greeting,
                .user-name {
                    margin-bottom: 0px;
                    word-break: break-all;
                    padding-right: 30px;

                    .editing {
                        position: absolute;
                        right: 0;
                        color: var(--color-color1);
                        padding-right: 5px;
                        font-size: 17px;
                    }
                }

                .greeting {
                    font-size: 16px;
                }

                .user-name {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }

        .bonus-level-line,
        .user-bonus,
        .bonus-level-line-filled {
            margin-top: 15px;
            margin-bottom: 15px;

        }

        .wrapper-level-bonus {
            position: relative;
            display: flex;
            margin-bottom: 20px;
            margin-top: 15px;

            .bonus-level-line,
            .bonus-level-line-filled {
                position: absolute;
                border: 6px solid #EDEDED;
                border-radius: 7px;
                width: 99%;
                top: -50%;
                z-index: -1;
            }

            .bonus-level-line-filled {
                border-color: var(--color-color2);
            }

            .bonus-level-line-filled.start {
                width: 29%;
            }

            .bonus-level-line-filled.finish {
                width: 99%;
            }

            .level-radius {
                span {
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #FFF;
                    color: var(--color-color2);
                    box-shadow: 0px 0px 6.95849px rgba(39, 39, 39, 0.2);
                    border-radius: 80px;
                    width: 22px;
                    height: 22px;
                    margin-left: 70%;
                    line-height: 1;
                }
            }

            .level-radius~.level-radius {
                span {
                    margin-left: 65%;
                }
            }

            .level-radius:last-of-type {
                span {
                    margin-left: 50%;
                }
            }
        }

        .user-bonus {
            display: flex;
            margin: auto;

            .wrapper-procent {
                background: var(--color-view-background);
                border: none;
                margin: 2px;
                width: 100%;
                padding: 0;
                border-radius: 5px;

                .text-block {
                    width: 100%;
                    border: none;
                    background: linear-gradient(90.07deg, #76B82B 0.06%, #00A0E3 98.88%, #A3D800);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    border-radius: 0px;
                }
            }

            div {
                display: flex;
                align-items: center;
                padding: 0px 11px;
                border: 2.5px solid var(--color-color2);
                border-radius: 7px;
                margin: 0 5px;
                flex-basis: 100%;
            }

            span {
                padding: 0 5px 0 0;
                white-space: nowrap;
                font-weight: 600;
                font-size: 13px;
            }

            span.min-xs {
                padding: 0;
            }

            @media (max-width: 360px) {
                span.min-xs {
                    display: none;
                }
            }

            span.bonus {
                margin-left: auto;
            }

            .get-bonus span {
                color: var(--color-color2);
                font-weight: 600;
            }

            .get-bonus span.bonus {
                font-size: 19px;
            }

            .cashback-procent {
                border: none;
                position: relative;
                background: linear-gradient(90.07deg, #76B82B 0.06%, #00A0E3 98.88%, #A3D800);
                padding: 0px;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                div.text-block {
                    margin: 0;
                }

                .cashback {
                    font-weight: 600;
                    font-size: 15px;
                    margin-left: auto;

                    .value {
                        padding: 0px;
                        font-size: 19px;
                    }
                }

            }

            .cashback-procent:before {
                content: "";
                position: absolute;
                z-index: -1;
                inset: 0;
                padding: 2px;
                border-radius: 7px;
                background: linear-gradient(90.07deg, #76B82B 0.06%, #00A0E3 98.88%, #A3D800);
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                z-index: 1;
            }
        }
    }
}
</style>