<template>
    <okraina-view :class="'okraina-v-ppush'" :refreshDisabled="true" ref="view" :hideMenu="true"
        :contentHorizontalPadding="32">

        <template v-slot:header>
            <h1>
                Настройки
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <template v-if="!loading">
                <okraina-control-checkbox label="Получать информационные сообщения" value="Y" defaultValue="N"
                    v-model="enabled" />


                <div class="label">Интервал "тихих" уведомлений</div>
                <div class="interval">
                    <div>с</div>
                    <div>
                        <okraina-control-time label='' v-model="from" :hideClear="true" />
                    </div>
                    <div>
                        по
                    </div>
                    <div>
                        <okraina-control-time label='' v-model="to" :hideClear="true" />
                    </div>
                    <div></div>
                </div>

            </template>



        </template>


    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaControlCheckbox from "@/components/controls/checkbox.vue";
import okrainaControlTime from "@/components/controls/time.vue";

import storage from '@/plugins/storage'

export default {
    name: "okraina-v-personal-push",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaControlCheckbox,
        okrainaControlTime
    },
    data() {
        return {
            loading: true,
            enabled: "Y",
            from: "00:00",
            to: "08:00"
        };
    },

    watch: {
        enabled: function () {
            if (!this.loading) {
                storage.set("push_info_enabled", this.enabled);
            }
        },
        from: function () {
            if (!this.loading) {
                if (this.from) {
                    storage.set("push_silent_from", this.from);
                } else {
                    this.from = "00:00";
                }

            }
        },
        to: function () {
            if (!this.loading) {
                if (this.to) {
                    storage.set("push_silent_to", this.to);
                } else {
                    this.to = "00:00";
                }

            }
        },

    },

    methods: {

        async loadData() {

            this.loading = true;

            let enabled = await storage.get("push_info_enabled");
            if (enabled == "N") {
                this.enabled = "N";
            }

            let from = await storage.get("push_silent_from");
            if (from) {
                this.from = from;
            }

            let to = await storage.get("push_silent_to");
            if (to) {
                this.to = to;
            }

            this.loading = false;


        }

    },

    created() {
        this.loadData();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        //meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-ppush {
    .view-outer {
        background-color: var(--color-block-background);
    }

    .label {
        margin-bottom: 10px;
    }

    .interval {
        display: grid;
        column-gap: 15px;

        grid-template-columns: auto 100px auto 100px 1fr;
        align-items: center;

        .form-group {
            margin-bottom: 0px;
        }
    }
}
</style>