<template>
    <div class="item okraina-a-cbp col-12 col-sm-12 col-md-6 col-lg-4">
        <div class="okraina-block">
            <div class="block-title name" v-html="params.NAME"></div>
            <div class="block-description description" v-html="params.DESCRIPTION"></div>

            <a class="value" :href="'tel:' + params.VALUE" target="_blank">{{ params.VALUE }}</a>
        </div>


    </div>
</template>

<script>

export default {
    name: "okraina-about-contacts-block-phone",
    props: {
        params: {
            type: Object,
            default() {
                return {};
            },
        },

    },
}
</script>

<style lang="scss"></style>