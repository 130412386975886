<template>
    <okraina-view :class="'okraina-v-auth-register'" :refreshDisabled="true" :hideMenu="true"
        :contentHorizontalPadding="32">

        <template v-slot:header>
            <h1>
                Регистрация
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <okraina-register />

        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaRegister from "@/components/auth/register.vue";

import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/auth.register.js";

export default {
    name: "okraina-v-auth-register",
    components: {
        okrainaView,
        okrainaRegister,
    },
    data() {
        return {
        };
    },
    computed: {},
    methods: {

    },


    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);

    },

};
</script>

<style lang="scss">
.okraina-v-auth-register {
    .view-outer {
        background-color: var(--color-block-background);
    }

    .view-content {
        padding-top: 15px;
    }

}
</style>