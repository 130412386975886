<template>
  <div class="okraina-c-r" v-if="count > 0 || items.length > 0">

    <h2>{{ title }}</h2>

    <template v-if="!slider">
      <okraina-catalog-list :filter="productFilter" :recommendationId="recommendationId" />
    </template>
    <template v-else>
      <okraina-slider-products :items="items" :recommendationId="recommendationId" />
    </template>

  </div>
</template>

<script>
import okrainaCatalogList from "@/components/catalog/list.vue";
import okrainaSliderProducts from "@/components/sliders/products.vue";

import rest from "@/plugins/rest";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
  name: "okraina-catalog-recommendations",
  components: {
    okrainaCatalogList,
    okrainaSliderProducts
  },
  data() {
    return {
      timer: false,
      attempt: 1,
      ids: [],
      items: [],
      count: 0,
      recommendationId: ""
    };
  },
  props: {
    refreshIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    slider: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return "Подборка для Вас";
      },
    },

  },
  computed: {
    ...mapGetters({
      siteSettings: "getSiteSettings",
      bigDataUserId: "bigdata/userId"
    }),
    productFilter() {
      return {
        ID: this.ids,
        CATALOG_AVAILABLE: "Y",
        PROPERTY_TEMPORARILY_NOT_AVAILABLE: false,
      };
    },
  },
  watch: {
    bigDataUserId: function () {
      this.loadRecommendations();
    },
    refreshIndex: function () {
      this.loadRecommendations();
    },
  },
  methods: {

    /**
     * ЗЩагружает список рекомендаций из сервиса BigData Bitrix
     */
    loadRecommendations() {

      this.count = 0;
      this.ids = [];

      if (!this.bigDataUserId) {
        return;
      }

      //запрашивает даные
      rest
        .call("system.recoms", {
          method: "post",
          data: {
            DATA: {
              uid: this.bigDataUserId,
              count: 30,
              op: "recommend",
            },
            USER_AGENT: window.navigator.userAgent
          },
        },
          true // //тихий режим - вместо отображения сообщения об ошибки генерит ошибку
        )
        .then((data) => {
          if (data.SUCCESS && data.items && data.items.length > 0) {
            this.ids = data.items;
            this.recommendationId = data.id;

            if (!this.slider) {
              this.loadCount();
            } else {
              this.loadProducts();
            }
          }

        }).catch(() => {
          //ничего не делаем, бывает что сервис тормозит или не работает
        });
    },

    /**
     * Загружает кол-во товаров по фильтру
     */
    loadCount() {
      //запрашивает даные
      rest
        .call("catalog.products.getcount", {
          method: "post",
          data: {
            FILTER: this.productFilter,
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            this.count = data.COUNT;
          }
        });
    },

    /**
     * Загружает товары по фильтру
     */
    loadProducts() {

      tools.for
      //запрашивает даные
      rest
        .call("catalog.products.getlist", {
          method: "post",
          data: {
            FILTER: this.productFilter,
            NAV_PARAMS: {
              NUM_PAGE: 1,
              PAGE_SIZE: 20,
              PAGES_COUNT: 1,
            },
            RESIZE_OPTINS: tools.getProductListResizeOptions()
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            this.items = data.ITEMS;
          }
        });
    }
  },

  created() {
    if (this.bigDataUserId) {
      this.loadRecommendations();
    }
  },
};
</script>

<style lang="scss"></style>
