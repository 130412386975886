<template>
  <div class="okraina-message" :class="[visible ? 'show' : '', type == 'success' ? 'success' : 'error']"
    @click.prevent="onClick">
    <div class="message" v-html="message"></div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
  name: "okraina-message",
  data() {
    return {
      message: "",
      visible: false,
      timer: 0,
      type: "error",
    };
  },
  computed: {
    ...mapGetters({
      platform: "getPlatform"
    }),
  },
  methods: {
    show(params) {
      if (this.timer > 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }

      this.message = params.message;
      this.visible = true;

      if (params.type) {
        this.type = params.type;
      } else {
        this.type = "error";
      }

      this.timer = setTimeout(this.hide, params.timeout ?? 3000);
    },

    hide() {
      if (this.timer > 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }

      this.visible = false;
    },

    onClick(e) {

      this.hide();

      //если клик по ссылке
      if (e.target.tagName == "A") {
        let url = e.target.getAttribute('href');
        this.$router.push({ path: url });
      }

    }
  },
  mounted() {
    //подписваемся на событие показа сообщения
    bus.on("OKRAINA_MESSAGE_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("OKRAINA_MESSAGE_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.okraina-message {
  position: fixed;
  bottom: -100px;
  right: 20px;
  left: 20px;
  padding: 10px 15px;
  text-align: left;
  z-index: 8500;
  font-size: 12px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: var(--safe-area-bottom);

  opacity: 0;
  visibility: hidden;

  transition: bottom 0.2s ease-out, opacity 0.2s ease-out, visibility 0s linear 200ms;

  &.show {
    bottom: 65px;
    opacity: 1;
    visibility: visible;
    transition: bottom 0.2s ease-out, opacity 0.2s ease-out, visibility 0s linear;
  }

  &.success {
    background-color: var(--color-message-success-background);
    color: var(--color-message-success-text);
  }

  &.error {
    background-color: var(--color-message-error-background);
    color: var(--color-message-error-text);
  }
}
</style>