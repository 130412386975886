const templates =  {
    "shop.okraina.ru": {
        title: "Каталог | Хиты продаж",
        tags: [
            {
                name: "description",
                content: "Большой выбор колбасы в интернет-магазине «Окраина». Бесплатная доставка, выгодные цены, натуральные и качественные мясные изделия, произведенные в день доставки заказа! Скидки постоянным покупателям!",
            }
        ]
    },
    "dostavka.okraina.ru": {
        title: "Каталог | Хиты продаж",
        tags: [
            
        ]
    },
}

export default templates;