<template>
    <div class="okraina-css">
        <okraina-tabs-scroll v-if="tabs.length > 0" :items="tabs" v-model="selected" />
    </div>
</template>

<script>

import okrainaTabsScroll from "@/components/tabs.scroll.vue";

export default {
    name: "okraina-catalog-sub-sections",
    components: {
        okrainaTabsScroll
    },
    data() {
        return {
            selected: this.current ? this.current.ID : 0
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        current: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        selected: function () {
            this.goToSelected();
        },
    },
    computed: {

        /**
         * Расширенный список подразделоу - включая текущий как  "Все"
         */
        itemsExt() {
            //берём копию
            let items = this.items.slice();

            if (items.length == 0) {
                return items;
            }

            //если текущего раздела нет в списке
            let i = this.items.find((item) => item.ID == this.current.ID);
            if (!i) {
                //то добавим его как "Все" в начало списка
                items.unshift({
                    ID: this.current.ID,
                    NAME: "Все",
                    SECTION_PAGE_URL: this.current.SECTION_PAGE_URL
                });
            }
            //иначе есть, но тогда нужно включить вышестоящий раздел
            else if (this.current.IBLOCK_SECTION_ID > 0) {
                //то добавим его как "Все" в начало списка
                //items.unshift({
                //    ID: this.current.IBLOCK_SECTION_ID,
                //    NAME: "Все",
                //    SECTION_PAGE_URL: this.current.PARENT_SECTION_PAGE_URL
                // });
            }
            return items;
        },

        tabs() {

            let tabs = this.itemsExt.map(item => {
                return {
                    id: item.ID,
                    name: item.UF_APP_SHORT_NAME ? item.UF_APP_SHORT_NAME : item.NAME,
                    url: item.SECTION_PAGE_URL
                }
            });

            return tabs;


        }
    },

    methods: {

        goToSelected() {
            let item = this.items.find((i) => i.ID == this.selected);
            if (!item) {
                return;
            }

            //если клик по текущему пункту меню
            if (this.current.ID == item.ID) {
                return;
            }

            //возвращаем обратно выбор к текущему, чтобы возврат по Back показал нам предыдущий выбранный раздел
            this.selected = this.current.ID;

            //если ткущий раздел присутствует в списке
            let curr = this.items.find((i) => i.ID == this.current.ID);
            if (curr) {
                //то заменяем в истории текущий URL
                this.$router.replace({ path: item.SECTION_PAGE_URL });

            } else {
                //иначе "проваливаемся в подраздел"
                this.$router.push({ path: item.SECTION_PAGE_URL });
            }

        },

    },

};
</script>


<style lang="scss">
.okraina-css {

    margin-bottom: 15px;

    .items {
        margin: 0px -8px;
        padding-left: 8px;
        overflow-x: auto;
        display: flex;
        box-sizing: content-box;
        min-height: 27px; //пока подгружается чтобы высота не скакала

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }


        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            flex-shrink: 0;
            margin-right: 8px;
            white-space: nowrap;

            background-color: var(--color-block-background);
            padding: 4px 10px;
            border-radius: 16px;
            color: var(--color-brand-dark);
            border-bottom: 0px;

            &.current {
                background-color: var(--color-color6);
            }

        }
    }


}
</style>