<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-payment-change">
                <div class="sheet-title">Выберите способ оплаты</div>

                <!--Загружка-->
                <template v-if="loading">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>
                </template>

                <template v-else>

                    <div class="items">
                        <div class="item" v-for="item in itemsInternal" :key="item.ID">
                            <okraina-radio :label="item.PSA_NAME" :value="item.ID" v-model="value"
                                :description="item.DESCRIPTION" />
                        </div>

                    </div>

                    <div class="btn btn-block btn1" :class="[saving ? 'wait' : '']" @click="apply">
                        Поменять
                        <div v-if="saving" class="btn-loader"></div>
                    </div>

                </template>


            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaRadio from "@/components/controls/radio.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-order-payment-change",
    components: {
        okrainaSheet,
        okrainaLoaderInline,
        okrainaRadio
    },
    emits: ["update:visible", "change"],
    data() {
        return {
            visibleInternal: this.visible,
            value: this.paymentId,
            sperPayAvailable: true,

            loading: false,
            saving: false,
            items: []
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        orderId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        paymentId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },


    },

    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings",
            isNative: "isNativePlatform",
        }),

        itemsInternal() {

            let list = [];
            this.items.forEach(item => {

                //оплата SberPay
                if (item.PSA_ACTION_FILE == "sberpay") {
                    //доступна ли эта оплата
                    if (!this.sperPayAvailable) {
                        return;
                    }

                }

                list.push(item)
            });

            return list;
        }

    },

    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.value = this.paymentId;
                this.loadData();
            }
        },
    },

    methods: {

        loadData() {
            this.loading = true;

            //получаем список доступных способов оплаты
            rest
                .call("order.availablepayments", {
                    method: "post",
                    data: {
                        ID: this.orderId,
                    },
                })
                .then((data) => {
                    //если ошибка входа
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        apply() {
            if (this.saving) {
                return;
            }
            this.saving = true;

            rest
                .call("order.changepayment", {
                    method: "post",
                    data: {
                        ID: this.orderId,
                        PAYMENT_ID: this.value
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.visibleInternal = false;
                        this.$emit("change");
                    } else {

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        }
    },

    created() {

        if (this.isNative) {
            //проверяем доступность мобильного приложения Сбера
            bus.emit("OKRAINA_APP_E_PAYMENT_SBERPAY_AVAILABLE", {
                callback: (result) => {
                    this.sperPayAvailable = result.ready;
                }
            });
        }


    }
}

</script>

<style lang="scss">
.okraina-order-payment-change {

    .items {
        margin-bottom: 30px;
    }

    .item {
        margin-bottom: 15px;
    }

    .okraina-radio {
        .message {
            font-weight: 600;
        }

        .description {
            color: var(--color-text-dark);
            font-weight: 500;
            margin-top: 5px;

        }
    }
}
</style>
