<template>
    <div class="okraina-p-v">

        <div class="photo" v-if="result.SITE_PRODUCT">
            <okraina-catalog-product-photo-slider :item="result.SITE_PRODUCT" />

            <div class="stickers">
                <okraina-catalog-product-stickers :item="result.SITE_PRODUCT" />
            </div>

        </div>

        <div class="inner">

            <div class="link-wrap text-center" v-if="result.SITE_PRODUCT">
                <a class="btn btn1" :href="'/catalog/product/' + result.SITE_PRODUCT.FIELDS.CODE + '/'"
                    @click.prevent="goToProduct">Перейти к
                    товару</a>
            </div>

            <h1 class="name" v-html="productName"></h1>

            <div class="pic">
                PIC {{ result.UPC.UPC }}
            </div>

            <div class="info">

                <div class="prop" v-if="result.UPC.DATE_PACK">
                    <div class="title">Дата производства</div>
                    <div class="value">{{ result.UPC.DATE_PACK }}</div>
                </div>

                <div class="prop" v-if="result.UPC.DATE_SALE">
                    <div class="title">Дата отгрузки продукта с предприятия</div>
                    <div class="value">{{ result.UPC.DATE_SALE }}</div>
                </div>

                <div class="prop" v-if="result.UPC.DATE_DROP">
                    <div class="title">Срок годности</div>
                    <div class="value">{{ result.UPC.DATE_DROP }}</div>
                </div>

                <div class="prop" v-if="result.SITE_PRODUCT && result.SITE_PRODUCT.PROPS.PIC_SOCIAL_PROGRAMS">
                    <div class="name">Социальные программы</div>
                    <div class="value">{{ result.SITE_PRODUCT.PROPS.PIC_SOCIAL_PROGRAMS }}</div>
                </div>

                <div class="prop" v-if="result.SITE_PRODUCT && result.SITE_PRODUCT.PROPS.PIC_RECOMMENDATIONS">
                    <div class="title">Рекомендации по приготовлению</div>
                    <div class="value">{{ result.SITE_PRODUCT.PROPS.PIC_RECOMMENDATIONS }}</div>
                </div>

                <div class="prop" v-if="result.DEALER">
                    <div class="title">Магазин, в который поставлен продукт</div>
                    <div class="value">
                        {{ result.DEALER.TITLE_FULL }}
                        <template v-if="result.DEALER.ADDRESS">
                            <br />
                            {{ result.DEALER.ADDRESS }}
                        </template>
                        <template v-if="result.DEALER.METRO">
                            <br />
                            Метро
                            {{ result.DEALER.METRO }}
                        </template>

                    </div>
                </div>
            </div>

            <div class="nut-value" v-if="canShowNut">
                <okraina-catalog-product-nutritional-value :item="result.SITE_PRODUCT" />
            </div>

            <div class="info">

                <div class="prop" v-if="result.PRODUCT && result.PRODUCT.COMPONENTS">
                    <div class="title">Состав</div>
                    <div class="value">{{ result.PRODUCT.COMPONENTS }}</div>
                </div>

                <div class="prop" v-if="result.SITE_PRODUCT && result.SITE_PRODUCT.PROPS.SROK_HRAN">
                    <div class="title">Срок хранения</div>
                    <div class="value" v-html="result.SITE_PRODUCT.PROPS.SROK_HRAN"></div>
                </div>

            </div>

            <div class="description prop" v-if="result.SITE_PRODUCT && result.SITE_PRODUCT.FIELDS.DETAIL_TEXT">
                <div class="title">Описание</div>
                <div class="value" v-html="result.SITE_PRODUCT.FIELDS.DETAIL_TEXT"></div>
            </div>

            <div class="btn-wrap text-center">
                <div class="btn btn1" v-ripple @click="onRateClick">Оценить
                    продукт</div>
            </div>



        </div>

    </div>

    <okraina-sheet v-model:visible="showRate" :maxHeight="92">
        <template v-slot:body>
            <okraina-web-form :id="webFormId" :sheetMode="true" :extData="webFormExtData"
                :extQuestions="webFormExtQuestions"
                :profileFields="{ NAME: 'NAME', EMAIL: 'EMAIL', PHONE: 'PERSONAL_PHONE' }" v-model:values="webFormValues" />
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaCatalogProductPhotoSlider from "@/components/catalog/product.photo.slider.vue";
import okrainaCatalogProductStickers from "@/components/catalog/product.stickers.vue";
import okrainaCatalogProductNutritionalValue from "@/components/catalog/product.nutritional.value.vue";

import okrainaWebForm from "@/components/web.form.vue"

import { mapGetters } from "vuex";

export default {
    name: "okraina-pic-view",
    emits: ["goToProduct"],
    components: {
        okrainaSheet,
        okrainaCatalogProductPhotoSlider,
        okrainaCatalogProductStickers,
        okrainaCatalogProductNutritionalValue,
        okrainaWebForm

    },
    data() {
        return {
            showRate: false,
            webFormValues: {}
        };
    },
    props: {
        result: {
            type: [Object, Boolean],
            default() {
                return false;
            },
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        productName() {
            if (this.result && this.result.SITE_PRODUCT) {
                return this.result.SITE_PRODUCT.FIELDS.NAME;
            } else if (this.result && this.result.PRODUCT) {
                return this.result.PRODUCT.NAME;
            }

            return "";
        },

        canShowNut() {
            if (!this.result || !this.result.SITE_PRODUCT) {
                return false;
            }

            if (this.result.SITE_PRODUCT.PROPS.CALORIUM > 0 || this.result.SITE_PRODUCT.PROPS.PROTEINS > 0 ||
                this.result.SITE_PRODUCT.PROPS.FAT > 0 || this.result.SITE_PRODUCT.PROPS.CARBOHYDRATE > 0) {
                return true;
            }

            return false;
        },

        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_RATE_PRODUCT : "";
        },

        webFormExtData() {
            return {
                PRODUCT_ID: this.result.PRODUCT ? this.result.PRODUCT.ID : "",
                PRODUCT_CODE: this.result.PRODUCT ? this.result.PRODUCT.CODE : "",
                PRODUCT_NAME: this.result.SITE_PRODUCT ? this.result.SITE_PRODUCT.FIELDS.NAME : (this.result.PRODUCT ? this.result.PRODUCT.NAME : ""),
                UPC: this.result.UPC ? this.result.UPC.UPC : "",
                RATING: this.webFormValues.RATING ? this.webFormValues.RATING : "",
            }
        },

        webFormExtQuestions() {
            return [
                {
                    ID: "RATING",
                    TITLE: "Ваша оценка",
                    SID: "RATING",
                    REQUIRED: "Y",
                    ANSWERS: [
                        {
                            ID: "rating_0",
                            MESSAGE: "",
                            FIELD_TYPE: "stars",
                            FIELD_NAME: "RATING",
                            VALUES: [
                                {
                                    ID: 1,
                                    MESSAGE: "1",
                                    VALUE: "1"
                                },
                                {
                                    ID: 2,
                                    MESSAGE: "2",
                                    VALUE: "2"
                                },
                                {
                                    ID: 3,
                                    MESSAGE: "3",
                                    VALUE: "3"
                                },
                                {
                                    ID: 4,
                                    MESSAGE: "4",
                                    VALUE: "4"
                                },
                                {
                                    ID: 5,
                                    MESSAGE: "5",
                                    VALUE: "5"
                                },

                            ]
                        }
                    ]
                }
            ]
        }
    },

    methods: {

        /**
         * Клик по оценить продукт
         */
        onRateClick() {
            this.showRate = true;
        },

        goToProduct() {
            this.$emit("goToProduct", this.result.SITE_PRODUCT);
        }

    },


}
</script>

<style lang="scss">
.okraina-p-v {

    .photo {
        position: relative;
        margin: 0px -8px 0px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05), 0px 0px 0px #fff;
        border-radius: 0px 0px 20px 20px;
        background-color: var(--color-block-background);

        .stickers {
            position: absolute;
            z-index: 10;
            top: 60px;
            right: 16px;
        }
    }

    .inner {
        padding: 24px 16px;
    }

    .name {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .pic {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 25px;
        padding: 20px 24px;
        font-weight: 600;
        font-size: 18px;
        background-color: var(--color-view-background);
        color: var(--color-text-title);
    }

    .info {
        margin-left: -24px;
        margin-right: -24px;
        padding: 0px 24px;

        .prop {
            margin-bottom: 20px;

            .title {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 8px;
                color: var(--color-text-title);
            }

            .value {
                font-size: 12px;
                color: rgba(var(--color-text-title-rgb), 0.7);
            }
        }
    }

    .nut-value,
    .description {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 20px;
        background-color: var(--color-view-background);
        padding: 20px 24px;
    }

    .btn-wrap {
        margin: 10px 0px;

        .btn {
            min-width: 270px;
        }
    }

    .link-wrap {
        margin: 0px 0px 20px;

        .btn {
            min-width: 270px;
        }
    }
}
</style>