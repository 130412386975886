<template>
    <okraina-sheet v-model:visible="visibleInternal" class="okraina-c-ram-wrap" :maxHeight="92">

        <template v-slot:body>
            <div class="okraina-c-ram">

                <okraina-web-form :id="webFormId" :showTitle="false" labelClass="h2"
                    :profileFields="{ NAME: 'NAME', EMAIL: 'EMAIL', PHONE: 'PERSONAL_PHONE' }">
                    <template v-slot:success>

                        <div class="success text-center">
                            <div class="title">Спасибо, <br /> Ваш отзыв отправлен</div>

                            <div class="stars">
                                <div class="star" v-for="index in 5" :key="index">
                                    <span class="fi fi-star"></span>
                                </div>
                            </div>

                            <div class="description">
                                Мы опубликоуем его после <br /> проверки модератором
                            </div>
                        </div>
                    </template>
                </okraina-web-form>

            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaWebForm from "@/components/web.form.vue"
import okrainaSheet from "@/components/sheet.vue"

import { mapGetters } from "vuex";

export default {
    name: "okraina-clients-review-add-modal",
    components: {
        okrainaSheet,
        okrainaWebForm
    },
    emits: ["update:visible"],
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false
        };
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
    },
    computed: {
        ...mapGetters({
            platform: "getPlatform",
            siteSettings: "getSiteSettings",
        }),
        webFormId() {
            return this.siteSettings ? this.siteSettings.FORM_REVIEWS : "";
        },
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        }
    },

    methods: {

        /**
         * Обработка открытия
         */
        onOpen() {

            this.watchVisible = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?sheetOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },

        /**
         * клик по иконке "Закрыть"
         */
        close() {
            this.visibleInternal = false;
        },


    }
}

</script>

<style lang="scss">
.okraina-c-ram {

    .success {
        .title {
            font-size: 18px;
            font-weight: 600;
            color: var(-color-text-title);
        }

        .stars {
            margin-top: 20px;
            margin-bottom: 20px;
            display: inline-block;

            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .star {
            float: left;
            margin-right: 8px;
            font-size: 22px;
            color: var(--color-icon-star);
        }

        .description {
            font-size: 13px;
        }
    }



}
</style>
