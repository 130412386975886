
import { Geolocation } from '@capacitor/geolocation';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

import bus from '@/plugins/bus'
import errorListiner from '@/plugins/error.listiner'

export default {

    init() {
        bus.on('OKRAINA_GEO_E_GET_LOCTION', (params) => {
            this.onGetLocation(params)
        });
    },

    /**
     * Запрос на полчение координат
     */
    async onGetLocation(params) {

        let status = await Geolocation.checkPermissions();

        //если разрешение не дано ни на точные координаты ни на приблизительные
        if(status.location != 'granted' &&  status.coarseLocation != 'granted') {

            try {
                //пробуем запросить доступ
                status = await Geolocation.requestPermissions({permissions: ['location','coarseLocation']});
            } catch(e) {
                //значит служба гео данных отключена и ничего с этим не сделать
                console.log(e);
                return;
            }

            //если доступ всё равно не дан
            if(status.location != 'granted' &&  status.coarseLocation != 'granted') {
                //запрашиваем разрешение через открытие экрана настроек приложения
                //ПОКА не будем принудильно пытаться увести пользователя в настройки приложения
                //так как пока функция определения местоположения нужно просто для того
                //чтобы понять правильно ли вообще определилили регион
                //this.permit();
            } else {
                this.getLocation(params);
            }
        } else {
            this.getLocation(params);
        }
    },

    /**
    * Спрашивает не открыть ли экран настроект разрешений прилоржения
    */
    async permit() {
        await new Promise((resolve) => {
            bus.emit("OKRAINA_CONFIRM_E_SHOW", {
                message: "У приложения нет разрешения на использование местоположения. Перейти в настройки разрешений приложения?",
                resolveText: "Да",
                rejectText: "Нет",
                resolve: function (value) {
                    if(value) {
                        BarcodeScanner.openAppSettings();
                    }
                    resolve(value);
                },
                reverse: false,
            });
        });
    },

    /**
     * Получает координаты
     */
    getLocation(params) {

        Geolocation.getCurrentPosition()
        .then((coordinates) => {
            if (params.resolve) {
                params.resolve(coordinates);
            }
        }).catch((e) => {
            errorListiner.onError(e);
            console.log(e);
        });

    }

}