<template>
    <div class="okraina-sber-tips">

        <div class="text-center form-title">Чаевые курьеру</div>

        <template v-if="!success && !fail && !waitPayment">
            <okraina-control-list-line name="Сумма чаевых" :list="values" v-model="type" :required="true"
                v-model:error="errors.type" v-model:valid="valids.type" />

            <okraina-control-input v-show="showValue" label="Введите сумму" type="tel" :mask="{ mask: '9{+}' }"
                :list="values" v-model="value" :required="true" v-model:error="errors.value"
                v-model:valid="valids.value" />



            <div class="btn btn1 btn-block" :class="{ wait: wait }" @click="register" v-ripple>
                Оплатить
                <div v-if="wait" class="btn-loader"></div>
            </div>
        </template>

        <template v-else-if="waitPayment">
            <div class="illustration-wrap">
                <okraina-illustration :picture="imageTimer" caption="Ожидаем оплату ..." />
            </div>
        </template>

        <template v-else-if="success">
            <div class="illustration-wrap">
                <okraina-illustration :picture="imageSuccess" caption="Оплата успешно произведена." />
            </div>
        </template>

        <template v-else-if="fail">
            <div class="illustration-wrap">
                <okraina-illustration :picture="imageError" caption="Произошла ошибка при проведении оплаты." />
            </div>
        </template>



    </div>
</template>

<script>
import okrainaControlListLine from "@/components/controls/list.line.vue"
import okrainaControlInput from "@/components/controls/input.vue"
import okrainaIllustration from "@/components/illustration.vue";

import imageSuccessPath from "@/assets/images/success.svg";
import imageErrorPath from "@/assets/images/error.svg";
import imageTimerPath from "@/assets/images/timer.svg";

import tools from "@/plugins/tools";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

export default {
    name: "okraina-sber-tips",
    components: {
        okrainaControlListLine,
        okrainaControlInput,
        okrainaIllustration
    },
    data() {
        return {
            wait: false,
            value: "",
            type: "",
            showValue: false,
            errors: {
                type: false,
                value: false,
            },
            valids: {
                type: true,
                value: true,
            },
            timer: 0,

            success: false,
            fail: false,
            waitPayment: false,

            imageError: imageErrorPath,
            imageSuccess: imageSuccessPath,
            imageTimer: imageTimerPath
        };
    },

    watch: {
        type: function () {
            if (this.type == 'other') {
                this.showValue = true;
            } else {
                this.showValue = false;
                this.value = this.type
            }
        }
    },

    props: {
        orderId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        min: {
            type: Number,
            default() {
                return 0;
            },
        },
        max: {
            type: Number,
            default() {
                return 0;
            },
        },
    },

    computed: {
        ...mapGetters({
            isNative: "isNativePlatform"
        }),

        values() {
            let list = [];


            for (let i = 0; i < 3; i++) {
                list.push({
                    ID: this.min + i * 50,
                    NAME: tools.formatPrice(this.min + i * 50, { includeDecimals: false })
                });
            }

            list.push({
                ID: "other",
                NAME: "другая"
            });

            return list;
        }
    },

    methods: {


        /**
         * Регистрирует заказ в Сберчаевых
         */
        register() {

            if (!this.checkValues()) {
                return;
            }

            if (this.wait) {
                return;
            }
            this.wait = true;

            rest
                .call("order.sber.tips.register", {
                    method: "post",
                    data: {
                        ORDER_ID: this.orderId,
                        SUM: this.value
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.openPayment(data);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.wait = false;
                    }
                });

        },

        openPayment(response) {

            this.waitPayment = true;

            this.startStatusTimer();

            if (this.isNative) {
                InAppBrowser.create(response.formUrl, "_system");
            } else {
                window.open(response.formUrl);
            }
        },

        /**
         * Проверяет значения
         */
        checkValues() {

            Object.keys(this.valids).forEach((field) => {
                this.errors[field] = !this.valids[field];
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Запускает таймер проверки статуса платежа
         */
        startStatusTimer() {

            if (this.timer > 0) {
                clearInterval(this.timer);
            }

            this.timer = setInterval(() => {
                this.checkStatus();
            }, 5 * 1000)
        },

        /**
         * Проверка статуса платежа
         */
        checkStatus() {

            rest
                .call("order.sber.tips.status", {
                    method: "post",
                    data: {
                        ORDER_ID: this.orderId,
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        if (data.transferStatus == "COMPLETED") {
                            this.success = true;
                            this.waitPayment = false;
                            clearInterval(this.timer);
                        } else if (data.transferStatus != "RUNNING") {
                            this.fail = true;
                            this.waitPayment = false;
                            clearInterval(this.timer);
                        }

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                });
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            return {
                "component": this.$options.name,
                "data": this.$data
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        },

    },

    /**
     * Обработка создания компонента
     */
    created() {

        //восстановление данных
        this.restoreData();

        //если восстановились и экран находился в режиме ожижания статуса
        if (this.waitPayment) {
            this.startStatusTimer();
        }
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {

        if (this.timer > 0) {
            clearInterval(this.timer);
        }

        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);

    }

}

</script>

<style lang="scss">
.okraina-sber-tips {
    .form-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .illustration-wrap {
        height: 300px;
        position: relative;
    }
}
</style>