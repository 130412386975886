<template>
    <okraina-view :class="'okraina-v-pit'" :refreshDisabled="true" :hideMenu="true">
        <template v-slot:header>
            <h1>
                Условия программы
            </h1>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <div class="iframe-wrap">
                <iframe :src="origin + '/rest/pages/invite/terms/'" :class="{ hidden: !canShow }" ref="frame" />
            </div>

        </template>
    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue";

import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/personal.invite.terms.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-personal-invite-terms",
    components: {
        okrainaView,
        okrainaLoaderPage
    },
    data() {
        return {
            loading: true,
            canShow: false
        };
    },
    computed: {
        ...mapGetters({
            server: "getServer"
        }),

        origin() {
            return 'https://' + this.server;
        }
    },

    methods: {

    },

    created() {
        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    mounted() {
        //подписываемся на завершение загрузки IFRAME
        this.$refs.frame.onload = () => {
            this.loading = false;
        }

    },


    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },
}
</script>

<style lang="scss">
.okraina-v-pit {

    iframe-wrap {
        position: absolute;
        top: 60px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 0px;
    }
}
</style>