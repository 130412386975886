<template>
    <okraina-view :class="'okraina-v-aa'" @refresh="refresh" ref="view" :hideMenu="true">

        <template v-slot:header>
            <h1>
                Наши награды
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">
                <okraina-illustration :picture="imageError" caption="Ошибка загрузки данных.">
                    <div class="btn btn1 slim" @click="retryLoad">
                        Повторить
                    </div>
                </okraina-illustration>
            </template>

            <template v-if="!loading && !loadingError && canShow">

                <template v-for="section in sections" :key="section.ID">
                    <h2>{{ section.NAME }}</h2>

                    <div class="row">
                        <div class="col-6 col-sm-4 col-md-2 item-wrap" v-for="item in itemsBySections['s' + section.ID]"
                            :key="item.FIELDS.ID">
                            <div class="item" @click="onItemClick(item)">
                                <div class="picture preloaded" v-if="item.FIELDS.PICTURE"
                                    v-lazy:background-image="item.FIELDS.PICTURE.PREVIEW.SRC">

                                    <div class="loader">
                                        <okraina-loader-inline />
                                    </div>
                                </div>
                                <div class="inner">
                                    <div class="name" v-html="item.FIELDS.NAME"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </template>

        </template>

    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue"
import okrainaLoaderPage from "@/components/loader.page.vue"
import okrainaIllustration from "@/components/illustration.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from '@/plugins/tools.js'

import imageErrorPath from "@/assets/images/error.svg";

export default {
    name: "okraina-v-about-awards",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaIllustration,
        okrainaLoaderInline
    },
    data() {
        return {
            loading: true,
            loadingError: false,
            items: [],
            sections: [],
            canShow: false,
            showForm: false,

            imageError: imageErrorPath
        };
    },
    computed: {

        /**
         * Награды по разделам
         */
        itemsBySections() {
            let list = {};

            this.sections.forEach(section => {
                //пришлось добавить s, иначе gallery собирается в другом порядке
                list["s" + section.ID] = this.items.filter(item => item.FIELDS.IBLOCK_SECTION_ID == section.ID);
            })

            return list;
        },

        /**
         * список фото для отображения в просмотре
         */
        gallery() {

            let images = [];

            for (var id in this.itemsBySections) {
                let items = this.itemsBySections[id];
                items.forEach(item => {
                    if (item.FIELDS.PICTURE) {
                        images.push({
                            caption: item.FIELDS.NAME,
                            src: item.FIELDS.PICTURE.FULL.SRC,
                            type: "image",
                            width: item.FIELDS.PICTURE.FULL.WIDTH,
                            height: item.FIELDS.PICTURE.FULL.HEIGHT,
                            id: item.FIELDS.ID,
                        });
                    }

                })
            }

            return images;
        },
    },
    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("company.awards", {
                    method: "post",
                    data: {
                        RESIZE_OPTIONS: {
                            "PREVIEW": {
                                "WIDTH": 210,
                                "HEIGHT": 300,
                                "TYPE": "PROPORTIONAL"
                            },
                            "FULL": {
                                "WIDTH": 1200,
                                "HEIGHT": 1200,
                                "TYPE": "PROPORTIONAL"
                            }
                        }
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                        this.sections = data.SECTIONS;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Открыть фото 
         */
        onItemClick(item) {
            let start = this.gallery.findIndex((i) => i.id == item.FIELDS.ID);
            this.openPhoto(start);
        },

        /**
         * Открыть фотона просмотр
         */
        openPhoto(start) {

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 1,
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "close",
                    ],
                },
                Thumbs: {
                    autoStart: false,
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        tools.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        tools.fancyBoxOnClose();
                    },

                },
            });
        },


    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        //meta.write(metaTemplates);
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-aa {

    .item-wrap {
        margin-bottom: 16px;
    }

    .item {
        background-color: var(--color-block-background);
        overflow: hidden;
        border-radius: 5px;
        min-height: 100%;

        .picture {
            height: 0px;
            padding-bottom: 140%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .inner {
            padding: 12px 16px 12px 16px;
        }

        .name {
            font-weight: 400;
            font-size: 11px;
            color: var(--color-text-title);
            line-height: 1.1;
        }
    }

}
</style>