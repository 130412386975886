<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-order-repeat">
                Все товары из этого заказа вновь отправятся в корзину
                <div class="btn btn-block btn3" :class="this.loading ? 'wait' : ''" @click="apply">
                    Повторить
                    <div v-if="this.loading" class="btn-loader"></div>
                </div>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "okraina-order-address",
    components: {
        okrainaSheet,
    },
    emits: ["update:visible"],

    data() {
        return {
            visibleInternal: this.visible,
            loading: false,
        }
    },

    props: {
        id: {
            type: String,
            default() {
                return null;
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    watch: {
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
        },
    },

    methods: {
        /**
         * Повторяем заказ
         */
        apply() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            rest
                .call("order.repeat", {
                    method: "post",
                    data: {
                        ID: this.id,
                    },
                })
                .then((data) => {
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        this.visibleInternal = false;

                        //перезагрузим все данные пользователя (в т.ч. корзина)
                        this.$store.dispatch("loadUserData");

                        //нужно чуть времени, чтобы компонент успел закрыться
                        setTimeout(() => {
                            this.$router.push({ path: '/personal/cart/' });
                        }, 1000)

                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }

    },
}

</script>

<style lang="scss">
.okraina-order-repeat {
    .btn-block {
        margin-top: 30px;
    }
}
</style>