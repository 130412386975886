<template>
  <div class="okraina-cp-nv">
    <h2>На 100 г продукта</h2>
    <div class="values">

      <div v-if="item.PROPS.CALORIUM">
        <div class="label">Калории</div>
        <span class="val">{{ item.PROPS.CALORIUM }}
        </span>
        <span class="measure">ккал</span>
      </div>

      <div v-if="item.PROPS.PROTEINS">
        <div class="label">Белки</div>
        <span class="val">{{ item.PROPS.PROTEINS }}
        </span>
        <span class="measure">г</span>
      </div>

      <div v-if="item.PROPS.FAT">
        <div class="label">Жиры</div>
        <span class="val">{{ item.PROPS.FAT }}
        </span>
        <span class="measure">г</span>
      </div>

      <div v-if="item.PROPS.CARBOHYDRATE">
        <div class="label">Углеводы</div>
        <span class="val">{{ item.PROPS.CARBOHYDRATE }}
        </span>
        <span class="measure">г</span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "okraina-catalog-product-nutritional-value",
  components: {},
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    visible() {
      return this.item.PROPS.CALORIUM > 0 || this.item.PROPS.PROTEINS > 0 || this.item.PROPS.FAT > 0 || this.item.PROPS.CARBOHYDRATE > 0;
    }
  },

  methods: {},

  created() { },
};
</script>

<style lang="scss">
.okraina-cp-nv {

  .values {
    display: grid;
    /*grid-auto-flow: column;*/
    grid-template-columns: 0.33fr 0.33fr 0.33fr 0.33fr;

    .label {
      color: rgba(var(--color-text-title-rgb), 0.7);
      font-size: 11px;
      margin-bottom: 5px;
    }

    .val {
      color: rgba(var(--color-text-title-rgb), 0.7);
      font-size: 18px;
      font-weight: 500;
    }

    .measure {
      margin-left: 5px;
      display: inline-block;
      font-size: 11px;
    }
  }
}
</style>
