

import './style.scss';


export default {
    ripple: false,
    beforeMount(el) {

        function ripper(el) {

            this.ripper = false;
            this.timer = 0;
            let _this = this;

            this.init = function() {
                this.attach(el);
                el.addEventListener("touchstart", function () {
                    _this.show();
                });
            },

            this.attach = function(el){
                this.ripple = document.createElement("div");
                this.ripple.className = 'ripper';
                el.appendChild(this.ripple);

                this.ripple.addEventListener("touchstart", function () {
                    _this.show();
                });
                
            },

            this.show = function() {

                if(this.timer > 0) {
                    clearTimeout(this.timer);
                    this.ripple.className = 'ripper';
                    setTimeout(() => {
                        this.ripple.className = 'ripper visible';
                    },10); 
                } else {
                    this.ripple.className = 'ripper visible';
                }
                this.timer = setTimeout(() => {
                    this.ripple.className = 'ripper';
                    this.timer = 0;
                },1000); 
            }

            return this;

        }

        let r= new ripper(el);
        r.init();
    },
}

