<template>
    <okraina-view :class="['okraina-v-pic-qr']" :refreshDisabled="true" ref="view" :hideMenu="true">

        <template v-slot:header>
            <div class="title"> Узнать информацию о продукте </div>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <template v-if="!loading">

                <template v-if="needCaptcha">
                    <div class="captcha-form">
                        <okraina-control-input label="" placeholder="Код на картинке" v-model="captchaWord"
                            :captcha="captchaImg" />

                        <div class="btn btn1 btn-block" @click="onCheckClick" :class="btnLoading ? 'wait' : ''" v-ripple>
                            Повторить
                            <div v-if="btnLoading" class="btn-loader"></div>
                        </div>
                    </div>


                </template>
                <template v-else>
                    <okraina-pic-view v-if="result" :result="result" @goToProduct="goToProduct" />
                </template>



            </template>

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaPicView from "@/components/pic/view.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";


export default {
    name: "okraina-v-pic-qr",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaControlInput,
        okrainaPicView

    },
    data() {
        return {
            loading: false,
            btnLoading: false,
            needCaptcha: false,
            captchaSid: "",
            captchaImg: "",
            captchaWord: "",
            result: false,
        };
    },

    props: {
        code: {
            type: String,
            default() {
                return "";
            },
        },
    },

    methods: {

        check(loading = true) {

            if (this.loading || this.btnLoading) {
                return;
            }
            this.loading = loading;
            this.btnLoading = !loading;

            rest
                .call("upc.getbycode", {
                    method: "post",
                    data: {
                        CODE: this.code,
                        RESIZE_OPTIONS: tools.getProductDetailResizeOptions(),
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid,
                    },
                })
                .then((data) => {
                    //если ошибка
                    if (!data.SUCCESS) {
                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.needCaptcha = true;
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaImg = data.CAPTCHA_URL;
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {

                        this.result = data;
                        this.needCaptcha = false;
                        //если товар на сайте есть
                        if (this.result.SITE_PRODUCT) {
                            //подымаем карточку товара для просмотра
                            bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                                id: this.result.SITE_PRODUCT.FIELDS.ID,
                                code: this.result.SITE_PRODUCT.FIELDS.CODE
                            });
                            //редиректим пользователя на экран товара
                            //this.$router.replace({ path: "/catalog/product/" + this.result.SITE_PRODUCT.FIELDS.CODE + "/" });
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.btnLoading = false;
                });

        },

        onCheckClick() {
            this.check(false);
        },

        goToProduct(product) {
            bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                id: product.FIELDS.ID,
                code: product.FIELDS.CODE
            });
        }
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.check();
        });
    }

}
</script>

<style lang="scss">
.okraina-v-pic-qr {
    &.okraina-view {
        .view-outer {
            background-color: var(--color-block-background);
        }
    }

    .captcha-form {
        margin: 30px -8px 0px;
        background-color: var(--color-view-background);
        padding: 20px 24px;

        .form-control-wrap {
            box-shadow: inset 1px 2px 7px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>